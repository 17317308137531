import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  Device,
  Devices,
  DeviceType,
  DeviceTypes,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import { differenceWith as _differenceWith } from 'lodash';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setStateDevices } from '../../../redux/reducers/devices.reducer';
import {
  setOptionsDeviceTypes,
  setStateDeviceTypes,
} from '../../../redux/reducers/deviceTypes.reducer';
import {
  setSorting,
  setViewOption,
} from '../../../redux/reducers/filters.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import Header from '../../../containers/HeaderWithActionButton';
import ListSelection from '../../../components/ListSelection';
import Card from '../../../components/Card';
import CardsGrid from '../../../components/CardsGrid';
import SharedTable, {
  TableItemType,
  TableSubItemsType,
} from '../../../components/SharedTable';
import ActionDialog from '../../../components/ActionDialog';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';
import { SortingOption } from '../../../models/common';
import getInitialSorting from '../../../helpers/getInitialSorting';
import parseFilters from '../../../helpers/parseFilters';
import { sortingOptions, viewsOptions } from '../constants';
import DevicesMetrics from '../DevicesMetrics';
import DevicesExtraActions from '../DevicesExtraActions';
import DevicesHeaderActions from '../DevicesHeaderActions';
import DevicesGenre from '../DevicesGenre';
import { DevicesColumns } from './columns';
import ActiveDevicesFilters from './ActiveDevicesFilters';
import DeviceCard from './ActiveDeviceCard';
import useStyles from '../styles';

const ActiveDevices: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const devicesState = useAppSelector((state: RootState) => state.devices);

  const [devices, setDevices] = useState<Device[]>([]);
  const [relatedDevices, setRelatedDevices] = useState<TableSubItemsType>();
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [deviceTypes, setDeviceTypes] = useState<DeviceType[]>(
    deviceTypesState.optionsDeviceTypes,
  );
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.devices.sortBy, sortingOptions),
  );
  const [selectedView, setSelectedView] = useState(filters.devices.view);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingSelectAll, setLoadingSelectAll] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const dispatchError = (errorMessage: string, highlight?: string): void => {
    dispatch(
      setAlert({
        highlight: highlight ?? errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const getDevices = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    Devices.list(parseFilters(filters.devices.filters ?? {}), pagination)
      .then((result) => {
        const newDevices = addPage
          ? [...devices, ...result.devices]
          : result.devices;
        setDevices(newDevices);
        dispatch(setStateDevices(newDevices));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  useEffect(() => {
    if (deviceTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          setDeviceTypes(result.deviceTypes);
          dispatch(setStateDeviceTypes(result.deviceTypes));
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    setSelectedDevices([]);
    getDevices(1);
  }, [filters.devices, devicesState.devicesGenre]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getDevices(page + 1, true);
  };

  const checkDeviceCallback =
    (deviceId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        const checkedDevice = devices.find((item) => item._id === deviceId);
        if (checkedDevice) {
          setSelectedDevices([...selectedDevices, checkedDevice]);
        }
      } else {
        setSelectedDevices(
          selectedDevices.filter((item) => item._id !== deviceId),
        );
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'devices'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'devices'));
    setSelectedView(view);
  };

  const handleSelectAll = (): void => {
    if (selectedDevices.length !== total) {
      if (devices.length === total) {
        // All devices are already in devices array
        setSelectedDevices(devices);
      } else {
        setLoadingSelectAll(true);
        // Get the total of devices by the filters, we already have the total number
        Devices.list(
          parseFilters(filters.devices.filters ?? {}),
          optionsPaginationsFilter,
        )
          .then((result) => {
            setSelectedDevices(result.devices);
          })
          .catch((error) => {
            dispatchError(error.message);
          })
          .finally(() => {
            setLoadingSelectAll(false);
          });
      }
    } else {
      setSelectedDevices([]);
    }
  };

  const handleImportSuccess = (): void => {
    getDevices(1);
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    const devicesIds = selectedDevices.map((item) => item._id);
    Devices.deleteMultiple(devicesIds)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(
              selectedDevices.length,
              'Device',
              'Devices',
            ),
            type: 'success',
          }),
        );
        setDevices(
          _differenceWith(
            devices,
            selectedDevices,
            (device, selectedDevice) => {
              return device._id === selectedDevice._id;
            },
          ),
        );
        setSelectedDevices([]);
        setTotal(total - selectedDevices.length);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoadingDelete(false);
        closeDeleteModal();
      });
  };

  const hasAttachedDevices = (item: TableItemType): boolean => {
    const device = item as Device;
    if (!device.attached_device_ids) {
      return false;
    }
    return device.attached_device_ids.length !== 0;
  };

  const getRelatedDevices = (item: TableItemType): void => {
    const device = item as Device;
    let result: TableSubItemsType = {};
    if (relatedDevices) {
      result = { ...relatedDevices };
    }
    if (!relatedDevices || !relatedDevices[item._id]) {
      // device.attached_device_ids in this case will absolutely have a value
      // as this action would never be triggered if it doesn't
      Devices.list(
        { _id: { operator: 'in', value: device.attached_device_ids ?? [] } },
        optionsPaginationsFilter,
      )
        .then((response) => {
          result[device._id] = response.devices;
          setRelatedDevices(result);
        })
        .catch((error) => {
          dispatchError(
            error.message,
            `Error getting related devices of: ${device.name}`,
          );
        });
    }
  };

  const removeSelectedDevice = (deviceId: string): void => {
    setSelectedDevices(selectedDevices.filter((item) => item._id !== deviceId));
  };

  const allSelected = devices.length !== 0 && selectedDevices.length === total;

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Devices"
        link="new-device"
        actionLabel="Add New Device"
        model={'device'}
        extraActions={
          <DevicesHeaderActions onImportSuccess={handleImportSuccess} />
        }
        nextTitleContent={<DevicesGenre />}
      />
      <ActiveDevicesFilters total={total} deviceTypes={deviceTypes} />
      <DevicesMetrics genre={devicesState.devicesGenre} />
      <ListSelection
        deleteAction={true}
        selectedLabel="Device"
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={sortingOptions}
        viewsOptions={viewsOptions}
        itemsSelected={selectedDevices.length !== 0}
        allSelected={allSelected}
        itemsSelectedCount={selectedDevices.length}
        loadingAllItems={loadingSelectAll}
        selectedActions={
          <DevicesExtraActions
            selectedDevices={selectedDevices}
            onRemoveDevice={removeSelectedDevice}
          />
        }
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && (
            <CardsGrid
              cards={devices.map((device) => (
                <Card
                  checked={selectedDevices.some(
                    (selectedDevice) => selectedDevice._id === device._id,
                  )}
                  checkboxCallback={checkDeviceCallback}
                  id={device._id}
                  baseLink="/device"
                  content={
                    <DeviceCard
                      device={device}
                      deviceType={deviceTypes.find(
                        (deviceType) =>
                          deviceType._id === device.device_type_id,
                      )}
                      searchText={filters.devices.filters?.search ?? undefined}
                    />
                  }
                />
              ))}
            />
          )}
          {selectedView === 'list' && (
            <SharedTable
              columns={DevicesColumns(deviceTypes, classes)}
              rows={devices}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={allSelected}
              loading={loading}
              selectedItemsIds={selectedDevices.map((item) => item._id)}
              hasActionColumn={true}
              subRows={relatedDevices}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkDeviceCallback}
              hasNestedTable={hasAttachedDevices}
              getRowSubItems={getRelatedDevices}
            />
          )}
          {selectedView === 'map' && <div>Map View</div>}
          {devices.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedDevices.length === 1 ? 'device' : 'devices'
            }:`}</span>
            <ul>
              {devices
                .filter((device) =>
                  selectedDevices.some(
                    (selectedDevice) => selectedDevice._id === device._id,
                  ),
                )
                .map((device) => (
                  <li key={device._id}>
                    {device.name} - Id: {device.unique_id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default ActiveDevices;
