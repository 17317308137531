import React, { Dispatch, useState } from 'react';
import { Typography, Grid, Box, Divider, Paper, Button } from '@mui/material';
import { Device, Devices, DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import useStyles from './styles';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
  setActualDevice,
  setNewDevice,
} from '../../../redux/reducers/devices.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight, LWM2M_TYPE } from '../../../app/constants';
import Tags from '../../../containers/Tags';
import IntegrationConfig from '../../../containers/IntegrationConfig';
import DeviceNetworkConfig from './deviceNetworkConfig';
import DeviceLogConfig from './deviceLogsConfig';
import DeviceActivities from './deviceActivities';
import DeviceLwm2mParametersDrawer from '../DeviceLwm2mParametersDrawer';
import DeviceForm from './deviceForm';

interface DeviceDetailsProps {
  deviceType?: DeviceType | undefined;
  heartbeatPeriod: number | undefined;
  setHeartbeatPeriod: Dispatch<React.SetStateAction<number | undefined>>;
  selectedHearbeatUnit: string;
  setSelectedHearbeatUnit: Dispatch<React.SetStateAction<string>>;
  handleOpenGatewayCommand: () => void;
}

const DeviceDetails: React.FC<DeviceDetailsProps> = ({
  deviceType,
  heartbeatPeriod,
  setHeartbeatPeriod,
  selectedHearbeatUnit,
  setSelectedHearbeatUnit,
  handleOpenGatewayCommand,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const originalDevice = useAppSelector(
    (state: RootState) => state.devices.device,
  );
  const [openLwm2mDrawer, setOpenLwm2mDrawer] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleInputChange = (
    prop: string,
    value: string | number | boolean,
  ): void => {
    switch (prop) {
      case 'heartbeat_period':
        return setHeartbeatPeriod(value as number);
      case 'heartbeatUnit':
        return setSelectedHearbeatUnit(value as string);
      case 'heartbeat_values':
        const heartbeatValues = editableDevice?.heartbeat_values;
        const newHeartbeatValues = !heartbeatValues
          ? [value]
          : heartbeatValues?.includes(value as string)
          ? heartbeatValues.filter((heartbeatValue) => heartbeatValue !== value)
          : [...heartbeatValues, value];
        return dispatch(
          setNewDevice({
            ...editableDevice,
            [prop]: newHeartbeatValues,
          } as Device),
        );
      case 'activated_at':
        return dispatch(
          setNewDevice({
            ...editableDevice,
            activated_at: value,
            deactivated_at: '',
          } as Device),
        );
      case 'deactivated_at':
        return dispatch(
          setNewDevice({
            ...editableDevice,
            activated_at: '',
            deactivated_at: value,
          } as Device),
        );
      default:
        dispatch(
          setNewDevice({
            ...editableDevice,
            [prop]: value,
          } as Device),
        );
    }
  };

  const handleTagsChange = (prop: string, tags: string[]): void => {
    dispatch(
      setNewDevice({
        ...editableDevice,
        [prop]: tags,
      } as Device),
    );
  };
  const handleLogConfigChange = (prop: string, value: string): void => {
    dispatch(
      setNewDevice({
        ...editableDevice,
        log_config: {
          ...(editableDevice?.log_config ?? {}),
          [prop]: value,
        },
      } as Device),
    );
  };

  const handleImportantMetadataChange = (
    key: string,
    value: string | number,
  ): void => {
    dispatch(
      setNewDevice({
        ...editableDevice,
        metadata: {
          ...editableDevice?.metadata,
          [key]: value,
        },
      } as Device),
    );
  };

  const handleOpenLwm2mDrawer = (): void => {
    setOpenLwm2mDrawer(true);
  };

  const handleCloseLwm2mDrawer = (): void => {
    setOpenLwm2mDrawer(false);
  };

  const updateLwm2mData = (): void => {
    setLoading(true);
    Devices.update(editableDevice as Device)
      .then((response) => {
        dispatch(setActualDevice(response));
        dispatch(
          setAlert({
            highlight: 'Update PSK Data',
            message: 'Device PSK Data successfully updated.',
            type: 'success',
          }),
        );
        handleCloseLwm2mDrawer();
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Grid container>
      {editableDevice && (
        <>
          <Grid component={Paper} item xs={8} className={clsx('p-7 shadow')}>
            {/* Details Form */}
            <Typography variant="h5">Details</Typography>
            <DeviceForm
              device={editableDevice}
              deviceType={deviceType}
              originalDevice={originalDevice as Device}
              onInputChange={handleInputChange}
              heartbeatUnit={selectedHearbeatUnit}
              heartbeatPeriod={heartbeatPeriod}
              setHeartbeatPeriod={setHeartbeatPeriod}
              onMetadataChange={handleImportantMetadataChange}
            />
            {/* Integration Form */}
            <IntegrationConfig
              device={editableDevice}
              deviceType={deviceType}
              onIntegrationChange={handleInputChange}
            />

            {deviceType?.type !== LWM2M_TYPE && (
              <>
                {/* Network Form */}
                <DeviceNetworkConfig
                  deviceNetworkConfigId={
                    editableDevice.device_config_id as string
                  }
                  deviceType={deviceType}
                  onSelectChange={handleInputChange}
                />

                {/* Logs Form */}
                <DeviceLogConfig
                  deviceType={deviceType}
                  localLog={editableDevice.log_config?.local_level}
                  forwardLog={editableDevice.log_config?.forward_level}
                  onChangeLogConfig={handleLogConfigChange}
                />
              </>
            )}

            {deviceType?.type === LWM2M_TYPE && (
              <Button
                data-cy="lwm2m-details-button"
                color="primary"
                size="large"
                variant="contained"
                className="my-7"
                onClick={handleOpenLwm2mDrawer}
              >
                LWM2M Connection parameters
              </Button>
            )}

            {/* Tags Form */}
            <Typography className="mt-3 mb-3" variant="h5">
              Tags
            </Typography>
            <Tags
              key="device-tags"
              originalTags={editableDevice.tags ?? []}
              onChangeTags={handleTagsChange}
            />
            <Divider className="mt-3 mb-6" />
            {/* Details footer */}
            <Box className={clsx(classes.rowBox)}>
              <Box className={clsx(classes.rowBox)}>
                <Typography className={classes.fontBold} variant="subtitle2">
                  System Id:
                </Typography>
                <Typography className="ml-1" variant="subtitle2">
                  {originalDevice?._id}
                </Typography>
              </Box>
              {editableDevice.edge_version && (
                <Box className={clsx(classes.rowBox)}>
                  <Typography
                    className={clsx('ml-2', classes.fontBold)}
                    variant="subtitle2"
                  >
                    Edge Version:
                  </Typography>
                  <Typography className="ml-1" variant="subtitle2">
                    {editableDevice.edge_version}
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          {/* Activities section  */}
          <DeviceActivities
            deviceType={deviceType}
            deviceId={editableDevice._id}
            handleOpenGatewayCommand={handleOpenGatewayCommand}
          />
          {deviceType?.type === LWM2M_TYPE && (
            <DeviceLwm2mParametersDrawer
              open={openLwm2mDrawer}
              loading={loading}
              device={editableDevice}
              onInputChange={handleInputChange}
              onUpdateData={updateLwm2mData}
              handleCloseDrawer={handleCloseLwm2mDrawer}
            />
          )}
        </>
      )}
    </Grid>
  );
};

export default DeviceDetails;
