import { ScheduledJob, ScheduledJobInput } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getScheduledJobSelector = createSelector(
  (state: ScheduledJobState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.scheduledJobs.length) {
      return state.actualScheduledJob;
    }
    return state.scheduledJobs.find(
      (scheduledJob: ScheduledJob) => scheduledJob._id === id,
    );
  },
);

export const newScheduledJob = {
  command_id: '',
  company_id: '',
  details: {
    command_options: {},
    workflow_options: {},
  },
  enabled: true,
  job_name: '',
  job_type: '',
  schedule: '',
};

export const ScheduledJobTypes = {
  CLEAN_NEW_SCHEDULE_JOBS: 'CLEAN_NEW_SCHEDULE_JOBS',
  SET_ACTUAL_SCHEDULE_JOB: 'SET_ACTUAL_SCHEDULE_JOB',
  SET_NEW_SCHEDULE_JOB: 'SET_NEW_SCHEDULE_JOB',
  SET_NEW_SCHEDULE_JOB_INPUT: 'SET_NEW_SCHEDULE_JOB_INPUT',
  SET_SCHEDULE_JOBS: 'SET_SCHEDULE_JOBS',
};

export const cleanNewScheduledJob = (): ScheduledJobAction => ({
  data: newScheduledJob as ScheduledJob,
  type: ScheduledJobTypes.CLEAN_NEW_SCHEDULE_JOBS,
});

export const setStateScheduledJobs = (
  scheduledJob: ScheduledJob[],
): ScheduledJobAction => ({
  data: scheduledJob,
  type: ScheduledJobTypes.SET_SCHEDULE_JOBS,
});

export const setActualScheduledJob = (
  scheduledJob: ScheduledJob,
): ScheduledJobAction => ({
  data: scheduledJob,
  type: ScheduledJobTypes.SET_ACTUAL_SCHEDULE_JOB,
});

export const setNewScheduledJob = (
  scheduledJob: ScheduledJob,
): ScheduledJobAction => ({
  data: scheduledJob,
  type: ScheduledJobTypes.SET_NEW_SCHEDULE_JOB,
});

export const setNewScheduledJobInput = (
  scheduledJob: ScheduledJobInput,
): ScheduledJobAction => ({
  data: scheduledJob,
  type: ScheduledJobTypes.SET_NEW_SCHEDULE_JOB_INPUT,
});

export type ScheduledJobState = {
  actualScheduledJob: ScheduledJob | null;
  scheduledJobs: ScheduledJob[];
  newScheduledJob: ScheduledJob | null;
  newScheduledJobInput: ScheduledJobInput | null;
};

// INITIAL STATE
const scheduledJobInitialState: ScheduledJobState = {
  actualScheduledJob: null,
  newScheduledJob: null,
  newScheduledJobInput: newScheduledJob as ScheduledJobInput,
  scheduledJobs: [],
};

export type ScheduledJobAction = {
  data: ScheduledJob[] | ScheduledJob | ScheduledJobInput | string;
  type: string;
};

const scheduledJobReducer = (
  state = scheduledJobInitialState,
  action: ScheduledJobAction,
): ScheduledJobState => {
  switch (action.type) {
    case ScheduledJobTypes.SET_SCHEDULE_JOBS:
      return {
        ...state,
        scheduledJobs: action.data as ScheduledJob[],
      };

    case ScheduledJobTypes.SET_ACTUAL_SCHEDULE_JOB:
      return {
        ...state,
        actualScheduledJob: action.data as ScheduledJob,
        newScheduledJob: action.data as ScheduledJob,
      };

    case ScheduledJobTypes.SET_NEW_SCHEDULE_JOB:
      return {
        ...state,
        newScheduledJob: action.data as ScheduledJob,
      };

    case ScheduledJobTypes.SET_NEW_SCHEDULE_JOB_INPUT:
      return {
        ...state,
        newScheduledJobInput: action.data as ScheduledJobInput,
      };

    case ScheduledJobTypes.CLEAN_NEW_SCHEDULE_JOBS:
      return {
        ...state,
        newScheduledJobInput: action.data as ScheduledJob,
      };
    default:
      return state;
  }
};

export default scheduledJobReducer;
