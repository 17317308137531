import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    boxContainer: {
      backgroundColor: '#fff',
    },
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.info.main,
      display: 'flex',
      justifyContent: 'center',
      width: 'fit-content',
    },
    filter: {
      fontSize: '14px',
    },
    label: {
      alignItems: 'center',
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.dark,
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      textTransform: 'uppercase',
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontWeight: 700,
    },
    subtitle: {
      color: theme.palette.grey[200],
    },
    title: {
      color: theme.palette.grey[100],
      fontWeight: 700,
      width: '225px',
    },
  }),
);

export default useStyles;
