import React, { useEffect, useState } from 'react';
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import {
  AwsThingGroup,
  DeviceInput,
  Device,
  Devices,
  DeviceType,
  DeviceTypeInput,
  Integration,
  Integrations,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setOptionsIntegrations } from '../../redux/reducers/integrations.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { RootState } from '../../redux/store';
import {
  optionsPaginationsFilter,
  errorHighlight,
  CLOUD_NATIVE_TYPE,
} from '../../app/constants';
import useStyles from './styles';
import AwsThingGroupsList from '../AwsThingGroupsList';
import { dispatchError } from '../../helpers/utils';

// logic: if `device` is set, then this is the device dialog (either create or detail), otherwise it is the device profile dialog
interface IntegrationConfigProps {
  deviceType: DeviceType | DeviceTypeInput | undefined;
  device?: Device | DeviceInput | undefined;
  onIntegrationChange: (prop: string, integrationId: string) => void;
  onDefaultThingGroupChange?: (value: AwsThingGroup) => void;
  isCreateDialog?: boolean;
}

const IntegrationConfig: React.FC<IntegrationConfigProps> = ({
  deviceType,
  device,
  onIntegrationChange: onIntegrationChange,
  onDefaultThingGroupChange,
  isCreateDialog = false,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const integrationsState = useAppSelector(
    (state: RootState) => state.integrations,
  );

  const [deviceIntegrations, setDeviceIntegrations] = useState<Integration[]>(
    [],
  );
  const [cloudIntegrations, setCloudIntegrations] = useState<Integration[]>([]);

  const [chosenIntegration, setChosenIntegration] = useState<
    Integration | undefined
  >();

  const [loading, setLoading] = useState(true);
  const [awsThingGroups, setAwsThingGroups] = useState<AwsThingGroup[]>([]);

  const [loadingThingGroupsOnDevice, setLoadingThingGroupsOnDevice] =
    useState(true);
  const [hasLoadedThingGroupsOnDevice, setHasLoadedThingGroupsOnDevice] =
    useState(false);
  const [awsThingGroupsOnDevice, setAwsThingGroupsOnDevice] = useState<
    AwsThingGroup[]
  >([]);

  const [inheritedIntegration, setInheritedIntegration] = useState<
    Integration | undefined
  >();
  const [integrationIsInherited, setIntegrationIsInherited] = useState(false);

  // get aws thing groups for integration
  const getAwsThingGroups = (): void => {
    setLoading(true);

    Integrations.getAwsThingGroups(chosenIntegration?._id ?? '')
      .then((res) => {
        setAwsThingGroups(res);
      })

      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => setLoading(false));
  };

  // get aws thing groups for device
  const getAwsThingGroupsForDevice = (): void => {
    if (device && device.hasOwnProperty('_id')) {
      setLoadingThingGroupsOnDevice(true);

      Devices.getAwsThingGroups((device as Device)._id)
        .then((res) => {
          setAwsThingGroupsOnDevice(res);
          setHasLoadedThingGroupsOnDevice(true);
        })

        .catch((error) => {
          dispatchError(error.message);
        })
        .finally(() => setLoadingThingGroupsOnDevice(false));
    }
  };

  const updateIntegrationIsInherited = (
    d: DeviceInput | undefined,
    dt: DeviceType | DeviceTypeInput | undefined,
  ): void => {
    // it is inherited, if the deviceType.device_integration_id is set, but the device.device_integration_id is not
    // the logic is only so complicated, because both device type and device can be null
    if (dt?.device_integration_id) {
      if (d?.device_integration_id) {
        setIntegrationIsInherited(false);
      } else {
        setIntegrationIsInherited(true);
      }
    } else {
      setIntegrationIsInherited(false);
    }
  };

  useEffect(() => {
    if (integrationsState.optionsIntegrations.length === 0) {
      Integrations.list({}, optionsPaginationsFilter)
        .then((result) => {
          dispatch(setOptionsIntegrations(result.integrations));
        })
        .catch((error) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: error.message,
              type: 'error',
            }),
          );
        });
    }
  }, []);

  useEffect(() => {
    setDeviceIntegrations(
      integrationsState.optionsIntegrations.filter(
        (integration) => integration.archetype === 'device',
      ),
    );
    setCloudIntegrations(
      integrationsState.optionsIntegrations.filter(
        (integration) => integration.archetype === 'cloud',
      ),
    );
    setChosenIntegration(
      integrationsState.optionsIntegrations.find(
        (integration) =>
          integration._id ===
          (device?.device_integration_id !== ''
            ? device?.device_integration_id
            : deviceType?.device_integration_id),
      ),
    );
  }, [integrationsState.optionsIntegrations]);

  useEffect(() => {
    // only applies to device profile dialog, not on device (thing groups are read only on devices)
    if (!device) {
      // if chosenIntegration changed
      if (
        chosenIntegration &&
        chosenIntegration.type === 'aws_device_integrations'
      ) {
        getAwsThingGroups();
      }
    }
    // only do this once, it cannot change during the lifecycle of the device detail dialog
    if (device && !isCreateDialog && !hasLoadedThingGroupsOnDevice) {
      // if chosenIntegration changed
      if (
        chosenIntegration &&
        chosenIntegration.type === 'aws_device_integrations'
      ) {
        getAwsThingGroups();
        getAwsThingGroupsForDevice();
      }
    }
  }, [chosenIntegration]);

  useEffect(() => {
    if (device?.device_integration_id) {
      setChosenIntegration(
        integrationsState.optionsIntegrations.find(
          (integration) => integration._id === device?.device_integration_id,
        ),
      );
    } else {
      setChosenIntegration(
        integrationsState.optionsIntegrations.find(
          (integration) =>
            integration._id === deviceType?.device_integration_id,
        ),
      );
    }
    // only relevant if this component is used in a device dialog (ie device is set)
    if (device) {
      if (deviceType?.device_integration_id) {
        setInheritedIntegration(
          integrationsState.optionsIntegrations.find(
            (integration) =>
              integration._id === deviceType?.device_integration_id,
          ),
        );
      } else {
        setInheritedIntegration(undefined);
      }
      updateIntegrationIsInherited(device, deviceType);
    }
  }, [device, deviceType, device?.device_integration_id]);

  const handleChangeIntegration = (event: SelectChangeEvent): void => {
    onIntegrationChange('device_integration_id', event.target.value as string);
  };

  const handleChangeCloudIntegration = (event: SelectChangeEvent): void => {
    onIntegrationChange(
      'cloud_native_integration_id',
      event.target.value as string,
    );
  };

  const hasCloudIntegration = deviceType?.type === CLOUD_NATIVE_TYPE;

  const printInheritedIntegrationLabel = (): string => {
    // The check for undefined is needed because in some cases the field 'device_integration_id' is not present at all
    return deviceType?.device_integration_id !== undefined &&
      deviceType?.device_integration_id !== ''
      ? `Inherited Integration ${
          inheritedIntegration?.name ? ` (${inheritedIntegration?.name})` : ''
        }`
      : 'Device Integration';
  };

  const renderInheritedIntegrationDetails = (): JSX.Element => {
    return (
      <>
        <p className="mt-0 mb-6">
          The integration is inherited from the device profile.
        </p>
        {/* only show for create device dialog */}
        {chosenIntegration?.type === 'aws_device_integrations' &&
          deviceType &&
          deviceType?.default_thing_groups &&
          isCreateDialog && (
            <AwsThingGroupsList
              title="Default AWS IoT Thing Groups (inherited)"
              allAvailableAwsThingGroups={deviceType.default_thing_groups}
              readOnly={true}
            />
          )}
      </>
    );
  };

  return (
    <>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-1', 'mt-6', classes.configTitle)}
      >
        Select a device integration
      </Typography>
      <FormControl className="mb-6" fullWidth={true}>
        <Select
          displayEmpty
          value={
            (device
              ? device.device_integration_id
              : deviceType?.device_integration_id) ?? ''
          }
          onChange={handleChangeIntegration}
          // always enable for create dialogs, and if its the device profile detail dialog
          disabled={isCreateDialog ? false : device !== undefined}
        >
          <MenuItem dense value="">
            {device ? printInheritedIntegrationLabel() : 'Device Integration'}
          </MenuItem>
          {deviceIntegrations.map((integration, index) => (
            <MenuItem dense key={index} value={integration._id}>
              {integration.name}
            </MenuItem>
          ))}
        </Select>
        {device &&
          integrationIsInherited &&
          renderInheritedIntegrationDetails()}
        {/* only show for device detail dialog */}
        {awsThingGroupsOnDevice.length > 0 && (
          <AwsThingGroupsList
            title="AWS IoT Thing Groups currently associated with this device"
            allAvailableAwsThingGroups={awsThingGroupsOnDevice}
            readOnly={true}
            loading={loadingThingGroupsOnDevice}
          />
        )}
        {/* only applies to device profiles - show the aws thing group list */}
        {!device && chosenIntegration?.type === 'aws_device_integrations' && (
          <AwsThingGroupsList
            allAvailableAwsThingGroups={awsThingGroups}
            selectedAwsThingGroups={deviceType?.default_thing_groups ?? []}
            onSelectedAwsThingGroupChange={onDefaultThingGroupChange}
            loading={loading}
          />
        )}
      </FormControl>
      {hasCloudIntegration && (
        <>
          <Typography
            variant="button"
            component="div"
            className={clsx('mb-1', classes.configTitle)}
          >
            Select a cloud integration
          </Typography>
          <FormControl className="mb-6" fullWidth={true}>
            <Select
              displayEmpty
              value={
                (device
                  ? device.cloud_native_integration_id
                  : deviceType?.cloud_native_integration_id) ?? ''
              }
              onChange={handleChangeCloudIntegration}
            >
              <MenuItem dense value="">
                Cloud Native Integration
              </MenuItem>
              {cloudIntegrations.map((integration, index) => (
                <MenuItem dense key={index} value={integration._id}>
                  {integration.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      )}
    </>
  );
};

export default IntegrationConfig;
