import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../components/TextInput';

interface TwilioIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
}

const TwilioIntegrationSection: React.FC<TwilioIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Sid"
          prop="sid"
          value={newIntegration.sid}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Token"
          prop="token"
          value={newIntegration.token}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="From"
          prop="from"
          value={newIntegration.from}
          onInputChange={onInputChange}
        />
      </Grid>
    </>
  );
};

export default TwilioIntegrationSection;
