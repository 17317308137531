import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import {
  Device,
  DeviceType,
  Rule,
  RuleInput,
  ScheduledJob,
  ScheduledJobInput,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import VerticalTabs from '../../components/VerticalTabs';

import useStyles from './styles';
import DevicesEntitiesSection from './DevicesEntitiesSection';
import DeviceProfilesEntitiesSection from './DeviceProfilesEntitiesSection';

const EntitiesTabsLabel = {
  deviceProfiles: 'Device Profiles',
  devices: 'Devices',
};

interface EntitiesSectionProps {
  newInput: ScheduledJob | Rule | ScheduledJobInput | RuleInput | null;
  selectedDevices: Device[];
  selectedDeviceTypes: DeviceType[];
  title?: string;
  subTitle?: string;
  mixedTypes?: boolean;
  showMixedTypes?: boolean;
  shadowContainer?: boolean;
  onChangeDevices: (devices: Device[]) => void;
  onChangeDeviceTypes: (deviceTypes: DeviceType[]) => void;
}

const EntitiesSection: React.FC<EntitiesSectionProps> = ({
  newInput,
  selectedDevices,
  selectedDeviceTypes,
  title = 'Select entities',
  subTitle = 'You can choose Devices and Device Profiles.',
  mixedTypes,
  showMixedTypes,
  shadowContainer,
  onChangeDevices,
  onChangeDeviceTypes,
}) => {
  const classes = useStyles();

  return (
    <Grid container>
      {newInput && (
        <Grid
          item
          xs={12}
          component={shadowContainer ? Paper : Grid}
          className={clsx({
            ['p-7 shadow']: shadowContainer,
          })}
        >
          <Typography
            variant="button"
            component="div"
            className={clsx('mb-1', classes.configTitle)}
          >
            {title}
          </Typography>
          <Typography
            variant="overline"
            component="div"
            className={clsx('mb-2', classes.configSubtitle)}
          >
            {subTitle}
          </Typography>
          {showMixedTypes && selectedDevices.length ? (
            <Box className="mb-4">
              <Typography component="div" variant="overline">
                {mixedTypes
                  ? "Only LWM2M devices alone support 'Gateway command' action type."
                  : "'Gateway command' action type enabled."}
              </Typography>
            </Box>
          ) : (
            <></>
          )}
          <VerticalTabs
            defaultTab="devices"
            tabsLabel={EntitiesTabsLabel}
            /* eslint sort-keys: 0 */
            tabs={{
              devices: (
                <DevicesEntitiesSection
                  newItem={newInput}
                  selectedDevices={selectedDevices}
                  onChangeDevices={onChangeDevices}
                />
              ),
              deviceProfiles: (
                <DeviceProfilesEntitiesSection
                  newItem={newInput}
                  selectedDeviceProfiles={selectedDeviceTypes}
                  onChangeDeviceTypes={onChangeDeviceTypes}
                />
              ),
            }}
            padding={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default EntitiesSection;
