import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button, CircularProgress } from '@mui/material';
import {
  SettingsApplicationRecord,
  SettingApplicationRecords,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import SettingApplicationListCard from '../../components/SettingApplicationListCard';

interface SettingApplicationListProps {
  settingsId?: string;
  gatewayCommandId?: string;
  deviceId?: string;
  configurationId?: string;
  settingSelected?: (
    settingApplication: SettingsApplicationRecord,
    open: string,
  ) => void;
}

const SettingApplicationList: React.FC<SettingApplicationListProps> = ({
  settingsId,
  gatewayCommandId,
  deviceId,
  configurationId,
  settingSelected,
}) => {
  const [settingsApplicationRecord, setSettingsApplicationRecord] = useState<
    SettingsApplicationRecord[]
  >([]);

  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    if (settingsId || gatewayCommandId || deviceId || configurationId) {
      getSettingsApplicationRecord(1);
    }
  }, [settingsId, gatewayCommandId, deviceId, configurationId]);

  useEffect(() => {
    setLoading(true);
    getSettingsApplicationRecord(1);
  }, []);

  const noLoading = (): void => {
    setLoading(false);
  };

  const getSettingsApplicationRecord = (
    pageNumber: number,
    addPage = false,
  ): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: 10,
      page: pageNumber,
    };

    let filter = {};
    if (deviceId) {
      filter = {
        device_id: { operator: 'eq', value: deviceId },
      };
    }

    if (settingsId) {
      filter = {
        settings_id: { operator: 'eq', value: settingsId },
      };
    }

    if (gatewayCommandId) {
      filter = {
        gateway_command_id: { operator: 'eq', value: gatewayCommandId },
      };
    }

    if (configurationId) {
      filter = {
        configuration_id: { operator: 'eq', value: configurationId },
      };
    }

    SettingApplicationRecords.list(filter, pagination)
      .then((result) => {
        setSettingsApplicationRecord([]);
        const newSettingApplication = addPage
          ? [...settingsApplicationRecord, ...result.settingsApplicationRecord]
          : result.settingsApplicationRecord;
        setSettingsApplicationRecord(newSettingApplication);
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getSettingsApplicationRecord(page + 1, true);
  };

  if (loading) {
    return (
      <Grid container className="loading-container">
        <CircularProgress size={75} thickness={5} />
      </Grid>
    );
  }

  return (
    <>
      <Typography className={clsx('mt-8 mb-4')} variant="h5">
        Settings Application Records
      </Typography>
      <Grid container direction={'column'}>
        {settingsApplicationRecord.map((settingApplication) => {
          return (
            <SettingApplicationListCard
              key={settingApplication._id}
              settingsApplicationRecord={settingApplication}
              settingSelected={settingSelected}
            />
          );
        })}
        {settingsApplicationRecord.length !== total && (
          <Grid item xs={12} className={clsx('mb-9 loading-container')}>
            <Button variant="outlined" size="large" onClick={handleLoadMore}>
              {!loadingMore ? (
                <Typography variant="button">Load more</Typography>
              ) : (
                <CircularProgress size={25} />
              )}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SettingApplicationList;
