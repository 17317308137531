import { useEffect, useState } from 'react';
import { Grid, MenuItem, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Translator,
  TranslatorInput,
  Translators,
} from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
  getTranslatorSelector,
  setActualTranslator,
  setNewTranslator,
} from '../../../redux/reducers/translators.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import TextInput from '../../../components/TextInput';
import FooterBar from '../../../components/FooterBar';
import Header from '../../../containers/HeaderWithActionButton';
import ContentHeader from '../../../components/ContentHeader';
import SelectInput from '../../../components/SelectInput';
import {
  ALL_TRANSLATOR_TYPES,
  TRANSLATOR_JAVASCRIPT_TYPE,
  TRANSLATOR_TEMPLATE_TYPE,
  TEXT_AREA_TYPES,
} from '../../../constants/translators';

import useStyles from './styles';

const TranslatorContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams<string>();
  const translatorState = useAppSelector(
    (state: RootState) => state.translators,
  );
  const { newTranslator, translator } = translatorState;
  const translatorData = useAppSelector((state: RootState) =>
    getTranslatorSelector(state.translators, id),
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (translatorData) {
      dispatch(setActualTranslator(translatorData));
    } else if (id) {
      Translators.getOneById(id)
        .then((response) => {
          dispatch(setActualTranslator(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [translatorData]);

  const handleChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    const newScript =
      prop === 'type'
        ? handleTypeChange(value as string)
        : prop === 'script'
        ? value
        : newTranslator?.script;
    dispatch(
      setNewTranslator({
        ...newTranslator,
        [prop]: value,
        script: newScript,
      } as TranslatorInput),
    );
  };

  const handleTypeChange = (type: string): string => {
    switch (type) {
      case 'javascript':
        return TRANSLATOR_JAVASCRIPT_TYPE;

      case 'template':
        return TRANSLATOR_TEMPLATE_TYPE;

      case 'template_cmd':
        return TRANSLATOR_TEMPLATE_TYPE;

      default:
        return '';
    }
  };

  const handleSaveChanges = (): void => {
    setLoading(true);
    Translators.update(newTranslator as Translator)
      .then((response) => {
        dispatch(setActualTranslator(response));
        dispatch(
          setAlert({
            highlight: 'Translator Updated',
            message: 'Translator successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (): void => {
    if (!translator) {
      return;
    }
    setLoading(true);
    Translators.delete(translator._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Translator Deleted',
            message: 'Translator successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/data-management');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newTranslator, translator);
  };

  const editTextAreaType = TEXT_AREA_TYPES as Record<string, string>;

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Translators content"
        goBack="data-management"
        model="translator"
      />
      {translator && (
        <ContentHeader
          contentType="translator"
          title={translator.name}
          subtitle={translator._id}
          tag={translator.type}
          origin={translator.origin}
          copySubtitleToClipboard
        />
      )}
      <Grid item xs={12}>
        <Paper className="shadow p-7 m-8">
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12} className="mt-6">
              <TextInput
                placeholder="Name"
                prop="name"
                onInputChange={handleChange}
                value={newTranslator?.name}
                required={true}
                label="Name *"
              />
            </Grid>
            <Grid item xs={12} className="">
              <SelectInput
                label="Type *"
                value={newTranslator?.type}
                prop="type"
                onSelectChange={handleChange}
                options={ALL_TRANSLATOR_TYPES.map((type) => (
                  <MenuItem dense key={type.key} value={type.key}>
                    {type.label}
                  </MenuItem>
                ))}
              />
            </Grid>
            {editTextAreaType[newTranslator?.type ?? ''] && (
              <Grid item xs={12} className={clsx('mt-6', classes.textArea)}>
                <TextInput
                  prop="script"
                  value={newTranslator?.script}
                  onInputChange={handleChange}
                  classes="full-height"
                  multiline
                  rows={12}
                />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <FooterBar
        deleteModalContent="You are about to delete this translator"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDelete}
      />
    </Grid>
  );
};

export default TranslatorContent;
