import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Company, User, Users } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import AccountCard from '../../../pages/accounts/AccountCard';
import CardsGrid from '../../../components/CardsGrid';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';
import RightDrawer from '../RightDrawer';

interface AttachAccountsDrawerProps {
  user: User;
  open: boolean;
  handleCloseDrawer: () => void;
  onAccountsAdded: (accountsIds: string[]) => void;
}

const AttachAccountsDrawer: React.FC<AttachAccountsDrawerProps> = ({
  user,
  open,
  handleCloseDrawer,
  onAccountsAdded,
}) => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state: RootState) => state.user);

  const [accounts, setAccounts] = useState<Company[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const getChildAccounts = (): void => {
    setLoading(true);
    const loggedUserAccounts = userState.userCompanies;
    let accountsToShow: Company[] = [];
    if (user.company_ids && user.company_ids.length !== 0) {
      // If the user has company_ids with values, then we can attach to them the child accounts of the accounts in company_ids.
      // Only the accounts that can be seen by the logged user are the ones in question
      accountsToShow = loggedUserAccounts.filter(
        (account) =>
          user.company_ids?.includes(account.company_id) &&
          !user.company_ids?.includes(account._id),
      );
    } else {
      // If the user doesn't have company_ids with values, then we can only attach to them the child accounts of their same company.
      // Only the accounts that can be seen by the logged user are the ones in question
      accountsToShow = loggedUserAccounts.filter(
        (account) => user.company_id === account.company_id,
      );
    }
    setAccounts(accountsToShow);
    setLoading(false);
  };

  useEffect(() => {
    getChildAccounts();
  }, [user, userState.userCompanies]);

  const checkAccountCallback =
    (accountId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setSelectedAccounts([...selectedAccounts, accountId]);
      } else {
        setSelectedAccounts(
          selectedAccounts.filter((item) => item !== accountId),
        );
      }
    };

  const handleActionCallback = (): void => {
    Promise.all(
      selectedAccounts.map(async (accountId) => {
        await Users.addCompanyId(accountId, user._id);
      }),
    )
      .then(() => {
        onAccountsAdded(selectedAccounts);
        dispatch(
          setAlert({
            highlight: 'Manage additional accounts',
            message: 'Additional account added to user.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while attaching new accounts.',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSelectAll = (): void => {
    if (selectedAccounts.length !== accounts.length) {
      setSelectedAccounts(accounts.map((account) => account._id));
    } else {
      setSelectedAccounts([]);
    }
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Attach"
      title="Select Accounts"
      disableAction={selectedAccounts.length === 0}
      actionCallback={handleActionCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <AttachItemsLayout
          allSelected={selectedAccounts.length === accounts.length}
          itemsSelected={selectedAccounts.length !== 0}
          hasItems={accounts.length !== 0}
          noItemsLabel="No accounts can be attached to the user."
          selectAllCallback={handleSelectAll}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <CardsGrid
                oneColumn={true}
                containerPadding={false}
                cards={accounts.map((account) => (
                  <AttachItemCard
                    content={
                      <AccountCard key={account._id} company={account} />
                    }
                    checked={selectedAccounts.includes(account._id)}
                    checkboxCallback={checkAccountCallback}
                    id={account._id}
                  />
                ))}
              />
            )
          }
        />
      }
    />
  );
};

export default AttachAccountsDrawer;
