import React from 'react';
import { SoftwareUpdate } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import { Typography, Grid, Paper } from '@mui/material';

import SoftwarePackageLogs from '../../../containers/SoftwarePackageLogs';
import SoftwarePackageForm from '../../../containers/Forms/SoftwarePackageForm';
import { useAppDispatch } from '../../../redux/hooks';
import { setNewSoftwareUpdate } from '../../../redux/reducers/softwareUpdates.reducer';

interface SoftwarePackageDetails {
  editableSoftwareUpdate: SoftwareUpdate | null;
  uploadedFiles: File[];
  setUploadedFiles: React.Dispatch<React.SetStateAction<File[]>>;
}

const SoftwarePackageDetails: React.FC<SoftwarePackageDetails> = ({
  editableSoftwareUpdate,
  setUploadedFiles,
  uploadedFiles,
}) => {
  const dispatch = useAppDispatch();

  const handleInputChange = (
    prop: string,
    value: string | number | boolean,
  ): void => {
    dispatch(
      setNewSoftwareUpdate({
        ...editableSoftwareUpdate,
        [prop]: value,
      } as SoftwareUpdate),
    );
  };

  return (
    <Grid container>
      {editableSoftwareUpdate && (
        <>
          <Grid
            component={Paper}
            item
            xs={12}
            md={8}
            className={clsx('p-7 shadow')}
          >
            {/* Details Form */}
            <Typography variant="h5">Details</Typography>
            <SoftwarePackageForm
              softwareUpdate={editableSoftwareUpdate}
              onInputChange={handleInputChange}
              setUploadedFiles={setUploadedFiles}
              uploadedFiles={uploadedFiles}
            />
          </Grid>
          {/* Activities section  */}
          <SoftwarePackageLogs softwareUpdateId={editableSoftwareUpdate._id} />
        </>
      )}
    </Grid>
  );
};

export default SoftwarePackageDetails;
