import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import ColoredBox from '../../components/ColoredBox';
import { RightDrawer } from '../RightDrawer';
import UserAvatar from '../UserAvatar';
import useStyles from './styles';
import { StatusTheme } from '../../models/common';

interface DetailsDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
  title: string;
  subtitle1?: string;
  subtitle2?: string;
  date?: string;
  badgeText?: string;
  badgeColor?: string;
  type?: string;
  user?: string;
}
const DetailsDrawer: React.FC<DetailsDrawerProps> = ({
  open,
  handleCloseDrawer,

  title,
  badgeColor = 'info',
  badgeText,
  type = 'command_execution_state',
  date,
  subtitle1,
  subtitle2,
  user,
  children,
}) => {
  const classes = useStyles();

  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      titleComponent={
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="h5" marginBottom={0}>
            {`${title}`}
          </Typography>
          {badgeText && (
            <ColoredBox
              colorTheme={badgeColor as StatusTheme}
              type={type}
              value={badgeText}
              className="ml-2"
            />
          )}
        </Box>
      }
      content={
        <Grid
          container
          spacing={1}
          className={classes.container}
          marginTop={0.2}
        >
          <Grid item xs={7} className={classes.deviceName} paddingTop={5}>
            {subtitle1 && (
              <Typography variant="button" className={classes.subtitle}>
                <p className="my-1">{subtitle1}</p>
              </Typography>
            )}
            {subtitle2 && (
              <Typography variant="button" className={classes.subtitle}>
                <p className="my-1">{subtitle2}</p>
              </Typography>
            )}
            {date && (
              <Typography variant="button" className={classes.subtitle}>
                <p className="my-1">{date}</p>
              </Typography>
            )}
          </Grid>
          <Grid padding={0}>
            <UserAvatar
              userId={user as string}
              hideErrorToast={true}
              preAvatarText="Executed by:"
              preAvatarTextClass="custom-label"
              preAvatarTextVariant="subtitle2"
            />
          </Grid>

          {children}
        </Grid>
      }
    />
  );
};

export default DetailsDrawer;
