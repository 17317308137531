import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from '@mui/material';
import { Companies, Company, PaginationFilter } from '@edgeiq/edgeiq-api-js';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { set, get } from 'lodash';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  setHierarchyLevels,
  setHierarchySearchAccount,
} from '../../redux/reducers/filters.reducer';
import { ExplorerItem, ExplorerLevel } from '../../models/common';
import { EMPTY_COMPANY } from '../../constants/companies';
import { errorHighlight } from '../../app/constants';
import AccountAutocomplete from '../AccountAutocomplete';
import useStyles from './styles';

const MAX_IDS_FILTERS = 20;

interface AccountsExplorerProps {
  selectedAccountsIds: string[];
  ancestorId?: string;
  onChoosingAccount: (id: string) => void;
  onChoosingAncestor: (id: string) => void;
}

const inicialLevel = {
  children: [],
  page: 1,
};

type RefType = Record<number, HTMLDivElement>;

const AccountsExplorer: React.FC<AccountsExplorerProps> = ({
  selectedAccountsIds,
  ancestorId,
  onChoosingAccount,
  onChoosingAncestor,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const stateUser = useAppSelector((state: RootState) => state.user);
  const hierarchyState = useAppSelector(
    (state: RootState) => state.filters.hierarchyFilters,
  );
  const levels = hierarchyState.levels;
  const searchAccount = hierarchyState.searchAccount;
  const [searchAccountAncestors, setSearchAccountAncestors] = useState<
    Company[]
  >([]);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const levelsRef = useRef<RefType>({});

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const fetchAccounts = (
    parent: Company,
    updatedLevels: ExplorerLevel[],
    levelIndex?: number,
    addPage = false,
  ): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: 25,
      order_by: 'name',
      page: 1,
    };
    let level: ExplorerLevel | undefined;
    if ((levelIndex || levelIndex === 0) && addPage) {
      level = levels[levelIndex];
      if (level) {
        pagination.page = level.page + 1;
      }
    }

    Companies.list(
      { company_id: { operator: 'eq', value: parent._id } },
      pagination,
    )
      .then((result) => {
        const levelsObject = {
          levels: [...updatedLevels],
        };
        const newAccounts =
          pagination.page !== 1
            ? [
                ...(level?.children?.map((child) => child.item) as Company[]),
                ...result.companies,
              ]
            : result.companies;
        set(levelsObject, `levels.${levelIndex || '0'}`, {
          children: newAccounts.map((newAccount) => {
            return {
              item: newAccount,
              selectBranch: false,
              selected: selectedAccountsIds.includes(newAccount._id),
            };
          }),
          loading: false,
          page: pagination.page,
          total: result.pagination.total,
        });
        dispatch(setHierarchyLevels(levelsObject.levels));
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        // Scroll to the right side of the container parent
        if (levelIndex) {
          if (levelsRef && containerRef.current) {
            const levelDiv = levelsRef.current[levelIndex];
            containerRef.current.scrollTo({
              behavior: 'smooth',
              left: levelDiv.offsetLeft + 300,
            });
          }
        }
      });
  };

  const getAccountAncestors = (id: string): void => {
    Companies.getAncestors(id)
      .then((res) => {
        const array = res
          .slice(1)
          .reverse()
          .map((element: Company) => {
            return element;
          });
        setSearchAccountAncestors(array);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const checkAncestorAccount = (id: string): void => {
    let exists = false;
    // Chose a for loop to be able to break it
    for (let i = 0; i < levels.length; i++) {
      const level = levels[i];
      for (let j = 0; j < level.children.length; j++) {
        const explorerItem = level.children[j];
        if (explorerItem.item._id === id) {
          exists = true;
          break;
        }
      }
    }
    if (!exists) {
      Companies.getOneById(id)
        .then((account) => {
          const firstLevelItem: ExplorerItem = {
            item: account,
            selectBranch: false,
            selected: false,
          };
          setLoading(true);
          fetchAccounts(
            firstLevelItem.item,
            [
              {
                ...inicialLevel,
                children: [firstLevelItem],
                chosenItem: firstLevelItem,
                total: 1,
              },
            ],
            1,
          );
          getAccountAncestors(account._id);
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  };

  useEffect(() => {
    const firstLevelItem: ExplorerItem = {
      item: {
        ...EMPTY_COMPANY,
        _id: '',
        created_at: '',
        origin: '',
        updated_at: '',
        user_id: '',
      },
      selectBranch: false,
      selected: false,
    };
    if (searchAccount) {
      // Case 1: The id of the search account is the same as the first item level in the present levels => don't fetch
      //          This case is when we closed the drawer and opened it again. We want to maintain the state in this case
      // Case 2: The id of the search account is NOT the same as the first item level in the present levels => fetch accounts
      //          This case is when we reopened the drawer and chose to search for a different account. We should be able to get new results
      if (
        levels.length !== 0 &&
        levels[0].chosenItem?.item._id !== searchAccount._id
      ) {
        firstLevelItem.item = searchAccount;
        setLoading(true);
        fetchAccounts(
          searchAccount,
          [
            {
              ...inicialLevel,
              children: [firstLevelItem],
              chosenItem: firstLevelItem,
              total: 1,
            },
          ],
          1,
        );
      }
    } else if (stateUser.userCompany) {
      setSearchAccountAncestors([]); // Reset the search accounts ancestors, which are used for the breadcrumb
      // Case 1: The user already navigated before without choosing an account to fiter by it's best to keep the navigation as it was
      // Case 2: The user navigated and chose an ancestor account
      //          => we should keep the navigation if the ancestor account is present in the levels we have in the redux state
      // Case 3: The user didn't use it or after removing the search account the levels will be empty
      //          Fetch children accounts using the account of the user as a parent
      //          This will populate the second level of the tree (index 1)
      if (levels.length === 0) {
        firstLevelItem.item = stateUser.userCompany;
        setLoading(true);
        fetchAccounts(
          stateUser.userCompany,
          [
            {
              ...inicialLevel,
              children: [firstLevelItem],
              chosenItem: firstLevelItem,
              total: 1,
            },
          ],
          1,
        );
      } else if (ancestorId) {
        // If we have an ancestorId, we check for its presence in the present levels
        checkAncestorAccount(ancestorId);
      }
    }
  }, [searchAccount, stateUser.userCompany]);

  const handleChooseAccount =
    (index: number, explorerItem: ExplorerItem) => (): void => {
      if (
        !levels[index].chosenItem ||
        (levels[index].chosenItem &&
          levels[index].chosenItem?.item._id !== explorerItem.item._id)
      ) {
        // Creating an object this way as it is necessary for the lodash set function to work properly
        const levelsObject = {
          levels: [...levels],
        };
        // If a different account was chosen in a level remove all sublevels as they are no longer relevant
        while (levelsObject.levels.length > index + 1) {
          levelsObject.levels.pop();
        }
        // update the level where the account was chosen
        set(levelsObject, `levels.${index}.chosenItem`, explorerItem);
        // Add a new level with one index more with no children. This will show the loading in the level.
        set(levelsObject, `levels.${index + 1}`, {
          children: [],
          loading: true,
          page: 1,
        });
        dispatch(setHierarchyLevels(levelsObject.levels));
        // Get the children accounts of the chosen account
        fetchAccounts(explorerItem.item, levelsObject.levels, index + 1);
      }
    };

  const handleCheckboxClick =
    (index: number, levelIndex: number, accountId: string) => (): void => {
      const levelsObject = {
        levels: [...levels],
      };
      const oldValue = get(
        levelsObject,
        `levels.${index}.children.${levelIndex}.selected`,
      );
      onChoosingAccount(accountId);
      set(
        levelsObject,
        `levels.${index}.children.${levelIndex}.selected`,
        !oldValue,
      );
      dispatch(setHierarchyLevels(levelsObject.levels));
    };

  const handleSwitchChange =
    (index: number, levelIndex: number, accountId: string) => (): void => {
      const levelsObject = {
        levels: [...levels],
      };
      const oldValue = get(
        levelsObject,
        `levels.${index}.children.${levelIndex}.selectBranch`,
      );
      if (!oldValue) {
        onChoosingAncestor(accountId);
      } else {
        onChoosingAncestor('');
      }
      set(
        levelsObject,
        `levels.${index}.children.${levelIndex}.selectBranch`,
        !oldValue,
      );
      dispatch(setHierarchyLevels(levelsObject.levels));
    };

  const handleLoadMore = (index: number) => (): void => {
    const levelsObject = {
      levels: [...levels],
    };
    set(levelsObject, `levels.${index}.loading`, true);
    dispatch(setHierarchyLevels(levelsObject.levels));
    if (index === 0 && stateUser.userCompany) {
      fetchAccounts(stateUser.userCompany, levelsObject.levels, index, true);
    } else if (levels[index - 1].chosenItem) {
      fetchAccounts(
        (levels[index - 1].chosenItem as ExplorerItem).item,
        levelsObject.levels,
        index,
        true,
      );
    }
  };

  const handleSearchAccountChange = (account: Company | undefined): void => {
    dispatch(setHierarchySearchAccount(account));
    if (account) {
      getAccountAncestors(account._id);
    } else {
      // if the user removed the search account then we should reset the explorer levels
      dispatch(setHierarchyLevels([]));
    }
  };

  return (
    <>
      <div className="my-3">
        <AccountAutocomplete
          isDisabled={false}
          includeParentId={true}
          selectedAccount={searchAccount?._id ?? ''}
          placeHolder="Search for an account to set as top level"
          onAccountChangeModel={handleSearchAccountChange}
        />
      </div>
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          <div
            className={clsx('mt-2 scrollbar', classes.container)}
            ref={containerRef}
          >
            <div className="mb-3">
              {searchAccountAncestors.length !== 0 &&
                searchAccountAncestors.map((ancestor, index) => (
                  <Typography
                    key={`breadcrumb-${ancestor._id}-${index}`}
                    className={classes.breadcrumb}
                    variant="overline"
                  >
                    {`${index === 0 ? '' : ' > '}${ancestor.name}${
                      index === searchAccountAncestors.length - 1 ? ' > ' : ''
                    }`}
                  </Typography>
                ))}
              {levels.map((level, index) =>
                level.chosenItem ? (
                  <Typography
                    key={`breadcrumb-${level.chosenItem.item._id}-${index}`}
                    className={classes.breadcrumb}
                    variant="overline"
                  >
                    {`${level.chosenItem?.item.name}${
                      index !== levels.length - 1 ? ' > ' : ''
                    }`}
                  </Typography>
                ) : (
                  <div key={`breadcrumb-${index}`}></div>
                ),
              )}
            </div>
            <div className={classes.levelsContainer}>
              {levels.map((level, index) => (
                <div
                  key={`account-filter-level-${index}`}
                  className={clsx(
                    `scrollbar pr-3 ${index !== 0 ? 'ml-3' : ''}`,
                    classes.levelContainer,
                  )}
                  ref={(el): void => {
                    levelsRef.current[index] = el as HTMLDivElement;
                  }}
                >
                  {level.children?.length === 0 && level.loading && (
                    <Grid container className="loading-container">
                      <CircularProgress size={75} thickness={5} />
                    </Grid>
                  )}
                  {level.children?.map((explorerItem, levelIndex) => (
                    <div
                      className={clsx('mt-2 br-1', classes.accountContainer, {
                        ['selected']:
                          explorerItem.item._id === level.chosenItem?.item._id,
                      })}
                      key={`account-filter-level-${index}-account-${levelIndex}`}
                    >
                      <div className="mr-2">
                        <Checkbox
                          data-prop={`checkbox-${explorerItem.item._id}`}
                          className="p-0"
                          color="primary"
                          disabled={
                            selectedAccountsIds.length === MAX_IDS_FILTERS &&
                            !explorerItem.selected
                          }
                          checked={explorerItem.selected}
                          onChange={handleCheckboxClick(
                            index,
                            levelIndex,
                            explorerItem.item._id,
                          )}
                        />
                      </div>
                      <div className={clsx(classes.accountNameContainer)}>
                        <div
                          className={clsx(classes.accountName)}
                          onClick={handleChooseAccount(index, explorerItem)}
                        >
                          <div className="mb-2">
                            <Typography variant="overline" component="div">
                              {explorerItem.item.name}
                            </Typography>
                            <Typography variant="caption" component="div">
                              ID: {explorerItem.item._id}
                            </Typography>
                          </div>
                          <NavigateNextIcon fontSize="small" />
                        </div>
                        <FormControlLabel
                          disableTypography
                          data-prop={`switch-${explorerItem.item._id}`}
                          label="Include descendants"
                          className={clsx('mr-2', classes.switchLabel, {
                            ['showLabel']:
                              explorerItem.selected ||
                              ancestorId === explorerItem.item._id,
                          })}
                          control={
                            <Switch
                              className="mr-2"
                              color="primary"
                              checked={
                                explorerItem.selectBranch ||
                                ancestorId === explorerItem.item._id
                              }
                              disabled={
                                ancestorId !== '' &&
                                ancestorId !== explorerItem.item._id
                              }
                              onChange={handleSwitchChange(
                                index,
                                levelIndex,
                                explorerItem.item._id,
                              )}
                            />
                          }
                        />
                      </div>
                    </div>
                  ))}
                  {level.children.length !== 0 &&
                    level.total !== level.children.length && (
                      <div className={clsx('my-4', classes.loadMoreContainer)}>
                        <Button
                          variant="outlined"
                          size="medium"
                          onClick={handleLoadMore(index)}
                        >
                          {!level.loading ? (
                            <Typography variant="button">Load more</Typography>
                          ) : (
                            <CircularProgress size={25} />
                          )}
                        </Button>
                      </div>
                    )}
                  {!level.loading && level.children.length === 0 && (
                    <Typography variant="overline">
                      The account has no children accounts.
                    </Typography>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AccountsExplorer;
