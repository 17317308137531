import React, { useEffect } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { Integration, IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { integrationTypesMap } from '../../../app/constants';
import IntegrationDynamicSection from './IntegrationDynamicSection';
import { verifySubmitEnable } from './helper';
import TextInput from '../../../components/TextInput';
import SelectInput from '../../../components/SelectInput';

interface IntegrationFormProps {
  newIntegration: Integration | IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
  disabled?: boolean;
  setEnableSubmit: (args: boolean) => void;
}

const IntegrationForm: React.FC<IntegrationFormProps> = ({
  newIntegration,
  onInputChange,
  disabled,
  setEnableSubmit,
}) => {
  useEffect(() => {
    setEnableSubmit(verifySubmitEnable(newIntegration));
  }, [newIntegration]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Integration Name"
          prop="name"
          value={newIntegration.name}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <SelectInput
          label="Integration Type"
          prop="type"
          value={newIntegration.type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(integrationTypesMap).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {integrationTypesMap[key]}
            </MenuItem>
          ))}
        />
      </Grid>

      <Grid container direction="row" spacing={2} className="pl-4">
        <Grid item xs={12} className="mt-6">
          <TextInput
            label="Description"
            prop="long_description"
            value={newIntegration.long_description}
            onInputChange={onInputChange}
            classes="full-height"
            multiline
            rows={4}
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <IntegrationDynamicSection
        newIntegration={newIntegration}
        onInputChange={onInputChange}
        disabled={disabled}
      />
    </Grid>
  );
};

export default IntegrationForm;
