import { IngestorInput } from '@edgeiq/edgeiq-api-js';

export const EMPTY_INGESTOR: IngestorInput = {
  company_id: '',
  handler: {
    dbus_map_key: '',
    delimited: '',
    index: 0,
    length: 0,
    map_keys: [],
    routes: [
      {
        methods: ['get'],
        path: '',
      },
    ],
    strip_delimited: false,
    type: 'int32',
  },
  handler_type: 'fixed',
  listener: {
    access_id: '',
    address: '',
    auto_promote_discovered_device: false,
    baud: '1200',
    command: '',
    community: '',
    create_discovered_device: false,
    dev: '',
    device_type_mapping: [
      {
        device_type_id: '',
        key: '',
        value: '',
      },
    ],
    filters: [
      {
        interface: '',
        member: '',
        path: '',
      },
    ],
    heartbeat_period: '',
    host: '',
    integration_id: '',
    method: '',
    min_timeout_seconds: 30,
    oids: [],
    params: {
      address: '',
      and_mask: 0,
      node_id: '',
      object_instance: 0,
      object_type: 'analog_value',
      or_mask: 0,
      property_id: '',
      quantity: 0,
      request_type: '',
      value: 0,
    },
    password: '',
    poll_interval: 0,
    port: 0,
    retries: 0,
    slave_id: 0,
    subscription_id: '',
    timeout: 0,
    url: '',
    version: '1',
  },
  listener_type: 'shell_polling',
  name: '',
  translator_id: '',
  type: 'edge',
};
