import { useEffect, useState, ChangeEvent } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Secrets, Secret, PaginationFilter } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import Header from '../../containers/HeaderWithActionButton';
import SecretCard from './SecretCard';
import CardsGrid from '../../components/CardsGrid';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import Card from '../../components/Card';
import { RootState } from '../../redux/store';
import { SortingOption } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import { sortingOptions } from './constants';
import parseFilters from '../../helpers/parseFilters';
import { setStateSecrets } from '../../redux/reducers/secrets.reducer';
import SecretsFilters from './SecretsFilters';
import ListSelection from '../../components/ListSelection';
import {
  errorHighlight,
  defaultItemsPerPage,
  genericViewOptions,
  deleteHighlight,
} from '../../app/constants';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import SharedTable from '../../components/SharedTable';
import { SecretColumns } from './columns';
import ActionDialog from '../../components/ActionDialog';

const SecretsPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state: RootState) => state.filters);
  const secretsState = useAppSelector((state: RootState) => state.secrets);
  const [secrets, setSecrets] = useState<Secret[]>(secretsState.secrets);
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [selectedSecrets, setSelectedSecrets] = useState<string[]>([]);
  const [selectedView, setSelectedView] = useState(filters.secrets.view);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.secrets.sortBy, sortingOptions),
  );
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    setLoading(true);
    setSelectedSecrets([]);
    getSecrets(1);
  }, [filters.secrets]);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const getSecrets = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    Secrets.list(parseFilters(filters.secrets.filters ?? {}), pagination)
      .then((result) => {
        const newSecret = addPage
          ? [...secrets, ...result.secrets]
          : result.secrets;
        setSecrets(newSecret);
        dispatch(setStateSecrets(newSecret));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getSecrets(page + 1, true);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    Secrets.deleteMultiple(selectedSecrets)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(
              selectedSecrets.length,
              'Secret',
              'Secrets',
            ),
            type: 'success',
          }),
        );
        setSecrets(
          secrets.filter((secret) => !selectedSecrets.includes(secret._id)),
        );
        setSelectedSecrets([]);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoadingDelete(false);
        closeDeleteModal();
      });
  };

  const checkSecretCallback =
    (secretId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedSecrets([...selectedSecrets, secretId]);
      } else {
        setSelectedSecrets(selectedSecrets.filter((item) => item !== secretId));
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'secrets'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'secrets'));
    setSelectedView(view);
  };

  const handleSelectAll = (): void => {
    if (selectedSecrets.length !== secrets.length) {
      setSelectedSecrets(secrets.map((secret) => secret._id));
    } else {
      setSelectedSecrets([]);
    }
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const generateCards = (): JSX.Element[] => {
    return secrets.map((secret) => (
      <Card
        checked={selectedSecrets.includes(secret._id)}
        checkboxCallback={checkSecretCallback}
        id={secret._id}
        content={<SecretCard secret={secret} />}
      />
    ));
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Secrets"
        link="new-secret"
        actionLabel="Create New Secret"
        model="secret"
      />
      <SecretsFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={sortingOptions}
        viewsOptions={genericViewOptions}
        itemsSelected={selectedSecrets.length !== 0}
        allSelected={
          secrets.length !== 0 && selectedSecrets.length === secrets.length
        }
        deleteAction={true}
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && <CardsGrid cards={generateCards()} />}
          {selectedView === 'list' && (
            <SharedTable
              columns={SecretColumns()}
              rows={secrets}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={selectedSecrets.length === secrets.length}
              loading={loading}
              selectedItemsIds={selectedSecrets}
              onRequestSort={handleTableSorting}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkSecretCallback}
            />
          )}
          {secrets.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedSecrets.length === 1 ? 'secret' : 'secrets'
            }:`}</span>
            <ul>
              {secrets
                .filter((secret) => selectedSecrets.includes(secret._id))
                .map((secret) => (
                  <li key={secret._id}>
                    {secret.name} - Id: {secret._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default SecretsPage;
