import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  FormControl,
  OutlinedInput,
  Button,
  IconButton,
  Select,
  MenuItem,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import isEqual from 'lodash.isequal';
import { useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { NetworkConnection } from '@edgeiq/edgeiq-api-js';

import {
  connectionsLabel,
  defaultConnectionType,
} from '../../../app/constants';
import RightDrawer from '../RightDrawer';

type indexes = 'type' | 'name';

interface ConnectionsDrawerProps {
  open: boolean;
  connections: NetworkConnection[];
  handleCloseDrawer: () => void;
  onConnectionsAdd: (connections: NetworkConnection[]) => void;
}

const ConnectionsDrawer: React.FC<ConnectionsDrawerProps> = ({
  open,
  connections,
  handleCloseDrawer,
  onConnectionsAdd,
}) => {
  const originalDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.deviceType,
  );
  const [connectionsList, setConnectionsList] =
    useState<NetworkConnection[]>(connections);

  const handleAddConnectionsCallback = (): void => {
    onConnectionsAdd(connectionsList);
  };

  const handleChange =
    (index: number, key: indexes) =>
    // eslint-disable-next-line
    (event: React.ChangeEvent<HTMLInputElement> | any): void => {
      const auxArray = [...connectionsList];
      auxArray[index][key] = event.target.value;

      setConnectionsList(auxArray);
    };

  const addNewConnection = (): void => {
    setConnectionsList([
      ...connectionsList,
      {
        name: '',
        type: defaultConnectionType,
      },
    ]);
  };

  const handleCloseClick = (): void => {
    setConnectionsList(connections);
    handleCloseDrawer();
  };

  const handleRemove = (index: number): void => {
    const auxArray = [...connectionsList];
    auxArray.splice(index, 1);

    setConnectionsList(auxArray);
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Update Connections"
      title="Update Connections"
      disableAction={isEqual(originalDeviceType?.capabilities, connections)}
      actionCallback={handleAddConnectionsCallback}
      onCloseDrawer={handleCloseClick}
      content={
        <Box>
          {connectionsList.map((item, index) => (
            <Grid
              key={index}
              container
              direction="row"
              spacing={2}
              className="mt-2"
              alignItems="center"
            >
              <Grid item xs={5}>
                <Typography variant="subtitle2" className="custom-label">
                  Connection Type
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <Select
                    value={item.type}
                    onChange={handleChange(index, 'type')}
                  >
                    {Object.keys(connectionsLabel).map((key) => (
                      <MenuItem key={key} dense value={key}>
                        {connectionsLabel[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={5}>
                <Typography variant="subtitle2" className="custom-label">
                  Connection Name
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <OutlinedInput
                    name="location"
                    placeholder="Name"
                    type="text"
                    required={true}
                    value={item.name}
                    onChange={handleChange(index, 'name')}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2} className="mt-6">
                <IconButton onClick={(): void => handleRemove(index)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Button
            variant="outlined"
            size="medium"
            className="mt-4"
            onClick={addNewConnection}
            startIcon={<AddIcon />}
          >
            Add new
          </Button>
        </Box>
      }
    />
  );
};

export default ConnectionsDrawer;
