import React, { useRef, useState } from 'react';
import { IconButton, InputAdornment, Popover } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ColorPicker, { ColorObject, themes } from 'react-pick-color';

import TextInput from '../TextInput';

interface ColorPickerInputProps {
  value?: string;
  prop: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  onInputChange: (prop: string, value: string | number) => void;
}

const ColorPickerInput: React.FC<ColorPickerInputProps> = ({
  value,
  prop,
  label,
  placeholder,
  required,
  disabled,
  onInputChange,
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const [color, setColor] = useState(value);
  const [open, setOpen] = useState(false);

  const handleOpenPicker = (): void => {
    setOpen(!open);
  };

  const handleClose = (): void => {
    setOpen(!open);
  };

  const handleColorChange = (colorObject: ColorObject): void => {
    const { hex } = colorObject;
    if (color !== hex) {
      setColor(hex);
      onInputChange(prop, hex);
    }
  };

  return (
    <div ref={anchorRef}>
      <TextInput
        value={value}
        prop={prop}
        label={label}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        onInputChange={onInputChange}
        handleClick={disabled ? handleOpenPicker : undefined}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label={`${prop}-edit-color-picker`}
              onClick={handleOpenPicker}
              size="small"
              data-testid="picker-button"
            >
              <EditIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <Popover
        id={`${prop}-color-picker`}
        open={open}
        anchorEl={anchorRef.current}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
      >
        <ColorPicker
          color={color}
          hideAlpha={true}
          theme={themes.dark}
          onChange={handleColorChange}
        />
      </Popover>
    </div>
  );
};

export default ColorPickerInput;
