import React from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { Device, DeviceType } from '@edgeiq/edgeiq-api-js';

import TextInput from '../../../components/TextInput';

interface ImportantMetadataProps {
  device: Device;
  deviceType?: DeviceType | null;
  onInputChange?: (key: string, value: string | number) => void;
}

const ImportantMetadata: React.FC<ImportantMetadataProps> = ({
  device,
  deviceType,
  onInputChange,
}) => {
  return deviceType?.important_metadata &&
    deviceType.important_metadata.length !== 0 &&
    device.metadata !== undefined ? (
    <Box className="mt-6">
      <Typography variant="h5">Important Metadata</Typography>
      <p className="mt-3">
        Selected device metadata. This list can be configured on the device
        profile.
      </p>
      <Grid container direction="column" spacing={2}>
        {deviceType.important_metadata.map((metadataKey, index) => (
          <Grid item xs={12} key={`important-metadata-${index}`}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} sm={6} className="mt-3">
                <TextInput
                  label="Key"
                  prop="metadata"
                  dataCy={`important-metadata-${index}-key`}
                  disabled={true}
                  value={metadataKey}
                  onInputChange={onInputChange}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="mt-3">
                <TextInput
                  label="Value"
                  prop={metadataKey}
                  dataCy={`important-metadata-${index}-value`}
                  value={(device.metadata?.[metadataKey] as string) ?? ''}
                  onInputChange={onInputChange}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  ) : (
    <></>
  );
};

export default ImportantMetadata;
