import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.grey[200],
    },
    chosenButton: {
      backgroundColor: theme.palette.grey.A100,
      color: theme.palette.primary.main,
    },
    configSubtitle: {
      alignItems: 'center',
      color: theme.palette.grey[200],
      display: 'flex',
    },
    configTitle: {
      color: theme.palette.grey[100],
    },
    drawerDeviceContainer: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey.A100,
      display: 'flex',
      justifyContent: 'space-between',
    },
    drawerSelectedDevices: {
      maxHeight: '150px',
      overflow: 'auto',
    },
    errorButton: {
      // backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
    },
    heartbeatInput: {
      width: '100px',
    },
    infoIcon: {
      color: theme.palette.grey[200],
    },
    input: {
      width: '350px',
    },
    invalidJson: {
      border: '1px solid red',
    },
    invalidLabel: {
      color: 'red',
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontWeight: 700,
    },
    policiesContainer: {
      // The height of 4 policies
      height: '228px',
      overflow: 'auto',
    },
    policy: {
      alignItems: 'center',
      borderTop: `1px solid ${theme.palette.grey.A200}`,
      display: 'flex',
      justifyContent: 'space-between',
    },
    publishContainer: {
      height: '100%',
    },

    resizePayload: {
      resize: 'both',
    },
    title: {
      fontWeight: 700,
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
  }),
);

export default useStyles;
