import { Typography } from '@mui/material';
import { TableColumn, TableItemType } from '../../../components/SharedTable';
import { BulkJob } from '../../../models/common';
import timeHelpers from '../../../helpers/timeHelpers';
import { formatReadableActionName } from '../../../helpers/utils';

export const BulkJobsColumns = (
  bulkJobs: BulkJob[],
  handleActionClick: (item: BulkJob, action: 'details') => void,
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const bulkJob = item as BulkJob;
        return `${timeHelpers.getDate(bulkJob.response.created_at)}`;
      },
      id: 'created_at',
      label: 'Date',
      type: 'text',
    },
    {
      id: 'summary',
      label: 'Summary',
      parser: (item: TableItemType): string | React.ReactElement => {
        const bulkJob = item as BulkJob;
        return bulkJob.summary;
      },
      type: 'custom',
    },
    {
      cellValue: (item: TableItemType): string => {
        const bulkJob = item as BulkJob;
        return `${bulkJob.successes}`;
      },
      id: 'successes',
      label: 'successes',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const bulkJob = item as BulkJob;
        return `${bulkJob.failures}`;
      },
      id: 'failues',
      label: 'Failues',
      type: 'text',
    },
    {
      cellValue: (item: TableItemType): string => {
        const bulkJob = item as BulkJob;
        return formatReadableActionName(bulkJob.response.type);
      },
      id: 'action',
      label: 'Action',
      type: 'text',
    },
    {
      id: 'details',
      label: 'Details',
      parser: (item: TableItemType): React.ReactElement => {
        const bulkJob = item as BulkJob;
        return (
          <Typography
            variant="button"
            style={{ cursor: 'pointer' }}
            onClick={(): void => handleActionClick(bulkJob, 'details')}
          >
            View Details
          </Typography>
        );
      },
      type: 'custom',
    },
  ];
};
