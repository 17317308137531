import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button, Divider, Grid, Typography } from '@mui/material';
import {
  CommandExecution,
  CommandExecutions,
  CommandExecutionsStatus,
  Device,
  Devices,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { commandExecutionColorThemeMap } from '../../../app/constants';
import ColoredBox from '../../../components/ColoredBox';
import { RightDrawer } from '../../../containers/RightDrawer';
import UserAvatar from '../../../containers/UserAvatar';
import timeHelpers from '../../../helpers/timeHelpers';
import copyTextToClipboard from '../../../helpers/copyTextToClipboard';
import {
  getCommandExecutionStatus,
  sortCommandExecutionStatuses,
} from '../../../helpers/commandExecution';
import useStyles from './styles';

interface CommandExecutionInfoDrawerProps {
  open: boolean;
  commandExecution?: CommandExecution;
  handleCloseDrawer: () => void;
}

const CommandExecutionInfoDrawer: React.FC<CommandExecutionInfoDrawerProps> = ({
  open,
  commandExecution,
  handleCloseDrawer,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const [executionStatus, setExecutionStatus] = useState<string>();
  const [executionOutput, setExecutionOutput] = useState<string>();
  const [executionDevice, setExecutionDevice] = useState<Device>();
  const [executionStatuses, setExecutionStatuses] = useState<
    CommandExecutionsStatus[]
  >([]);

  const getCommandExecutionOutput = (): void => {
    if (commandExecution) {
      CommandExecutions.getCommandExecutionsOutput(commandExecution._id)
        .then((result) => {
          if (typeof result === 'object') {
            result = JSON.stringify(result);
          }
          setExecutionOutput(result);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getCommandExecutionDevice = (): void => {
    if (commandExecution) {
      Devices.getOneById(commandExecution.device_id)
        .then((result) => {
          setExecutionDevice(result);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    setExecutionOutput('');
    if (commandExecution) {
      if (commandExecution) {
        const statuses = sortCommandExecutionStatuses(commandExecution);
        setExecutionStatuses(statuses);
        setExecutionStatus(getCommandExecutionStatus(statuses));
      }
      getCommandExecutionOutput();
      getCommandExecutionDevice();
    }
  }, [commandExecution]);

  const goToDevice = (): void => {
    if (executionDevice) {
      navigate(`/device/${executionDevice._id}`);
    }
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      titleComponent={
        <>
          <Typography variant="h5" className="custom-label">
            {`Execution Id: ${commandExecution?._id} `}
          </Typography>
          {executionStatus && (
            <ColoredBox
              colorTheme={commandExecutionColorThemeMap[executionStatus]}
              type="command_execution_state"
              value={executionStatus}
            />
          )}
        </>
      }
      content={
        <Grid container spacing={2} className={classes.container}>
          <Grid item xs={8} className={classes.deviceName}>
            {executionDevice ? (
              <Typography variant="button">
                Device:
                <Button
                  color="primary"
                  variant="text"
                  type="button"
                  onClick={goToDevice}
                >
                  {executionDevice.unique_id}
                </Button>
              </Typography>
            ) : (
              <Typography variant="subtitle2" className="custom-label">
                {`Device: ${commandExecution?.device_id}`}
              </Typography>
            )}
          </Grid>
          <Grid item xs={4}>
            <UserAvatar
              userId={commandExecution?.user_id as string}
              hideErrorToast={true}
              preAvatarText="Executed by:"
              preAvatarTextClass="custom-label"
              preAvatarTextVariant="subtitle2"
            />
          </Grid>
          {executionOutput && (
            <Grid
              item
              xs={12}
              className={clsx('scrollbar', classes.executionInfoContainer)}
            >
              <Button
                color="primary"
                variant="text"
                type="button"
                onClick={copyTextToClipboard(executionOutput)}
              >
                Click to copy output
              </Button>
              <pre className={classes.executionInfo}>{executionOutput}</pre>
            </Grid>
          )}
          {executionStatuses &&
            executionStatuses.map((item) => (
              <div key={item._id} className="w-100">
                <Divider className="my-2" />
                <Grid
                  item
                  xs={12}
                  className={clsx('scrollbar', classes.executionInfoContainer)}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography variant="caption" color="white">
                        {timeHelpers.getDate(item.created_at, false, true)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <ColoredBox
                        colorTheme={commandExecutionColorThemeMap[item.status]}
                        type="command_execution_state"
                        value={item.status}
                      />
                    </Grid>
                    {item.status !== 'initiated' && (
                      <Grid item xs={12}>
                        <pre className={classes.executionInfo}>
                          {item?.message}
                        </pre>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </div>
            ))}
        </Grid>
      }
    />
  );
};

export default CommandExecutionInfoDrawer;
