import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: 'center',
      backgroundColor: theme.palette.info.main,
      display: 'flex',
      justifyContent: 'center',
      width: 'fit-content',
    },
    copyIcon: {
      color: theme.palette.primary.main,
    },
    highlight: {
      alignItems: 'center',
      color: theme.palette.warning.dark,
      display: 'flex',
      textTransform: 'uppercase',
    },
    infoIcon: {
      color: theme.palette.common.white,
    },
    label: {
      color: theme.palette.info.dark,
      textTransform: 'uppercase',
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontWeight: 700,
    },
    loadMoreContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    scriptContainer: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'space-between',
    },
    subtitle: {
      color: theme.palette.grey[200],
    },
    tag: {
      alignItems: 'center',
      color: theme.palette.grey[100],
      display: 'flex',
    },
    tagIcon: {
      color: theme.palette.grey[200],
    },
    title: {
      color: theme.palette.grey[100],
      fontWeight: 700,
      width: '225px',
    },
  }),
);

export default useStyles;
