import React, { useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import {
  Command,
  Commands,
  DeviceTypes,
  Translators,
} from '@edgeiq/edgeiq-api-js';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { commandsSenderTypes, errorHighlight } from '../../../app/constants';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import {
  setSelectedCommands,
  setOriginalSelectedCommands,
} from '../../../redux/reducers/commands.reducer';
import { setStateTranslators } from '../../../redux/reducers/translators.reducer';

import TabsPage from '../../../components/TabsPage';
import AttachCommandsDrawer from '../../../containers/RightDrawer/AttachCommands/AttachCommandsDrawer';

const DeviceTypeCommands: React.FC = () => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedCommands = useAppSelector(
    (state: RootState) => state.commands.selectedCommands,
  );
  const originalCommands = useAppSelector(
    (state: RootState) => state.commands.originalSelectedCommands,
  );
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const userCompanies = useAppSelector(
    (state: RootState) => state.user.userCompanies,
  );

  const translators = useAppSelector(
    (state: RootState) => state.translators.translators,
  );

  useEffect(() => {
    if (!translators.length) {
      Translators.list().then((response) => {
        dispatch(setStateTranslators(response.translators));
      });
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <strong>{params.row.name}</strong>
      ),
    },
    {
      field: 'company_id',
      flex: 0.5,
      headerName: 'Account',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            userCompanies.find(
              (company) => company._id === params.row.company_id,
            )?.name
          }
        </span>
      ),
    },
    {
      field: 'sender_type',
      flex: 1,
      headerName: 'Sender Type',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <strong>{commandsSenderTypes[params.row.sender_type]}</strong>
      ),
    },
    {
      field: 'translator_id',
      flex: 0.5,
      headerName: 'Translator',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            translators.find(
              (translator) => translator._id === params.row.translator_id,
            )?.name
          }
        </span>
      ),
    },
  ];

  const handleOpenCommandsDrawer = (): void => {
    setOpen(true);
  };

  const handleCloseCommandsDrawer = (): void => {
    setOpen(false);
  };

  const handleChooseCommands = (newCommands: Command[]): void => {
    dispatch(setSelectedCommands(newCommands));
    handleCloseCommandsDrawer();
    setLoading(true);

    const attachCommands = newCommands.filter((newCommand) =>
      originalCommands.every(
        (originalPolicy) => newCommand._id !== originalPolicy._id,
      ),
    );
    const detachCommands = originalCommands.filter((command) =>
      newCommands.every((newCommand) => command._id !== newCommand._id),
    );

    Promise.all([
      Promise.all(
        attachCommands.map(async (attachCommand) => {
          await Commands.assignToDeviceType(attachCommand._id, id as string);
        }),
      ),
      Promise.all(
        detachCommands.map(async (detachCommand) => {
          await Commands.removeFromDeviceType(detachCommand._id, id as string);
        }),
      ),
    ])
      .then(() => {
        dispatch(setOriginalSelectedCommands(newCommands));
        dispatch(
          setAlert({
            highlight: 'Managing commands',
            message: 'Commands successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while managing commands.',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editableDeviceType) {
      setLoading(true);

      DeviceTypes.getCommands(editableDeviceType._id)
        .then((response) => {
          dispatch(setSelectedCommands(response));
          dispatch(setOriginalSelectedCommands(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDeviceType]);

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedCommands}
        addButtonLabel="Attach commands"
        onAddClick={handleOpenCommandsDrawer}
        tableTitle="Commands attached"
        loading={loading}
      />
      <AttachCommandsDrawer
        open={open}
        handleCloseDrawer={handleCloseCommandsDrawer}
        itemCommands={true}
        handleChooseCommands={handleChooseCommands}
      />
    </Box>
  );
};

export default DeviceTypeCommands;
