import React, { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Paper } from '@mui/material';
import clsx from 'clsx';

import useStyles from './styles';

interface CardProps {
  content: JSX.Element;
  checked: boolean;
  id: string;
  baseLink?: string;
  checkboxCallback: (
    id: string,
  ) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  onClickCallback?: (id: string) => void;
  extraClasses?: string;
}

const Card: React.FC<CardProps> = ({
  content,
  checked,
  id,
  baseLink,
  checkboxCallback,
  onClickCallback,
  extraClasses = '',
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (): void => {
    if (baseLink) {
      navigate(`${baseLink}/${id}`);
    } else if (onClickCallback) {
      onClickCallback(id);
    }
  };

  return (
    <Paper
      data-testid="card-container"
      className={clsx(
        'shadow p-4',
        classes.card,
        {
          [classes.hoverCard]: !!baseLink,
        },
        extraClasses,
      )}
    >
      <div
        className={clsx('checkboxContainer mr-1', classes.checkboxContainer, {
          ['checked']: checked,
        })}
      >
        <Checkbox
          data-testid={`${id}-checkbox`}
          className="p-0"
          checked={checked}
          onChange={checkboxCallback(id)}
        />
      </div>
      <div role="article" className={classes.cardContent} onClick={handleClick}>
        {content}
      </div>
    </Paper>
  );
};

export default Card;
