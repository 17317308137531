import { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AlertAction } from './reducers/alert.reducer';
import { CommandsAction } from './reducers/commands.reducer';
import { CommandsExecutionsAction } from './reducers/commandsExecutions.reducer';
import { CompaniesAction } from './reducers/companies.reducer';
import { ConfigsAction } from './reducers/configs.reducer';
import { ConfigurationAction } from './reducers/configuration.reducer';
import { DevicesAction } from './reducers/devices.reducer';
import { DeviceTypesAction } from './reducers/deviceTypes.reducer';
import { DeviceTransferRequestAction } from './reducers/deviceTransfer.reducer';
import { FilesAction } from './reducers/files.reducer';
import { FiltersAction } from './reducers/filters.reducer';
import { IngestorsAction } from './reducers/ingestors.reducer';
import { IntegrationsAction } from './reducers/integrations.reducer';
import { PoliciesAction } from './reducers/policies.reducer';
import { PollableAttributesAction } from './reducers/pollableAttributes.reducer';
import { SecretsAction } from './reducers/secrets.reducer';
import { SettingAction } from './reducers/setting.reducer';
import { SettingsApplicationRecordAction } from './reducers/settingsApplicationRecord.reducer';
import { SoftwareUpdatesAction } from './reducers/softwareUpdates.reducer';
import { TranslatorsAction } from './reducers/translators.reducer';
import { UserAction } from './reducers/user.reducer';
import { UsersAction } from './reducers/users.reducer';
import { UserTypesAction } from './reducers/userTypes.reducer';
import type { AppDispatch } from './store';
import { HeartbeatsAction } from './reducers/heartbeats.reducer';
import { MessageReportsAction } from './reducers/reports.reducer';
import { GatewayCommandsAction } from './reducers/gatewayCommands';
import { DeviceErrorsAction } from './reducers/deviceError';
import { IntegrationEventAction } from './reducers/integrationEvents.reducer';
import { ScheduledJobAction } from './reducers/scheduledJobs.reducer';

export type ActionDispatcher = Dispatch<
  | AlertAction
  | CompaniesAction
  | CommandsAction
  | CommandsExecutionsAction
  | ConfigurationAction
  | DevicesAction
  | DeviceTypesAction
  | DeviceTransferRequestAction
  | FilesAction
  | FiltersAction
  | IngestorsAction
  | IntegrationsAction
  | PoliciesAction
  | PollableAttributesAction
  | TranslatorsAction
  | UserAction
  | UsersAction
  | UserTypesAction
  | SecretsAction
  | SettingAction
  | SettingsApplicationRecordAction
  | SoftwareUpdatesAction
  | ConfigsAction
  | HeartbeatsAction
  | MessageReportsAction
  | GatewayCommandsAction
  | DeviceErrorsAction
  | IntegrationEventAction
  | ScheduledJobAction
>;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): ActionDispatcher =>
  useDispatch<AppDispatch>();
export const useAppSelector = useSelector;
