import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  PaginationFilter,
  ScheduledJob,
  ScheduledJobs,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  genericViewOptions,
} from '../../app/constants';
import { SortingOption, genericSorting } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import ListSelection from '../../components/ListSelection';
import SharedTable from '../../components/SharedTable';
import CardsGrid from '../../components/CardsGrid';
import Card from '../../components/Card';
import ActionDialog from '../../components/ActionDialog';
import Header from '../../containers/HeaderWithActionButton';
import useStyles from './styles';
import ScheduledJobsFilters from './ScheduledJobsFilters';
import { setStateScheduledJobs } from '../../redux/reducers/scheduledJobs.reducer';
import { ScheduledJobColumns } from './columns';
import ScheduledJobCard from './ScheduledJobCard';
import { usePageSetter } from '../../hooks/usePageSetter';

const ScheduledJobsPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const [jobStatus, setJobStatus] = useState<boolean>();
  const scheduledJobsState = useAppSelector(
    (state: RootState) => state.scheduledJobs,
  );
  const [selectedScheduledJobs, setSelectedScheduledJobs] = useState<string[]>(
    [],
  );
  const [scheduledJobs, setJobs] = useState<ScheduledJob[]>(
    scheduledJobsState.scheduledJobs,
  );
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.scheduledJobs.sortBy, genericSorting),
  );
  const [selectedView, setSelectedView] = useState(filters.scheduledJobs.view);
  const { setTotalAndPage, total, page, setTotal } = usePageSetter(0, 1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const setScheduledJobs = (nextScheduledJobs: ScheduledJob[]): void => {
    if (typeof jobStatus !== 'boolean') {
      setJobs(nextScheduledJobs);
      return;
    }
    setJobs(
      nextScheduledJobs.filter(
        (scheduledJob) => scheduledJob.enabled === jobStatus,
      ),
    );
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getScheduledJobs = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    ScheduledJobs.list(
      parseFilters(filters.scheduledJobs.filters ?? {}),
      pagination,
    )
      .then((result) => {
        const newScheduledJobs = addPage
          ? [...scheduledJobs, ...result.scheduledJobs]
          : result.scheduledJobs;
        setScheduledJobs(newScheduledJobs);
        dispatch(setStateScheduledJobs(newScheduledJobs));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    whenComponetMounted(1);
  }, [filters.scheduledJobs]);

  useEffect(() => {
    setSelectedScheduledJobs([]);
    setScheduledJobs(scheduledJobsState.scheduledJobs);
  }, [jobStatus]);

  const whenComponetMounted = (newPage: number): void => {
    setLoading(true);
    setSelectedScheduledJobs([]);
    getScheduledJobs(newPage);
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getScheduledJobs(page + 1, true);
  };

  const checkScheduledJobCallback =
    (deviceId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedScheduledJobs([...selectedScheduledJobs, deviceId]);
      } else {
        setSelectedScheduledJobs(
          selectedScheduledJobs.filter((item) => item !== deviceId),
        );
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'scheduledJobs'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'scheduledJobs'));
    setSelectedView(view);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleSelectAll = (): void => {
    if (selectedScheduledJobs.length !== scheduledJobs.length) {
      setSelectedScheduledJobs(
        scheduledJobs.map((scheduledJob) => scheduledJob._id),
      );
    } else {
      setSelectedScheduledJobs([]);
    }
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);

    const deleteApis = selectedScheduledJobs.map((e) => {
      return ScheduledJobs.delete(e);
    });

    Promise.all(deleteApis)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(
              selectedScheduledJobs.length,
              'Scheduled Jobs',
              'Scheduled Jobs',
            ),
            type: 'success',
          }),
        );
        whenComponetMounted(page);
        setTotal(total - selectedScheduledJobs.length);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoadingDelete(false);
        closeDeleteModal();
      });
  };

  const handleJobStatus = (isEnabled?: boolean): void => {
    setJobStatus(isEnabled);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Scheduled Jobs"
        link="new-scheduled-job"
        actionLabel="Create New Scheduled Job"
        model={'scheduled_job'}
      />
      <ScheduledJobsFilters handleJobStatus={handleJobStatus} total={total} />

      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={genericSorting}
        selectedLabel="scheduled job"
        viewsOptions={genericViewOptions}
        itemsSelected={selectedScheduledJobs.length !== 0}
        itemsSelectedCount={selectedScheduledJobs.length}
        allSelected={
          scheduledJobs.length !== 0 &&
          selectedScheduledJobs.length === scheduledJobs.length
        }
        deleteAction={true}
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && (
            <CardsGrid
              cards={scheduledJobs.map((scheduledJob) => (
                <Card
                  checked={selectedScheduledJobs.includes(scheduledJob._id)}
                  checkboxCallback={checkScheduledJobCallback}
                  id={scheduledJob._id}
                  baseLink="/scheduled-job"
                  content={<ScheduledJobCard scheduledJob={scheduledJob} />}
                />
              ))}
            />
          )}
          {selectedView === 'list' && (
            <SharedTable
              columns={ScheduledJobColumns(classes)}
              rows={scheduledJobs}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={
                selectedScheduledJobs.length === scheduledJobs.length
              }
              loading={loading}
              selectedItemsIds={selectedScheduledJobs}
              onRequestSort={handleTableSorting}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkScheduledJobCallback}
            />
          )}
          {scheduledJobs.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedScheduledJobs.length === 1
                ? 'scheduled job'
                : 'scheduled jobs'
            }:`}</span>
            <ul>
              {scheduledJobs
                .filter((scheduledJob) =>
                  selectedScheduledJobs.includes(scheduledJob._id),
                )
                .map((scheduledJob) => (
                  <li key={scheduledJob._id}>
                    {scheduledJob.job_name} - Id: {scheduledJob._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default ScheduledJobsPage;
