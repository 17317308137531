import { Company } from '@edgeiq/edgeiq-api-js';

const getCompanyParent = (
  companyId: string,
  companies?: Company[],
): Company | undefined => {
  let companyParent: Company | undefined = undefined;
  if (companies && companies.length !== 0) {
    companyParent = companies.find((item) => item._id === companyId);
  }

  return companyParent;
};

export default getCompanyParent;
