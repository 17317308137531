import React from 'react';
import { Button, Box, Grid, MenuItem, Paper, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TextInput from '../../../components/TextInput';
import {
  Configuration,
  ConfigurationInput,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import Accordion from '../../../components/Accordion';
import ConfigurationSettingForm from './ConfigurationSettingForm';
import SelectInput from '../../../components/SelectInput';
import useStyles from './styles';

interface ConfigurationForm {
  configuration: Configuration | ConfigurationInput;
  action: string;
  deviceTypes: DeviceType[];
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
  onAddNewConfigurationValue: () => void;
  onDynamicRowsChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
    parentIndex: number,
  ) => void;
  handleAddRow: (prop: string, index: number) => void;
  handleRemoveRow: (prop: string, index: number, parentIndex: number) => void;
  onRemoveArrayRow: (index: number) => void;
}

const ConfigurationForm: React.FC<ConfigurationForm> = ({
  configuration,
  action,
  deviceTypes,
  onInputChange,
  onAddNewConfigurationValue,
  onDynamicRowsChange,
  handleAddRow,
  handleRemoveRow,
  onRemoveArrayRow,
}) => {
  const handleDynamicChange =
    (prop: string, index: number | string) =>
    (field: string, value: string | number): void => {
      onInputChange(prop, value, field, index as number);
    };

  const classes = useStyles();

  const handleClickAddDerivedValue = () => (): void => {
    onAddNewConfigurationValue();
  };

  const configurationSettingList = (): JSX.Element[] | JSX.Element => {
    const { configurable_settings } = configuration || {};

    if (configurable_settings) {
      return configurable_settings.map((item, index) => {
        return (
          <Accordion
            key={`config-setting-accordion-${index}`}
            title={item.key || `Untitled Setting`}
            content={
              <ConfigurationSettingForm
                onInputChange={onInputChange}
                configurableSettings={item}
                configIndex={index}
                onDynamicRowsChange={onDynamicRowsChange}
                handleAddRow={handleAddRow}
                handleRemoveRow={handleRemoveRow}
                onRemoveArrayRow={onRemoveArrayRow}
              />
            }
          />
        );
      });
    }
    return <strong>There is no configuration settings.</strong>;
  };

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <Paper className="shadow p-8">
            <Box className={clsx('mb-6', classes.titleContainer)}>
              {action === 'create' && (
                <Typography variant="h5" className={classes.title}>
                  Create a new Configuration
                </Typography>
              )}
            </Box>
            <Grid container direction="row" className="mt-2" spacing={2}>
              <Grid item xs={12}>
                <TextInput
                  prop="name"
                  label="Name*"
                  placeholder=""
                  type="text"
                  value={configuration?.name as string}
                  onInputChange={handleDynamicChange('name', '')}
                />
              </Grid>
              <Grid item xs={12} className="mt-6 ">
                <SelectInput
                  label="Device Profile*"
                  prop="device_type_id"
                  value={configuration?.device_type_id || ''}
                  onSelectChange={handleDynamicChange('device_type_id', '')}
                  options={deviceTypes?.map((type) => (
                    <MenuItem dense key={type._id} value={type._id}>
                      {type.name}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={4} className="d-flex flex-items-center mt-8">
                <Typography variant="h5" className={classes.title}>
                  Configurable Settings
                </Typography>
              </Grid>
              <Grid item xs={6} className="d-flex flex-items-center mt-8">
                <Button
                  className="w-100"
                  variant="outlined"
                  size="large"
                  onClick={handleClickAddDerivedValue()}
                  startIcon={<AddIcon />}
                >
                  Add Another Configurable Setting
                </Button>
              </Grid>
            </Grid>

            {configurationSettingList()}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfigurationForm;
