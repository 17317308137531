import React, { ChangeEvent } from 'react';
import {
  Grid,
  Typography,
  FormControl,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import PasswordInputWithAlert from '../PasswordInputWithAlert/PasswordInputWithAlert';

interface DeviceMqttPasswordProps {
  mqttPasswordProp?: string;
  mqttPassword?: string;
  enforceSecureMqttProp?: string;
  enforceSecureMqtt?: boolean;
  hasPasswordDisclaimer?: boolean;
  hasTitle?: boolean;
  passwordChangeInProgress?: boolean;
  onInputChange: (prop: string, value: string | boolean) => void;
}

const DeviceMqttPassword: React.FC<DeviceMqttPasswordProps> = ({
  mqttPasswordProp = 'mqtt_password',
  mqttPassword: mqtt_password = '',
  enforceSecureMqttProp = 'enforce_secure_mqtt',
  enforceSecureMqtt: enforce_secure_mqtt = false,
  hasPasswordDisclaimer = false,
  hasTitle = false,
  passwordChangeInProgress = false,
  onInputChange,
}) => {
  const handleChangePassword = (prop: string, value: string | number): void => {
    if (typeof value === 'string') {
      onInputChange(prop, value);
    } else if (typeof value === 'number') {
      onInputChange(prop, value.toString());
    }
  };

  const handleChangeEnforceSecureMqtt = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ): void => {
    onInputChange(enforceSecureMqttProp, checked);
  };

  return (
    <>
      {hasTitle && (
        <Typography className="mt-6 mb-3" variant="h5">
          MQTT
        </Typography>
      )}
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          {/* <Typography variant="subtitle2" className="custom-label"> */}
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  checked={enforce_secure_mqtt}
                  onChange={handleChangeEnforceSecureMqtt}
                />
              }
              label="Enforce secure MQTT communication (requires edge > v3.0)"
            />
          </FormControl>
          {/* </Typography> */}
        </Grid>
        <Grid item xs={12} sm={12}>
          <PasswordInputWithAlert
            label={enforce_secure_mqtt ? 'MQTT Password *' : 'MQTT Password'}
            placeholder={
              enforce_secure_mqtt ? 'MQTT Password *' : 'MQTT Password'
            }
            prop={mqttPasswordProp}
            required={enforce_secure_mqtt}
            value={mqtt_password}
            onInputChange={handleChangePassword}
            hasCopyButton={true}
            disabled={passwordChangeInProgress}
          />
        </Grid>
        {passwordChangeInProgress && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="custom-label">
              <p>
                <b>Password Change in Progress</b>
              </p>
              <p>
                A password change is in progress for this device. Unless the
                device reconnects with the new password at least once, the
                password cannot be changed. This is to protect against losing
                access to the device.
              </p>
              <p>
                If you want to force a password change, please issue the "Cancel
                Password Change" Gateway Command to revert back to the old
                password and then change the password again.
              </p>
            </Typography>
          </Grid>
        )}
        {hasPasswordDisclaimer && (
          <Grid item xs={12}>
            <Typography variant="subtitle1" className="custom-label">
              Please enter an MQTT password to be used for the device. An MQTT
              password must be set unless you choose to disable 'Enforce secure
              MQTT communication' for this device. In that case, leaving the
              password field empty will use the default shared password. It is
              strongly recommended to always use a custom password and leave
              'Enforce secure MQTT communication' enabled.
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default DeviceMqttPassword;
