import {
  createdNewestSorting,
  createdOldestSorting,
  SortingOption,
} from '../../models/common';

export const sortingOptions: SortingOption[] = [
  createdOldestSorting,
  createdNewestSorting,
  {
    label: '(A-Z)',
    value: 'name',
  },
];
