import React, { useState, useEffect } from 'react';
import { Grid, MenuItem } from '@mui/material';

import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import SwitchButton from '../../../../components/SwitchButton';
import {
  deviceConfigConnectionAssignLabel,
  deviceConfigConnectionNetModeLabel,
  deviceConfigConnectionTimeLabel,
} from '../../../../app/constants';
import {
  EMPTY_CONFIG_CONNECTION_WAN,
  EMPTY_CONFIG_CONNECTION_WANLAN,
} from '../../../../constants/configConnections';

interface DeviceConfigConnectionFormProps {
  deviceConnection?: { [key: string]: unknown };
  connectionType: string;
  configIndex: number;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
}

const DeviceConfigConnectionForm: React.FC<DeviceConfigConnectionFormProps> = ({
  configIndex,
  deviceConnection,
  connectionType,
  onInputChange,
}) => {
  const [configConnection, setConfigConnection] = useState<{
    [key: string]: unknown;
  }>(
    deviceConnection ||
      EMPTY_CONFIG_CONNECTION_WAN ||
      EMPTY_CONFIG_CONNECTION_WANLAN,
  );

  const [connectionTimeType, setConnectionTimeType] = useState<string>('Days');
  const [dhcpLeaseValue, setDhcpLeaseValue] = useState(1);

  const handerLocalConfigConnection = (
    prop: string,
    value: string | number | boolean,
  ): void => {
    setConfigConnection((currentConfig) => {
      return (currentConfig = {
        ...currentConfig,
        [prop]: value,
      });
    });
  };

  useEffect(() => {
    const value = convertToSeconds() as number;
    onInputChange('dhcp_lease', value as number, '', configIndex);
  }, [dhcpLeaseValue]);

  const convertToSeconds = (time?: string): number | undefined => {
    const currentTimeType = time || connectionTimeType;
    switch (currentTimeType) {
      case deviceConfigConnectionTimeLabel.Seconds:
        return dhcpLeaseValue;
      case deviceConfigConnectionTimeLabel.Minutes:
        return dhcpLeaseValue * 60;
      case deviceConfigConnectionTimeLabel.Hours:
        return dhcpLeaseValue * 3600;
      case deviceConfigConnectionTimeLabel.Days:
        return dhcpLeaseValue * 86400;
    }
  };

  const handleDynamicChange =
    (prop: string, index: number) =>
    (field: string, value: string | number): void => {
      if (prop === 'dhcp_lease') {
        setDhcpLeaseValue(parseInt(value as string));
        return;
      }
      handerLocalConfigConnection(prop, value as string);
      onInputChange(prop, value, field, index);
    };

  const handleChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    if (prop === 'connectionTimeType') {
      prop = 'dhcp_lease';
      setConnectionTimeType(value as string);
      value = convertToSeconds(value as string) as number;
    }
    handerLocalConfigConnection(prop, value as boolean);
    onInputChange(prop, value as number, '', configIndex);
  };

  return (
    <>
      <Grid container direction="row" className="mt-2" spacing={2}>
        <Grid item xs={6}>
          <SwitchButton
            label="Enabled"
            value={configConnection?.net_enable as boolean}
            prop="net_enable"
            onSwitchChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <SwitchButton
            label="NAT"
            value={configConnection?.net_nat as boolean}
            prop="net_nat"
            onSwitchChange={handleChange}
          />
        </Grid>
        {connectionType !== 'ethernet-lan' && (
          <Grid item xs={12}>
            <SelectInput
              label="Second"
              prop="net_ip_assign"
              value={configConnection?.net_ip_assign as string}
              onSelectChange={handleDynamicChange('net_ip_assign', configIndex)}
              options={Object.keys(deviceConfigConnectionAssignLabel).map(
                (key, index) => (
                  <MenuItem className="m-4 p-2" key={index} dense value={key}>
                    {deviceConfigConnectionAssignLabel[key]}
                  </MenuItem>
                ),
              )}
            />
          </Grid>
        )}
        {configConnection.net_ip_assign !== 'auto' && (
          <>
            {' '}
            <Grid item xs={6}>
              <TextInput
                prop="net_ip"
                label="Ip Address"
                placeholder=""
                type="text"
                value={configConnection?.net_ip as string}
                onInputChange={handleDynamicChange('net_ip', configIndex)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="net_gateway"
                label="Gateway Ip"
                placeholder=""
                type="text"
                value={configConnection?.net_gateway as string}
                onInputChange={handleDynamicChange('net_gateway', configIndex)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="net_mask"
                label="Net Mask"
                placeholder=""
                type="text"
                value={configConnection?.net_mask as string}
                onInputChange={handleDynamicChange('net_mask', configIndex)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="net_broadcast"
                label="Broadcast Ip"
                placeholder=""
                type="text"
                value={configConnection?.net_broadcast as string}
                onInputChange={handleDynamicChange(
                  'net_broadcast',
                  configIndex,
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="net_dns_1"
                label="DNS 1"
                placeholder=""
                type="text"
                value={configConnection?.net_dns_1 as string}
                onInputChange={handleDynamicChange('net_dns_1', configIndex)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextInput
                prop="net_dns_2"
                label="DNS 2"
                placeholder=""
                type="text"
                value={configConnection?.net_dns_2 as string}
                onInputChange={handleDynamicChange('net_dns_2', configIndex)}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <TextInput
            prop="net_mtu"
            label="Max Transmission Unit"
            placeholder=""
            type="number"
            value={configConnection?.net_mtu as number}
            onInputChange={handleDynamicChange('net_mtu', configIndex)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            prop="net_metric"
            label="Metric"
            placeholder=""
            type="number"
            value={configConnection.net_metric as number}
            onInputChange={handleDynamicChange('net_metric', configIndex)}
          />
        </Grid>
        {connectionType && (
          <>
            {connectionType !== 'ethernet-lan' && (
              <Grid item xs={12}>
                <SelectInput
                  label="Net Mode"
                  prop="net_mode"
                  value={configConnection?.net_mode as string}
                  onSelectChange={handleDynamicChange('net_mode', configIndex)}
                  options={Object.keys(deviceConfigConnectionNetModeLabel).map(
                    (key, index) => (
                      <MenuItem
                        className="m-4 p-2"
                        key={index}
                        dense
                        value={key}
                      >
                        {deviceConfigConnectionNetModeLabel[key]}
                      </MenuItem>
                    ),
                  )}
                />
              </Grid>
            )}
            {configConnection?.net_mode !== 'WAN' && (
              <>
                <Grid item xs={6}>
                  <SwitchButton
                    label="DHCP Enabled"
                    value={configConnection?.dhcp_enable as boolean}
                    prop="dhcp_enable"
                    onSwitchChange={handleChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SwitchButton
                    label="DHCP Relay"
                    value={configConnection?.dhcp_relay as boolean}
                    prop="dhcp_relay"
                    onSwitchChange={handleChange}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextInput
                    prop="dhcp_dns1"
                    label="DHCP DNS 1"
                    placeholder=""
                    type="text"
                    value={configConnection?.dhcp_dns1 as string}
                    onInputChange={handleDynamicChange(
                      'dhcp_dns1',
                      configIndex,
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    prop="dhcp_dns2"
                    label="DHCP DNS 2"
                    placeholder=""
                    type="text"
                    value={configConnection?.dhcp_dns2 as string}
                    onInputChange={handleDynamicChange(
                      'dhcp_dns2',
                      configIndex,
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    prop="dhcp_start"
                    label="DHCP Start"
                    placeholder=""
                    type="text"
                    value={configConnection?.dhcp_start as string}
                    onInputChange={handleDynamicChange(
                      'dhcp_start',
                      configIndex,
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    prop="dhcp_end"
                    label="DHCP End"
                    placeholder=""
                    type="text"
                    value={configConnection?.dhcp_end as string}
                    onInputChange={handleDynamicChange('dhcp_end', configIndex)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextInput
                    prop="dhcp_lease"
                    label="DHCP LEASE"
                    placeholder=""
                    type="number"
                    value={dhcpLeaseValue}
                    onInputChange={handleDynamicChange(
                      'dhcp_lease',
                      configIndex,
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectInput
                    label={'Time'}
                    prop={'connectionTimeType'}
                    value={connectionTimeType}
                    onSelectChange={handleChange}
                    options={Object.keys(deviceConfigConnectionTimeLabel).map(
                      (key, index) => (
                        <MenuItem
                          className="m-4 p-2"
                          key={index}
                          dense
                          value={key}
                        >
                          {deviceConfigConnectionTimeLabel[key]}
                        </MenuItem>
                      ),
                    )}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default DeviceConfigConnectionForm;
