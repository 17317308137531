import React, { useState } from 'react';
import { Box, Grid } from '@mui/material';
import { EIQFiles, Device } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import { RightDrawer } from '../../../containers/RightDrawer';
import TextInput from '../../../components/TextInput';
import DropZone from '../../../components/DropZone';

interface NewDeviceFileDrawerProps {
  device: Device;
  openDrawer: boolean;
  handleCloseDrawer: () => void;
  handleUploadSuccess: () => void;
}

const NewDeviceFileDrawer: React.FC<NewDeviceFileDrawerProps> = ({
  device,
  openDrawer,
  handleCloseDrawer,
  handleUploadSuccess,
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();
  const [fileName, setFileName] = useState<string>('');

  const fileUploadedSuccess = (): void => {
    dispatch(
      setAlert({
        highlight: `${uploadedFile?.name} uploaded correctly.`,
        type: 'success',
      }),
    );
    onRemoveFile();
    setFileName('');
    handleUploadSuccess();
  };

  const handleSubmitCallback = (): void => {
    if (uploadedFile) {
      const fileType = uploadedFile?.type || 'application/octet-string';
      setLoading(true);
      EIQFiles.create({
        company_id: device.company_id,
        device_id: device._id,
        mime_type: fileType,
        name: fileName,
      })
        .then((createdFile) => {
          return EIQFiles.uploadFile(createdFile.id, uploadedFile);
        })
        .then((fileUploaded) => {
          if (fileUploaded.mime_type !== fileType) {
            return EIQFiles.update({
              ...fileUploaded,
              mime_type: fileType,
            });
          }
          fileUploadedSuccess();
        })
        .then((_res) => {
          fileUploadedSuccess();
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onAddFile = (file: File): void => {
    setUploadedFile(file);
  };

  const onRemoveFile = (): void => {
    setUploadedFile(undefined);
  };

  const handleFileNameChange = (
    _prop: string,
    value: string | number,
  ): void => {
    setFileName(value as string);
  };

  return (
    <RightDrawer
      open={openDrawer}
      actionLabel="Submit"
      title={'Select Files'}
      disableAction={!uploadedFile}
      actionLoading={loading}
      actionCallback={handleSubmitCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <div>
          <Box>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} className="mb-6">
                <TextInput
                  label="File Name"
                  prop="name"
                  onInputChange={handleFileNameChange}
                />
              </Grid>
            </Grid>
          </Box>
          <DropZone
            title="Upload File"
            stateFiles={uploadedFile ? [uploadedFile] : []}
            onFileUpload={onAddFile}
            onRemoveFile={onRemoveFile}
          />
        </div>
      }
    />
  );
};

export default NewDeviceFileDrawer;
