import React from 'react';
import { Box, Typography, Avatar } from '@mui/material';
import { Company } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { stringAvatar } from '../../helpers/avatar';
import useStyles from './styles';

interface AccountCardProps {
  company?: Company;
}

const AccountCard: React.FC<AccountCardProps> = ({ company }) => {
  const classes = useStyles();
  const logoUrl = company?.branding?.logo_url;
  return (
    <Box sx={{ display: 'flex', width: 1 }}>
      {logoUrl ? (
        <div className={classes.logoContainer}>
          <img
            src={logoUrl}
            className={classes.accountLogo}
            alt="account-logo"
          />
        </div>
      ) : (
        <Avatar
          className={classes.avatar}
          {...stringAvatar(`${company?.name}`)}
        />
      )}
      <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: 5 }}>
        <Typography
          variant="h5"
          component="div"
          noWrap
          className={clsx('ml-5 mb-4 ', classes.title)}
        >
          {company?.name}
        </Typography>
        <Typography
          variant="button"
          component="div"
          className={clsx('ml-5 mb-4', classes.subtitle)}
        >
          {company?.created_at}
        </Typography>
      </Box>
    </Box>
  );
};

export default AccountCard;
