import {
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { Translators, TranslatorInput } from '@edgeiq/edgeiq-api-js';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  cleanNewCommandTranslator,
  setNewTranslator,
} from '../../redux/reducers/translators.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { RootState } from '../../redux/store';
import { errorHighlight } from '../../app/constants';
import Publish from '../../containers/Publish';
import TextInput from '../../components/TextInput';
import Header from '../../containers/HeaderWithActionButton';
import useStyles from './styles';
import {
  COMMAND_TRANSLATOR_TYPES,
  TRANSLATOR_TEMPLATE_TYPE,
} from '../../constants/translators';

const CreateCommandTranslator: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const translatorState = useAppSelector(
    (state: RootState) => state.translators,
  );
  const { newTranslator } = translatorState;

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    dispatch(cleanNewCommandTranslator());
  }, []);

  const handleChange = (
    prop: string,
    value: string | number | string[],
  ): void => {
    dispatch(
      setNewTranslator({
        ...newTranslator,
        [prop]: value,
      } as TranslatorInput),
    );
  };

  const handleTypeChange = (event: SelectChangeEvent): void => {
    const type = event.target.value;
    let script = '';
    switch (type) {
      case 'template_cmd':
        script = TRANSLATOR_TEMPLATE_TYPE;
        break;

      default:
        script = '';
        break;
    }
    dispatch(
      setNewTranslator({
        ...newTranslator,
        script,
        type,
      } as TranslatorInput),
    );
  };

  const handleAccountChange = (companyId: string): void => {
    dispatch(
      setNewTranslator({
        ...newTranslator,
        company_id: companyId,
      } as TranslatorInput),
    );
  };

  const isEnableToSubmit = (): boolean => {
    return newTranslator?.name !== '' && newTranslator?.company_id !== '';
  };

  const submitHandler = (): void => {
    setIsSubmitting(true);
    Translators.create(newTranslator as TranslatorInput)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: 'Translator created successfully',
            type: 'success',
          }),
        );
        navigate('/command-translators');
      })
      .catch((error) => {
        setAlert({
          highlight: errorHighlight,
          message: error.messages,
          type: 'error',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="command-translators"
          goBackLabel="Translators"
          isCreatePage={true}
          model="translator"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8 ">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-command-translator-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new command translator
                </Typography>
              </div>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} className="mt-6">
                  <TextInput
                    placeholder="Name"
                    prop="name"
                    onInputChange={handleChange}
                    value={newTranslator?.name}
                    required={true}
                    label="Name *"
                  />
                </Grid>
                <Grid item xs={12} className="">
                  <Typography variant="subtitle2" className="custom-label mt-6">
                    Type *
                  </Typography>
                  <FormControl variant="outlined" fullWidth>
                    <Select
                      displayEmpty
                      value={newTranslator?.type}
                      onChange={handleTypeChange}
                    >
                      {COMMAND_TRANSLATOR_TYPES.map((type) => (
                        <MenuItem dense key={type.key} value={type.key}>
                          {type.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  container
                  direction="column"
                  spacing={3}
                  className={clsx(classes.container)}
                >
                  <Grid item xs={12} className={clsx('mt-6', classes.textArea)}>
                    <textarea
                      style={{
                        height: '350px',
                        marginLeft: '16px',
                        width: '100%',
                      }}
                      defaultValue={newTranslator?.script}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="discovered device"
          submitting={isSubmitting}
          companyId={newTranslator?.company_id ?? ''}
          onChangeAccount={handleAccountChange}
          onSubmit={submitHandler}
          enableSubmit={isEnableToSubmit()}
        />
      </Grid>
    </Grid>
  );
};

export default CreateCommandTranslator;
