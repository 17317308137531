import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

interface StyleProps {
  image?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    actionContainer: {
      textAlign: 'end',
    },
    container: {
      backgroundColor: '#fff',
      display: 'flex',
    },
    extraContainer: {
      alignSelf: 'end',
      display: 'flex',
    },
    extraImage: {
      maxHeight: '50px',
    },
    imageBox: (props) => ({
      backgroundColor: theme.palette.grey[500],
      backgroundImage: `url(${props.image})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      borderRadius: 4,
      fontSize: '70px',
      height: '90px',
      textAlign: 'center',
      width: '90px',
    }),
    initials: {
      backgroundColor: theme.palette.grey[200],
      borderRadius: '50%',
      color: theme.palette.grey[500],
      fontSize: '44px',
      height: '90px',
      width: '90px',
    },
    logoContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    overline: {
      display: 'flex',
    },
    overlineIcon: {
      height: '16px',
      verticalAlign: 'bottom',
      width: '16px',
    },
    overlineProfileName: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
    subtitle: {
      alignItems: 'center',
      color: theme.palette.grey[200],
      display: 'flex',
      maxWidth: '700px',
    },
    subtitleCopyIcon: {
      cursor: 'pointer',
    },
    tag: {
      height: '18px',
    },
    title: {
      maxWidth: '700px',
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
  }),
);

export default useStyles;
