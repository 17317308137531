import React, { ReactElement, useEffect, useState } from 'react';
import { Device, Integration, RuleAction } from '@edgeiq/edgeiq-api-js';
import { Grid, MenuItem } from '@mui/material';
import SelectInput from '../../../../components/SelectInput';
import {
  ACTION_TYPE_OPTIONS,
  HTTP_HEADERS,
} from '../../../../constants/policies';
import SingleIntegrationSection from './ActionTypeDynamicSection/SingleIntegrationSection';
import TopicTemplateSection from './ActionTypeDynamicSection/TopicTemplateSection';
import RetrySection from './ActionTypeDynamicSection/RetrySection';
import HealthStatusSection from './ActionTypeDynamicSection/HealthStatusSection';
import LocationObservationSection from './ActionTypeDynamicSection/LocationObservationSection';
import EmailSection from './ActionTypeDynamicSection/EmailSection';
import HttpRequestSection from './ActionTypeDynamicSection/HttpRequestSection';
import MqttMessageSection from './ActionTypeDynamicSection/MqttMessageSection';
import NotificationSection from './ActionTypeDynamicSection/NotificationSection';
import SendSmsSection from './ActionTypeDynamicSection/SendSmsSection';
import SendTcpSection from './ActionTypeDynamicSection/SendTcpSection';
import UpdateMetadataSection from './ActionTypeDynamicSection/UpdateMetadataSection';
import ExecuteWorkflowSection from './ActionTypeDynamicSection/ExecuteWorkflowSection';
import GatewaySection from './ActionTypeDynamicSection/GatewayCommandSection';

interface ActionSectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (
    prop: string,
    value: string | number | boolean | string[] | { [key: string]: string },
  ) => void;
  disabled?: boolean;
  integrations: Integration[];
  selectedDevices?: Device[];
  mixedTypes: boolean;
}

const ActionSection: React.FC<ActionSectionProps> = ({
  action,
  actionIndex,
  onInputChange,
  disabled,
  integrations,
  selectedDevices,
  mixedTypes,
}) => {
  const [headers, setHeaders] = useState<HTTP_HEADERS>([]);

  useEffect(() => {
    if (action && action.headers) {
      Object.entries(action.headers).forEach(([k, v]) => {
        headers.push({
          key: k,
          value: v,
        });
      });
      setHeaders([...headers]);
    }
  }, []);

  const onHeaderChange = (
    prop: string,
    value: string | number | boolean | string[],
  ): void => {
    const propArr = prop.split('.');
    const index = +propArr[2];
    const propChanged = propArr[3] as 'key' | 'value';
    headers[index][propChanged] = value as string;
    setHeaders([...headers]);
    onInputChange(prop, getHeaderObject(headers));
  };

  const onAddHeader = (): void => {
    setHeaders([
      ...headers,
      {
        key: '',
        value: '',
      },
    ]);
    const prop = `${actionIndex}.header.add`;
    onInputChange(prop, getHeaderObject(headers));
  };

  const onRemoveHeader = (index: number): void => {
    headers.splice(index, 1);
    setHeaders([...headers]);
    const prop = `${actionIndex}.header.remove`;
    onInputChange(prop, getHeaderObject(headers));
  };

  const getHeaderObject = (
    headersListUpdated: HTTP_HEADERS,
  ): { [key: string]: string } => {
    const obj: { [key: string]: string } = {};
    headersListUpdated.forEach((e) => {
      obj[e.key] = e.value;
    });
    return obj;
  };

  const defaultComponent = (label: string): ReactElement => (
    <>
      <RetrySection
        action={action}
        actionIndex={actionIndex}
        onInputChange={onInputChange}
        disabled={disabled}
      />
      <SingleIntegrationSection
        action={action}
        actionIndex={actionIndex}
        onInputChange={onInputChange}
        disabled={disabled}
        integrations={integrations}
        codeEditorLabel={label}
      />
    </>
  );
  const renderDynamicActionSection = (): ReactElement => {
    switch (action.type) {
      case 'aws_iot':
      case 'azure_iot':
        return defaultComponent(
          'Body template (must be valid JSON, leave empty to relay report payload)',
        );
      case 'aws_iot_publish':
        return (
          <>
            {defaultComponent(
              'Body template (must be valid JSON, leave empty to relay report payload)',
            )}
            <TopicTemplateSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </>
        );
      case 'create_device_health_status_event':
        return (
          <>
            <RetrySection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
            <HealthStatusSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </>
        );
      case 'create_device_location_observation':
        return (
          <>
            <RetrySection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
            <LocationObservationSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </>
        );
      case 'email':
        return (
          <>
            {defaultComponent('Email message')}
            <EmailSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </>
        );
      case 'http_request':
        return (
          <>
            <RetrySection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
            <HttpRequestSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
              onAddHeader={onAddHeader}
              onRemoveHeader={onRemoveHeader}
              headers={headers}
              onHeaderChange={onHeaderChange}
            />
          </>
        );
      case 'mqtt':
        return (
          <>
            <RetrySection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
            <MqttMessageSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </>
        );
      case 'notification':
        return (
          <>
            <RetrySection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
            <NotificationSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </>
        );
      case 'sms':
        return (
          <>
            {defaultComponent('SMS message')}
            <SendSmsSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </>
        );
      case 'tcp':
        return (
          <>
            <RetrySection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
            <SendTcpSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </>
        );
      case 'update_device_metadata':
        return (
          <>
            <RetrySection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
            <UpdateMetadataSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
          </>
        );
      case 'workflow':
        return (
          <>
            <RetrySection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
              disabled={disabled}
            />
            <ExecuteWorkflowSection
              action={action}
              actionIndex={actionIndex}
              onInputChange={onInputChange}
            />
          </>
        );
      case 'gateway_command':
        return (
          <GatewaySection
            action={action}
            actionIndex={actionIndex}
            onInputChange={onInputChange}
            selectedDevices={selectedDevices}
          ></GatewaySection>
        );
      default:
        return (
          <RetrySection
            action={action}
            actionIndex={actionIndex}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <SelectInput
          label="Type"
          value={action.type}
          prop={`${actionIndex}.type`}
          onSelectChange={onInputChange}
          options={Object.keys(ACTION_TYPE_OPTIONS).map((key) => (
            <MenuItem
              className="m-4 p-2"
              key={key}
              dense
              value={key}
              disabled={key === 'gateway_command' && mixedTypes}
            >
              {ACTION_TYPE_OPTIONS[key]}
            </MenuItem>
          ))}
          disabled={disabled}
        />
      </Grid>

      {renderDynamicActionSection()}
    </>
  );
};

export default ActionSection;
