import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  ListAlt as ListIcon,
  TurnedInNotOutlined as TurnedInIcon,
  CalendarMonthOutlined as CalendarIcon,
  ArticleOutlined as ArticleIcon,
} from '@mui/icons-material';
import { DiscoveredDevice } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import timeHelpers from '../../../helpers/timeHelpers';
import { heartbeatColorThemeMap } from '../../../app/constants';
import ColoredBox from '../../../components/ColoredBox';
import TypographyWithCopy from '../../../components/TypographyWithCopy';
import useStyles from '../styles';

interface DiscoveredDeviceCardProps {
  discoveredDevice: DiscoveredDevice;
}

const DiscoveredDeviceCard: React.FC<DiscoveredDeviceCardProps> = ({
  discoveredDevice,
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Tooltip placement="top" title={discoveredDevice.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-1', classes.title)}
        >
          {discoveredDevice.name}
        </Typography>
      </Tooltip>
      <TypographyWithCopy
        dataCy={`copy-discovered-device-id-${discoveredDevice.unique_id}`}
        containerClassName="mb-4"
        textClassName={classes.subtitle}
        component="div"
        text={`Id: ${discoveredDevice.unique_id}`}
        textToCopy={discoveredDevice.unique_id}
        typographyVariant="button"
      />
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-4', classes.tag)}
      >
        <TurnedInIcon
          fontSize="small"
          className={clsx('mr-2', classes.tagIcon)}
        />
        Manufacturer:{' '}
        {discoveredDevice.manufacturer ? discoveredDevice.manufacturer : '--'}
      </Typography>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-4', classes.tag)}
      >
        <ListIcon fontSize="small" className={clsx('mr-2', classes.tagIcon)} />
        Model: {discoveredDevice.model ? discoveredDevice.model : '--'}
      </Typography>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-4', classes.tag)}
      >
        <CalendarIcon
          fontSize="small"
          className={clsx('mr-2', classes.tagIcon)}
        />
        Discovered Date:{' '}
        {discoveredDevice.created_at
          ? timeHelpers.getDate(discoveredDevice.created_at)
          : '--'}
      </Typography>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-4', classes.tag)}
      >
        <ArticleIcon
          fontSize="small"
          className={clsx('mr-2', classes.tagIcon)}
        />
        Last Heartbeat Date:{' '}
        {discoveredDevice.heartbeat_status === 'never_reported'
          ? '--'
          : discoveredDevice.heartbeat_at
          ? timeHelpers.getDate(discoveredDevice.heartbeat_at)
          : ''}
      </Typography>
      {discoveredDevice.heartbeat_status && (
        <div className={classes.statusContainer}>
          <ColoredBox
            type="heartbeat_status"
            value={discoveredDevice.heartbeat_status ?? 'never_reported'}
            colorTheme={
              heartbeatColorThemeMap[
                discoveredDevice.heartbeat_status ?? 'never_reported'
              ]
            }
          />
        </div>
      )}
    </Box>
  );
};

export default DiscoveredDeviceCard;
