import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { Typography, Grid, Button, CircularProgress } from '@mui/material';
import {
  Commands,
  CommandExecution,
  CommandExecutions,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import CommandExecutionInfoDrawer from './CommandExecutionInfoDrawer';
import CommandExecutionCard from './CommandExecutionCard';
import useStyles from './styles';

interface CommandExecutionListProps {
  commandId?: string;
  deviceId?: string;
  ref?: React.ForwardedRef<unknown>;
}

const CommandExecutionList: React.FC<CommandExecutionListProps> = forwardRef(
  (props, ref) => {
    const dispatch = useAppDispatch();
    const classes = useStyles();
    const { commandId, deviceId } = props;
    const [commandExecutions, setCommandExecutions] = useState<
      CommandExecution[]
    >([]);
    const [selectedCommandExecution, setSelectedCommandExecution] =
      useState<CommandExecution>();
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [openInfoDrawer, setOpenInfoDrawer] = useState(false);

    useEffect(() => {
      if (commandId) {
        setLoading(true);
        getCommandExecutions(1);
      }

      if (deviceId) {
        setLoading(true);
        getCommandExecutions(1);
      }
    }, [commandId, deviceId]);

    const noLoading = (): void => {
      setLoading(false);
    };

    const handleCommandExecutionCardCallback = (
      commandExecution?: CommandExecution,
    ): void => {
      if (commandExecution) {
        setSelectedCommandExecution(commandExecution);
        handleOpenCommandExecutionDrawer();
      }
    };

    const handleOpenCommandExecutionDrawer = (): void => {
      setOpenInfoDrawer(true);
    };

    const handleCloseCommandExecutionDrawer = (): void => {
      setOpenInfoDrawer(false);
    };

    const getCommandExecutions = (
      pageNumber: number,
      addPage = false,
    ): void => {
      const pagination: PaginationFilter = {
        itemsPerPage: 10,
        page: pageNumber,
      };
      if (commandId) {
        Commands.getCommandExecutions(commandId as string, {}, pagination)
          .then((result) => {
            setCommandExecutions([]);
            const newCommandExecutions = addPage
              ? [...commandExecutions, ...result.commandExecutions]
              : result.commandExecutions;
            setCommandExecutions(newCommandExecutions);
            setTotalAndPage(result.pagination.total, addPage);
          })
          .catch((error) => {
            dispatchError(error.message);
          })
          .finally(() => noLoading());
      } else if (deviceId) {
        CommandExecutions.list(
          {
            device_id: { operator: 'eq', value: deviceId },
          },
          pagination,
        )
          .then((result) => {
            setCommandExecutions([]);
            const newCommandExecutions = addPage
              ? [...commandExecutions, ...result.commandExecutions]
              : result.commandExecutions;
            setCommandExecutions(newCommandExecutions);
            setTotalAndPage(result.pagination.total, addPage);
          })
          .catch((error) => {
            dispatchError(error.message);
          })
          .finally(() => noLoading());
      }
    };

    const handleLoadMore = (
      event: React.MouseEvent<HTMLButtonElement>,
    ): void => {
      event.preventDefault();
      setLoadingMore(true);
      getCommandExecutions(page + 1, true);
    };

    const setTotalAndPage = (newTotal: number, addPage = false): void => {
      setTotal(newTotal);
      if (addPage) {
        setPage(page + 1);
      }
    };

    const dispatchError = (errorMessage: string): void => {
      dispatch(
        setAlert({
          highlight: errorHighlight,
          message: errorMessage,
          type: 'error',
        }),
      );
    };

    // as the second argument
    useImperativeHandle(ref, () => ({
      refreshExecutionList(): void {
        getCommandExecutions(1);
      },
    }));

    return (
      <>
        {commandId && (
          <Typography className={clsx('mt-8 mb-4')} variant="h5">
            Execution Records
          </Typography>
        )}
        {loading ? (
          <Grid container className="loading-container">
            <CircularProgress size={75} thickness={5} />
          </Grid>
        ) : (
          <>
            <Grid
              container
              direction={'row'}
              className={clsx('scrollbar', classes.listContainer)}
            >
              {commandExecutions.map((commandExecution) => {
                return (
                  <CommandExecutionCard
                    key={commandExecution._id}
                    commandExecution={commandExecution}
                    actionCallback={handleCommandExecutionCardCallback}
                  />
                );
              })}
            </Grid>
            {commandExecutions.length !== total && (
              <Grid item xs={12} className={clsx('mb-9 loading-container')}>
                <Button
                  variant="outlined"
                  size="large"
                  onClick={handleLoadMore}
                >
                  {!loadingMore ? (
                    <Typography variant="button">Load more</Typography>
                  ) : (
                    <CircularProgress size={25} />
                  )}
                </Button>
              </Grid>
            )}
          </>
        )}

        <CommandExecutionInfoDrawer
          open={openInfoDrawer}
          handleCloseDrawer={handleCloseCommandExecutionDrawer}
          commandExecution={selectedCommandExecution}
        />
      </>
    );
  },
);

export default CommandExecutionList;
