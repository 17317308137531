import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Configurations,
  Configuration,
  ConfigurationsFilters,
} from '@edgeiq/edgeiq-api-js';
import DeleteIcon from '@mui/icons-material/Delete';
import { deleteHighlight, errorHighlight } from '../../../app/constants';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';

import TabsPage from '../../../components/TabsPage';
import ActionDialog from '../../../components/ActionDialog';

export interface ConfigurationCreateLocationState {
  from: string;
}

const DeviceTypeConfigurations: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const [loading, setLoading] = useState(false);
  const [updatesList, setUpdatesList] = useState<Configuration[]>([]);
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const [selectedConfiguration, setSelectedConfiguration] = useState<string>();
  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };
  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <strong>{params.row.name}</strong>
      ),
    },
    {
      field: 'configurable_settings',
      flex: 0.3,
      headerName: 'Configurable Settings',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{`${
          params.row.configurable_settings
            ? params.row.configurable_settings.length
            : 0
        } SETTINGS`}</span>
      ),
    },
    {
      field: 'action',
      flex: 0.1,
      headerName: 'Action',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <IconButton onClick={(): void => handleClickRemoveRow(params)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleClickRemoveRow = (prop: GridRenderCellParams): void => {
    openDeleteModal();
    setSelectedConfiguration(prop.row._id);
  };

  const handleDelete = (): void => {
    setLoading(true);
    Configurations.delete(selectedConfiguration as string)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(1, 'Update', 'Updates'),
            type: 'success',
          }),
        );
        setUpdatesList(
          updatesList.filter(
            (configuration) => selectedConfiguration !== configuration._id,
          ),
        );
        setSelectedConfiguration('');
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        closeDeleteModal();
      });
  };

  useEffect(() => {
    if (editableDeviceType) {
      setLoading(true);

      const filters: ConfigurationsFilters = {
        device_type_id: {
          operator: 'eq',
          value: editableDeviceType._id,
        },
      };
      Configurations.list(filters)
        .then((resUpdates) => {
          setUpdatesList(resUpdates.configurations);
        })
        .catch((err) => {
          dispatchError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDeviceType]);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleAddConfiguration = (): void => {
    const state: ConfigurationCreateLocationState = {
      from: `/device-profile/${id}`,
    };
    navigate('/new-configuration', { state });
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={updatesList}
        addButtonLabel="Add Configuration"
        onAddClick={handleAddConfiguration}
        tableTitle="Configuration added"
        loading={loading}
      />
      <ActionDialog
        open={ActionDialogOpen}
        loading={loading}
        content={
          <>
            <span>You are about to delete this update:</span>
            <ul>
              {updatesList
                .filter(
                  (configuration) =>
                    selectedConfiguration === configuration._id,
                )
                .map((update) => (
                  <li key={update._id}>{update.name}</li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleDelete}
      />
    </Box>
  );
};

export default DeviceTypeConfigurations;
