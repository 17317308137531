import { Typography } from '@mui/material';
import React from 'react';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';

export interface CodeEditorProps {
  prop: string;
  value?: string;
  onInputChange: (prop: string, value: string) => void;
  label?: string;
  width?: number;
  height?: number;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  prop,
  value,
  onInputChange,
  label,
  width,
  height,
}) => {
  const handleChange = (newValue: string): void => {
    onInputChange(prop, newValue);
  };
  return (
    <>
      {label && (
        <Typography variant="subtitle2" className="mb-2" data-testid="label">
          {label}
        </Typography>
      )}
      <AceEditor
        value={value}
        mode="javascript"
        theme="github"
        onChange={handleChange}
        width={`${width}px`}
        height={`${height}px`}
      />
    </>
  );
};

export default CodeEditor;
