import { Setting, SettingInput } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getSettingSelector = createSelector(
  (state: SettingState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.settings.length) {
      return state.actualSetting;
    }
    return state.settings.find((setting: Setting) => setting._id === id);
  },
);

export const newSettingInitialState = {
  configuration_id: '',
  name: '',
  settings_values: [
    {
      configurable_settings_id: '',
    },
  ],
};

export const SettingTypes = {
  SET_ACTUAL_SETTING: 'SET_ACTUAL_SETTING',
  SET_NEW_SETTING: 'SET_NEW_SETTING',
  SET_NEW_SETTING_INPUT: 'SET_NEW_SETTING_INPUT',
  SET_SETTINGS: 'SET_SETTINGS',
};

export const setStateSettings = (settings: Setting[]): SettingAction => ({
  data: settings,
  type: SettingTypes.SET_SETTINGS,
});

export const setActualSetting = (setting: Setting): SettingAction => ({
  data: setting,
  type: SettingTypes.SET_ACTUAL_SETTING,
});

export const setNewSetting = (setting: Setting): SettingAction => ({
  data: setting,
  type: SettingTypes.SET_NEW_SETTING,
});

export const setNewSettingInput = (setting: SettingInput): SettingAction => ({
  data: setting,
  type: SettingTypes.SET_NEW_SETTING_INPUT,
});

export type SettingState = {
  actualSetting: Setting | null;
  settings: Setting[];
  newSetting: Setting | null;
  newSettingInput: SettingInput | null;
};

// INITIAL STATE
const settingInitialState: SettingState = {
  actualSetting: null,
  newSetting: null,
  newSettingInput: newSettingInitialState as SettingInput,
  settings: [],
};

export type SettingAction = {
  data: Setting[] | Setting | SettingInput | string;
  type: string;
};

const settingReducer = (
  state = settingInitialState,
  action: SettingAction,
): SettingState => {
  switch (action.type) {
    case SettingTypes.SET_SETTINGS:
      return {
        ...state,
        settings: action.data as Setting[],
      };

    case SettingTypes.SET_ACTUAL_SETTING:
      return {
        ...state,
        actualSetting: action.data as Setting,
        newSetting: action.data as Setting,
      };

    case SettingTypes.SET_NEW_SETTING:
      return {
        ...state,
        newSetting: action.data as Setting,
      };

    case SettingTypes.SET_NEW_SETTING_INPUT:
      return {
        ...state,
        newSettingInput: action.data as SettingInput,
      };

    default:
      return state;
  }
};

export default settingReducer;
