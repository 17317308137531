import React, { useState } from 'react';
import { User, UserType } from '@edgeiq/edgeiq-api-js';
import { Typography, Grid, Paper, Button } from '@mui/material';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setStateNewUser } from '../../../redux/reducers/users.reducer';
import {
  AttachAccountsDrawer,
  ResetApiTokenDrawer,
} from '../../../containers/RightDrawer';
import UserForm from './userForm';
import UserAccountsList from './UserAccountsList';
import useStyles from './styles';

interface UserDetailsProps {
  isValidEmail?: boolean;
  user?: User;
  userTypes: UserType[];
}

const UserDetails: React.FC<UserDetailsProps> = ({
  user,
  userTypes,
  isValidEmail,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { user: loggedUser } = useAppSelector((state: RootState) => state.user);
  const [open, setOpen] = useState(false);
  const [openAPITokenDrawer, setOpenAPITokenDrawer] = useState(false);

  const handleInputChange = (prop: string, value: string | number): void => {
    dispatch(
      setStateNewUser({
        ...user,
        [prop]: value,
      } as User),
    );
  };

  const handleAccountsAdded = (accountsIds: string[]): void => {
    dispatch(
      setStateNewUser({
        ...user,
        company_ids: [...(user?.company_ids ?? []), ...accountsIds],
      } as User),
    );
    handleCloseDrawer();
  };

  const handleAccountDetached = (accountId: string): void => {
    dispatch(
      setStateNewUser({
        ...user,
        company_ids: [
          ...(user?.company_ids?.filter((id) => id !== accountId) ?? []),
        ],
      } as User),
    );
  };

  const handleOpenDrawer = (): void => {
    setOpen(true);
  };

  const handleCloseDrawer = (): void => {
    setOpen(false);
  };

  const handleOpenResetAPITokenDrawer = (): void => {
    setOpenAPITokenDrawer(true);
  };

  const handleCloseResetAPITokenDrawer = (): void => {
    setOpenAPITokenDrawer(false);
  };

  return (
    <Grid container spacing={3}>
      {user && (
        <>
          <Grid item xs={12} md={8}>
            <Paper className="p-8 shadow">
              <Typography variant="h5">Details</Typography>
              <UserForm
                user={user}
                userTypes={userTypes}
                onInputChange={handleInputChange}
                isValidEmail={isValidEmail ? isValidEmail : false}
              />
              {loggedUser?._id === user._id && (
                <Button
                  data-cy="reset-api-token-button"
                  variant="contained"
                  size="large"
                  className="mt-8"
                  onClick={handleOpenResetAPITokenDrawer}
                >
                  Reset API token
                </Button>
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper className={clsx(classes.container, 'shadow p-7')}>
              <Typography
                variant="h5"
                className="mb-2"
                data-cy="user-additional-accounts-title"
              >
                Additional Accounts
                <Button
                  color="primary"
                  variant="text"
                  type="button"
                  data-cy="attach-additional-account-button"
                  onClick={handleOpenDrawer}
                >
                  <Typography variant="button">Attach</Typography>
                </Button>
              </Typography>
              <UserAccountsList
                user={user}
                onAccountRemoved={handleAccountDetached}
              />
            </Paper>
          </Grid>
          <AttachAccountsDrawer
            open={open}
            user={user}
            handleCloseDrawer={handleCloseDrawer}
            onAccountsAdded={handleAccountsAdded}
          />
          <ResetApiTokenDrawer
            open={openAPITokenDrawer}
            handleCloseDrawer={handleCloseResetAPITokenDrawer}
          />
        </>
      )}
    </Grid>
  );
};

export default UserDetails;
