import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import {
  GatewayCommand,
  GatewayCommands,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import parseFilters from '../../helpers/parseFilters';
import { errorHighlight } from '../../app/constants';
import CommandExecutionCard from '../CommandExecutionCard';
import TypographyWithCopy from '../../components/TypographyWithCopy';
// import useStyles from './styles';
import CommandExecutionDrawer from '../CommandExecutionDrawer';
import timeHelpers from '../../helpers/timeHelpers';

const LogUpdates: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const [logs, setLogs] = useState<GatewayCommand[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  // const classes = useStyles();
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [selectedGatewayCommand, setSelectedGatewayCommand] =
    useState<GatewayCommand>();

  const handleOpenCommandExecutionDrawer = (): void => {
    setOpenInfoDrawer(true);
  };

  const handleCloseCommandExecutionDrawer = (): void => {
    setOpenInfoDrawer(false);
  };

  const handleGatewayCommandExecutionCardCallback = (
    commandExecution?: GatewayCommand,
  ): void => {
    if (commandExecution) {
      setSelectedGatewayCommand(commandExecution);
      handleOpenCommandExecutionDrawer();
    }
  };

  const getUpdateLogs = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: 10,
      page: pageNumber,
    };

    GatewayCommands.list(
      parseFilters({
        command_type: 'software_update',
      }),
      pagination,
    )
      .then((result) => {
        const newLogs = addPage
          ? [...logs, ...result.gatewayCommands]
          : result.gatewayCommands;
        setLogs(newLogs);
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  useEffect(() => {
    setLoading(true);
    getUpdateLogs(1);
  }, []);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getUpdateLogs(page + 1, true);
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  return (
    <Paper
      className={clsx(
        'shadow py-8 px-8 overflow-y scrollbar overflow-x-hidden',
      )}
    >
      <Typography variant="h5" className="mb-6">
        Recent Updates
      </Typography>
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <Grid container direction={'column'}>
          {logs.map((activity) => {
            return (
              <CommandExecutionCard
                actionCallback={handleGatewayCommandExecutionCardCallback}
                gatewayCommand={activity}
                key={activity._id}
                data={
                  <>
                    <TypographyWithCopy
                      dataCy={`copy-device-id-${activity.device_unique_id}`}
                      component="p"
                      text={(activity.payload?.name as string) || ''}
                      textToCopy={`${window.location.origin}/software-updates/${activity.software_update_id}`}
                      typographyVariant="subtitle1"
                      tooltipText={'Software package'}
                    />

                    <TypographyWithCopy
                      dataCy={`copy-device-id-${activity.device_unique_id}`}
                      // textClassName={classes.subtitle}
                      component="p"
                      text={`Device: ${activity.device_unique_id}`}
                      textToCopy={`${window.location.origin}/device/${activity.device_id}`}
                      typographyVariant="button"
                    />

                    <Typography variant="overline" component="div">
                      {timeHelpers.getDate(activity.created_at, false, true)}
                    </Typography>
                  </>
                }
              />
            );
          })}

          {logs.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </Grid>
      )}

      {selectedGatewayCommand && (
        <CommandExecutionDrawer
          open={openInfoDrawer}
          handleCloseDrawer={handleCloseCommandExecutionDrawer}
          gatewayCommand={selectedGatewayCommand}
          title={'Software Update'}
          subtitle1={`Device: ${selectedGatewayCommand.device_unique_id}`}
          subtitle2={`Package: ${selectedGatewayCommand.software_update_id}`}
        />
      )}
    </Paper>
  );
};

export default LogUpdates;
