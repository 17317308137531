import { RuleCondition, RuleInput } from '@edgeiq/edgeiq-api-js';

export const DEVICE_ERROR = 'device_error';
export const EQUAL_CONDITION = 'equal';
export const NOT_EQUAL_CONDITION = 'not_equal';
export const IN_CONDITION = 'in';
export const NOT_IN_CONDITION = 'not_in';
export const MOVING_AVERAGE = 'moving_average';
export const PROPERTY_EVALUATION = 'property_evaluation';
export const VALUE_CHANGED = 'value_changed';
export const CONTAINS_CONDITION = 'contains';

export const policyTypeLabel: {
  [key: string]: string;
} = {
  false: 'On the Edge',
  true: 'In the Cloud',
};

export const policyConditionTypes: {
  [key: string]: string;
} = {
  device_error: 'Device Error',
  heartbeat_status_changed: 'On Heartbeat Status Change',
  lwm2m_reg_update: 'LwM2M Registration Update',
  lwm2m_registration: 'LwM2M Registration',
  property_evaluation: 'Via Property Evaluation',
  status_changed: 'On Status Change',
  true: 'Always execute',
};
export const policyDeviceErrorsLabel: {
  [key: string]: string;
} = {
  aws_greengrass_iot: 'AWS Greengrass Iot Error',
  aws_iot: 'AWS Iot Error',
  azure_iot: 'Azure Iot Error',
  bluemix_iot: 'Bluemix Iot Error',
  config: 'Configuration Error',
  rule_action: 'Policy Action Error',
  system: 'System Error',
};
/* eslint sort-keys: 0 */
export const policyPropertyEvaluations: {
  [key: string]: string;
} = {
  equal: 'equals',
  not_equal: 'does not equal',
  greater_than: 'is greater than',
  greater_than_equal: 'is greater than or equal to',
  less_than: 'is less than',
  less_than_equal: 'is less than or equal to',
  in: 'in',
  not_in: 'not in',
  contains: 'contains',
  moving_average: 'changes in value by',
  value_changed: 'changes in value',
};

export const policyConditionRuleLabel: {
  [key: string]: string;
} = {
  and: 'All',
  or: 'Any',
};

export const derivedValuesTypesOptions: {
  [key: string]: string;
} = {
  avg: 'Average',
  convert_indexed_keys: 'Convert Indexes',
  count: 'Count',
  max: 'Max',
  min: 'Min',
  sum: 'Sum',
};

export const whereConditionsTypesOptions: {
  [key: string]: string;
} = {
  or_where: 'Or Where',
  where: 'Where',
};

export const whereConditionsOperatorOptions: {
  [key: string]: string;
} = {
  contains: 'Contains',
  ends_with: 'Ends With',
  equal: 'Equal',
  greater_than: 'Greater Than',
  greater_than_equal: 'Greater Than/Equal',
  in: 'In',
  length_equals: 'Length Equals',
  length_greater_than: 'Length Greater Than',
  length_greater_than_equal: 'Length Greater Than/Equals',
  length_less_than: 'Length Less Than',
  length_less_than_equal: 'Length Less Than/Equals',
  length_not_equals: 'Length Not Equals',
  less_than: 'Less Than',
  less_than_equal: 'Less Than/Equal',
  not_equal: 'Not Equal',
  not_in: 'Not In',
  starts_with: 'Starts With',
  strict_contains: 'Strict Contains',
};

export const policyConditionTypeValueLabel: {
  [key: string]: string;
} = {
  absolute: 'an absolute value of',
  percent: 'a percentage of',
};

export const MOVING_AVERAGE_NEW_CONDITION: RuleCondition = {
  type: MOVING_AVERAGE,
  value: '',
  value_type: 'absolute',
};

export const EMPTY_POLICY: RuleInput = {
  active: true,
  cloud_rule: false,
  company_id: '',
  description: '',
  else_actions: [],
  rule_condition: {
    type: 'true',
  },
  then_actions: [],
};

export const ACTION_TYPE_OPTIONS: {
  [key: string]: string;
} = {
  aws_iot: 'Update AWS IoT Shadow',
  aws_iot_publish: 'Publish Message to AWS IoT Topic',
  azure_iot: 'Relay Report to Microsoft Azure IoT',
  create_device_health_status_event: 'Create Device Health Status Event',
  create_device_location_observation: 'Create Device Location Observation',
  disable_data_restriction: 'Disable Data Usage Restriction',
  email: 'Send Email',
  enable_data_restriction: 'Enable Data Usage Restriction',
  gateway_command: 'Gateway Command',
  greengrass_restart: 'Restart AWS Greengrass',
  http_request: 'Issue HTTP Request',
  mqtt: 'Send MQTT Message',
  notification: 'Create Notification',
  relay: 'Relay Report',
  sms: 'Send SMS',
  tcp: 'Send Data Over TCP',
  update_device_metadata: 'Update Device Metadata',
  workflow: 'Execute Workflow',
};

export const HEALTH_STATUS_OPTIONS: {
  [key: string]: string;
} = {
  critical: 'Critical',
  needs_attention: 'Needs Attention',
  none: 'None',
  normal: 'Normal',
};

export const GENERATED_AT_FORMAT_OPTIONS: {
  [key: string]: string;
} = {
  rfc3339: 'Rfc3339',
  unix_timestamp: 'Unix Timestamp',
};

export const LOCATION_VIA_OPTIONS: {
  [key: string]: string;
} = {
  bluetooth: 'Bluetooth',
  cell_tower: 'Cell Tower',
  gps: 'GPS',
  other: 'Other',
  wifi: 'Wifi',
};

export const BODY_TYPE_OPTIONS: {
  [key: string]: string;
} = {
  html: 'HTML',
  text: 'Text',
};

export const HTTP_REQUEST_TYPE_OPTIONS: {
  [key: string]: string;
} = {
  delete: 'DELETE',
  get: 'GET',
  post: 'POST',
  put: 'PUT',
};

export type HTTP_HEADERS = Array<{
  key: string;
  value: string;
}>;
