import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import { SoftwareUpdates } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import timeHelpers from '../../helpers/timeHelpers';

interface ISoftwareUpdatesChartData {
  name: string;
  origin: string;
  reboot: string;
  type: string;
  packages: string;
  datetime: string;
}

const SoftwareUpdatesChart: React.FC = () => {
  const [softwareUpdatesChartData, setSoftwareUpdatesChartData] = useState<
    ISoftwareUpdatesChartData[]
  >([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    SoftwareUpdates.list({}, { itemsPerPage: 6, page: 1 })
      .then((res) => {
        const mappedData = res.softwareUpdates.map((d) => {
          let packages = '';
          if (d.files && d.files.length) {
            packages = d.files.map((e) => e?.name ?? '-').join(', ');
          }
          const data: ISoftwareUpdatesChartData = {
            datetime: d.created_at,
            name: d.name,
            origin: d.origin,
            packages,
            reboot: d.reboot ? 'true' : 'false',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            type: (d as any).type,
          };
          return data;
        });
        setSoftwareUpdatesChartData(mappedData);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleGoSoftwareUpdates = (): void => {
    navigate(`/software-updates`);
  };

  return (
    <div className="d-flex flex-items-stretch flex-column">
      <Typography
        variant="h5"
        className={clsx('mb-4 d-flex flex-justify-between pb-4 pt-4')}
      >
        Software Updates
        <Button
          className="p-0"
          color="primary"
          variant="text"
          type="button"
          onClick={handleGoSoftwareUpdates}
        >
          <Typography variant="button">View More</Typography>
        </Button>
      </Typography>
      <div>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Date/Time</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Type</TableCell>
                <TableCell align="center">Origin</TableCell>
                <TableCell align="center">Reboot</TableCell>
                <TableCell align="left">Packages</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {softwareUpdatesChartData.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell align="left">
                    {row.datetime && (
                      <>{timeHelpers.getDate(row.datetime, false, false)}</>
                    )}
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.type}</TableCell>
                  <TableCell align="center">{row.origin}</TableCell>
                  <TableCell align="center">{row.reboot}</TableCell>
                  <TableCell align="left">{row.packages}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SoftwareUpdatesChart;
