import React from 'react';
import { Grid, IconButton, Button, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { methodsTypes } from '../../../../app/constants';
import TextInput from '../../../../components/TextInput';
import SelectInput from '../../../../components/SelectInput';

interface RouterSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
  disabled?: boolean;
  addRoute: () => void;
  removeRoute: (index: number) => void;
}

const RouterSection: React.FC<RouterSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
  addRoute,
  removeRoute,
}) => {
  return (
    <>
      {Array.isArray(newIngestor.handler.routes) &&
        newIngestor.handler.routes.map((item, index) => (
          <Grid
            key={index}
            container
            direction="row"
            spacing={2}
            className="mt-2 ml-0"
            alignItems="center"
          >
            <Grid item xs={5} className="mt-2">
              <SelectInput
                label="Methods"
                prop={`routes.methods.${index}`}
                values={item.methods}
                multiple={true}
                onMultiSelectChange={onInputChange}
                disabled={disabled}
                options={Object.keys(methodsTypes).map((key, subIndex) => (
                  <MenuItem
                    className="m-4 p-2"
                    key={subIndex}
                    dense
                    value={key}
                  >
                    {methodsTypes[key]}
                  </MenuItem>
                ))}
              />
            </Grid>

            <Grid item xs={5}>
              <TextInput
                label="Path"
                placeholder=""
                prop={`routes.path.${index}`}
                value={item.path}
                onInputChange={onInputChange}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={2} className="mt-6">
              <IconButton onClick={(): void => removeRoute(index)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        ))}
      <Button
        variant="outlined"
        size="medium"
        className="mt-4 ml-4"
        onClick={addRoute}
        startIcon={<AddIcon />}
      >
        Add new
      </Button>
    </>
  );
};

export default RouterSection;
