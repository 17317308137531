import { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Ingestor,
  Ingestors,
  Translator,
  Translators,
} from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
  getIngestorSelector,
  setActualIngestor,
  setNewIngestor,
} from '../../../redux/reducers/ingestors.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import {
  addRowsIngestorArrays,
  createNewIngestorObject,
  removeRowsIngestorArray,
} from '../../../containers/Forms/IngestorForm/helper';
import FooterBar from '../../../components/FooterBar';
import Header from '../../../containers/HeaderWithActionButton';
import ContentHeader from '../../../components/ContentHeader';
import IngestorForm from '../../../containers/Forms/IngestorForm/IngestorForm';

const IngestorContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const ingestorState = useAppSelector((state: RootState) => state.ingestors);
  const [loading, setLoading] = useState(false);
  const [dynamicSubmit, setDynamicSubmit] = useState(false);
  const [translatorOptions, setTranslatorOptions] = useState<Translator[]>([]);
  const { newIngestor, ingestor } = ingestorState;
  const ingestorData = useAppSelector((state: RootState) =>
    getIngestorSelector(state.ingestors, id),
  );
  const deviceCompany = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );

  useEffect(() => {
    const getTranslators = (): void => {
      Translators.list()
        .then((response) => {
          setTranslatorOptions(response.translators);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    };

    getTranslators();
  }, []);

  useEffect(() => {
    if (ingestorData) {
      dispatch(setActualIngestor(ingestorData));
    } else if (id) {
      Ingestors.getOneById(id)
        .then((response) => {
          dispatch(setActualIngestor(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [ingestorData]);

  const handleSaveChanges = (): void => {
    setLoading(true);
    Ingestors.update(newIngestor as Ingestor)
      .then((response) => {
        dispatch(setActualIngestor(response));
        dispatch(
          setAlert({
            highlight: 'Ingestor Updated',
            message: 'Ingestor successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (): void => {
    if (!ingestor) {
      return;
    }
    setLoading(true);
    Ingestors.delete(ingestor._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Ingestor Deleted',
            message: 'Ingestor successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/data-management');
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleValueChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    if (newIngestor) {
      return dispatch(
        setNewIngestor(createNewIngestorObject(prop, value, newIngestor)),
      );
    }
  };

  const handleEnableSubmit = (args: boolean): void => {
    setDynamicSubmit(args);
  };

  const handleAddNewFilter = (): void => {
    if (newIngestor) {
      dispatch(setNewIngestor(addRowsIngestorArrays('filters', newIngestor)));
    }
  };

  const handleRemoveFilter = (index: number): void => {
    if (newIngestor) {
      dispatch(
        setNewIngestor(removeRowsIngestorArray('filters', index, newIngestor)),
      );
    }
  };

  const handleAddDeviceTypeMapping = (): void => {
    if (newIngestor) {
      dispatch(
        setNewIngestor(
          addRowsIngestorArrays('device_type_mapping', newIngestor),
        ),
      );
    }
  };

  const handleRemoveDeviceTypeMapping = (index: number): void => {
    if (newIngestor) {
      dispatch(
        setNewIngestor(
          removeRowsIngestorArray('device_type_mapping', index, newIngestor),
        ),
      );
    }
  };

  const handleAddRoute = (): void => {
    if (newIngestor) {
      dispatch(setNewIngestor(addRowsIngestorArrays('routes', newIngestor)));
    }
  };

  const handleRemoveRoute = (index: number): void => {
    if (newIngestor) {
      dispatch(
        setNewIngestor(removeRowsIngestorArray('routes', index, newIngestor)),
      );
    }
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newIngestor, ingestor) || !dynamicSubmit;
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item xs={12}>
        <Header
          title="Ingestor content"
          goBack="data-management"
          goBackLabel="Data Management"
          model="ingestor"
        />
        {ingestor && (
          <ContentHeader
            title={ingestor.name}
            contentType="ingestor"
            subtitle={ingestor._id}
            ingestorType={ingestor.type}
            listenerType={ingestor.listener_type}
            extraImage={deviceCompany?.branding?.logo_url}
            extraTitle={deviceCompany?.name}
            extraSubtitle={deviceCompany?._id}
            copySubtitleToClipboard
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Paper className="shadow p-7 m-8">
          {newIngestor && (
            <IngestorForm
              newIngestor={newIngestor}
              onInputChange={handleValueChange}
              setEnableSubmit={handleEnableSubmit}
              addNewFilters={handleAddNewFilter}
              addDeviceTypeMapping={handleAddDeviceTypeMapping}
              addRoute={handleAddRoute}
              removeFilter={handleRemoveFilter}
              removeDeviceTypeMapping={handleRemoveDeviceTypeMapping}
              removeRoute={handleRemoveRoute}
              translatorOptions={translatorOptions}
            />
          )}
        </Paper>
      </Grid>
      <FooterBar
        deleteModalContent="You are about to delete this ingestor"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDelete}
      />
    </Grid>
  );
};

export default IngestorContent;
