import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { DeviceType, DeviceTypes } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getDeviceTypeSelector,
  setActualDeviceType,
  setOptionsDeviceTypes,
} from '../../redux/reducers/deviceTypes.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import {
  heartbeatColorThemeMap,
  deviceTypeTabsLabel,
} from '../../app/constants';
import FooterBar from '../../components/FooterBar';
import DeviceTypeDetails from './deviceTypeDetails';
import DeviceTypeAbilites from './deviceTypeAbilities';
import DeviceTypePolicies from './deviceTypePolicies';
import DeviceTypeConnections from './deviceTypeConnections';
import DeviceTypePollableAttributes from './deviceTypePollableAttributes';
import DeviceTypeSoftwareUpdates from './deviceTypeSoftwareUpdate';
import DeviceTypeConfiguration from './deviceTypeConfigurations';
import DeviceTypeIngestors from './deviceTypeIngestors';
import DeviceTypeCommands from './deviceTypeCommands';
import DeviceProfileMetadata from './deviceProfileMetadata';
import { useFetchCompany } from '../../hooks/useFetchCompany';
import { dispatchError } from '../../helpers/utils';

const DeviceProfileContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<string>();
  const deviceTypeData = useAppSelector((state: RootState) =>
    getDeviceTypeSelector(state.deviceTypes, id),
  );
  const newDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const { optionsDeviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [loading, setLoading] = useState(false);
  const errorDispatcher = dispatchError(useAppDispatch);
  const [deviceCompany] = useFetchCompany(
    deviceTypeData?.company_id,
    errorDispatcher,
  );

  useEffect(() => {
    if (deviceTypeData && deviceTypeData._id === id) {
      dispatch(setActualDeviceType(deviceTypeData));
    } else if (id) {
      DeviceTypes.getOneById(id)
        .then((response) => {
          dispatch(setActualDeviceType(response));
        })
        .catch((error) => {
          errorDispatcher(error.message);
        });
    }
  }, [id]);

  const handleDeleteDeviceType = (): void => {
    if (!deviceTypeData) {
      return;
    }
    setLoading(true);
    DeviceTypes.delete(deviceTypeData._id)
      .then(() => {
        dispatch(
          setOptionsDeviceTypes(
            optionsDeviceTypes.filter(
              (optionDeviceType) => optionDeviceType._id !== deviceTypeData._id,
            ),
          ),
        );
        dispatch(
          setAlert({
            highlight: 'Delete device type',
            message: 'Device type successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/device-profiles');
      })
      .catch((error) => {
        errorDispatcher(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateProfileState = (
    updatedContent: DeviceType | undefined,
  ): void => {
    if (optionsDeviceTypes && updatedContent) {
      const foundIndex = optionsDeviceTypes.findIndex(
        (e) => e._id === updatedContent._id,
      );
      optionsDeviceTypes[foundIndex] = updatedContent;
      if (foundIndex >= 0) {
        dispatch(setOptionsDeviceTypes([...optionsDeviceTypes]));
      }
    }
  };

  const handleSaveChanges = (): void => {
    if (newDeviceType !== deviceTypeData) {
      setLoading(true);
      DeviceTypes.update(newDeviceType as DeviceType)
        .then((response) => {
          dispatch(setActualDeviceType(response));
          handleUpdateProfileState(newDeviceType);
          dispatch(
            setAlert({
              highlight: 'Update device type',
              message: 'Device type successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((error) => {
          errorDispatcher(error.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newDeviceType, deviceTypeData);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Grid item xs={12}>
        <Header
          title="Device type content"
          goBack="device-profiles"
          goBackLabel="Device Profiles"
          model="device-type"
        />
      </Grid>
      <Grid item xs={12}>
        {deviceTypeData && deviceTypeData && (
          <Box>
            <ContentHeader
              title={deviceTypeData.name}
              contentType="device-type"
              profileName={deviceTypeData.name}
              profileType={deviceTypeData.type}
              profileRole={deviceTypeData.role}
              subtitle={deviceTypeData._id}
              tagTheme={heartbeatColorThemeMap}
              extraImage={deviceCompany?.branding?.logo_url}
              extraTitle={deviceCompany?.name}
              extraSubtitle={deviceCompany?._id}
              copySubtitleToClipboard
            />
            <VerticalTabs
              /* eslint sort-keys: 0 */
              tabs={{
                details: <DeviceTypeDetails />,
                abilities: <DeviceTypeAbilites />,
                ingestors: <DeviceTypeIngestors />,
                policies: <DeviceTypePolicies />,
                commands: <DeviceTypeCommands />,
                configurations: <DeviceTypeConfiguration />,
                connections: <DeviceTypeConnections />,
                pollableAttributes: <DeviceTypePollableAttributes />,
                softwareUpdate: <DeviceTypeSoftwareUpdates />,
                metadata: <DeviceProfileMetadata />,
              }}
              defaultTab="details"
              tabsLabel={deviceTypeTabsLabel}
            />
          </Box>
        )}
      </Grid>
      <FooterBar
        deleteModalContent="You are about to delete this device profile"
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteDeviceType}
      />
    </Grid>
  );
};

export default DeviceProfileContent;
