import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontWeight: 700,
    },
  }),
);

export default useStyles;
