import React, { useEffect, useState } from 'react';
import {
  Command,
  ConfigurableSettings,
  Configuration,
  Setting,
  Settings,
  SettingInput,
  SettingsValue,
} from '@edgeiq/edgeiq-api-js';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setNewSettingInput } from '../../../redux/reducers/setting.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import AttachCommandsDrawer from '../AttachCommands/AttachCommandsDrawer';
import { errorHighlight } from '../../../app/constants';
import SettingForm from '../../Forms/SettingForm';
import { RightDrawer } from '..';

interface SettingsValueFormat {
  configurable_settings_id?: string;
  value?: string;
}

interface SettingManagementDrawerProps {
  selectedConfiguration: Configuration;
  setting: Setting | null;
  open: boolean;
  onCloseDrawer: () => void;
}

const SettingManagementDrawer: React.FC<SettingManagementDrawerProps> = ({
  selectedConfiguration,
  setting,
  open,
  onCloseDrawer,
}) => {
  const dispatch = useAppDispatch();
  const settingInput = useAppSelector(
    (state: RootState) => state.setting.newSettingInput,
  );
  const [loading, setLoading] = useState(false);
  const [actualSetting, setActualSetting] = useState<Setting | null>();
  const [openAttachCommandDrawer, setOpenAttachCommandDrawer] = useState(false);

  const handleOpenAttachCommandDrawer = (): void => {
    setOpenAttachCommandDrawer(true);
  };

  const handleCloseAttachCommandDrawer = (): void => {
    setOpenAttachCommandDrawer(false);
  };

  const handleChooseCommands = (newCommands: Command[]): void => {
    if (!actualSetting) {
      dispatch(
        setNewSettingInput({
          ...settingInput,
          command_id: newCommands[0]._id,
        } as SettingInput),
      );
    } else {
      setActualSetting({
        ...actualSetting,
        command_id: newCommands[0]._id,
      } as Setting);
    }

    handleCloseAttachCommandDrawer();
  };

  const handleSettingCallback = (): void => {
    if (actualSetting) {
      handleSaveChanges();
    } else {
      handleCreateSetting();
    }
    onCloseDrawer();
  };

  useEffect(() => {
    if (setting) {
      setActualSetting(setting);
    }
    setUpSettingValues();
  }, [setting]);

  const setUpSettingValues = (): void => {
    const settingValues = formatSettingValue();
    dispatch(
      setNewSettingInput({
        ...settingInput,
        configuration_id: selectedConfiguration?._id as string,
        name: '',
        settings_values: settingValues,
      } as SettingInput),
    );
  };

  const formatSettingValue = (): SettingsValue[] => {
    let settingValues: SettingsValue[] = [];
    if (selectedConfiguration) {
      const configurableSettings =
        selectedConfiguration?.configurable_settings as
          | ConfigurableSettings[]
          | [];
      if (configurableSettings) {
        configurableSettings.forEach((item) => {
          settingValues = [
            ...settingValues,
            ...[
              {
                configurable_setting: item,
                configurable_settings_id: item._id,
                value: item.default,
              } as SettingsValue,
            ],
          ];
        });
      }
    }
    return settingValues;
  };

  const getRequiredConfigurable = (
    configurableSettings: ConfigurableSettings[],
    settingValues: SettingsValue[],
  ): boolean => {
    for (let i = 0; i < configurableSettings?.length; i++) {
      const item = configurableSettings[i];
      if (
        item.required &&
        !getValidateRequiredSettingsConfigurable(item._id, settingValues)
      ) {
        return false;
      }
    }
    return true;
  };

  const getValidateRequiredSettingsConfigurable = (
    configurableSettingId: string,
    configurableValues: SettingsValue[],
  ): boolean => {
    if (!configurableValues) {
      return false;
    }
    const index = configurableValues.findIndex(
      (item) => item.configurable_settings_id === configurableSettingId,
    );
    return (
      index >= 0 && configurableValues && configurableValues[index].value !== ''
    );
  };

  const isValidForm = (): boolean => {
    return getRequiredConfigurable(
      selectedConfiguration?.configurable_settings as ConfigurableSettings[],
      actualSetting
        ? actualSetting.settings_values
        : (settingInput?.settings_values as SettingsValue[]),
    );
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ): void => {
    switch (field) {
      case 'settings_values':
        if (actualSetting) {
          const currentSettingValues = actualSetting?.settings_values || [];

          currentSettingValues[index] = {
            ...currentSettingValues[index],
            [prop]: value.toString(),
          };
          setActualSetting({
            ...actualSetting,
            settings_values: currentSettingValues,
          } as Setting);
        } else {
          const currentSettingValues = settingInput?.settings_values || [];

          currentSettingValues[index] = {
            ...currentSettingValues[index],
            [prop]: value.toString(),
          };
          dispatch(
            setNewSettingInput({
              ...settingInput,
              settings_values: currentSettingValues,
            } as Setting),
          );
        }
        break;
      default:
        if (actualSetting) {
          setActualSetting({
            ...actualSetting,
            [prop]: value,
          } as Setting);
        } else {
          dispatch(
            setNewSettingInput({
              ...settingInput,
              [prop]: value,
            } as SettingInput),
          );
        }
        break;
    }
  };

  const handleCreateSetting = (): void => {
    setLoading(true);
    const settingsFormtted = settingInput?.settings_values as
      | SettingsValueFormat[]
      | [];

    const settingInputFormatted = {
      ...settingInput,
      settings_values: settingsFormtted as SettingsValue[],
    };

    Settings.create(settingInputFormatted as SettingInput)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: 'Setting created successfully',
            type: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
        onCloseDrawer();
        setUpSettingValues();
      });
  };

  const handleSaveChanges = (): void => {
    setLoading(true);
    Settings.update(actualSetting as Setting)
      .then((_response) => {
        onCloseDrawer();
        dispatch(
          setAlert({
            highlight: 'Update setting',
            message: 'Setting successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <RightDrawer
        open={open}
        actionLoading={loading}
        actionLabel={actualSetting ? 'Update Setting' : 'Create Setting'}
        title={actualSetting ? 'Update Setting' : 'Create Setting'}
        actionCallback={handleSettingCallback}
        disableAction={!isValidForm()}
        onCloseDrawer={onCloseDrawer}
        size="800"
        content={
          <SettingForm
            action={actualSetting ? 'save' : 'create'}
            onInputChange={handleDynamicChange}
            setting={
              actualSetting
                ? (actualSetting as Setting)
                : (settingInput as SettingInput)
            }
            handleOpenAttachCommandDrawer={handleOpenAttachCommandDrawer}
          />
        }
      />

      <AttachCommandsDrawer
        open={openAttachCommandDrawer}
        singleSelect={true}
        itemCommands={true}
        handleChooseCommands={handleChooseCommands}
        handleCloseDrawer={handleCloseAttachCommandDrawer}
      />
    </>
  );
};

export default SettingManagementDrawer;
