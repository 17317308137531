import { Command } from '@edgeiq/edgeiq-api-js';

import { TableColumn, TableItemType } from '../../components/SharedTable';
import { commandsSenderTypes } from '../../app/constants';

export const CommandsColumns: TableColumn[] = [
  {
    cellValue: (item: TableItemType): string => {
      const command = item as Command;
      return command.name;
    },
    id: 'name',
    isBold: (_item): boolean => true,
    label: 'Name',
    link: (item: TableItemType): string => {
      const command = item as Command;
      return `/command/${command._id}`;
    },
    type: 'link',
  },
  {
    cellValue: (item: TableItemType): string => {
      const command = item as Command;
      return commandsSenderTypes[command.sender_type];
    },
    id: 'sender_type',
    label: 'Sender Type',
    type: 'text',
  },
];
