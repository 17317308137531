import React, { ChangeEvent, useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import { Companies, Company, PaginationFilter } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setStateCompanies } from '../../redux/reducers/companies.reducer';
import {
  setFilters,
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { RootState } from '../../redux/store';
import Header from '../../containers/HeaderWithActionButton';
import ListSelection from '../../components/ListSelection';
import Card from '../../components/Card';
import CardsGrid from '../../components/CardsGrid';
import SharedTable from '../../components/SharedTable';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  genericViewOptions,
} from '../../app/constants';
import { SortingOption } from '../../models/common';
import parseFilters from '../../helpers/parseFilters';
import getInitialSorting from '../../helpers/getInitialSorting';
import AccountCard from './AccountCard';
import AccountsFilters from './AccountsFilters';
import { sortingOptions } from './constants';
import { CompaniesColumns } from './columns';
import useStyles from './styles';
import ActionDialog from '../../components/ActionDialog';
import { setUserCompanies } from '../../redux/reducers/user.reducer';

const AccountsPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const companyState = useAppSelector((state: RootState) => state.companies);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [companyList, setCompanyList] = useState<Company[]>(
    companyState.companies,
  );
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.companies.sortBy, sortingOptions),
  );
  const [selectedView, setSelectedView] = useState(filters.companies.view);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const userCompaniesState = useAppSelector(
    (state: RootState) => state.user.userCompanies,
  );
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const fetchCompanies = async (
    pageNumber: number,
    addPage = false,
  ): Promise<Company[]> => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    const result = await Companies.list(
      parseFilters(filters.companies.filters ?? {}),
      pagination,
    );

    const newCompanies = addPage
      ? [...companyList, ...result.companies]
      : result.companies;

    setTotalAndPage(result.pagination.total, addPage);
    return newCompanies;
  };

  const getCompanies = (pageNum: number, addPage = false): void => {
    fetchCompanies(pageNum, addPage)
      .then((result) => {
        setCompanyList(result);
        dispatch(setStateCompanies(result));
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  useEffect(() => {
    setLoading(true);
    setCompanyList([]);
    getCompanies(1);
  }, [filters.companies]);

  const checkCompanyCallback =
    (companyId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedCompanies([...selectedCompanies, companyId]);
      } else {
        setSelectedCompanies(
          selectedCompanies.filter((item) => item !== companyId),
        );
      }
    };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getCompanies(page + 1, true);
  };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'companies'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'companies'));
    setSelectedView(view);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleSelectAll = (): void => {
    if (selectedCompanies.length !== companyList.length) {
      setSelectedCompanies(companyList.map((company) => company._id));
    } else {
      setSelectedCompanies([]);
    }
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);

    const deleteApis = selectedCompanies.map((e) => {
      return Companies.delete(e);
    });

    Promise.all(deleteApis)
      .then(() => {
        let pickedFilters = '';
        if (filters?.companies?.filters) {
          pickedFilters = filters.companies.filters._id;
        }
        const includesCompany = (key: string): boolean =>
          selectedCompanies.includes(key);
        const updatedCompanyFilters =
          pickedFilters &&
          pickedFilters
            .split('|')
            .filter((filterKey) => !includesCompany(filterKey))
            .join('|');

        if (updatedCompanyFilters) {
          dispatch(
            setFilters(
              {
                _id: updatedCompanyFilters,
              },
              'companies',
            ),
          );
        }
        dispatch(
          setUserCompanies(
            userCompaniesState.filter(
              (company) => !includesCompany(company._id),
            ),
          ),
        );
        dispatch(
          setAlert({
            highlight: deleteHighlight(
              selectedCompanies.length,
              'Account',
              'Accounts',
            ),
            type: 'success',
          }),
        );
        setCompanyList(
          companyList.filter(
            (company) => !selectedCompanies.includes(company._id),
          ),
        );
        setTotal(total - selectedCompanies.length);
        setSelectedCompanies([]);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoadingDelete(false);
        closeDeleteModal();
      });
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Accounts"
        link="new-account"
        actionLabel="Create New Account"
        model="company"
      />
      <AccountsFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={sortingOptions}
        viewsOptions={genericViewOptions}
        itemsSelected={!!selectedCompanies.length}
        allSelected={
          companyList.length !== 0 &&
          selectedCompanies.length === companyList.length
        }
        deleteAction={true}
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
        itemsSelectedCount={selectedCompanies.length}
        selectedLabel="account"
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && (
            <CardsGrid
              twoColumns={true}
              cards={companyList.map((company) => (
                <Card
                  checked={selectedCompanies.includes(company._id)}
                  checkboxCallback={checkCompanyCallback}
                  id={company._id}
                  baseLink="/account"
                  content={<AccountCard company={company} />}
                />
              ))}
            />
          )}
          {selectedView === 'list' && (
            <SharedTable
              columns={CompaniesColumns}
              rows={companyList}
              sortBy={selectedSorting.value}
              sortDirection={
                selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={selectedCompanies.length === companyList.length}
              loading={loading}
              selectedItemsIds={selectedCompanies}
              onRequestSort={handleTableSorting}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkCompanyCallback}
            />
          )}
          {companyList.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedCompanies.length === 1 ? 'account' : 'accounts'
            }:`}</span>
            <ul>
              {companyList
                .filter((company) => selectedCompanies.includes(company._id))
                .map((company) => (
                  <li key={company._id}>
                    {company.name} - Id: {company._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default AccountsPage;
