import React, { useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { DeviceTypes, Rule } from '@edgeiq/edgeiq-api-js';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import { errorHighlight } from '../../../app/constants';
import { AttachPoliciesDrawer } from '../../../containers/RightDrawer';
import TabsPage from '../../../components/TabsPage';
import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  setSelectedPolicies,
  setOriginalSelectedPolicies,
} from '../../../redux/reducers/policies.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';

const DeviceTypePolicies: React.FC = () => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedPolicies = useAppSelector(
    (state: RootState) => state.policies.selectedPolicies,
  );
  const originalPolicies = useAppSelector(
    (state: RootState) => state.policies.originalSelectedPolicies,
  );
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const userCompanies = useAppSelector(
    (state: RootState) => state.user.userCompanies,
  );

  const columns: GridColDef[] = [
    {
      field: 'description',
      flex: 1,
      headerName: 'Policy Name (Description)',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <strong>{params.row.description}</strong>
      ),
    },
    {
      field: 'company_id',
      flex: 0.5,
      headerName: 'Account',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            userCompanies.find(
              (company) => company._id === params.row.company_id,
            )?.name
          }
        </span>
      ),
    },
  ];

  const handleOpenPoliciesDrawer = (): void => {
    setOpen(true);
  };

  const handleClosePoliciesDrawer = (): void => {
    setOpen(false);
  };

  const handleChoosePolicies = (newPolicies: Rule[]): void => {
    dispatch(setSelectedPolicies(newPolicies));
    handleClosePoliciesDrawer();
    setLoading(true);

    const attachPolicies = newPolicies.filter((newPolicy) =>
      originalPolicies.every(
        (originalPolicy) => newPolicy._id !== originalPolicy._id,
      ),
    );
    const detachPolicies = originalPolicies.filter((originalPolicy) =>
      newPolicies.every((newPolicy) => originalPolicy._id !== newPolicy._id),
    );

    //TODO: Check what its happening to bulkAttachRules
    //TODO: Check bulkDetachRules avaiability
    Promise.all([
      Promise.all(
        attachPolicies.map(async (attachPolicy) => {
          await DeviceTypes.attachRule(id as string, attachPolicy._id);
        }),
      ),
      Promise.all(
        detachPolicies.map(async (detachPolicy) => {
          await DeviceTypes.detachRule(id as string, detachPolicy._id);
        }),
      ),
    ])
      .then(() => {
        dispatch(setOriginalSelectedPolicies(newPolicies));
        dispatch(
          setAlert({
            highlight: 'Managing policies',
            message: 'Policies successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while managing policies.',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editableDeviceType) {
      setLoading(true);

      DeviceTypes.getRules(editableDeviceType._id)
        .then((response) => {
          dispatch(setSelectedPolicies(response));
          dispatch(setOriginalSelectedPolicies(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDeviceType]);

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedPolicies}
        addButtonLabel="Attach policies"
        onAddClick={handleOpenPoliciesDrawer}
        tableTitle="Policies attached"
        loading={loading}
      />
      <AttachPoliciesDrawer
        open={open}
        handleCloseDrawer={handleClosePoliciesDrawer}
        itemPolicies={true}
        onChoosingPolicies={handleChoosePolicies}
      />
    </Box>
  );
};

export default DeviceTypePolicies;
