import React, { useState, useEffect, ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { DeviceTypes, Ingestor, Translators } from '@edgeiq/edgeiq-api-js';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import {
  errorHighlight,
  ingestorListenerTypesMap,
  ingestorHandlerTypesMap,
} from '../../../app/constants';
import { AttachIngestorsDrawer } from '../../../containers/RightDrawer';
import TabsPage from '../../../components/TabsPage';
import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import {
  setOriginalIngestors,
  setSelectedIngestors,
} from '../../../redux/reducers/ingestors.reducer';
import { setStateTranslators } from '../../../redux/reducers/translators.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';

const DeviceTypeIngestors: React.FC = () => {
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedIngestors = useAppSelector(
    (state: RootState) => state.ingestors.selectedIngestors,
  );
  const originalIngestors = useAppSelector(
    (state: RootState) => state.ingestors.originalIngestors,
  );
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const userCompanies = useAppSelector(
    (state: RootState) => state.user.userCompanies,
  );
  const translators = useAppSelector(
    (state: RootState) => state.translators.translators,
  );

  useEffect(() => {
    if (!translators.length) {
      Translators.list().then((response) => {
        dispatch(setStateTranslators(response.translators));
      });
    }
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <strong>{params.row.name}</strong>
      ),
    },
    {
      field: 'company_id',
      flex: 0.5,
      headerName: 'Account',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            userCompanies.find(
              (company) => company._id === params.row.company_id,
            )?.name
          }
        </span>
      ),
    },
    {
      field: 'listener_type',
      flex: 0.3,
      headerName: 'Listener Type',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{ingestorListenerTypesMap[params.row.listener_type]}</span>
      ),
    },
    {
      field: 'handler_type',
      flex: 0.3,
      headerName: 'Handler Type',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{ingestorHandlerTypesMap[params.row.handler_type]}</span>
      ),
    },
    {
      field: 'translator_id',
      flex: 0.5,
      headerName: 'Translator',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            translators.find(
              (translator) => translator._id === params.row.translator_id,
            )?.name
          }
        </span>
      ),
    },
  ];

  const handleChooseIngestors = (newIngestors: Ingestor[]): void => {
    dispatch(setSelectedIngestors(newIngestors));
    setOpen(false);
    setLoading(true);

    const attachIngestors = newIngestors.filter((newIngestor) =>
      originalIngestors.every(
        (originalIngestor) => newIngestor._id !== originalIngestor._id,
      ),
    );
    const detachIngestors = originalIngestors.filter((originalIngestor) =>
      newIngestors.every(
        (newIngestor) => originalIngestor._id !== newIngestor._id,
      ),
    );

    Promise.all([
      Promise.all(
        attachIngestors.map(async (attachIngestor) => {
          await DeviceTypes.attachIngestor(id as string, attachIngestor._id);
        }),
      ),
      Promise.all(
        detachIngestors.map(async (detachIngestor) => {
          await DeviceTypes.detachIngestor(id as string, detachIngestor._id);
        }),
      ),
    ])
      .then(() => {
        dispatch(setOriginalIngestors(newIngestors));
        dispatch(
          setAlert({
            highlight: 'Managing Ingestors',
            message: 'Ingestors successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while managing ingestors.',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (editableDeviceType) {
      setLoading(true);

      DeviceTypes.getIngestors(editableDeviceType._id)
        .then((response) => {
          dispatch(setOriginalIngestors(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDeviceType]);

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedIngestors}
        addButtonIcon={false}
        addButtonLabel="Manage Ingestors"
        onAddClick={(): void => setOpen(true)}
        tableTitle="Ingestors attached"
        loading={loading}
      />
      <AttachIngestorsDrawer
        open={open}
        handleCloseDrawer={(): void => setOpen(false)}
        itemIngestors={true}
        onChoosingIngestors={handleChooseIngestors}
      />
    </Box>
  );
};

export default DeviceTypeIngestors;
