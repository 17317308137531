import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../components/TextInput';

interface PostmarkIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
}

const PostmarkIntegrationSection: React.FC<PostmarkIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Token"
          prop="token"
          value={newIntegration.token}
          onInputChange={onInputChange}
        />
      </Grid>
    </>
  );
};

export default PostmarkIntegrationSection;
