import React from 'react';
import {
  Typography,
  Grid,
  Divider,
  CircularProgress,
  List,
  Box,
  ListItemText,
  ListItem,
} from '@mui/material';
import { AwsThingGroup } from '@edgeiq/edgeiq-api-js';

import CheckboxInput from '../../components/CheckboxInput';

interface AwsThingGroupsListProps {
  title?: string;
  allAvailableAwsThingGroups: AwsThingGroup[];
  selectedAwsThingGroups?: AwsThingGroup[];
  readOnly?: boolean;
  loading?: boolean;
  onSelectedAwsThingGroupChange?: (value: AwsThingGroup) => void;
}

const AwsThingGroupsList: React.FC<AwsThingGroupsListProps> = ({
  title = 'Default AWS IoT Thing Groups',
  allAvailableAwsThingGroups,
  selectedAwsThingGroups,
  readOnly = false,
  loading = false,
  onSelectedAwsThingGroupChange,
}) => {
  return (
    <>
      <Typography className="mt-4 mb-0" variant="h6">
        {title}
      </Typography>
      {loading && (
        <Grid container direction="row" alignItems="center">
          <Grid item xs={1}>
            <CircularProgress size="2rem" />
          </Grid>
          <Grid item xs={11}>
            <p>Loading AWS Thing Groups for integration...</p>
          </Grid>
        </Grid>
      )}
      {!loading && (
        <>
          <List>
            {allAvailableAwsThingGroups.map(
              (thingGroup: AwsThingGroup, index: number) => {
                return (
                  <Box key={thingGroup.arn}>
                    <ListItem
                      alignItems="flex-start"
                      data-cy="thinggroup-container"
                      secondaryAction={
                        !readOnly && (
                          <CheckboxInput
                            label=""
                            prop="default_thing_groups"
                            value={thingGroup.arn}
                            onCheckboxClick={(): void => {
                              if (onSelectedAwsThingGroupChange) {
                                onSelectedAwsThingGroupChange(thingGroup);
                              }
                            }}
                            checked={selectedAwsThingGroups?.some(
                              (dtg) => dtg.arn === thingGroup.arn,
                            )}
                          />
                        )
                      }
                    >
                      <ListItemText
                        primary={thingGroup.name}
                        secondary={
                          <Typography
                            sx={{ display: 'inline' }}
                            component="span"
                            variant="body2"
                            color="text.primary"
                            data-cy="thinggroup-arn"
                          >
                            {thingGroup.arn}
                          </Typography>
                        }
                      />
                    </ListItem>
                    {index !== allAvailableAwsThingGroups.length - 1 && (
                      <Divider variant="inset" component="li" />
                    )}
                  </Box>
                );
              },
            )}
          </List>
        </>
      )}
    </>
  );
};

export default AwsThingGroupsList;
