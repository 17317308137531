import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { HomeMax } from '@mui/icons-material';
import { Command } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppSelector } from '../../redux/hooks';
import { getDeviceSelector } from '../../redux/reducers/devices.reducer';
import { RootState } from '../../redux/store';
import { commandsSenderTypes } from '../../app/constants';
import useStyles from './styles';

interface CommandCardProps {
  command: Command;
}

const CommandCard: React.FC<CommandCardProps> = ({ command }) => {
  const classes = useStyles();

  const deviceData = useAppSelector((state: RootState) =>
    getDeviceSelector(state.devices, command.device_id),
  );

  return (
    <Box>
      <Tooltip placement="top" title={command.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-2', classes.title)}
        >
          {command.name}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Type">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          {commandsSenderTypes[command.sender_type]}
        </Typography>
      </Tooltip>
      {deviceData && (
        <Tooltip placement="top" title="Type">
          <Typography
            variant="overline"
            component="div"
            className={clsx('mb-1', classes.tag)}
          >
            <HomeMax fontSize="medium" className="mr-2" />
            {deviceData.name}
          </Typography>
        </Tooltip>
      )}
    </Box>
  );
};

export default CommandCard;
