import { Command, CommandInput, SenderType } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

interface AdaptedCommand extends Command {
  is_from_device_type?: boolean;
}

const emptyCommand: CommandInput = {
  company_id: '',
  device_id: '',
  long_description: '',
  name: '',
  options: {},
  payload: '',
  sender: {},
  sender_type: SenderType.tcp_sender,
  translator_id: '',
};

export const CommandsTypes = {
  CLEAN_NEW_COMMAND_INPUT: 'CLEAN_NEW_COMMAND_INPUT',
  SET_ACTUAL_COMMAND: 'SET_ACTUAL_COMMAND',
  SET_COMMANDS: 'SET_COMMANDS',
  SET_NEW_COMMAND: 'SET_NEW_COMMAND',
  SET_NEW_COMMAND_INPUT: 'SET_NEW_COMMAND_INPUT',
  SET_ORIGINAL_COMMANDS: 'SET_ORIGINAL_COMMANDS',
  SET_SELECTED_COMMANDS: 'SET_SELECTED_COMMANDS',
};

export const cleanNewDeviceConfig = (): CommandsAction => ({
  data: emptyCommand as CommandInput,
  type: CommandsTypes.CLEAN_NEW_COMMAND_INPUT,
});

export const setStateCommands = (commands: Command[]): CommandsAction => ({
  data: commands,
  type: CommandsTypes.SET_COMMANDS,
});

export const setActualCommand = (command: Command): CommandsAction => ({
  data: command,
  type: CommandsTypes.SET_ACTUAL_COMMAND,
});

export const setNewCommand = (command: Command): CommandsAction => ({
  data: command,
  type: CommandsTypes.SET_NEW_COMMAND,
});

export const setNewCommandInput = (command: CommandInput): CommandsAction => ({
  data: command,
  type: CommandsTypes.SET_NEW_COMMAND_INPUT,
});

export const setSelectedCommands = (
  commands: AdaptedCommand[],
): CommandsAction => ({
  data: commands,
  type: CommandsTypes.SET_SELECTED_COMMANDS,
});

export const setOriginalSelectedCommands = (
  commands: AdaptedCommand[],
): CommandsAction => ({
  data: commands,
  type: CommandsTypes.SET_ORIGINAL_COMMANDS,
});

export type CommandsState = {
  command: Command | null;
  commands: Command[];
  newCommand: Command | null;
  newCommandInput: CommandInput | null;
  originalSelectedCommands: AdaptedCommand[];
  selectedCommands: AdaptedCommand[];
};

export const getCommandSelector = createSelector(
  (state: CommandsState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.commands.length) {
      return state.command;
    }
    return state.commands.find((command: Command) => command._id === id);
  },
);

// INITIAL STATE
const commandsInitialState: CommandsState = {
  command: null,
  commands: [],
  newCommand: null,
  newCommandInput: emptyCommand as CommandInput,
  originalSelectedCommands: [],
  selectedCommands: [],
};

export type CommandsAction = {
  data: Command[] | AdaptedCommand[] | Command | CommandInput;
  type: string;
};

const commandsReducer = (
  state = commandsInitialState,
  action: CommandsAction,
): CommandsState => {
  switch (action.type) {
    case CommandsTypes.SET_COMMANDS:
      return {
        ...state,
        commands: action.data as Command[],
      };

    case CommandsTypes.SET_ACTUAL_COMMAND:
      return {
        ...state,
        command: action.data as Command,
        newCommand: action.data as Command,
      };

    case CommandsTypes.SET_NEW_COMMAND:
      return {
        ...state,
        newCommand: action.data as Command,
      };

    case CommandsTypes.SET_SELECTED_COMMANDS:
      return {
        ...state,
        selectedCommands: action.data as AdaptedCommand[],
      };

    case CommandsTypes.SET_ORIGINAL_COMMANDS:
      return {
        ...state,
        originalSelectedCommands: action.data as AdaptedCommand[],
      };

    case CommandsTypes.SET_NEW_COMMAND_INPUT:
      return {
        ...state,
        newCommandInput: action.data as CommandInput,
      };

    case CommandsTypes.CLEAN_NEW_COMMAND_INPUT:
      return {
        ...state,
        newCommandInput: action.data as CommandInput,
      };
    default:
      return state;
  }
};

export default commandsReducer;
