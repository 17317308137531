import React, { useState } from 'react';
import { ScheduledJob } from '@edgeiq/edgeiq-api-js';
import { Typography, Grid, Paper } from '@mui/material';
import clsx from 'clsx';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import {
  getScheduledJobSelector,
  setNewScheduledJob,
} from '../../../redux/reducers/scheduledJobs.reducer';
import ScheduledJobsForm from '../../../containers/Forms/ScheduledJobsForm/ScheduledJobsForm';
import { useParams } from 'react-router-dom';

const ScheduledJobDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<string>();

  const editableScheduledJob = useAppSelector(
    (state: RootState) => state.scheduledJobs.newScheduledJob,
  );
  const scheduledJobData = useAppSelector((state: RootState) =>
    getScheduledJobSelector(state.scheduledJobs, id),
  );
  const newScheduledJob = useAppSelector(
    (state: RootState) => state.scheduledJobs.newScheduledJob,
  );
  const [invalidOptionJson, setInvalidOptionJson] = useState(false);

  const handleValueChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    switch (prop) {
      case 'options':
        try {
          const type =
            scheduledJobData?.job_type === 'user-defined-command'
              ? 'command_options'
              : 'workflow_options';

          const formattedJson = JSON.parse(value as string);
          dispatch(
            setNewScheduledJob({
              ...(newScheduledJob as ScheduledJob),
              details: {
                [type]: formattedJson,
              },
            }),
          );
          setInvalidJsonValue(prop, false);
        } catch (e) {
          if (!value) {
            setInvalidJsonValue(prop, false);
            return;
          }
          setInvalidJsonValue(prop, true);
        }
        break;
      default:
        dispatch(
          setNewScheduledJob({
            ...(newScheduledJob as ScheduledJob),
            [prop]: value,
          }),
        );
        break;
    }
  };
  const setInvalidJsonValue = (prop: string, value: boolean): void => {
    switch (prop) {
      case 'options':
        setInvalidOptionJson(value);
        break;
    }
  };

  return (
    <Grid container>
      {editableScheduledJob && (
        <>
          <Grid component={Paper} item xs={12} className={clsx('p-7 shadow')}>
            {/* Details Form */}
            <Typography variant="h5">Details</Typography>
            <ScheduledJobsForm
              invalidOptionJson={invalidOptionJson}
              newScheduledJob={newScheduledJob as ScheduledJob}
              onInputChange={handleValueChange}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ScheduledJobDetails;
