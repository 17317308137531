import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Device } from '@edgeiq/edgeiq-api-js';
import {
  GENRE_ACTIVE,
  GENRE_DISCOVERED,
  GENRE_ESCROW,
} from '../../app/constants';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { IssueCommandDrawer } from '../../containers/RightDrawer';
import IssueTransferDrawer from './escrowDevices/IssueTransfer';
import PromoteDevicesDrawer from './discoveredDevices/PromoteDevices';

interface DevicesExtraActionsProps {
  selectedDevicesIds?: string[];
  selectedDevices?: Device[];
  onRemoveDevice?: (deviceId: string) => void;
}

const DevicesExtraActions: React.FC<DevicesExtraActionsProps> = ({
  selectedDevicesIds,
  selectedDevices,
  onRemoveDevice,
}) => {
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const isActive = devicesState.devicesGenre === GENRE_ACTIVE;
  const isEscrow = devicesState.devicesGenre === GENRE_ESCROW;
  const isDiscovered = devicesState.devicesGenre === GENRE_DISCOVERED;

  const [openIssueCommand, setopenIssueCommand] = useState(false);
  const [openTransfers, setOpenTransfers] = useState(false);
  const [openPromote, setOpenPromote] = useState(false);

  const handleOpen = (type: string) => (): void => {
    if (type === 'command') {
      setopenIssueCommand(true);
    }
    if (type === 'transfer') {
      setOpenTransfers(true);
    }
    if (type === 'promote') {
      setOpenPromote(true);
    }
  };

  const handleCloseIssueCommand = (): void => {
    setopenIssueCommand(false);
  };

  const handleCloseTransfers = (): void => {
    setOpenTransfers(false);
  };

  const handleClosePromote = (): void => {
    setOpenPromote(false);
  };

  const handleIssueCommandCallback = (): void => {
    handleCloseIssueCommand();
  };

  return isActive ? (
    <>
      <Button
        variant="contained"
        size="medium"
        className="ml-4"
        onClick={handleOpen('command')}
      >
        Issue Command
      </Button>
      <IssueCommandDrawer
        open={openIssueCommand}
        devices={selectedDevices}
        handleCloseDrawer={handleCloseIssueCommand}
        handleSubmitSuccess={handleIssueCommandCallback}
        onRemoveDevice={onRemoveDevice}
      />
    </>
  ) : isEscrow ? (
    <>
      <Button
        variant="contained"
        size="medium"
        className="ml-4"
        onClick={handleOpen('transfer')}
      >
        Issue Transfer
      </Button>
      <IssueTransferDrawer
        open={openTransfers}
        escrowDevices={devicesState.escrowDevices.filter((escrowDevice) =>
          selectedDevicesIds?.includes(escrowDevice._id),
        )}
        handleCloseDrawer={handleCloseTransfers}
      />
    </>
  ) : isDiscovered ? (
    <>
      <Button
        variant="contained"
        size="medium"
        className="ml-4"
        onClick={handleOpen('promote')}
      >
        Promote
      </Button>
      <PromoteDevicesDrawer
        open={openPromote}
        discoveredDevices={devicesState.discoveredDevices.filter((device) =>
          selectedDevicesIds?.includes(device._id),
        )}
        handleCloseDrawer={handleClosePromote}
      />
    </>
  ) : (
    <></>
  );
};

export default DevicesExtraActions;
