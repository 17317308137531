import React from 'react';
import { Grid, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { SettingsApplicationRecord } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import useStyles from './styles';
import timeHelpers from '../../helpers/timeHelpers';

export interface SettingApplicationListCardProps {
  settingsApplicationRecord: SettingsApplicationRecord;
  settingSelected?: (
    settingApplication: SettingsApplicationRecord,
    open: string,
  ) => void;
}

const SettingApplicationListCard: React.FC<SettingApplicationListCardProps> = ({
  settingsApplicationRecord,
  settingSelected,
}) => {
  const classes = useStyles();

  const editSetting = (open: 'command' | 'setting'): void => {
    if (settingSelected) {
      settingSelected(settingsApplicationRecord, open);
    }
  };

  const creationDate = timeHelpers.getDate(
    settingsApplicationRecord.created_at,
  );

  const handleOpenCommandSettingDrawer = (): void => editSetting('command');
  const handleOpenEditSettingDrawer = (): void => editSetting('setting');

  const executionStatuses =
    settingsApplicationRecord.command_execution.execution_statuses;

  return (
    <Grid container direction="row">
      <Box className="mb-6">
        <>
          <div>
            {executionStatuses && (
              <div className={clsx('px-2 py-1 br-1 mb-2', classes.container)}>
                <Typography
                  data-testid="display-status"
                  variant="caption"
                  className={classes.label}
                  onClick={handleOpenCommandSettingDrawer}
                >
                  {executionStatuses[0].status}
                </Typography>
              </div>
            )}
            <Tooltip placement="top-start" title="Setting">
              <Typography
                data-testid="tooltip-setting-drawer"
                variant="button"
                component="div"
                noWrap
                className={clsx('mb-1', classes.title)}
                onClick={handleOpenEditSettingDrawer}
              >
                {settingsApplicationRecord.setting.name}
              </Typography>
            </Tooltip>
          </div>
          <Typography
            variant="overline"
            component="div"
            className={clsx(classes.subtitle, 'mb-1')}
          >
            {creationDate}
          </Typography>
          <Tooltip placement="top-start" title="Command">
            <Typography
              data-testid="tooltip-command-drawer"
              className={clsx(classes.link, 'mb-1')}
              onClick={handleOpenCommandSettingDrawer}
            >
              {settingsApplicationRecord.command.name}
            </Typography>
          </Tooltip>
        </>
      </Box>
    </Grid>
  );
};

export default SettingApplicationListCard;
