import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../components/TextInput';

interface AzureIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
  disabled?: boolean;
}

const AzureIntegrationSection: React.FC<AzureIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Iot Hub Hostname"
          prop="iot_hub_hostname"
          value={newIntegration.iot_hub_hostname}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Shared Access Key"
          prop="shared_access_key"
          value={newIntegration.shared_access_key}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Access Policy Name"
          prop="access_policy_name"
          value={newIntegration.access_policy_name}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default AzureIntegrationSection;
