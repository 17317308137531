import React, { useEffect, useState } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  DeviceType,
  DeviceTypes,
  SoftwareUpdates,
} from '@edgeiq/edgeiq-api-js';
import cloneDeep from 'lodash.clonedeep';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  setNewSoftwareUpdateInput,
  cleanNewSoftwareUpdateInput,
} from '../../redux/reducers/softwareUpdates.reducer';
import { setOptionsDeviceTypes } from '../../redux/reducers/deviceTypes.reducer';
import { errorHighlight, optionsPaginationsFilter } from '../../app/constants';
import Publish from '../../containers/Publish';
import SoftwarePackageForm from '../../containers/Forms/SoftwarePackageForm';
import Header from '../../containers/HeaderWithActionButton';
import { SoftwareCreateLocationState } from '../deviceProfileContent/deviceTypeSoftwareUpdate/DeviceTypeSoftwareUpdates';
import useStyles from './styles';

const CreateSoftwarePackage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { state: locationState } = useLocation();
  const softwareUpdatesState = useAppSelector(
    (state: RootState) => state.softwareUpdates,
  );
  const { newSoftwareUpdateInput } = softwareUpdatesState;
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [devicesTypes, setDevicesTypes] = useState<DeviceType[]>(
    deviceTypesState.optionsDeviceTypes,
  );
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  useEffect(() => {
    if (devicesTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          setDevicesTypes(result.deviceTypes);
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  useEffect(() => {
    setEnableSubmit(
      newSoftwareUpdateInput.name !== '' &&
        newSoftwareUpdateInput.device_type_id !== '' &&
        newSoftwareUpdateInput.company_id !== '',
    );
  }, [newSoftwareUpdateInput]);

  const handleChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    let deviceToUpdate = cloneDeep(newSoftwareUpdateInput);

    switch (prop) {
      default:
        deviceToUpdate = { ...deviceToUpdate, [prop]: value };
    }
    dispatch(setNewSoftwareUpdateInput(deviceToUpdate));
  };

  const handleAccountChange = (companyId: string): void => {
    handleChange('company_id', companyId);
  };

  const submitHandler = (): void => {
    setIsSubmitting(true);
    SoftwareUpdates.create(newSoftwareUpdateInput)
      .then(async (result) => {
        try {
          const files = await SoftwareUpdates.uploadFile(
            result._id,
            uploadedFiles,
          );
          result.files = files;
        } catch (error: unknown) {
          const { message } = error as { message: string };
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message,
              type: 'error',
            }),
          );
        }

        dispatch(
          setAlert({
            highlight: 'Software updates created successfully',
            type: 'success',
          }),
        );
        dispatch(cleanNewSoftwareUpdateInput());
        navigate(
          (locationState as SoftwareCreateLocationState)?.from ||
            `/software-updates/${result._id}`,
        );
      })
      .catch((error) => {
        setAlert({
          highlight: errorHighlight,
          message: error.messages,
          type: 'error',
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="software-updates"
          goBackLabel="Software Packages"
          isCreatePage={true}
          model="software_update"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8 ">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-software-package-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create new package
                </Typography>
              </div>
              <SoftwarePackageForm
                softwareUpdate={newSoftwareUpdateInput}
                onInputChange={handleChange}
                uploadedFiles={uploadedFiles}
                setUploadedFiles={setUploadedFiles}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="software updates"
          submitting={isSubmitting}
          companyId={newSoftwareUpdateInput.company_id ?? ''}
          onChangeAccount={handleAccountChange}
          onSubmit={submitHandler}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateSoftwarePackage;
