import React, { ChangeEvent } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import { Company, CompanyInput } from '@edgeiq/edgeiq-api-js';

import TextInput from '../../../../components/TextInput';
import ColorPickerInput from '../../../../components/ColorPicker';
import DropZone from '../../../../components/DropZone';

interface AccountFormProps {
  company?: Company;
  companyInput?: CompanyInput;
  uploadedLogo?: File;
  onInputChange: (prop: string, value: string | number) => void;
  onCheckboxChange: (prop: string, value: boolean) => void;
  setUploadedLogo: React.Dispatch<React.SetStateAction<File | undefined>>;
}

const validateInputValues = (
  company?: string,
  companyInput?: string,
): string => {
  if (company) {
    return company;
  }
  if (companyInput) {
    return companyInput;
  }
  return '';
};

const AccountForm: React.FC<AccountFormProps> = ({
  company,
  companyInput,
  uploadedLogo,
  onCheckboxChange,
  onInputChange,
  setUploadedLogo,
}) => {
  const handleCheckboxChange =
    (prop: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      onCheckboxChange(prop, checked);
    };

  const handleRadioChange =
    (prop: string) =>
    (_event: ChangeEvent<HTMLInputElement>, value: string): void => {
      onInputChange(prop, value);
    };

  // if company._id is defined it means we are entering the form to edit the account
  const branding = company?._id ? company.branding : companyInput?.branding;

  const onAddFile = (file: File): void => {
    setUploadedLogo(file);
  };

  const onRemoveFile = (): void => {
    setUploadedLogo(undefined);
  };

  return (
    <Box>
      {/* Row 1 */}
      <Grid container direction="row" spacing={2} className={clsx('mt-4')}>
        <Grid item xs={12} className="mt-12">
          <TextInput
            label="Name*"
            onInputChange={onInputChange}
            placeholder="Account Name"
            prop="name"
            required={true}
            value={validateInputValues(company?.name, companyInput?.name)}
          />
        </Grid>
      </Grid>

      {/* Row 2 */}
      {!company?._id && (
        <Grid container direction="row" spacing={2} className={clsx('mt-4')}>
          <Grid item xs={12} className="mt-12 ">
            <TextInput
              label="Account ID*"
              onInputChange={onInputChange}
              placeholder="Account ID"
              prop="_id"
              required={true}
              value={companyInput?._id ?? ''}
            />
          </Grid>
        </Grid>
      )}

      {/* Row 3 */}
      <Grid container direction="row" spacing={2} className={clsx('mt-2')}>
        <Grid item xs={12} className="mt-12">
          <TextInput
            label="Logo URL"
            onInputChange={onInputChange}
            placeholder="Logo URL"
            prop="branding.logo_url"
            value={branding?.logo_url}
          />
        </Grid>
      </Grid>

      {/* Row 4 */}
      <Grid container direction="row" spacing={2} className={clsx('mt-2')}>
        <Grid item xs={12} className="mt-12">
          <TextInput
            label="Icon URL"
            onInputChange={onInputChange}
            placeholder="Icon URL"
            prop="branding.icon_url"
            value={branding?.icon_url}
          />
        </Grid>
      </Grid>
      {/* Row 5 */}
      <Grid container direction="row" spacing={2} className={clsx('mt-2')}>
        <Grid item xs={12} className="mt-12">
          <TextInput
            label="Portal Title"
            onInputChange={onInputChange}
            placeholder="Portal Title"
            prop="branding.portal_title"
            value={branding?.portal_title}
          />
        </Grid>
      </Grid>

      {/* Row 6 */}
      <Grid container direction="row" spacing={2} className={clsx('mt-2')}>
        <Grid item xs={12} sm={12} className="mt-12">
          <Typography variant="h6" className="mb-4">
            Logo
          </Typography>
          <DropZone
            title="Rectangular shaped logo is recommended. Allowed file types: JPEG, PNG, SVG, WEBP, BMP"
            acceptTypes={[
              'image/jpeg',
              'image/png',
              'image/svg+xml',
              'image/x-ms-bmp',
              'image/webp',
            ]}
            stateFiles={uploadedLogo ? [uploadedLogo] : []}
            onFileUpload={onAddFile}
            onRemoveFile={onRemoveFile}
          />
        </Grid>
      </Grid>

      {/* Row 7 */}
      <Grid container direction="row" spacing={2} className={clsx('mt-2')}>
        <Grid item xs={12} sm={6} className="mt-12">
          <ColorPickerInput
            disabled={true}
            label="Primary Color"
            onInputChange={onInputChange}
            placeholder="Primary Color"
            prop="branding.primary_color"
            value={branding?.primary_color}
          />
        </Grid>
        <Grid item xs={12} sm={6} className="mt-12">
          <Typography variant="subtitle2" className="custom-label">
            Sidebar Links Color
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="sidebar-text-color-buttons-group-label"
              row
              name="branding.sidebar_text_color"
              onChange={handleRadioChange('branding.sidebar_text_color')}
              value={branding?.sidebar_text_color}
            >
              <FormControlLabel
                data-cy="radio-light"
                control={<Radio />}
                label="Light"
                value="#ffffff"
              />
              <FormControlLabel
                data-cy="radio-dark"
                control={<Radio />}
                label="Dark"
                value="#000000"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>

      {/* Row 7 */}
      <Grid container direction="row" spacing={2} className={clsx('mt-2')}>
        <Grid item xs={12} sm={6} className="mt-12" display="flex">
          <FormControlLabel
            control={
              <Checkbox
                data-cy="checkbox-gradient_sidbar"
                checked={branding?.gradient_sidbar}
                onChange={handleCheckboxChange('branding.gradient_sidbar')}
              />
            }
            label="Gradient Sidebar Background"
          />
        </Grid>
        {branding?.gradient_sidbar && (
          <Grid item xs={12} sm={6} className="mt-12">
            <ColorPickerInput
              data-cy="color-picker-secondary_color"
              disabled={true}
              label="Sidebar second Color"
              onInputChange={onInputChange}
              placeholder="Sidebar second Color"
              prop="branding.secondary_color"
              value={branding?.secondary_color}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AccountForm;
