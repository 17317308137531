import React, { useEffect } from 'react';
import {
  Typography,
  Snackbar,
  IconButton,
  useTheme,
  useMediaQuery,
  ClassNameMap,
} from '@mui/material';
import {
  Close as CloseIcon,
  CheckCircle as CheckIcon,
  Warning as WarningIcon,
  InfoOutlined as InfoOutlinedIcon,
} from '@mui/icons-material';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { closeAlert } from '../../redux/reducers/alert.reducer';
import useStyles from './styles';
import { StatusTheme } from '../../models/common';
import { useNavigate } from 'react-router-dom';

const ToastAlert: React.FC<Record<string, unknown>> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const alert = useAppSelector((state: RootState) => state.alert);

  const classes = useStyles({ status: alert.type });
  const [open, setOpen] = React.useState(false);

  const onClose = (): void => {
    setOpen(false);
    dispatch(closeAlert(false));
  };

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  useEffect(() => {
    if (!alert.show) {
      return;
    }
    setOpen(alert.show);
    if (alert.type === 'success') {
      const onCloseTimeout = setTimeout(() => {
        onClose();
      }, 5000);

      return () => clearTimeout(onCloseTimeout);
    }
  }, [alert]);

  const handleLink = (): void => {
    navigate(`/${alert.link}`);
  };

  const vertical = isMobile ? 'bottom' : 'top';
  const horizontal = isMobile ? 'center' : 'right';

  return (
    <Snackbar
      anchorOrigin={{
        horizontal,
        vertical,
      }}
      open={open}
      onClose={handleClose}
      className={classes.snackBar}
    >
      {renderContent(
        alert.type,
        classes,
        true,
        alert.highlight,
        alert.message,
        handleClose,
        alert.link,
        alert.linkText,
        handleLink,
      )}
    </Snackbar>
  );
};

const renderMessage = (
  message: string,
  link?: string,
  linkText?: string,
  handleLink?: () => void,
): React.ReactElement => {
  let finalMessage = <span>{message}</span>;
  const index = message.indexOf('<link>');
  if (link && linkText && handleLink && index !== -1) {
    finalMessage = (
      <>
        <span>{message.substring(0, index)}</span>
        <a
          onClick={handleLink}
          style={{
            color: 'rgb(64, 160, 219)',
            cursor: 'pointer',
          }}
        >
          {linkText}
        </a>
        <span>{message.substring(index + 6, message.length - 1)}</span>
      </>
    );
  }
  return finalMessage;
};

const renderContent = (
  type: StatusTheme,
  classes: ClassNameMap<string>,
  isToast: boolean,
  highlight?: string,
  message?: string,
  handleClose?: (event: React.SyntheticEvent | Event, reason?: string) => void,
  link?: string,
  linkText?: string,
  handleLink?: () => void,
): JSX.Element => (
  <div
    className={clsx('py-2 px-4 br-1', classes.contentContainer, {
      [classes.alertContainer]: !isToast,
      [classes.toastContainer]: isToast,
      ['shadow']: isToast,
    })}
  >
    <div className={classes.content}>
      {type === 'error' && (
        <WarningIcon fontSize="small" className={clsx('mr-2', classes.icon)} />
      )}
      {type === 'success' && (
        <CheckIcon fontSize="small" className={clsx('mr-2', classes.icon)} />
      )}
      {type === 'warning' && (
        <InfoOutlinedIcon
          fontSize="small"
          className={clsx('mr-2', classes.icon)}
        />
      )}
      {!isToast && (
        <Typography
          data-cy="alert-message"
          variant="button"
          className={classes.message}
        >
          {highlight && (
            <span className={clsx('mr-1', classes.highlight)}>
              {highlight}.
            </span>
          )}
          {message && renderMessage(message, link, linkText, handleLink)}
        </Typography>
      )}
      {isToast && (
        <div>
          {highlight && (
            <Typography
              data-cy="toast-alert"
              variant="button"
              component="div"
              className={classes.highlight}
            >
              {highlight}
            </Typography>
          )}
          {message && (
            <Typography
              variant="button"
              component="div"
              data-cy="toast-alert"
              className={classes.message}
            >
              {renderMessage(message, link, linkText, handleLink)}
            </Typography>
          )}
        </div>
      )}
    </div>
    {handleClose && (
      <IconButton
        className={clsx('ml-4', classes.clearIconBtn)}
        aria-label="close-toast"
        size="small"
        onClick={handleClose}
        edge="end"
      >
        <CloseIcon className={classes.clearIcon} />
      </IconButton>
    )}
  </div>
);

interface AlertProps {
  type: StatusTheme;
  message?: string;
  highlight?: string;
  handleClose?: (event: React.SyntheticEvent | Event, reason?: string) => void;
}

export const Alert: React.FC<AlertProps> = ({
  type,
  message,
  highlight,
  handleClose,
}) => {
  const classes = useStyles({ status: type });
  return (
    <>{renderContent(type, classes, false, highlight, message, handleClose)}</>
  );
};

export default ToastAlert;
