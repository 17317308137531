import React from 'react';
import { Grid } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../components/TextInput';

interface HttpClientSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | string[]) => void;
  disabled?: boolean;
}

const HttpClientSection: React.FC<HttpClientSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Method"
          placeholder="Examples: GET, POST, PUT"
          prop="listener.method"
          value={newIngestor.listener.method}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="URL"
          placeholder="Single: http://host.com/endpoint"
          prop="listener.url"
          value={newIngestor.listener.url}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Timeout (seconds)"
          prop="listener.timeout"
          placeholder=""
          value={newIngestor.listener.timeout}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Poll Interval"
          placeholder=""
          prop="listener.poll_interval"
          value={newIngestor.listener.poll_interval}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>
    </>
  );
};

export default HttpClientSection;
