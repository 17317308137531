import React, { useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setNewPolicy } from '../../../redux/reducers/policies.reducer';
import { RootState } from '../../../redux/store';
import PolicyConditionSection from '../../../containers/Forms/PolicyForm/PolicyConditionSection/PolicyConditionSection';
import {
  policyConditionTypes,
  PROPERTY_EVALUATION,
} from '../../../constants/policies';
import {
  addNewCondition,
  addNewValue,
  changeRuleConditionValues,
  removeCondition,
  removeValue,
} from '../../../containers/Forms/PolicyForm/helper';

const PolicyConditionSectionWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  const editablePolicy = useAppSelector(
    (state: RootState) => state.policies.newPolicy,
  );
  const [policyType, setPolicyType] = useState(
    editablePolicy.rule_condition.type as string,
  );

  useEffect(() => {
    if (editablePolicy?.rule_condition?.type) {
      let newPolicyType = Object.keys(policyConditionTypes).find(
        (e) => e === editablePolicy.rule_condition.type,
      );
      if (!newPolicyType) {
        newPolicyType = PROPERTY_EVALUATION;
      }
      setPolicyType(newPolicyType);
    }
  }, [editablePolicy.rule_condition]);

  // START OF THE RULE CONDITION MODULE
  const handleConditionChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    if (prop === 'policyType') {
      setPolicyType(value as string);
      return dispatch(
        setNewPolicy(changeRuleConditionValues(editablePolicy, 'type', value)),
      );
    }
    dispatch(
      setNewPolicy(changeRuleConditionValues(editablePolicy, prop, value)),
    );
  };

  const handleAddNewCondition = (): void => {
    return dispatch(setNewPolicy(addNewCondition(editablePolicy)));
  };

  const handleRemoveCondition = (conditionIndex: number): void => {
    return dispatch(
      setNewPolicy(removeCondition(editablePolicy, conditionIndex)),
    );
  };

  const handleAddNewValue = (index?: number): void => {
    return dispatch(setNewPolicy(addNewValue(editablePolicy, index)));
  };

  const handleRemoveValue = (index: number, conditionIndex?: number): void => {
    return dispatch(
      setNewPolicy(removeValue(editablePolicy, index, conditionIndex)),
    );
  };

  return (
    <Grid container>
      {editablePolicy && (
        <Grid component={Paper} item xs={12} className={'p-7 shadow'}>
          <PolicyConditionSection
            newPolicy={editablePolicy}
            onInputChange={handleConditionChange}
            policyType={policyType}
            onAddNewValue={handleAddNewValue}
            onRemoveValue={handleRemoveValue}
            onAddNewCondition={handleAddNewCondition}
            onRemoveCondition={handleRemoveCondition}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default PolicyConditionSectionWrapper;
