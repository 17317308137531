import React, { ChangeEvent } from 'react';
import {
  Grid,
  IconButton,
  MenuItem,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import TextInput from '../../../components/TextInput';
import SwitchButton from '../../../components/SwitchButton';
import { ConfigurableSettingsInput } from '@edgeiq/edgeiq-api-js';
import SelectInput from '../../../components/SelectInput';
import { configurationType } from '../../../app/constants';
import DynamicRows from '../../../components/DynamicRows';
import { ItemList } from '../../../components/DynamicRows/constants';

interface ConfigurationSettingForm {
  configurableSettings: ConfigurableSettingsInput;
  configIndex: number;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
  onDynamicRowsChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
    parentIndex: number,
  ) => void;

  handleAddRow: (prop: string, index: number) => void;
  handleRemoveRow: (prop: string, index: number, parentIndex: number) => void;
  onRemoveArrayRow: (index: number) => void;
}

const ConfigurationSettingForm: React.FC<ConfigurationSettingForm> = ({
  configurableSettings,
  configIndex,
  onInputChange,
  onDynamicRowsChange,
  handleAddRow,
  handleRemoveRow,
  onRemoveArrayRow,
}) => {
  const handleDynamicChange =
    (prop: string, index: number) =>
    (_field: string, value: string | number): void => {
      onInputChange(prop, value, 'configurable_settings', index);
    };

  const handleChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    onInputChange(prop, value as number, 'configurable_settings', configIndex);
  };

  const handleRadioChange =
    (prop: string) =>
    (_event: ChangeEvent<HTMLInputElement>, value: string): void => {
      onInputChange(
        prop,
        value as string,
        'configurable_settings',
        configIndex,
      );
    };

  const handleDynamicRowsChange = (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ): void => {
    onDynamicRowsChange(prop, value as number, field, index, configIndex);
  };

  const handleAddRowLocal = (prop: string): void => {
    handleAddRow(prop, configIndex);
  };

  const handleRemoveRowLocal = (prop: string, index: number): void => {
    handleRemoveRow(prop, index, configIndex);
  };

  const handleClickRemoveArrayRow = (index: number) => (): void => {
    onRemoveArrayRow(index);
  };

  const formatDefaultToArray = (
    itemList:
      | [
          {
            label: string;
            value: string;
          },
        ]
      | undefined,
  ): ItemList[] => {
    const resultItems = itemList || [];
    return resultItems.map((item) => {
      return {
        key: item.value,
        label: item.label,
      } as ItemList;
    });
  };

  return (
    <Grid container direction="row" className="mt-2" spacing={2}>
      <Grid item xs={11}>
        <SwitchButton
          label="Required"
          value={configurableSettings?.required as boolean}
          prop="required"
          onSwitchChange={handleChange}
        />
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={handleClickRemoveArrayRow(configIndex)}>
          <DeleteIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <TextInput
          prop="key"
          label="Key*"
          placeholder=""
          type="text"
          value={configurableSettings?.key as string}
          onInputChange={handleDynamicChange('key', configIndex)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          prop="description"
          label="Description*"
          placeholder=""
          type="text"
          classes="full-height"
          multiline
          rows={4}
          value={configurableSettings?.description as string}
          onInputChange={handleDynamicChange('description', configIndex)}
        />
      </Grid>
      <Grid item xs={12}>
        <SelectInput
          label="Type*"
          prop="type"
          value={configurableSettings?.type as string}
          onSelectChange={handleDynamicChange('type', configIndex)}
          options={[
            <MenuItem dense value="" key="empty">
              Select Type
            </MenuItem>,
            ...Object.keys(configurationType).map((key, index) => (
              <MenuItem className="m-4 p-2" key={index} dense value={key}>
                {configurationType[key]}
              </MenuItem>
            )),
          ]}
        />
      </Grid>
      {configurableSettings?.type === 'boolean' && (
        <Grid item xs={12}>
          <Typography variant="subtitle2" className="custom-label">
            Default
          </Typography>
          <RadioGroup
            aria-labelledby="sidebar-text-color-buttons-group-label"
            row
            name="configurableSettings.default"
            onChange={handleRadioChange('default')}
            value={configurableSettings?.default}
          >
            <FormControlLabel control={<Radio />} label="True" value="true" />
            <FormControlLabel control={<Radio />} label="False" value="false" />
          </RadioGroup>
        </Grid>
      )}
      {configurableSettings?.type === 'string' && (
        <Grid item xs={12}>
          <TextInput
            prop="default"
            label="Default"
            placeholder=""
            type="text"
            value={configurableSettings?.default as string}
            onInputChange={handleDynamicChange('default', configIndex)}
          />
        </Grid>
      )}

      {configurableSettings?.type === 'integer' && (
        <Grid item xs={12}>
          <TextInput
            prop="number"
            label="Default"
            placeholder=""
            type="number"
            value={configurableSettings?.default as string}
            onInputChange={handleDynamicChange('number', configIndex)}
          />
        </Grid>
      )}

      {configurableSettings?.type === 'predefined_list' && (
        <>
          <DynamicRows
            prop="values"
            arrayRowsTypes={formatDefaultToArray(
              configurableSettings?.list_items,
            )}
            propLabel="Defaults Values"
            itemKey="name"
            itemValue="value"
            buttonLabel="Add List Item"
            itemValuePlaceHolder={'Value'}
            itemValueLabel={'Value'}
            itemDescriptionPlaceHolder={'Description'}
            itemDescriptionLabel={'Description'}
            onInputChange={handleDynamicRowsChange}
            onAddRow={handleAddRowLocal}
            onRemoveArrayRow={handleRemoveRowLocal}
          />

          <Grid item xs={12}>
            <SelectInput
              label="Default"
              prop="default"
              value={configurableSettings?.default as string}
              onSelectChange={handleDynamicChange('default', configIndex)}
              options={[
                <MenuItem dense value="" key={'empty'}>
                  Select Type
                </MenuItem>,
                ...(configurableSettings.list_items?.map((item) => (
                  <MenuItem
                    className="m-4 p-2"
                    key={item.value}
                    dense
                    value={item.value}
                  >
                    {`${item.value}: ${item.label}`}
                  </MenuItem>
                )) || []),
              ]}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ConfigurationSettingForm;
