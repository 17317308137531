import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  Connection,
  DeviceConfigInput,
  DeviceConfigs,
  DeviceTypes,
  DeviceType,
  IPTableAction,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import {
  cleanNewDeviceConfig,
  setNewDeviceConfigConnectionInput,
} from '../../redux/reducers/configs.reducer';
import { setStateDeviceTypes } from '../../redux/reducers/deviceTypes.reducer';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight, GATEWAY_TYPE } from '../../app/constants';
import DeviceConfigForm from '../../containers/Forms/DeviceConfigForm';
import DeviceConfigConnectionForm from '../../containers/Forms/DeviceConfigForm/DeviceConfigConnectionForm';
import Publish from '../../containers/Publish';
import {
  EMPTY_CONFIG_CONNECTION_WAN,
  EMPTY_CONFIG_CONNECTION_WANLAN,
  EMPTY_CONFIG_CONNECTION_LAN,
  EMPTY_CONFIG_CONNECTION_CELULAR,
  EMPTY_CONFIG_CONNECTION_WIFE,
} from '../../constants/configConnections';
import {
  addNewIPTable,
  removeNewIPTable,
} from '../../containers/Forms/DeviceConfigForm/helper';
import Header from '../../containers/HeaderWithActionButton';
import useStyles from './styles';

const CreateDeviceConfigConnection: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [selectedDeviceCompany, setSelectedDeviceCompany] =
    useState<string>('');

  const deviceConfigInput = useAppSelector(
    (state: RootState) => state.configs.newDeviceConfigConnectionInput,
  );

  const [getewayDeviceTypes, setGatewayDeviceTypes] = useState<DeviceType[]>(
    [],
  );

  const { deviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );

  useEffect(() => {
    if (!deviceTypes.length) {
      DeviceTypes.list({})
        .then((result) => {
          dispatch(setStateDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    setGatewayDeviceTypes(
      deviceTypes.filter((e) => {
        return e.type === GATEWAY_TYPE;
      }),
    );
  }, [deviceTypes]);

  useEffect(() => {
    checkSubmitEnable();
  }, [deviceConfigInput]);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      deviceConfigInput?.name !== '' &&
        deviceConfigInput?.device_type_id !== '',
    );
  };

  const mapDeviceConfigConnection = (
    currentDeviceType: DeviceType | undefined,
  ): void => {
    let tempDeviceConfigConnection: Connection[] = [];
    currentDeviceType?.capabilities?.network_connections.forEach((item) => {
      tempDeviceConfigConnection = [
        ...tempDeviceConfigConnection,
        ...[
          {
            config:
              (item.type === 'ethernet-wan' && EMPTY_CONFIG_CONNECTION_WAN) ||
              (item.type === 'ethernet-wan-lan' &&
                EMPTY_CONFIG_CONNECTION_WANLAN) ||
              (item.type === 'ethernet-lan' && EMPTY_CONFIG_CONNECTION_LAN) ||
              (item.type === 'cellular' && EMPTY_CONFIG_CONNECTION_CELULAR) ||
              (item.type === 'wifi' && EMPTY_CONFIG_CONNECTION_WIFE),
            name: item.name,
            type: item.type,
          } as Connection,
        ],
      ];
    });

    const tempDeviceConfigInput: DeviceConfigInput = {
      connections: [...tempDeviceConfigConnection],
      device_type_id: currentDeviceType?._id || '',
      name: deviceConfigInput?.name || '',
    };

    dispatch(setNewDeviceConfigConnectionInput(tempDeviceConfigInput));
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ): void => {
    switch (field) {
      case 'iptables':
        const ipTables = deviceConfigInput?.iptables || [];

        ipTables[index] = {
          ...ipTables[index],
          [prop]: value,
        } as IPTableAction;
        dispatch(
          setNewDeviceConfigConnectionInput({
            ...deviceConfigInput,
            iptables: ipTables,
          } as DeviceConfigInput),
        );
        break;
      default:
        const tempDeviceConnections = deviceConfigInput?.connections || [];

        tempDeviceConnections[index] = {
          config: {
            ...tempDeviceConnections[index].config,
            [prop]: value,
          },
          name: tempDeviceConnections[index]?.name,
          type: tempDeviceConnections[index]?.type,
        };
        dispatch(
          setNewDeviceConfigConnectionInput({
            ...deviceConfigInput,
            connections: tempDeviceConnections,
          } as DeviceConfigInput),
        );
        break;
    }
  };

  const validateIpTablelist = (): number => {
    let findIndex = -1;
    if (deviceConfigInput?.iptables) {
      deviceConfigInput?.iptables.forEach((element, index) => {
        if (!element?.action || !element?.chain) {
          findIndex = index;
          return findIndex;
        }
      });
    }
    return findIndex;
  };

  const handlePublishSubmit = (): void => {
    const ipTableValidation = validateIpTablelist();
    if (ipTableValidation >= 0) {
      dispatch(
        setAlert({
          highlight: errorHighlight,
          message: `Invalid Ip Table Entry (${ipTableValidation}): 'action' required and must be 'append' or 'flush' Issue with iptables[0] entry: 'chain' required`,
          type: 'error',
        }),
      );
      return;
    }

    setSubmitting(true);
    DeviceConfigs.create(deviceConfigInput as DeviceConfigInput)
      .then((deviceConfig) => {
        dispatch(
          setAlert({
            highlight: 'Device Config Connection created correctly',
            type: 'success',
          }),
        );
        dispatch(cleanNewDeviceConfig());
        navigate(`/config/${deviceConfig._id}`);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleChange = (prop: string, value: string | number): void => {
    if (prop === 'device_type_id') {
      const selectedDeviceType = deviceTypes.find((item) => item._id === value);
      setSelectedDeviceCompany(selectedDeviceType?.company_id as string);
      mapDeviceConfigConnection(selectedDeviceType);
      return;
    }
    dispatch(
      setNewDeviceConfigConnectionInput({
        ...deviceConfigInput,
        [prop]: value,
      } as DeviceConfigInput),
    );
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleChange('company_id', companyId);
    setSelectedDeviceCompany(companyId);
  };

  const onAddNewIPTable = (): void => {
    return dispatch(
      setNewDeviceConfigConnectionInput(
        addNewIPTable(deviceConfigInput as DeviceConfigInput),
      ),
    );
  };

  const onRemoveNewIPTable = (index: number): void => {
    return dispatch(
      setNewDeviceConfigConnectionInput(
        removeNewIPTable(deviceConfigInput as DeviceConfigInput, index),
      ),
    );
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="configs"
          goBackLabel="Network Configurations"
          isCreatePage={true}
          model="device_config"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-net-config-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create Network Configuration
                </Typography>
              </div>
              <Grid container direction="row" spacing={2}>
                <DeviceConfigForm
                  onInputChange={handleChange}
                  deviceTypes={getewayDeviceTypes}
                  deviceConfigInput={deviceConfigInput || undefined}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className="shadow p-8">
              <div className={clsx('mb-6')}>
                <Typography variant="h5" className={classes.title}>
                  Connection
                </Typography>
              </div>
              {deviceConfigInput?.device_type_id ? (
                <DeviceConfigConnectionForm
                  onInputChange={handleDynamicChange}
                  onAddNewIPTable={onAddNewIPTable}
                  onRemoveIPTableValue={onRemoveNewIPTable}
                  iptables={deviceConfigInput?.iptables}
                  deviceNetConnections={deviceConfigInput?.connections || []}
                />
              ) : (
                <Typography variant="h6">
                  Select a device type in order to configure the appropriate the
                  network settings.
                </Typography>
              )}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="deviceConfig"
          submitting={submitting}
          companyId={selectedDeviceCompany}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateDeviceConfigConnection;
