import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../components/TextInput';

interface GcpIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
}

const GcpIntegrationSection: React.FC<GcpIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Gcp Service Account Key Secret Id"
          prop="gcp_service_account_key_secret_id"
          value={newIntegration.gcp_service_account_key_secret_id}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Gcp Project Id"
          prop="gcp_project_id"
          value={newIntegration.gcp_project_id}
          onInputChange={onInputChange}
        />
      </Grid>
    </>
  );
};

export default GcpIntegrationSection;
