import React from 'react';
import {
  CircularProgress,
  MenuItem,
  Typography,
  TextField,
} from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { Company } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { EMPTY_COMPANY } from '../../constants/companies';
import useStyles from './styles';

const filter = createFilterOptions<Company>();

interface AccountAutocompleteProps {
  allowAdd?: boolean;
  isDisabled?: boolean;
  includeParentId?: boolean;
  placeHolder?: string;
  selectedAccount?: string;
  options: Company[];
  handleChangeAccount: (value: string) => void;
  getAccountModel: (accountId: string) => Company | undefined;
  setSearchValue: React.Dispatch<React.SetStateAction<string>>;
  loading: boolean;
}

const AccountAutocomplete: React.FC<AccountAutocompleteProps> = ({
  allowAdd = false,
  isDisabled,
  includeParentId = false,
  placeHolder,
  selectedAccount,
  options,
  handleChangeAccount,
  getAccountModel,
  setSearchValue,
  loading,
}) => {
  const classes = useStyles();

  const renderOptionsAutoComplete = (
    props: React.HTMLAttributes<HTMLLIElement>,
    company: Company,
  ): React.ReactElement => {
    return (
      <MenuItem
        key={`autocomplete-account-filter-${company._id}`}
        value={company._id}
        {...props}
      >
        {company.branding?.logo_url ? (
          <div className={clsx('mr-2', classes.logo)}>
            <img
              src={company.branding?.logo_url}
              className={classes.logoImage}
            />
          </div>
        ) : (
          <div className={clsx('mr-2', classes.logo, classes.nameLogo)}>
            <Typography variant="h5" noWrap color="white">
              {company.name.charAt(0)}
              {company.name.charAt(1)}
            </Typography>
          </div>
        )}
        <div>
          <Typography variant="button" component="div">
            {company.name}
          </Typography>
          <Typography variant="caption" component="div">
            ID: {company._id}
            {includeParentId ? ` - Parent ID: ${company.company_id}` : ''}
          </Typography>
        </div>
      </MenuItem>
    );
  };

  return (
    <Autocomplete
      id="account-autocomplete"
      placeholder={placeHolder}
      disabled={isDisabled}
      value={selectedAccount}
      selectOnFocus
      handleHomeEndKeys
      freeSolo
      fullWidth
      options={options}
      renderOption={renderOptionsAutoComplete}
      onInputChange={(_event, newInputValue): void => {
        setSearchValue(newInputValue);
      }}
      renderInput={(params): React.ReactNode => (
        <TextField
          {...params}
          placeholder={placeHolder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      onChange={(_event, newValue): void => {
        if (newValue && typeof newValue !== 'string' && newValue._id) {
          handleChangeAccount(newValue._id);
        } else {
          handleChangeAccount(newValue as unknown as string);
        }
      }}
      filterOptions={(filterOptions, params): Company[] => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = filterOptions.some(
          (option) => inputValue === option.name,
        );
        if (inputValue !== '' && !isExisting && allowAdd) {
          filtered.push({
            ...EMPTY_COMPANY,
            _id: inputValue,
            created_at: '',
            name: `Use company_id "${inputValue}"`,
            origin: '',
            updated_at: '',
            user_id: '',
          });
        }
        return filtered;
      }}
      getOptionLabel={(option: string | Company): string => {
        if (typeof option === 'string') {
          const value = getAccountModel(option);
          if (value) {
            return value.name;
          }
          return '';
        }
        if ((option as Company).name) {
          return (option as Company).name;
        }
        return (option as Company)._id;
      }}
    />
  );
};

export default AccountAutocomplete;
