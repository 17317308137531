import React, { ReactElement, useState } from 'react';
import {
  Typography,
  Grid,
  Select,
  SelectChangeEvent,
  MenuItem,
  Button,
} from '@mui/material';
import { DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import {
  activityFilterOptions,
  activityFilterValues,
} from '../../../../app/constants';
import CommandExecutionListProps from '../../../commandContent/commandExecutionList';
import DeviceReportsActivities from './ActivityTimelines/ReportsActivities';
import DeviceErrorsActivity from './ActivityTimelines/ErrorsActivities';
import RecentSoftwareUpdates from '../../../../containers/RecentSoftwareUpdates';
import GatewayCommandActivities from '../../../../containers/GatewayCommandActivities';
import useStyles from './styles';

interface DeviceActivitiesProps {
  deviceType?: DeviceType | null;
  deviceId?: string;
  handleOpenGatewayCommand: () => void;
}

const DeviceActivities: React.FC<DeviceActivitiesProps> = ({
  deviceType,
  deviceId,
  handleOpenGatewayCommand,
}) => {
  const classes = useStyles();
  const [reportFilterValue, setReportFilterValue] = useState<string>('reports');
  const handleChangeReportFilter = (event: SelectChangeEvent): void => {
    setReportFilterValue(event.target.value);
  };

  return (
    <Grid
      item
      xs={3}
      className={clsx('p-7 ml-6 shadow br-1', classes.boxContainer)}
    >
      <Typography className={clsx('mb-4')} variant="h5">
        Device Activity
      </Typography>
      <Select
        displayEmpty
        value={reportFilterValue}
        onChange={handleChangeReportFilter}
        className={clsx('mb-4', classes.filter)}
      >
        {activityFilterOptions.map(
          (filterOption, index): ReactElement => (
            <MenuItem
              dense
              key={index}
              value={filterOption.value}
              className="m-4 p-2"
            >
              {filterOption.label}
            </MenuItem>
          ),
        )}
      </Select>

      {reportFilterValue === activityFilterValues.recent_command && (
        <Button
          className={clsx('mb-4', classes.commandBtn)}
          variant="contained"
          size="medium"
          onClick={handleOpenGatewayCommand}
        >
          Issue Gateway Command
        </Button>
      )}

      {reportFilterValue === activityFilterValues.reports && (
        <DeviceReportsActivities />
      )}

      {reportFilterValue === activityFilterValues.heartbeats && (
        <DeviceReportsActivities heartbeat={true} />
      )}

      {reportFilterValue === activityFilterValues.errors && (
        <DeviceErrorsActivity />
      )}
      {reportFilterValue === activityFilterValues.recent_software_updates && (
        <RecentSoftwareUpdates deviceType={deviceType} />
      )}
      {reportFilterValue === activityFilterValues.recent_command && (
        <GatewayCommandActivities deviceType={deviceType} />
      )}
      {reportFilterValue ===
        activityFilterValues.recent_user_defined_command && (
        <CommandExecutionListProps deviceId={deviceId as string} />
      )}
    </Grid>
  );
};

export default DeviceActivities;
