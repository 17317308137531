import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Publish from '../../containers/Publish';
import {
  Configurations,
  ConfigurationInput,
  DeviceTypes,
} from '@edgeiq/edgeiq-api-js';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import {
  setNewConfigurationInput,
  newConfiguration,
} from '../../redux/reducers/configuration.reducer';
import { setStateDeviceTypes } from '../../redux/reducers/deviceTypes.reducer';
import ConfigurationForm from '../../containers/Forms/ConfigurationForm';
import Header from '../../containers/HeaderWithActionButton';
import { EMPTY_CONFIGURABLE_SETTINGS } from '../../constants/configurableSettings';

const CreateConfiguration: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const configurationInput = useAppSelector(
    (state: RootState) => state.configurations.newConfigurationInput,
  );

  const { deviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );

  const [submitting, setSubmitting] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      configurationInput?.name !== '' &&
        configurationInput?.device_type_id !== '' &&
        configurationInput?.company_id !== '',
    );
  };

  const handleChange = (prop: string, value: string | number): void => {
    dispatch(
      setNewConfigurationInput({
        ...configurationInput,
        [prop]: value,
      } as ConfigurationInput),
    );
  };

  const onDynamicRowsChange = (
    _prop: string,
    value: string | number,
    field: string,
    index: string,
    parentIndex: number,
  ): void => {
    const currentConfigurationSettings =
      configurationInput?.configurable_settings || [];
    const tempArrayValues =
      currentConfigurationSettings[parentIndex].list_items || [];

    if (field === 'name') {
      tempArrayValues[Number(index)].value = String(value);
    } else {
      tempArrayValues[Number(index)].label = String(value);
    }
    currentConfigurationSettings[parentIndex].list_items = tempArrayValues as [
      { label: string; value: string },
    ];

    dispatch(
      setNewConfigurationInput({
        ...configurationInput,
        configurable_settings: currentConfigurationSettings,
      } as ConfigurationInput),
    );
  };

  useEffect(() => {
    if (!deviceTypes.length) {
      DeviceTypes.list({})
        .then((result) => {
          dispatch(setStateDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    checkSubmitEnable();
  }, [configurationInput]);

  const handleOnAccountChange = (companyId: string): void => {
    handleChange('company_id', companyId);
  };

  const handleAddConfigurationValue = (): void => {
    const currentConfigurationSettings =
      configurationInput?.configurable_settings || [];
    const newConfigurableSettings = [
      ...currentConfigurationSettings,
      ...[EMPTY_CONFIGURABLE_SETTINGS],
    ];

    dispatch(
      setNewConfigurationInput({
        ...configurationInput,
        configurable_settings: newConfigurableSettings,
      } as ConfigurationInput),
    );
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    Configurations.create(configurationInput as ConfigurationInput)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: 'Configuration created successfully',
            type: 'success',
          }),
        );
        dispatch(
          setNewConfigurationInput(newConfiguration as ConfigurationInput),
        );
        navigate(`/configuration/${_result._id}`);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleAddRow = (prop: string, index: number): void => {
    const currentConfigurationSettings =
      configurationInput?.configurable_settings || [];
    let tempArrayValues =
      configurationInput?.configurable_settings[index].list_items || [];
    tempArrayValues = [...tempArrayValues, { label: '', value: '' }] as [
      { label: string; value: string },
    ];

    currentConfigurationSettings[index].list_items = tempArrayValues;

    dispatch(
      setNewConfigurationInput({
        ...configurationInput,
        configurable_settings: currentConfigurationSettings,
      } as ConfigurationInput),
    );
  };

  const handleRemoveRow = (
    _prop: string,
    index: number,
    parentIndex: number,
  ): void => {
    const currentConfigurationSettings =
      configurationInput?.configurable_settings || [];
    const currentConfigurationSettingsItems =
      currentConfigurationSettings[parentIndex].list_items || [];
    currentConfigurationSettingsItems?.splice(index, 1);
    currentConfigurationSettings[parentIndex].list_items =
      (currentConfigurationSettingsItems as [
        {
          label: string;
          value: string;
        },
      ]) || [];

    dispatch(
      setNewConfigurationInput({
        ...configurationInput,
        configurable_settings: currentConfigurationSettings,
      } as ConfigurationInput),
    );
  };

  const onRemoveArrayRow = (index: number): void => {
    const currentConfigurationSettings =
      configurationInput?.configurable_settings || [];
    currentConfigurationSettings?.splice(index, 1);
    dispatch(
      setNewConfigurationInput({
        ...configurationInput,
        configurable_settings: currentConfigurationSettings,
      } as ConfigurationInput),
    );
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ): void => {
    switch (field) {
      case 'configurable_settings':
        const currentConfigurationSettings =
          configurationInput?.configurable_settings || [];

        if (prop === 'type') {
          currentConfigurationSettings[index] = {
            ...currentConfigurationSettings[index],
            default: '',
          };
        }

        if (prop === 'number') {
          currentConfigurationSettings[index] = {
            ...currentConfigurationSettings[index],
            ['default']: value.toString(),
          };
        } else {
          currentConfigurationSettings[index] = {
            ...currentConfigurationSettings[index],
            [prop]: value,
          };
        }

        dispatch(
          setNewConfigurationInput({
            ...configurationInput,
            configurable_settings: currentConfigurationSettings,
          } as ConfigurationInput),
        );
        break;
      default:
        dispatch(
          setNewConfigurationInput({
            ...configurationInput,
            [prop]: value,
          } as ConfigurationInput),
        );
        break;
    }
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="configurations"
          goBackLabel="Configurations"
          isCreatePage={true}
          model="configuration"
        />
      </Grid>
      <Grid item xs={8}>
        <ConfigurationForm
          onInputChange={handleDynamicChange}
          action={'create'}
          configuration={
            (configurationInput as ConfigurationInput) || undefined
          }
          deviceTypes={deviceTypes}
          onAddNewConfigurationValue={handleAddConfigurationValue}
          onDynamicRowsChange={onDynamicRowsChange}
          handleAddRow={handleAddRow}
          handleRemoveRow={handleRemoveRow}
          onRemoveArrayRow={onRemoveArrayRow}
        />
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="account"
          submitting={submitting}
          companyId={configurationInput?.company_id as string}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateConfiguration;
