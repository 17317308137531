import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import isEqual from 'lodash.isequal';

import {
  Rules,
  Rule,
  Device,
  DeviceType,
  Devices,
  DeviceTypes,
  Integrations,
} from '@edgeiq/edgeiq-api-js';
import cloneDeep from 'lodash.clonedeep';

import { RootState } from '../../redux/store';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  getPolicySelector,
  setActualPolicy,
  setNewPolicy,
} from '../../redux/reducers/policies.reducer';
import { setOptionsDeviceTypes } from '../../redux/reducers/deviceTypes.reducer';
import { setOptionsIntegrations } from '../../redux/reducers/integrations.reducer';
import {
  errorHighlight,
  policyDetailsTabsLabel,
  optionsPaginationsFilter,
} from '../../app/constants';
import { checkMixedTypes, dispatchError } from '../../helpers/utils';
import { policyTypeLabel } from '../../constants/policies';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import FooterBar from '../../components/FooterBar';
import EntitiesSection from '../../containers/EntitiesSection';
import PolicyDerivedValuesSectionWrapper from './policyDerivedValuesSection/policyDerivedValuesSection';
import PolicyActionsSectionWrapper from './policyActionsSection/policyActionsSection';
import PolicyElseActionsSectionWrapper from './policyElseActionsSection/policyElseActionsSection';
import PolicyConditionSectionWrapper from './policyConditionSection/PolicyConditionSection';
import PolicyDetails from './policyDetails';

const PolicyContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<string>();

  const policyFromList = useAppSelector((state: RootState) =>
    getPolicySelector(state.policies, id),
  );
  const statePolicy = useAppSelector(
    (state: RootState) => state.policies.policy,
  );
  const policyData = policyFromList
    ? policyFromList
    : statePolicy && statePolicy._id === id
    ? statePolicy
    : null;

  const newPolicy = useAppSelector(
    (state: RootState) => state.policies.newPolicy,
  );
  const integrationsList = useAppSelector(
    (state: RootState) => state.integrations.optionsIntegrations,
  );

  const [loading, setLoading] = useState(false);
  const [originalDevices, setOriginalDevices] = useState<Device[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [originalDeviceTypes, setOriginalDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const [mixedTypes, setMixedTypes] = useState(false);
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [devicesTypes, setDevicesTypes] = useState<DeviceType[]>(
    deviceTypesState.optionsDeviceTypes,
  );

  useEffect(() => {
    if (devicesTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          setDevicesTypes(result.deviceTypes);
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }

    if (!integrationsList.length) {
      getIntegrationsList();
    }
  }, []);

  useEffect(() => {
    if (devicesTypes.length) {
      if (checkMixedTypes(selectedDevices, devicesTypes)) {
        setMixedTypes(true);
      } else {
        setMixedTypes(false);
      }
    }
  }, [selectedDevices, devicesTypes]);

  const setPolicyePageData = (policy: Rule): void => {
    // The cloneDeep function is necessary because otherwise both policy and newPolicy will have the same reference to any action, event if they are different objects,
    // the items in the array will referrer to the same object and any change on 'newPolicy.then_actions' or 'newPolicy.else_actions' will change
    // 'policy.then_actions' and 'policy.else_actions' respectively.
    // Therefore the function that evaluates changes will always be false and the user won't be able to save the changes.
    // with cloneDeep this problem is fixed.
    dispatch(
      setActualPolicy({
        ...policy,
        else_actions: cloneDeep(policy.else_actions),
        then_actions: cloneDeep(policy.then_actions),
      }),
    );
    dispatch(
      setNewPolicy({
        ...policy,
        else_actions: cloneDeep(policy.else_actions),
        then_actions: cloneDeep(policy.then_actions),
      }),
    );
    getDevicesAndDeviceTypes(policy._id);
  };

  useEffect(() => {
    if (policyData && policyData._id === id) {
      setPolicyePageData(policyData);
    } else if (id && statePolicy && id === statePolicy._id) {
      setPolicyePageData(statePolicy);
    } else if (id) {
      Rules.getOneById(id)
        .then((response) => {
          setPolicyePageData(response);
        })
        .catch((err) => {
          showError(err.message);
        });
    }
  }, [id]);

  const showError = (err: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: err,
        type: 'error',
      }),
    );
  };

  const getDevicesAndDeviceTypes = (ruleId: string): void => {
    Rules.getDevices(ruleId)
      .then((res) => {
        if (res) {
          setOriginalDevices([...res]);
          setSelectedDevices([...res]);
        }
      })
      .catch((err) => {
        showError(err.message);
      });

    Rules.getDeviceTypes(ruleId)
      .then((res) => {
        if (res) {
          setOriginalDeviceTypes([...res]);
          setSelectedDeviceTypes([...res]);
        }
      })
      .catch((err) => {
        showError(err.message);
      });
  };

  const getIntegrationsList = (): void => {
    Integrations.list()
      .then((response) => {
        dispatch(setOptionsIntegrations(response.integrations));
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while fetching integrations.',
            type: 'error',
          }),
        );
      });
  };

  const handleDeleteDevice = (): void => {
    if (!policyData) {
      return;
    }
    setLoading(true);
    Rules.delete(policyData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete policy',
            message: 'Policy successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/policies');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    if (typeof newPolicy.cloud_rule === 'string') {
      newPolicy.cloud_rule = newPolicy.cloud_rule == 'true';
    }

    setLoading(true);
    Rules.update(newPolicy as Rule)
      .then((response) => {
        const policyId = response._id;

        // First remove the devices and device types that have been unselected.
        const removeDevices = originalDevices
          .filter((e) => {
            return !selectedDevices.find((d) => d._id === e._id);
          })
          .map((e) => e._id);
        if (removeDevices.length) {
          Promise.all(
            removeDevices.map(async (detachDevice) => {
              await Devices.detachRule(detachDevice, policyId);
            }),
          );
        }

        const removeDeviceTypes = originalDeviceTypes
          .filter((e) => {
            return !selectedDeviceTypes.find((dt) => dt._id === e._id);
          })
          .map((e) => e._id);
        if (removeDeviceTypes.length) {
          Promise.all(
            removeDeviceTypes.map(async (detachDeviceType) => {
              await DeviceTypes.detachRule(detachDeviceType, policyId);
            }),
          );
        }

        // Then attach the ones that have been selected
        Promise.all([
          Promise.all(
            selectedDevices.map(async (attachDevice) => {
              await Devices.attachRule(attachDevice._id, policyId);
            }),
          ),
          Promise.all(
            selectedDeviceTypes.map(async (attachDeviceTypes) => {
              await DeviceTypes.attachRule(attachDeviceTypes._id, policyId);
            }),
          ),
        ]);

        dispatch(setActualPolicy(response));
        dispatch(
          setAlert({
            highlight: 'Update policy',
            message: 'Policy successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangeDevices = (devices: Device[]): void => {
    setSelectedDevices(devices);
  };

  const handleChangeDeviceTypes = (deviceTypes: DeviceType[]): void => {
    setSelectedDeviceTypes(deviceTypes);
  };

  /**
   * PLEASE KEEP THE ORDER THE WAY IT IS AND IGNORE THE LINTING ORDER
   */
  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    /* eslint sort-keys: 0 */
    const tabs: {
      [key: string]: JSX.Element;
    } = {
      details: <PolicyDetails />,
      conditions: <PolicyConditionSectionWrapper />,
      entities: (
        <EntitiesSection
          newInput={newPolicy}
          selectedDevices={selectedDevices}
          selectedDeviceTypes={selectedDeviceTypes}
          mixedTypes={mixedTypes}
          showMixedTypes={true}
          shadowContainer={true}
          onChangeDevices={handleChangeDevices}
          onChangeDeviceTypes={handleChangeDeviceTypes}
        />
      ),
      actions: (
        <PolicyActionsSectionWrapper
          integrations={integrationsList}
          selectedDevices={selectedDevices}
          mixedTypes={mixedTypes}
        />
      ),
      elseAction: (
        <PolicyElseActionsSectionWrapper
          integrations={integrationsList}
          mixedTypes={mixedTypes}
        />
      ),
      derivedValues: <PolicyDerivedValuesSectionWrapper />,
    };
    return tabs;
  };

  const noChangesToPolicy = (): boolean => {
    return (
      isEqual(newPolicy, policyData) &&
      isEqual(originalDevices, selectedDevices) &&
      isEqual(originalDeviceTypes, selectedDeviceTypes)
    );
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header title="Policy content" goBack="policies" model="rule" />
      {newPolicy && (
        <Box className="content-page-container">
          <ContentHeader
            contentType="policy"
            title={newPolicy.description}
            subtitle={newPolicy?._id}
            hideTitleImage={true}
            policyType={policyTypeLabel[newPolicy.cloud_rule.toString()]}
          />
          <VerticalTabs
            tabs={getTabs()}
            defaultTab="details"
            tabsLabel={policyDetailsTabsLabel}
          />
        </Box>
      )}
      <FooterBar
        deleteModalContent="You are about to delete this policy"
        loading={loading}
        disableSaveButton={noChangesToPolicy()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteDevice}
      />
    </Grid>
  );
};

export default PolicyContent;
