import {
  CommandExecution,
  CommandExecutionsStatus,
} from '@edgeiq/edgeiq-api-js';

export const getCommandExecutionStatus = (
  statuses: CommandExecutionsStatus[],
): string => {
  if (statuses?.length) {
    const statusIndex = statuses.findIndex(
      (e) => e.status === 'success' || e.status === 'error',
    );
    if (statusIndex >= 0) {
      return statuses[statusIndex].status;
    }
    return statuses[0].status;
  }
  return '';
};

export const sortCommandExecutionStatuses = (
  commandExecution: CommandExecution,
): CommandExecutionsStatus[] => {
  if (
    commandExecution.execution_statuses &&
    commandExecution.execution_statuses.length > 1
  ) {
    const statuses = commandExecution.execution_statuses.sort((a, b) => {
      const keyA = new Date(a.created_at);
      const keyB = new Date(b.created_at);
      // Compare the 2 dates
      if (keyA < keyB) {
        return 1;
      }
      if (keyA > keyB) {
        return -1;
      }
      return 0;
    });
    return statuses;
  }
  return commandExecution.execution_statuses;
};
