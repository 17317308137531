import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: '0',
      width: '100%',
    },
    deviceName: {
      padding: '0 !important',
    },
    executionInfo: {
      color: theme.palette.common.white,
      fontFamily: 'Inter, arial, sans-serif',
    },
    executionInfoContainer: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: '4px',
      marginBottom: '10px',
      marginTop: '10px',
      overflow: 'auto',
      padding: '10px',
    },
    listContainer: {
      height: 'calc(100vh - 250px)',
      overflow: 'auto',
    },
    subtitle: {
      color: theme.palette.grey[200],
    },
    title: {
      color: theme.palette.grey[100],
      fontWeight: 700,
    },
  }),
);

export default useStyles;
