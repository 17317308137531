import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../components/TextInput';

interface InmarsatIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
}

const InmarsatIntegrationSection: React.FC<InmarsatIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Inmarsat Client Id"
          prop="inmarsat_client_id"
          value={newIntegration.inmarsat_client_id}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Inmarsat Secret"
          prop="inmarsat_secret"
          value={newIntegration.inmarsat_secret}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Inmarsat Username"
          prop="inmarsat_username"
          value={newIntegration.inmarsat_username}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Inmarsat Password"
          prop="inmarsat_password"
          value={newIntegration.inmarsat_password}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Inmarsat Customer Id"
          prop="inmarsat_customer_id"
          value={newIntegration.inmarsat_customer_id}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Inmarsat Scap Id"
          prop="inmarsat_scap_id"
          value={newIntegration.inmarsat_scap_id}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Inmarsat Industry Code"
          prop="inmarsat_industry_code"
          value={newIntegration.inmarsat_industry_code}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Inmarsat Mailbox Id"
          prop="inmarsat_mailbox_id"
          value={newIntegration.inmarsat_mailbox_id}
          onInputChange={onInputChange}
        />
      </Grid>
    </>
  );
};

export default InmarsatIntegrationSection;
