import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

import { dataManagementTabsLabels } from '../../app/constants';
import Header from '../../components/Header';
import VerticalTabs from '../../components/VerticalTabs';
import Ingestors from './ingestors';
import TranslatorsPage from './translators';
import PollableAttributesPage from './pollableAttributes/PollableAttributes';
import { getPageHash } from '../../helpers/utils';

const defaultTab = 'ingestors';

const DataManagementPage: React.FC = () => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(defaultTab);

  useEffect(() => {
    setActiveTab(
      getPageHash(location.hash, defaultTab, dataManagementTabsLabels),
    );
  }, [location.hash]);

  return (
    <Grid container direction="column" spacing={0}>
      <Header title="Data management" />
      <VerticalTabs
        changeUrl={true}
        defaultTab={activeTab}
        tabs={{
          ingestors: <Ingestors />,
          pollable_attributes: <PollableAttributesPage />,
          translators: <TranslatorsPage />,
        }}
        tabsLabel={dataManagementTabsLabels}
      />
    </Grid>
  );
};

export default DataManagementPage;
