import { FilterObject } from '../models/common';
import { FiltersState } from '../redux/reducers/filters.reducer';

const genericFilterObject: FilterObject = {
  sortBy: '-updated_at',
  view: 'grid',
};

const genericFilterSecret: FilterObject = {
  sortBy: '-created_at',
  view: 'grid',
};

const genericReadingFilterObject: FilterObject = {
  sortBy: '-created_at',
  view: 'list',
};

export const defaultFilters: FiltersState = {
  alerts: genericReadingFilterObject,
  bulkJobs: genericReadingFilterObject,
  commands: genericFilterObject,
  commandsExecutions: genericReadingFilterObject,
  companies: genericFilterObject,
  configs: genericFilterObject,
  configurations: genericFilterObject,
  deviceErrors: genericReadingFilterObject,
  devices: genericFilterObject,
  devicesTypes: genericFilterObject,
  deviceTransfers: genericFilterObject,
  discoveredDevices: genericFilterObject,
  escrowDevices: genericFilterObject,
  files: genericFilterObject,
  gatewayCommands: genericFilterObject,
  heartbeats: genericReadingFilterObject,
  hierarchyFilters: {
    levels: [],
    searchAccount: undefined,
  },
  ingestors: genericFilterObject,
  integrationEvents: genericReadingFilterObject,
  integrations: genericFilterObject,
  messageReports: genericReadingFilterObject,
  policies: genericFilterObject,
  pollableAttributes: genericFilterObject,
  scheduledJobs: genericFilterObject,
  secrets: genericFilterSecret,
  settingsApplicationRecord: genericReadingFilterObject,
  softwareUpdates: genericFilterObject,
  translators: genericFilterObject,
  users: genericFilterObject,
  userTypes: genericFilterObject,
};
