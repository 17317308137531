import { ClassNameMap, Typography } from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import { Device, DeviceType } from '@edgeiq/edgeiq-api-js';
import { Link } from 'react-router-dom';

import { heartbeatColorThemeMap } from '../../../app/constants';
import { TableColumn } from '../../../components/SharedTable';
import ColoredBox from '../../../components/ColoredBox';
import TypographyWithCopy from '../../../components/TypographyWithCopy';

const getDeviceTypeName = (
  deviceTypeId: string,
  devicesTypes: DeviceType[],
): string => {
  const index = devicesTypes.findIndex(
    (deviceType) => deviceType._id === deviceTypeId,
  );
  if (index === -1) {
    return '';
  }
  return devicesTypes[index].name;
};

export const DevicesColumns = (
  devicesTypes: DeviceType[],
  classes: ClassNameMap,
): TableColumn[] => {
  return [
    {
      cellValue: (item): string => {
        const device = item as Device;
        return device.name;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      link: (item): string => {
        const device = item as Device;
        return `/device/${device._id}`;
      },
      parser: (item): string | React.ReactElement => {
        const device = item as Device;
        return (
          <Link to={`/device/${device._id}`} className={classes.linkButton}>
            <Typography
              variant="button"
              fontWeight={700}
              component="div"
              noWrap
              className={classes.tableTitle}
            >
              {device.name}
            </Typography>
          </Link>
        );
      },
      type: 'custom',
    },
    {
      id: 'unique_id',
      label: 'Unique ID',
      parser: (item): string | React.ReactElement => {
        const device = item as Device;
        if (!device.unique_id) {
          return '--';
        }
        return (
          <TypographyWithCopy
            component="div"
            dataCy={`copy-discovered-device-id-${device.unique_id}-table`}
            text={device.unique_id}
            textToCopy={device.unique_id}
            tooltipText={device.unique_id}
            typographyVariant="button"
            textMaxWidth={300}
          />
        );
      },
      type: 'custom',
    },
    {
      id: 'device_type_id',
      label: 'Device profile',
      parser: (item): string | React.ReactElement => {
        const device = item as Device;
        if (!device.device_type_id) {
          return '--';
        }
        return (
          <Typography variant="button" component="div" className={classes.tag}>
            <DevicesIcon fontSize="small" className="mr-2" />
            {getDeviceTypeName(device.device_type_id, devicesTypes)}
          </Typography>
        );
      },
      type: 'custom',
    },
    {
      id: 'active',
      label: 'Active',
      parser: (item): React.ReactElement => {
        const device = item as Device;
        return (
          <Typography variant="button" component="div" className={classes.tag}>
            <FiberSmartRecordIcon fontSize="small" className="mr-2" />
            {device.active ? 'Active' : 'Inactive'}
          </Typography>
        );
      },
      type: 'custom',
    },
    {
      id: 'heartbeat_status',
      label: 'Heartbeat',
      parser: (item): React.ReactElement => {
        const device = item as Device;
        return (
          <div>
            <ColoredBox
              type="heartbeat_status"
              value={device.heartbeat_status ?? ''}
              colorTheme={
                heartbeatColorThemeMap[
                  device.heartbeat_status ?? 'never_reported'
                ]
              }
            />
          </div>
        );
      },
      type: 'custom',
    },
  ];
};
