import React from 'react';
import { Button, Divider, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import clsx from 'clsx';

import ColoredBox from '../ColoredBox';
import { StatusTheme } from '../../models/common';

interface SummaryCardProps {
  badgeText: string;
  badgeColor?: string;
  actionBtnText: string;
  handleActionBtnClick: () => void;
  footer: React.ReactElement;
}

const SummaryCard: React.FC<SummaryCardProps> = ({
  handleActionBtnClick,
  actionBtnText,
  badgeColor = 'info',
  badgeText,
  children,
  footer,
}) => {
  const handleClick = (): void => {
    handleActionBtnClick();
  };

  return (
    <>
      <Grid container direction="row">
        <Box>
          <div>
            {badgeText && (
              <ColoredBox
                colorTheme={badgeColor as StatusTheme}
                type="command_execution_state"
                value={badgeText}
                className="mb-2"
              />
            )}

            {children}

            {footer}

            <Box>
              <Button
                color="primary"
                variant="text"
                type="button"
                onClick={handleClick}
                className={clsx('p-0')}
              >
                <Typography variant="overline">{actionBtnText}</Typography>
              </Button>
            </Box>
          </div>
        </Box>
      </Grid>
      <Divider className="my-3" />
    </>
  );
};

export default SummaryCard;
