import React, { useState } from 'react';
import { CircularProgress, Grid, MenuItem, Typography } from '@mui/material';
import {
  Company,
  DeviceTransferRequest,
  DeviceTransferRequests,
  DeviceType,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import { getCompanyDescription } from '../../../helpers/utils';
import SelectInput from '../../../components/SelectInput';
import RightDrawer from '../RightDrawer';
import useStyles from './styles';

interface AcceptDeviceTransferDrawerProps {
  deviceTransfers: DeviceTransferRequest[];
  deviceTypes: DeviceType[];
  open: boolean;
  selectedDeviceTransferIds: string[];
  userCompanies: Company[];
  handleCloseDrawer: () => void;
}

const AcceptDeviceTransferDrawer: React.FC<AcceptDeviceTransferDrawerProps> = ({
  deviceTransfers,
  deviceTypes,
  open,
  selectedDeviceTransferIds = [],
  userCompanies,
  handleCloseDrawer,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [deviceType, setDeviceType] = useState('');
  const [loading, setLoading] = useState(false);

  const onSelectChange = (_prop: string, value: string): void => {
    setDeviceType(value);
  };

  const handleActionCallback = (): void => {
    setLoading(true);
    DeviceTransferRequests.bulkAccept(selectedDeviceTransferIds, deviceType)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: 'Device Transfer',
            message: 'Device successfully transfer.',
            type: 'success',
          }),
        );
        handleCloseDrawer();
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Accept"
      title="Accepting a Device Transfer"
      disableAction={deviceTransfers.length === 0 || !deviceType}
      actionCallback={handleActionCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <>
          {loading ? (
            <Grid container className="loading-container">
              <CircularProgress size={75} thickness={5} />
            </Grid>
          ) : (
            <>
              <SelectInput
                value={deviceType ?? ''}
                prop="_id"
                onSelectChange={onSelectChange}
                classes="mb-4"
                options={[
                  <MenuItem key="no-value-placeholder" dense value="">
                    Device Type
                  </MenuItem>,
                  ...deviceTypes.map((deviceTypeItem, index) => (
                    <MenuItem
                      dense
                      className="m-4 p-2"
                      key={`${index}-${deviceTypeItem._id}`}
                      value={deviceTypeItem._id}
                    >
                      {deviceTypeItem.name}
                    </MenuItem>
                  )),
                ]}
              />
              {deviceTransfers.map((item) => (
                <div
                  key={`in-drawer-transfer-${item._id}`}
                  className={clsx('mb-2 br-1 p-4', classes.container)}
                >
                  <Typography variant="subtitle2">
                    <strong>From account:</strong>{' '}
                    {getCompanyDescription(
                      item.from_company_id || '',
                      userCompanies,
                    )}
                  </Typography>
                  <Typography variant="subtitle2">
                    <strong>Device Count:</strong>{' '}
                    {item.escrow_device_ids.length.toString() || '0'}
                  </Typography>
                </div>
              ))}
            </>
          )}
        </>
      }
    />
  );
};

export default AcceptDeviceTransferDrawer;
