import React from 'react';
import {
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';

import useStyles from './styles';

interface RightDrawerProps {
  open: boolean;
  title?: string;
  titleComponent?: JSX.Element;
  content: JSX.Element;
  actionLabel: string;
  disableAction: boolean;
  actionLoading?: boolean;
  actionCallback: () => void;
  onCloseDrawer: () => void;
  size?: string;
  cancelLabel?: string;
}

const RightDrawer: React.FC<RightDrawerProps> = ({
  open,
  title,
  titleComponent,
  content,
  actionLabel,
  disableAction,
  actionLoading = false,
  actionCallback,
  onCloseDrawer,
  size,
  cancelLabel,
}) => {
  const classes = useStyles({});

  const toggleDrawer = (
    event: React.KeyboardEvent | React.MouseEvent,
  ): void => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    onCloseDrawer();
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      PaperProps={{
        sx: { width: `${size}px` },
      }}
    >
      <div className={classes.container}>
        <div className={clsx('px-7 pt-7 mb-4', classes.header)}>
          {title && (
            <Typography
              variant="h4"
              component="span"
              data-cy="right-drawer-title"
            >
              {title}
            </Typography>
          )}
          {titleComponent && titleComponent}
          <IconButton
            aria-label="close-drawer"
            onClick={toggleDrawer}
            className="p-0"
          >
            <CloseIcon className={classes.closeDrawerIcon} />
          </IconButton>
        </div>
        <div className={clsx('px-7 scrollbar', classes.content)}>{content}</div>
        <div className={clsx('p-4', classes.footer)}>
          <Button
            variant="text"
            type="button"
            color="primary"
            data-cy="right-drawer-cancel-button"
            onClick={toggleDrawer}
          >
            <Typography variant="button">{cancelLabel ?? 'Cancel'}</Typography>
          </Button>
          <Button
            color="primary"
            size="large"
            variant="contained"
            type="button"
            disabled={disableAction || actionLoading}
            data-cy="right-drawer-action-button"
            onClick={actionCallback}
          >
            {!actionLoading ? (
              <Typography variant="button">{actionLabel}</Typography>
            ) : (
              <CircularProgress size={25} />
            )}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default RightDrawer;
