import React, { ReactElement, useEffect, useState } from 'react';
import { Box, IconButton, Link } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Configuration,
  CommandType,
  Device,
  Devices,
  DevicesFilters,
  GatewayCommandRequest,
  SettingFilters,
  Setting,
  Settings,
} from '@edgeiq/edgeiq-api-js';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DeleteIcon from '@mui/icons-material/Delete';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import TabsPage from '../../../components/TabsPage';
import SelectDevicesDrawer from '../../../containers/RightDrawer/SelectDevices/SelectDevicesDrawer';
import SettingManagementDrawer from '../../../containers/RightDrawer/SettingManagementDrawer/SettingManagementDrawer';
import ActionDialog from '../../../components/ActionDialog';
import { errorHighlight } from '../../../app/constants';
import useStyles from './styles';

const ConfigurationSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const selectedConfiguration = useAppSelector(
    (state: RootState) => state.configurations.newConfiguration,
  );

  const deviceCompany = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );

  const [settingList, setSettingList] = useState<Setting[]>([]);
  const [deviceDrawerOpen, setDeviceDrawerOpen] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [executeCommandDialog, setExecuteCommandDialog] = useState(false);
  const [deleteSettingDialog, setDeleteSettingDialog] = useState(false);
  const [settingToExecute, setSettingToExecute] = useState<Setting>();
  const [actualSetting, setActualSetting] = useState<Setting | null>();
  const [settingToDelete, setSettingToDelete] = useState<Setting | null>();
  const [openSetting, setOpenSetting] = useState(false);
  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <Link
          onClick={handleOpenEditSetting(params.row)}
          underline="none"
          className={classes.link}
        >
          <strong>{params.row.name}</strong>
        </Link>
      ),
    },
    {
      field: '_id',
      flex: 0.3,
      headerName: 'ID',
    },
    {
      field: 'setting',
      flex: 0.3,
      headerName: 'Delete Setting',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <IconButton
          aria-label="delete-setting"
          onClick={deleteSettingAction(params.row)}
          size="small"
        >
          <DeleteIcon />
        </IconButton>
      ),
      sortable: false,
    },
    {
      field: 'options',
      flex: 0.1,
      headerName: 'Apply Setting',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <IconButton
          aria-label="apply-setting"
          onClick={handleOpenExecuteSettingDrawer(params.row)}
          size="small"
        >
          <PlayArrowIcon />
        </IconButton>
      ),
      sortable: false,
    },
  ];

  const handleCloseDeviceDrawer = (): void => {
    setDeviceDrawerOpen(false);
  };

  useEffect(() => {
    loadConfigurationSettings();
  }, []);

  const loadConfigurationSettings = (): void => {
    if (selectedConfiguration && selectedConfiguration._id) {
      setLoading(true);

      const filters: SettingFilters = {
        configuration_id: {
          operator: 'eq',
          value: selectedConfiguration._id as string,
        },
      };

      Settings.list(filters)
        .then((res) => {
          setSettingList(res.settings);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleExecuteCommand = (): void => {
    setLoading(true);
    if (settingToExecute && selectedDevices.length) {
      Promise.all(
        selectedDevices.map((device) => {
          return Devices.processGatewayCommand(device._id, {
            command_type: 'setting',
            settings_id: settingToExecute._id as string,
            type: 'setting' as CommandType,
          } as GatewayCommandRequest);
        }),
      )
        .then(() => {
          dispatch(
            setAlert({
              highlight: 'Commands execution',
              message: 'Command executed with success.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          setSelectedDevices([]);
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
          setExecuteCommandDialog(false);
          setSelectedDevices([]);
        });
    }
  };

  const handleDeleteSetting = (): void => {
    setLoading(true);
    if (settingToDelete) {
      Settings.delete(settingToDelete._id)
        .then(() => {
          dispatch(
            setAlert({
              highlight: 'Delete setting',
              message: 'Setting successfully deleted.',
              type: 'success',
            }),
          );
          handleCloseDeleteDialog();
          loadConfigurationSettings();
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleOpenExecuteSettingDrawer = (setting: Setting) => (): void => {
    setSettingToExecute(setting);
    setDeviceDrawerOpen(true);
  };

  const handleDeviceCallback = (devices: Device[]): void => {
    handleCloseDeviceDrawer();
    setSelectedDevices(devices);
    setExecuteCommandDialog(true);
  };

  const handleCloseExecuteCommandDialog = (): void => {
    setExecuteCommandDialog(false);
    setSelectedDevices([]);
  };

  const onCloseSettingDrawer = (): void => {
    setActualSetting(null);
    setOpenSetting(false);
    loadConfigurationSettings();
  };

  const onOpenSettingDrawer = (): void => {
    setOpenSetting(true);
  };

  const handleOpenEditSetting = (setting: Setting) => (): void => {
    setActualSetting(setting);
    onOpenSettingDrawer();
  };

  const deleteSettingAction = (setting: Setting) => (): void => {
    setDeleteSettingDialog(true);
    setSettingToDelete(setting as Setting);
  };

  const handleCloseDeleteDialog = (): void => {
    setSettingToDelete(null);
    setDeleteSettingDialog(false);
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={settingList}
        addButtonIcon={false}
        addButtonLabel="Create New Setting"
        onAddClick={onOpenSettingDrawer}
        tableTitle="Settings added"
        loading={loading}
      />

      <SelectDevicesDrawer
        open={deviceDrawerOpen}
        selectedDevices={[]}
        onCloseDrawer={handleCloseDeviceDrawer}
        onChoosingDevices={handleDeviceCallback}
        companyId={deviceCompany?._id}
        filters={
          {
            device_type_id: {
              operator: 'eq',
              value: selectedConfiguration?.device_type_id || '',
            },
          } as DevicesFilters
        }
      />

      <SettingManagementDrawer
        selectedConfiguration={selectedConfiguration as Configuration}
        setting={actualSetting as Setting}
        open={openSetting}
        onCloseDrawer={onCloseSettingDrawer}
      />

      <ActionDialog
        open={executeCommandDialog}
        loading={loading}
        onDeleteCallback={handleExecuteCommand}
        onCloseCallback={handleCloseExecuteCommandDialog}
        content={`You are about to apply the setting: ${settingToExecute?.name}`}
        actionButtonLabel="Execute"
      />

      <ActionDialog
        open={deleteSettingDialog}
        loading={loading}
        onDeleteCallback={handleDeleteSetting}
        onCloseCallback={handleCloseDeleteDialog}
        content={`You are about to delete the setting: ${settingToDelete?.name}`}
        actionButtonLabel="Delete"
      />
    </Box>
  );
};

export default ConfigurationSettings;
