import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    accountContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    accountLogo: {
      maxHeight: '50px',
    },
    avatar: {
      fontSize: '25px',
      height: '50px',
      width: '50px',
    },
    centerItem: {
      alignItems: 'center',
      display: 'flex',
    },
    companyLogo: {
      maxHeight: '50px',
      maxWidth: '150px',
    },
    input: {
      height: '44px',
    },
    loadMoreContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    logoContainer: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
    },
    subtitle: {
      color: theme.palette.grey[100],
    },
    tagContainer: {
      display: 'flex',
    },
    tagIcon: {
      color: theme.palette.grey[200],
    },
    tagName: {
      color: theme.palette.grey[200],
    },
    tagsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    tagValue: {
      color: theme.palette.grey[100],
    },
    title: {
      borderBottom: `1px solid ${theme.palette.grey.A200}`,
      color: theme.palette.grey[100],
    },
  }),
);

export default useStyles;
