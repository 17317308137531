import React, { ChangeEvent } from 'react';
import {
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import TextInput from '../../../components/TextInput';
import {
  ConfigurableSettingsInput,
  SettingsValue,
} from '@edgeiq/edgeiq-api-js';
import SelectInput from '../../../components/SelectInput';

interface ConfigurationTypeSettingForm {
  configurableSettings: ConfigurableSettingsInput;
  configIndex: number;
  settingValue: SettingsValue;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
}

const ConfigurationTypeSettingForm: React.FC<ConfigurationTypeSettingForm> = ({
  configurableSettings,
  configIndex,
  settingValue,
  onInputChange,
}) => {
  const handleDynamicChange =
    (prop: string, index: number) =>
    (_field: string, value: string | number): void => {
      onInputChange(prop, value, 'settings_values', index);
    };

  const handleRadioChange =
    (prop: string) =>
    (_event: ChangeEvent<HTMLInputElement>, value: string): void => {
      onInputChange(prop, value as string, 'settings_values', configIndex);
    };

  return (
    <Grid container direction="row" className="mt-2" spacing={2}>
      {configurableSettings?.type === 'boolean' && (
        <Grid item xs={12}>
          <RadioGroup
            aria-labelledby="sidebar-text-color-buttons-group-label"
            row
            name="value"
            onChange={handleRadioChange('value')}
            value={settingValue?.value}
          >
            <FormControlLabel control={<Radio />} label="True" value="true" />
            <FormControlLabel control={<Radio />} label="False" value="false" />
          </RadioGroup>
        </Grid>
      )}
      {configurableSettings?.type === 'string' && (
        <Grid item xs={12}>
          <TextInput
            prop="default"
            label={
              configurableSettings.required
                ? `${configurableSettings.key}*:`
                : configurableSettings.key
            }
            placeholder=""
            type="text"
            value={settingValue?.value as string}
            onInputChange={handleDynamicChange('value', configIndex)}
          />
        </Grid>
      )}

      {configurableSettings?.type === 'integer' && (
        <Grid item xs={12}>
          <TextInput
            prop="default"
            label={
              configurableSettings.required
                ? `${configurableSettings.key}*:`
                : configurableSettings.key
            }
            placeholder=""
            type="number"
            value={settingValue?.value as string}
            onInputChange={handleDynamicChange('value', configIndex)}
          />
        </Grid>
      )}

      {configurableSettings?.type === 'predefined_list' && (
        <Grid item xs={12}>
          <SelectInput
            label={
              configurableSettings.required
                ? `${configurableSettings.key}*:`
                : configurableSettings.key
            }
            prop="default"
            value={settingValue?.value as string}
            onSelectChange={handleDynamicChange('value', configIndex)}
            options={[
              <MenuItem dense value="" key={'empty'}>
                Select Type
              </MenuItem>,
              ...(configurableSettings.list_items?.map((item) => (
                <MenuItem
                  className="m-4 p-2"
                  key={item.value}
                  dense
                  value={item.value}
                >
                  {item.value}
                </MenuItem>
              )) || []),
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default ConfigurationTypeSettingForm;
