import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from '@mui/material';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { Company, User, Users } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { stringAvatar } from '../../../helpers/avatar';
import getCompanyParent from '../../../helpers/getCompanyParent';
import { errorHighlight } from '../../../app/constants';

interface UserAccountsProps {
  user: User;
  onAccountRemoved: (accountId: string) => void;
}

const UserAccountsList: React.FC<UserAccountsProps> = ({
  user,
  onAccountRemoved,
}) => {
  const dispatch = useAppDispatch();
  const userState = useAppSelector((state: RootState) => state.user);
  const [accounts, setAccounts] = useState<Company[]>([]);

  const getUserAccounts = (): void => {
    if (user.company_ids && user.company_ids.length !== 0) {
      const loggedUserAccounts = userState.userCompanies;
      const accountsToShow = loggedUserAccounts.filter(
        (account) =>
          user.company_ids?.includes(account._id) &&
          account._id !== user.company_id,
      );
      setAccounts(accountsToShow);
    }
  };

  useEffect(() => {
    getUserAccounts();
  }, [user, userState.userCompanies]);

  const removeAccount = (account: Company) => (): void => {
    Users.removeCompanyId(account._id, user._id)
      .then(() => {
        onAccountRemoved(account._id);
        dispatch(
          setAlert({
            highlight: 'Additional account detached',
            message: 'Additional account detached from user.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while deattaching account.',
            type: 'error',
          }),
        );
      });
  };

  return (
    <List
      sx={{
        bgcolor: 'background.paper',
        maxHeight: 360,
        overflow: 'auto',
        width: '100%',
      }}
      className="scrollbar"
    >
      {accounts.map((account: Company, index: number) => {
        return (
          <Box key={account._id}>
            <ListItem
              alignItems="flex-start"
              data-cy="user-additional-account-container"
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="link off"
                  data-cy="detach-additional-account-button"
                  onClick={removeAccount(account)}
                >
                  <LinkOffIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                {account.branding?.logo_url ? (
                  <Avatar alt={account.name} src={account.branding?.logo_url} />
                ) : (
                  <Avatar {...stringAvatar(account.name)} />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={account.name}
                secondary={
                  <Tooltip placement="top" title="Parent account">
                    <Typography
                      sx={{ display: 'inline' }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                      data-cy="user-additional-account-name"
                    >
                      {
                        getCompanyParent(
                          account.company_id,
                          userState.userCompanies,
                        )?.name
                      }
                    </Typography>
                  </Tooltip>
                }
              />
            </ListItem>
            {index !== accounts.length - 1 && (
              <Divider variant="inset" component="li" />
            )}
          </Box>
        );
      })}
    </List>
  );
};

export default UserAccountsList;
