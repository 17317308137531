import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import clsx from 'clsx';

import {
  AwsGreengrassDocsURL,
  AwsGreengrassDocsURLV2,
  AwsGreengrassV1EndOfMaintenanceURL,
  AwsGreengrassV2LatestInstallURL,
} from '../../../app/constants';
import integrationInfoImage from '../../../assets/aws_greengrass_info.png';
import RightDrawer from '../RightDrawer';
import useStyles from '../styles';
import TypographyWithCopy from '../../../components/TypographyWithCopy';

interface AwsInformationDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
}

const AwsInformationDrawer: React.FC<AwsInformationDrawerProps> = ({
  open,
  handleCloseDrawer,
}) => {
  const classes = useStyles({ integrationInfoImage });

  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      title="Locate your version URL"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Box>
          <Typography variant="h5" className="mt-4">
            Greengrass V2
          </Typography>
          <div className="my-2">
            To install the latest Greengrass 2, you can copy and paste the
            following download URL to install the latest version:
          </div>
          <TypographyWithCopy
            dataCy={'greengrass-v2-install-url'}
            text={AwsGreengrassV2LatestInstallURL}
            tooltipText={AwsGreengrassV2LatestInstallURL}
            typographyVariant={'h6'}
          ></TypographyWithCopy>
          <div className="my-2">
            For specific Greengrass 2.x versions please follow the link below on
            how to assemble the download URL:
          </div>
          <Button
            color="primary"
            className={classes.link}
            target="_blank"
            href={AwsGreengrassDocsURLV2}
          >
            AWS Greengrass Docs - Download Instructions (V2)
          </Button>
          <Typography variant="h5" className="mt-8">
            Greengrass V1 (deprecated)
          </Typography>
          <Typography className="mt-4">
            <b>Note:</b> Greengrass V1 has been deprecated by AWS and will no
            longer receive updates. We recommend using Greengrass V2. Please see
            the link below for more information.
          </Typography>
          <Button
            color="primary"
            className={classes.link}
            target="_blank"
            href={AwsGreengrassV1EndOfMaintenanceURL}
          >
            AWS Greengrass V1 End of Maintenance Schedule
          </Button>
          <Typography className="mt-4">
            In case you still want to proceed with Greengrass V1, you can find
            the download URL by following the steps below.
          </Typography>
          <Button href={AwsGreengrassDocsURL} target="_blank">
            Follow this link to AWS's Greengrass V1 Download Page
          </Button>
          <Box className={clsx('mt-4', classes.imageLayout)} />

          <Typography className="mt-4">
            You'll see something similar to the image above. Versions are
            specified in the tabs. Download URLs for the specific architectures
            are in the table. Right-click on the link and select "Copy Link
            Address" and paste the URL into the text field.
          </Typography>
        </Box>
      }
    />
  );
};

export default AwsInformationDrawer;
