import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, MenuItem, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  Ingestor,
  IngestorInput,
  Ingestors,
  Translator,
  Translators,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { RootState } from '../../../redux/store';
import {
  cleanNewIngestor,
  setNewIngestor,
} from '../../../redux/reducers/ingestors.reducer';
import Publish from '../../../containers/Publish';
import SideTabs from '../../../components/SideTabs';
import SelectInput from '../../../components/SelectInput';
import TextInput from '../../../components/TextInput';
import Header from '../../../containers/HeaderWithActionButton';
import {
  addRowsIngestorArrays,
  createNewIngestorObject,
  removeRowsIngestorArray,
} from '../../../containers/Forms/IngestorForm/helper';
import { errorHighlight, ingestorTypesMap } from '../../../app/constants';
import { EMPTY_INGESTOR } from '../../../constants/ingestors';
import NewIngestorListenerConfig from './NewIngestorListenerConfig';
import NewIngestorHandlerConfig from './NewIngestorHandlerConfig';
import NewIngestorTranslatorConfig from './NewIngestorTranslatorConfig';
import useStyles from './styles';

const CreateIngestorPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const newIngestor = useAppSelector(
    (state: RootState) => state.ingestors.newIngestor,
  );
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [dynamicSubmit, setDynamicSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [translatorOptions, setTranslatorOptions] = useState<Translator[]>([]);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      newIngestor?.name !== '' &&
        newIngestor?.company_id !== '' &&
        newIngestor?.translator_id !== '',
    );
  };

  useEffect(() => {
    checkSubmitEnable();
  }, [newIngestor]);

  useEffect(() => {
    if (!newIngestor || (newIngestor as Ingestor)?._id) {
      dispatch(setNewIngestor(EMPTY_INGESTOR));
    }
  }, []);

  useEffect(() => {
    const getTranslators = (): void => {
      Translators.list()
        .then((response) => {
          setTranslatorOptions(response.translators);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    };

    getTranslators();
  }, []);

  const handleValueChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    if (newIngestor) {
      return dispatch(
        setNewIngestor(createNewIngestorObject(prop, value, newIngestor)),
      );
    }
  };

  const handleEnableSubmit = (args: boolean): void => {
    setDynamicSubmit(args);
  };

  const handleAddNewFilter = (): void => {
    if (newIngestor) {
      dispatch(setNewIngestor(addRowsIngestorArrays('filters', newIngestor)));
    }
  };

  const handleRemoveFilter = (index: number): void => {
    if (newIngestor) {
      dispatch(
        setNewIngestor(removeRowsIngestorArray('filters', index, newIngestor)),
      );
    }
  };

  const handleAddDeviceTypeMapping = (): void => {
    if (newIngestor) {
      dispatch(
        setNewIngestor(
          addRowsIngestorArrays('device_type_mapping', newIngestor),
        ),
      );
    }
  };

  const handleRemoveDeviceTypeMapping = (index: number): void => {
    if (newIngestor) {
      dispatch(
        setNewIngestor(
          removeRowsIngestorArray('device_type_mapping', index, newIngestor),
        ),
      );
    }
  };

  const handleAddRoute = (): void => {
    if (newIngestor) {
      dispatch(setNewIngestor(addRowsIngestorArrays('routes', newIngestor)));
    }
  };

  const handleRemoveRoute = (index: number): void => {
    if (newIngestor) {
      dispatch(
        setNewIngestor(removeRowsIngestorArray('routes', index, newIngestor)),
      );
    }
  };

  const handleAccountChange = (companyId: string): void => {
    dispatch(
      setNewIngestor({
        ...newIngestor,
        company_id: companyId,
      } as Ingestor),
    );
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);

    Ingestors.create(newIngestor as IngestorInput)
      .then((response) => {
        dispatch(
          setAlert({
            highlight: 'Ingestor created successfully',
            type: 'success',
          }),
        );
        navigate(`/data-management/ingestor/${response._id}`);
        dispatch(cleanNewIngestor());
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="data-management#ingestors"
          goBackLabel="Ingestors"
          isCreatePage={true}
          model="ingestor"
        />
      </Grid>
      {newIngestor && (
        <Grid item xs={8}>
          <Grid container direction="column" spacing={3}>
            <Grid item xs={12}>
              <Paper className="shadow p-8">
                <Box className={clsx('mb-6', classes.titleContainer)}>
                  <Typography
                    data-cy="create-ingestor-title"
                    variant="h5"
                    className={classes.title}
                  >
                    Create a new ingestor
                  </Typography>
                </Box>

                <Grid container direction="row" spacing={2}>
                  <Grid item xs={6} className="mt-2">
                    <TextInput
                      label="Ingestor Name"
                      prop="name"
                      value={newIngestor.name}
                      onInputChange={handleValueChange}
                    />
                  </Grid>
                  <Grid item xs={6} className="mt-2">
                    <SelectInput
                      label="Ingestor Type"
                      prop="type"
                      value={newIngestor.type}
                      onSelectChange={handleValueChange}
                      options={Object.keys(ingestorTypesMap).map(
                        (key, index) => (
                          <MenuItem
                            className="m-4 p-2"
                            key={index}
                            dense
                            value={key}
                          >
                            {ingestorTypesMap[key]}
                          </MenuItem>
                        ),
                      )}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper className="shadow p-8 mt-4">
              <Typography variant="h5" className={classes.title}>
                Configuration
              </Typography>
              <SideTabs
                defaultTab="listener_type"
                tabs={{
                  handler_type: (
                    <NewIngestorHandlerConfig
                      newIngestor={newIngestor}
                      onInputChange={handleValueChange}
                      setEnableSubmit={handleEnableSubmit}
                      addRoute={handleAddRoute}
                      removeRoute={handleRemoveRoute}
                    />
                  ),
                  listener_type: (
                    <NewIngestorListenerConfig
                      newIngestor={newIngestor}
                      onInputChange={handleValueChange}
                      setEnableSubmit={handleEnableSubmit}
                      addNewFilters={handleAddNewFilter}
                      removeFilter={handleRemoveFilter}
                      addDeviceTypeMapping={handleAddDeviceTypeMapping}
                      removeDeviceTypeMapping={handleRemoveDeviceTypeMapping}
                    />
                  ),
                  translator: (
                    <NewIngestorTranslatorConfig
                      newIngestor={newIngestor}
                      onInputChange={handleValueChange}
                      setEnableSubmit={handleEnableSubmit}
                      translatorOptions={translatorOptions}
                    />
                  ),
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      )}
      <Grid item xs={4}>
        <Publish
          label="ingestor"
          submitting={submitting}
          companyId={newIngestor ? newIngestor.company_id : ''}
          onChangeAccount={handleAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit && dynamicSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreateIngestorPage;
