import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, IconButton } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  SoftwareUpdates,
  SoftwareUpdate,
  SoftwareUpdatesFilters,
} from '@edgeiq/edgeiq-api-js';
import DeleteIcon from '@mui/icons-material/Delete';

import { deleteHighlight, errorHighlight } from '../../../app/constants';
import timeHelpers from '../../../helpers/timeHelpers';

import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';

import TabsPage from '../../../components/TabsPage';
import ActionDialog from '../../../components/ActionDialog';

export interface SoftwareCreateLocationState {
  from: string;
}

const DeviceTypeSoftwareUpdates: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const [loading, setLoading] = useState(false);
  const [updatesList, setUpdatesList] = useState<SoftwareUpdate[]>([]);
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const [selectedSoftwarePackages, setSelectedSoftwarePackages] = useState<
    string[]
  >([]);
  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };
  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Software Package Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <strong>{params.row.name}</strong>
      ),
    },
    {
      field: 'created_at',
      flex: 0.3,
      headerName: 'Date',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{timeHelpers.getPlainDate(params.row.created_at)}</span>
      ),
    },
    {
      field: 'summary',
      flex: 0.3,
      headerName: 'Summary',
    },
    {
      field: 'action',
      flex: 0.1,
      headerName: 'Action',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <IconButton onClick={(): void => handleClickRemoveRow(params)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleClickRemoveRow = (prop: GridRenderCellParams): void => {
    openDeleteModal();
    setSelectedSoftwarePackages([prop.row._id]);
  };

  const handleBulkDelete = (): void => {
    setLoading(true);
    SoftwareUpdates.deleteMultiple(selectedSoftwarePackages)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(1, 'Update', 'Updates'),
            type: 'success',
          }),
        );
        setUpdatesList(
          updatesList.filter(
            (softwareUpdate) =>
              !selectedSoftwarePackages.includes(softwareUpdate._id),
          ),
        );
        setSelectedSoftwarePackages([]);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        closeDeleteModal();
      });
  };

  useEffect(() => {
    if (editableDeviceType) {
      setLoading(true);

      const filters: SoftwareUpdatesFilters = {
        device_type_id: {
          operator: 'eq',
          value: editableDeviceType._id,
        },
      };
      SoftwareUpdates.list(filters)
        .then((resUpdates) => {
          setUpdatesList(resUpdates.softwareUpdates);
        })
        .catch((err) => {
          dispatchError(err.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDeviceType]);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const handleAddSoftwareUpdate = (): void => {
    const state: SoftwareCreateLocationState = {
      from: `/device-profile/${id}`,
    };
    navigate('/new-software-update', { state });
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={updatesList}
        addButtonLabel="Add Software Update"
        onAddClick={handleAddSoftwareUpdate}
        tableTitle="Software added"
        loading={loading}
      />
      <ActionDialog
        open={ActionDialogOpen}
        loading={loading}
        content={
          <>
            <span>You are about to delete this update:</span>
            <ul>
              {updatesList
                .filter((softwareUpdate) =>
                  selectedSoftwarePackages.includes(softwareUpdate._id),
                )
                .map((update) => (
                  <li key={update._id}>{update.name}</li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Box>
  );
};

export default DeviceTypeSoftwareUpdates;
