import React, { useState } from 'react';
import { Typography, Button, IconButton } from '@mui/material';
import {
  DeviceType,
  Rule,
  RuleInput,
  ScheduledJob,
  ScheduledJobInput,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import DeleteIcon from '@mui/icons-material/Delete';

import SelectDeviceProfilesDrawer from '../RightDrawer/SelectDeviceProfilesDrawer/SelectDeviceProfilesDrawer';
import useStyles from './styles';

interface DeviceProfilesEntitiesSectionProps {
  newItem: ScheduledJob | Rule | ScheduledJobInput | RuleInput;
  selectedDeviceProfiles: DeviceType[];
  onChangeDeviceTypes: (deviceTypes: DeviceType[]) => void;
}

const DeviceProfilesEntitiesSection: React.FC<
  DeviceProfilesEntitiesSectionProps
> = ({ newItem, selectedDeviceProfiles, onChangeDeviceTypes }) => {
  const classes = useStyles();
  const [deviceProfileDrawerOpen, setDeviceProfileDrawerOpen] = useState(false);

  const handleDeviceTypeCallback = (deviceTypes: DeviceType[]): void => {
    onChangeDeviceTypes(deviceTypes);
    handleCloseDeviceProfileDrawer();
  };

  const handleOpenDeviceProfileDrawer = (): void => {
    setDeviceProfileDrawerOpen(true);
  };

  const handleCloseDeviceProfileDrawer = (): void => {
    setDeviceProfileDrawerOpen(false);
  };

  const handleDeleteDevice = (i: number): void => {
    selectedDeviceProfiles.splice(i, 1);
    handleDeviceTypeCallback([...selectedDeviceProfiles]);
  };

  return (
    <>
      {selectedDeviceProfiles.length === 0 ? (
        <Typography
          variant="overline"
          component="div"
          className={clsx('mt-8', classes.configSubtitle)}
        >
          No device profiles selected.
        </Typography>
      ) : (
        <div className={clsx('scrollbar', classes.container)}>
          {selectedDeviceProfiles.map((entity, i) => (
            <Typography
              noWrap
              key={entity._id}
              variant="button"
              component="div"
              className={clsx('py-2 fw-700', classes.item)}
            >
              {entity.name}
              <IconButton
                color="primary"
                size="large"
                className="p-0"
                onClick={(): void => handleDeleteDevice(i)}
              >
                <DeleteIcon />
              </IconButton>
            </Typography>
          ))}
        </div>
      )}

      <Button
        className="mt-8"
        color="primary"
        size="large"
        variant="contained"
        type="button"
        onClick={handleOpenDeviceProfileDrawer}
      >
        <Typography variant="button">Attach Device Profiles</Typography>
      </Button>
      <SelectDeviceProfilesDrawer
        open={deviceProfileDrawerOpen}
        selectedDeviceTypes={selectedDeviceProfiles.map(
          (deviceProfile) => deviceProfile._id,
        )}
        onCloseDrawer={handleCloseDeviceProfileDrawer}
        onChoosingDeviceTypes={handleDeviceTypeCallback}
        companyId={newItem.company_id}
      />
    </>
  );
};

export default DeviceProfilesEntitiesSection;
