import { Configuration, ConfigurationInput } from '@edgeiq/edgeiq-api-js';
import { createSelector } from 'reselect';

export const getConfigurationSelector = createSelector(
  (state: ConfigurationState) => state,
  (_: null, id: string | undefined): string | undefined => id,
  (state, id) => {
    if (!state.configurations.length) {
      return state.actualConfiguration;
    }
    return state.configurations.find(
      (configuration: Configuration) => configuration._id === id,
    );
  },
);

export const newConfiguration = {
  company_id: '',
  configurable_settings: [],
  device_type_id: '',
  name: '',
};

export const ConfigurationTypes = {
  SET_ACTUAL_CONFIGURATION: 'SET_ACTUAL_CONFIGURATION',
  SET_CONFIGURATIONS: 'SET_CONFIGURATIONS',
  SET_NEW_CONFIGURATION: 'SET_NEW_CONFIGURATION',
  SET_NEW_CONFIGURATION_INPUT: 'SET_NEW_CONFIGURATION_INPUT',
};

export const setStateConfigurations = (
  configurations: Configuration[],
): ConfigurationAction => ({
  data: configurations,
  type: ConfigurationTypes.SET_CONFIGURATIONS,
});

export const setActualConfiguration = (
  configuration: Configuration,
): ConfigurationAction => ({
  data: configuration,
  type: ConfigurationTypes.SET_ACTUAL_CONFIGURATION,
});

export const setNewConfiguration = (
  configuration: Configuration,
): ConfigurationAction => ({
  data: configuration,
  type: ConfigurationTypes.SET_NEW_CONFIGURATION,
});

export const setNewConfigurationInput = (
  configuration: ConfigurationInput,
): ConfigurationAction => ({
  data: configuration,
  type: ConfigurationTypes.SET_NEW_CONFIGURATION_INPUT,
});

export type ConfigurationState = {
  actualConfiguration: Configuration | null;
  configurations: Configuration[];
  newConfiguration: Configuration | null;
  newConfigurationInput: ConfigurationInput | null;
};

// INITIAL STATE
const configurationInitialState: ConfigurationState = {
  actualConfiguration: null,
  configurations: [],
  newConfiguration: null,
  newConfigurationInput: newConfiguration as ConfigurationInput,
};

export type ConfigurationAction = {
  data: Configuration[] | Configuration | ConfigurationInput | string;
  type: string;
};

const configurationReducer = (
  state = configurationInitialState,
  action: ConfigurationAction,
): ConfigurationState => {
  switch (action.type) {
    case ConfigurationTypes.SET_CONFIGURATIONS:
      return {
        ...state,
        configurations: action.data as Configuration[],
      };

    case ConfigurationTypes.SET_ACTUAL_CONFIGURATION:
      return {
        ...state,
        actualConfiguration: action.data as Configuration,
        newConfiguration: action.data as Configuration,
      };

    case ConfigurationTypes.SET_NEW_CONFIGURATION:
      return {
        ...state,
        newConfiguration: action.data as Configuration,
      };

    case ConfigurationTypes.SET_NEW_CONFIGURATION_INPUT:
      return {
        ...state,
        newConfigurationInput: action.data as ConfigurationInput,
      };

    default:
      return state;
  }
};

export default configurationReducer;
