import {
  IconButton,
  Tooltip,
  Typography,
  TypographyVariant,
} from '@mui/material';
import clsx from 'clsx';
import { ContentCopy } from '@mui/icons-material';

import copyTextToClipboard from '../../helpers/copyTextToClipboard';
import { highlightTextPart } from '../../helpers/utils';
import useStyles from './styles';

export interface TypographyWithCopyProps {
  containerClassName?: string;
  component?: React.ElementType;
  dataCy: string;
  copyTooltipText?: string;
  text: string;
  textToCopy?: string;
  textMaxWidth?: number;
  textClassName?: string;
  tooltipText?: string;
  typographyVariant: TypographyVariant;
  searchText?: string;
}

const TypographyWithCopy: React.FC<TypographyWithCopyProps> = ({
  textClassName,
  component,
  dataCy,
  text,
  textToCopy,
  copyTooltipText,
  typographyVariant,
  tooltipText,
  textMaxWidth,
  containerClassName,
  searchText,
}) => {
  const classes = useStyles();
  const renderText = searchText ? (
    highlightTextPart(text, searchText)
  ) : (
    <span>{text}</span>
  );
  return (
    <div className={clsx(classes.container, containerClassName)}>
      <Typography
        data-cy={dataCy}
        className={textClassName}
        variant={typographyVariant}
        component={component ?? 'span'}
        noWrap
        style={{
          maxWidth: textMaxWidth ? `${textMaxWidth}px` : 'none',
        }}
      >
        <>
          {tooltipText && (
            <Tooltip
              placement="top"
              title={<p className={classes.tooltip}>{tooltipText}</p>}
            >
              {renderText}
            </Tooltip>
          )}
          {!tooltipText && renderText}
        </>
      </Typography>
      <Tooltip
        placement="top"
        title={
          <p className={classes.tooltip}>
            {copyTooltipText ?? 'Click to copy'}
          </p>
        }
      >
        <IconButton
          size="small"
          data-y={`${dataCy}-copy-button`}
          className={clsx('ml-1', classes.iconButton)}
          onClick={copyTextToClipboard(textToCopy ?? text)}
        >
          <ContentCopy className={classes.icon} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default TypographyWithCopy;
