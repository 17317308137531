import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import { SoftwareUpdates, SoftwareUpdate } from '@edgeiq/edgeiq-api-js';
import isEqual from 'lodash.isequal';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getSoftwareUpdatesSelector,
  setActualSoftwareUpdate,
} from '../../redux/reducers/softwareUpdates.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { deviceDetailsTabsLabel, errorHighlight } from '../../app/constants';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import FooterBar from '../../components/FooterBar';
import SoftwarePackageDetails from './softwarePackageDetails';

const SoftwarePackageContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams<string>();
  const softwareUpdateData = useAppSelector((state: RootState) =>
    getSoftwareUpdatesSelector(state.softwareUpdates, id),
  );
  const softwareUpdateCompany = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );
  const newSoftwareUpdate = useAppSelector(
    (state: RootState) => state.softwareUpdates.newSoftwareUpdate,
  );
  const [loading, setLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const setSoftwareUploadedFiles = (filesData: SoftwareUpdate): void => {
    const softwareUpdateDatafiles = filesData.files;
    const toSetUploadedFiles =
      (softwareUpdateDatafiles &&
        (softwareUpdateDatafiles.filter(
          (file) => !!file,
        ) as unknown as File[])) ??
      ([] as File[]);
    setUploadedFiles(toSetUploadedFiles);
  };

  useEffect(() => {
    if (softwareUpdateData) {
      dispatch(setActualSoftwareUpdate(softwareUpdateData));
      setSoftwareUploadedFiles(softwareUpdateData);
    } else if (id) {
      SoftwareUpdates.getOneById(id)
        .then((response) => {
          dispatch(setActualSoftwareUpdate(response));
          setSoftwareUploadedFiles(response);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [softwareUpdateData]);

  const handleDeleteDevice = (): void => {
    if (!softwareUpdateData) {
      return;
    }
    setLoading(true);
    SoftwareUpdates.delete(softwareUpdateData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete package',
            message: 'Package successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/software-updates');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    if (newSoftwareUpdate) {
      const updateFiles =
        newSoftwareUpdate.files &&
        newSoftwareUpdate.files.filter(
          (file) =>
            file &&
            uploadedFiles.find((uploaded) => uploaded.name === file.name),
        );
      setLoading(true);
      SoftwareUpdates.update({
        ...newSoftwareUpdate,
        files: updateFiles,
      })
        .then(async (response) => {
          try {
            const files = await SoftwareUpdates.uploadFile(
              response._id,
              uploadedFiles ?? [],
            );
            response.files = files;
          } catch (error: unknown) {
            const { message } = error as { message: string };
            dispatch(
              setAlert({
                highlight: errorHighlight,
                message,
                type: 'error',
              }),
            );
          }

          dispatch(setActualSoftwareUpdate(response));

          dispatch(
            setAlert({
              highlight: 'Update package',
              message: 'Package successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    const tabs: {
      [key: string]: JSX.Element;
    } = {
      details: (
        <SoftwarePackageDetails
          editableSoftwareUpdate={newSoftwareUpdate}
          setUploadedFiles={setUploadedFiles}
          uploadedFiles={uploadedFiles}
        />
      ),
    };
    return tabs;
  };

  const isAbleToBeSaved = (): boolean => {
    return isEqual(newSoftwareUpdate, softwareUpdateCompany);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Software Package content"
        goBackLabel={'Software Packages'}
        goBack="software-updates"
        model="software_update"
      />
      {softwareUpdateData && (
        <Box className="content-page-container">
          <ContentHeader
            contentType="software-package"
            title={softwareUpdateData.name}
            subtitle={softwareUpdateData._id}
            extraImage={softwareUpdateCompany?.branding?.logo_url}
            extraTitle={softwareUpdateCompany?.name}
            extraSubtitle={softwareUpdateCompany?._id}
            hideTitleImage={true}
            copySubtitleToClipboard
          />
          <VerticalTabs
            tabs={getTabs()}
            defaultTab="details"
            tabsLabel={deviceDetailsTabsLabel}
          />
        </Box>
      )}
      <FooterBar
        loading={loading}
        disableSaveButton={isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteDevice}
      />
    </Grid>
  );
};

export default SoftwarePackageContent;
