import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  PaginationFilter,
  Notification,
  Notifications,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setSorting } from '../../../redux/reducers/filters.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { defaultItemsPerPage, errorHighlight } from '../../../app/constants';
import { genericReportsSorting, SortingOption } from '../../../models/common';
import getInitialSorting from '../../../helpers/getInitialSorting';
import parseFilters from '../../../helpers/parseFilters';
import ListSelection from '../../../components/ListSelection';
import SharedTable from '../../../components/SharedTable';
import useStyles from '../styles';
import AlertsMessagesFilters from './AlertsMessagesFilters';
import { AlertsMessagesColumns } from './columns';

const AlertsMessages: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const filters = useAppSelector((state: RootState) => state.filters);

  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.alerts.sortBy, genericReportsSorting),
  );
  const [alerts, setAlerts] = useState<Notification[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getAlerts = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    Notifications.list(parseFilters(filters.alerts.filters ?? {}), pagination)
      .then((result) => {
        const results: Notification[] = addPage
          ? [...alerts, ...result.resources]
          : result.resources;

        setAlerts(results);
        setTotal(result.pagination.total);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    getAlerts(1);
  }, [filters.alerts]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getAlerts(page + 1, true);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'alerts'));
    setSelectedSorting(option);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <AlertsMessagesFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={'list'}
        sortingOptions={genericReportsSorting}
        viewsOptions={[]}
        itemsSelected={false}
        allSelected={false}
        deleteAction={true}
        xPadding={false}
        sortingCallback={handleSorting}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          <SharedTable
            columns={AlertsMessagesColumns}
            rows={alerts}
            sortBy={selectedSorting.value}
            sortDirection={
              selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
            }
            allSelected={false}
            loading={loading}
            selectedItemsIds={[]}
            xPadding={false}
            onRequestSort={handleTableSorting}
            viewOnly={true}
          />

          {alerts.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default AlertsMessages;
