import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRowsProp } from '@mui/x-data-grid';
import clsx from 'clsx';

import useStyles from './styles';

export interface BasicDialogProps {
  title?: string;
  columns: GridColDef[];
  rows?: GridRowsProp;
  loading?: boolean;
}

const AttachTable: React.FC<BasicDialogProps> = ({
  title,
  columns,
  rows,
  loading,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography data-cy="attach-table-title" className="mb-7" variant="h5">
        {title}
      </Typography>

      <Box data-cy="attach-table" className={clsx(classes.tableContainer)}>
        <DataGrid
          className={clsx(classes.table)}
          rows={rows ? rows : []}
          columns={columns}
          pageSize={5}
          getRowId={(row): string => row._id || row.key || row.type || row.name}
          autoPageSize={true}
          disableSelectionOnClick
          disableColumnMenu
          loading={loading}
          components={{
            NoRowsOverlay: () => (
              <Stack
                data-cy="attach-table-no-rows"
                height="100%"
                alignItems="center"
                justifyContent="center"
              >
                No rows
              </Stack>
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default AttachTable;
