import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  Device,
  Devices,
  DeviceType,
  DeviceTypes,
  Integrations,
  Rules,
  RuleInput,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight, optionsPaginationsFilter } from '../../app/constants';
import { RootState } from '../../redux/store';
import {
  cleanNewPolicy,
  setNewPolicy,
} from '../../redux/reducers/policies.reducer';
import { setOptionsIntegrations } from '../../redux/reducers/integrations.reducer';
import Header from '../../containers/HeaderWithActionButton';
import SideTabs from '../../components/SideTabs';
import Publish from '../../containers/Publish';
import {
  addNewValue,
  removeValue,
  addNewCondition,
  removeCondition,
  addNewProperty,
  removeProperty,
  addNewDerivedValue,
  removeDerivedValue,
  addNewWhereCondition,
  removeWhereCondition,
  addNewThenAction,
  removeThenAction,
  addNewElseAction,
  removeElseAction,
  changeThenActionsValues,
  changeElseActionsValues,
  changeDerivedValues,
  changeRuleConditionValues,
  changeDetailsValues,
} from '../../containers/Forms/PolicyForm/helper';
import PolicyDetailsSection from '../../containers/Forms/PolicyForm/PolicyDetailsSection/PolicyDetailsSection';
import PolicyConditionSection from '../../containers/Forms/PolicyForm/PolicyConditionSection/PolicyConditionSection';
import PolicyDerivedValuesSection from '../../containers/Forms/PolicyForm/PolicyDerivedValuesSection/PolicyDerivedValuesSection';
import PolicyActionsSection from '../../containers/Forms/PolicyForm/PolicyActionsSection/PolicyActionsSection';
import PolicyElseActionsSection from '../../containers/Forms/PolicyForm/PolicyActionsSection/PolicyElseActionsSection';
import useStyles from './styles';
import { EMPTY_POLICY } from '../../constants/policies';
import { checkMixedTypes, dispatchError } from '../../helpers/utils';
import { setOptionsDeviceTypes } from '../../redux/reducers/deviceTypes.reducer';
import EntitiesSection from '../../containers/EntitiesSection';

const CreatePolicyPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const newPolicy = useAppSelector(
    (state: RootState) => state.policies.newPolicy,
  );
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [policyType, setPolicyType] = useState('true');
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );

  const integrationsList = useAppSelector(
    (state: RootState) => state.integrations.optionsIntegrations,
  );
  const [mixedTypes, setMixedTypes] = useState(false);
  const deviceTypesState = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [devicesTypes, setDevicesTypes] = useState<DeviceType[]>(
    deviceTypesState.optionsDeviceTypes,
  );

  useEffect(() => {
    if (devicesTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          setDevicesTypes(result.deviceTypes);
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    if (devicesTypes.length) {
      if (checkMixedTypes(selectedDevices, devicesTypes)) {
        setMixedTypes(true);
      } else {
        setMixedTypes(false);
      }
    }
  }, [selectedDevices, devicesTypes]);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      newPolicy.description !== '' && newPolicy.company_id !== '',
    );
  };

  useEffect(() => {
    dispatch(setNewPolicy(EMPTY_POLICY as RuleInput));
    if (!integrationsList.length) {
      getIntegrationsList();
    }
    dispatch(
      setNewPolicy({ ...EMPTY_POLICY, company_id: newPolicy.company_id }),
    );
  }, []);

  useEffect(() => {
    checkSubmitEnable();
  }, [newPolicy]);

  const getIntegrationsList = (): void => {
    Integrations.list()
      .then((response) => {
        dispatch(setOptionsIntegrations(response.integrations));
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while fetching integrations.',
            type: 'error',
          }),
        );
      });
  };

  const handleDetailsChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    dispatch(setNewPolicy(changeDetailsValues(newPolicy, prop, value)));
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleDetailsChange('company_id', companyId);
  };

  // START OF THE RULE CONDITION MODULE
  const handleConditionChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    if (prop === 'policyType') {
      setPolicyType(value as string);
      return dispatch(
        setNewPolicy(changeRuleConditionValues(newPolicy, 'type', value)),
      );
    }
    dispatch(setNewPolicy(changeRuleConditionValues(newPolicy, prop, value)));
  };

  const handleAddNewCondition = (): void => {
    return dispatch(setNewPolicy(addNewCondition(newPolicy)));
  };

  const handleRemoveCondition = (conditionIndex: number): void => {
    return dispatch(setNewPolicy(removeCondition(newPolicy, conditionIndex)));
  };

  const handleAddNewValue = (index?: number): void => {
    return dispatch(setNewPolicy(addNewValue(newPolicy, index)));
  };

  const handleRemoveValue = (index: number, conditionIndex?: number): void => {
    return dispatch(
      setNewPolicy(removeValue(newPolicy, index, conditionIndex)),
    );
  };
  // END OF THE RULE CONDITION MODULE

  // START OF THE DERIVED VALUES MODULE
  const handleDerivedValuesChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    dispatch(setNewPolicy(changeDerivedValues(newPolicy, prop, value)));
  };

  const handleAddDerivedValue = (): void => {
    return dispatch(setNewPolicy(addNewDerivedValue(newPolicy)));
  };

  const handleRemoveDerivedValue = (derivedValueIndex: number): void => {
    return dispatch(
      setNewPolicy(removeDerivedValue(newPolicy, derivedValueIndex)),
    );
  };

  const handleAddNewProperty = (derivedValueIndex: number): void => {
    return dispatch(setNewPolicy(addNewProperty(newPolicy, derivedValueIndex)));
  };

  const handleRemoveProperty = (
    derivedValueIndex: number,
    propertyIndex: number,
  ): void => {
    return dispatch(
      setNewPolicy(removeProperty(newPolicy, derivedValueIndex, propertyIndex)),
    );
  };

  const handleAddWhereCondition = (derivedValueIndex: number): void => {
    return dispatch(
      setNewPolicy(addNewWhereCondition(newPolicy, derivedValueIndex)),
    );
  };

  const handleRemoveWhereCondition = (
    derivedValueIndex: number,
    whereConditionIndex: number,
  ): void => {
    return dispatch(
      setNewPolicy(
        removeWhereCondition(newPolicy, derivedValueIndex, whereConditionIndex),
      ),
    );
  };
  // END OF THE DERIVED VALUES MODULE

  // START OF THEN ACTIONS MODULE
  const handleThenActionValueChange = (
    prop: string,
    value: string | number | string[] | boolean | { [key: string]: string },
  ): void => {
    dispatch(
      setNewPolicy(changeThenActionsValues(newPolicy, prop, value as string)),
    );
  };

  const handleAddNewAction = (): void => {
    return dispatch(setNewPolicy(addNewThenAction(newPolicy)));
  };

  const handleRemoveAction = (actionIndex: number): void => {
    return dispatch(setNewPolicy(removeThenAction(newPolicy, actionIndex)));
  };

  // START OF ELSE ACTIONS MODULE
  const handleElseActionValueChange = (
    prop: string,
    value: string | number | string[] | boolean | { [key: string]: string },
  ): void => {
    dispatch(
      setNewPolicy(changeElseActionsValues(newPolicy, prop, value as string)),
    );
  };

  const handleAddNewElseAction = (): void => {
    return dispatch(setNewPolicy(addNewElseAction(newPolicy)));
  };

  const handleRemoveElseAction = (actionIndex: number): void => {
    return dispatch(setNewPolicy(removeElseAction(newPolicy, actionIndex)));
  };

  const handleChangeDevices = (devices: Device[]): void => {
    setSelectedDevices(devices);
  };

  const handleChangeDeviceTypes = (deviceTypes: DeviceType[]): void => {
    setSelectedDeviceTypes(deviceTypes);
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);

    let policyId = '';

    newPolicy.cloud_rule = newPolicy.cloud_rule !== false;

    Rules.create(newPolicy)
      .then((response) => {
        policyId = response._id;

        Promise.all([
          Promise.all(
            selectedDevices.map(async (attachDevice) => {
              await Devices.attachRule(attachDevice._id, policyId);
            }),
          ),
          Promise.all(
            selectedDeviceTypes.map(async (attachDeviceTypes) => {
              await DeviceTypes.attachRule(attachDeviceTypes._id, policyId);
            }),
          ),
        ]);
      })
      .then((_response) => {
        dispatch(
          setAlert({
            highlight: 'Policy created successfully',
            type: 'success',
          }),
        );
        navigate(`/policies`);
        dispatch(cleanNewPolicy());
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Grid container direction="row" spacing={2} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="policies"
          goBackLabel="Policies"
          isCreatePage={true}
          model="rule"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column">
          <Paper className="shadow p-8">
            <Box className={clsx('mb-6', classes.titleContainer)}>
              <Typography
                data-cy="create-policy-title"
                variant="h5"
                className={classes.title}
              >
                Create a new policy
              </Typography>
            </Box>
            <PolicyDetailsSection
              newPolicy={newPolicy}
              onInputChange={handleDetailsChange}
            />
          </Paper>
          <Paper className="shadow p-8 mt-4">
            <SideTabs
              defaultTab="condition"
              tabs={{
                /* eslint sort-keys: 0 */
                condition: (
                  <PolicyConditionSection
                    newPolicy={newPolicy}
                    onInputChange={handleConditionChange}
                    policyType={policyType}
                    onAddNewValue={handleAddNewValue}
                    onRemoveValue={handleRemoveValue}
                    onAddNewCondition={handleAddNewCondition}
                    onRemoveCondition={handleRemoveCondition}
                  />
                ),
                entities: (
                  <EntitiesSection
                    newInput={newPolicy}
                    selectedDevices={selectedDevices}
                    selectedDeviceTypes={selectedDeviceTypes}
                    mixedTypes={mixedTypes}
                    showMixedTypes={true}
                    onChangeDevices={handleChangeDevices}
                    onChangeDeviceTypes={handleChangeDeviceTypes}
                  />
                ),
                derived_values: (
                  <PolicyDerivedValuesSection
                    newPolicy={newPolicy}
                    onInputChange={handleDerivedValuesChange}
                    onAddNewProperty={handleAddNewProperty}
                    onRemoveProperty={handleRemoveProperty}
                    onAddNewDerivedValue={handleAddDerivedValue}
                    onRemoveDerivedValue={handleRemoveDerivedValue}
                    onAddWhereCondition={handleAddWhereCondition}
                    onRemoveWhereCondition={handleRemoveWhereCondition}
                  />
                ),
                actions: (
                  <PolicyActionsSection
                    selectedDevices={selectedDevices}
                    newPolicy={newPolicy}
                    onInputChange={handleThenActionValueChange}
                    onAddNewAction={handleAddNewAction}
                    onRemoveAction={handleRemoveAction}
                    integrations={integrationsList}
                    mixedTypes={mixedTypes}
                  />
                ),
                else_actions: (
                  <PolicyElseActionsSection
                    newPolicy={newPolicy}
                    onInputChange={handleElseActionValueChange}
                    onAddNewAction={handleAddNewElseAction}
                    onRemoveAction={handleRemoveElseAction}
                    integrations={integrationsList}
                    mixedTypes={mixedTypes}
                  />
                ),
              }}
            />
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="policy"
          submitting={submitting}
          companyId={newPolicy.company_id}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
    </Grid>
  );
};

export default CreatePolicyPage;
