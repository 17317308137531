import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  DiscoveredDevice,
  DiscoveredDevices,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setStateDiscoveredDevices } from '../../../redux/reducers/devices.reducer';
import {
  setSorting,
  setViewOption,
} from '../../../redux/reducers/filters.reducer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import Header from '../../../components/Header';
import ListSelection from '../../../components/ListSelection';
import Card from '../../../components/Card';
import CardsGrid from '../../../components/CardsGrid';
import SharedTable from '../../../components/SharedTable';
import ActionDialog from '../../../components/ActionDialog';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  genericViewOptions,
  optionsPaginationsFilter,
} from '../../../app/constants';
import { sortingOptions } from '../constants';
import { SortingOption } from '../../../models/common';
import getInitialSorting from '../../../helpers/getInitialSorting';
import parseFilters from '../../../helpers/parseFilters';
import DevicesMetrics from '../DevicesMetrics';
import DevicesExtraActions from '../DevicesExtraActions';
import DevicesHeaderActions from '../DevicesHeaderActions';
import DevicesGenre from '../DevicesGenre';
import useStyles from '../styles';
import { DiscoveredColumns } from './columns';
import DiscoveredDevicesFilters from './DiscoveredDevicesFilters';
import DiscoveredDeviceCard from './DiscoveredDeviceCard';

const DiscoveredDevicesPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const [discoveredDevices, setDiscoveredDevices] = useState<
    DiscoveredDevice[]
  >([]);
  const [selectedDevices, setSelectedDevices] = useState<string[]>([]);
  const [discoveredSorting, setDiscoveredSorting] = useState<SortingOption>(
    getInitialSorting(filters.discoveredDevices.sortBy, sortingOptions),
  );
  const [selectedView, setSelectedView] = useState(
    filters.discoveredDevices.view,
  );
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSelectAll, setLoadingSelectAll] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);

  const dispatchError = (errorMessage: string, highlight?: string): void => {
    dispatch(
      setAlert({
        highlight: highlight ?? errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };

  const getDevices = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: discoveredSorting.value,
      page: pageNumber,
    };

    DiscoveredDevices.list(
      parseFilters(filters.discoveredDevices.filters ?? {}),
      pagination,
    )
      .then((result) => {
        const newDiscoveredDevices = addPage
          ? [...discoveredDevices, ...result.discoveredDevices]
          : result.discoveredDevices;
        setDiscoveredDevices(newDiscoveredDevices);
        dispatch(setStateDiscoveredDevices(newDiscoveredDevices));
        setTotalAndPage(result.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  useEffect(() => {
    setLoading(true);
    setSelectedDevices([]);
    getDevices(1);
  }, [filters.discoveredDevices, devicesState.devicesGenre]);

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getDevices(page + 1, true);
  };

  const checkDeviceCallback =
    (deviceId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setSelectedDevices([...selectedDevices, deviceId]);
      } else {
        setSelectedDevices(selectedDevices.filter((item) => item !== deviceId));
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'discoveredDevices'));
    setDiscoveredSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'devices'));
    setSelectedView(view);
  };

  const handleSelectAll = (): void => {
    if (selectedDevices.length !== total) {
      if (discoveredDevices.length === total) {
        // All devices are already in discoveredDevices array
        setSelectedDevices(discoveredDevices.map((device) => device._id));
      } else {
        setLoadingSelectAll(true);
        // Get the total of discoveredDevices by the filters, we already have the total number
        DiscoveredDevices.list(
          parseFilters(filters.discoveredDevices.filters ?? {}),
          optionsPaginationsFilter,
        )
          .then((result) => {
            setSelectedDevices(
              result.discoveredDevices.map((device) => device._id),
            );
          })
          .catch((error) => {
            dispatchError(error.message);
          })
          .finally(() => {
            setLoadingSelectAll(false);
          });
      }
    } else {
      setSelectedDevices([]);
    }
  };

  const handleImportSucces = (): void => {
    getDevices(1);
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);

    DiscoveredDevices.deleteMultiple(selectedDevices)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(
              selectedDevices.length,
              'Discovered Device',
              'Discovered Devices',
            ),
            type: 'success',
          }),
        );
        setDiscoveredDevices(
          discoveredDevices.filter(
            (device) => !selectedDevices.includes(device._id),
          ),
        );
        setSelectedDevices([]);
        setTotal(total - selectedDevices.length);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoadingDelete(false);
        closeDeleteModal();
      });
  };

  const allSelected =
    discoveredDevices.length !== 0 && selectedDevices.length === total;

  return (
    <Grid container direction="column" spacing={0}>
      <Header title="Devices" nextTitleContent={<DevicesGenre />}>
        <DevicesHeaderActions onImportSuccess={handleImportSucces} />
      </Header>
      <DiscoveredDevicesFilters total={total} />
      <DevicesMetrics genre={devicesState.devicesGenre} />
      <ListSelection
        deleteAction={true}
        selectedLabel="Discovered Device"
        selectedSorting={discoveredSorting}
        selectedView={selectedView}
        sortingOptions={sortingOptions}
        viewsOptions={genericViewOptions}
        itemsSelected={selectedDevices.length !== 0}
        allSelected={allSelected}
        itemsSelectedCount={selectedDevices.length}
        loadingAllItems={loadingSelectAll}
        selectedActions={
          <DevicesExtraActions selectedDevicesIds={selectedDevices} />
        }
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {selectedView === 'grid' && (
            <CardsGrid
              cards={discoveredDevices.map((discoveredDevice) => (
                <Card
                  checked={selectedDevices.includes(discoveredDevice._id)}
                  checkboxCallback={checkDeviceCallback}
                  id={discoveredDevice._id}
                  baseLink="/discovered-device"
                  content={
                    <DiscoveredDeviceCard discoveredDevice={discoveredDevice} />
                  }
                />
              ))}
            />
          )}
          {selectedView === 'list' && (
            <SharedTable
              columns={DiscoveredColumns}
              rows={discoveredDevices}
              sortBy={discoveredSorting.value}
              sortDirection={
                discoveredSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
              }
              allSelected={allSelected}
              loading={loading}
              selectedItemsIds={selectedDevices}
              hasActionColumn={true}
              selectAllCallback={handleSelectAll}
              checkboxCallback={checkDeviceCallback}
            />
          )}
          {discoveredDevices.length !== total && (
            <Grid
              item
              xs={12}
              className={clsx('mb-9', classes.loadMoreContainer)}
            >
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedDevices.length === 1 ? 'device' : 'devices'
            }:`}</span>
            <ul>
              {discoveredDevices
                .filter((device) => selectedDevices.includes(device._id))
                .map((device) => (
                  <li key={device._id}>
                    {device.name} - Id: {device.unique_id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default DiscoveredDevicesPage;
