import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import {
  Key as KeyIcon,
  SwapHoriz as SwapHorizIcon,
} from '@mui/icons-material';
import { EscrowDevice } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import TypographyWithCopy from '../../../components/TypographyWithCopy';
import useStyles from '../styles';

interface EscrowDeviceCardProps {
  escrowDevice: EscrowDevice;
}

const EscrowDeviceCard: React.FC<EscrowDeviceCardProps> = ({
  escrowDevice,
}) => {
  const classes = useStyles();
  return (
    <Box>
      <TypographyWithCopy
        dataCy={`copy-escrow-device-id-${escrowDevice.unique_id}`}
        containerClassName="mb-4"
        textClassName={classes.subtitle}
        component="div"
        text={`ID: ${escrowDevice.unique_id}`}
        textToCopy={escrowDevice.unique_id}
        typographyVariant="button"
      />
      <Tooltip placement="top" title="Token">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          <KeyIcon fontSize="small" className="mr-2" />
          {escrowDevice.token}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Transfer Status">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          <SwapHorizIcon fontSize="small" className="mr-2" />
          {escrowDevice.transfer_initiated_at
            ? 'Device transfer initiated'
            : escrowDevice.transfer_completed_at
            ? 'Device transfer completed'
            : 'Device transfer not yet initiated'}
        </Typography>
      </Tooltip>
    </Box>
  );
};

export default EscrowDeviceCard;
