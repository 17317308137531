import React, { useState } from 'react';
import { Grid } from '@mui/material';
import {
  Configuration,
  DeviceType,
  Setting,
  Settings,
  SettingsApplicationRecord,
} from '@edgeiq/edgeiq-api-js';
import ConfigurationForm from '../../../containers/Forms/ConfigurationForm';
import SettingApplicationList from '../../../containers/SettingApplicationList';
import { errorHighlight } from '../../../app/constants';
import { useAppDispatch } from '../../../redux/hooks';
import SettingManagementDrawer from '../../../containers/RightDrawer/SettingManagementDrawer/SettingManagementDrawer';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import clsx from 'clsx';
import useStyles from './styles';
import CommandExecutionInfoDrawer from '../../commandContent/commandExecutionList/CommandExecutionInfoDrawer';

interface ConfigurationSettingDetailsProp {
  configuration: Configuration;
  action: string;
  deviceTypes: DeviceType[];
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
  onAddNewConfigurationValue: () => void;
  onDynamicRowsChange: (
    prop: string,
    value: string | number,
    field: string,
    index: string,
    parentIndex: number,
  ) => void;
  handleAddRow: (prop: string, index: number) => void;
  handleRemoveRow: (prop: string, index: number, parentIndex: number) => void;
  onRemoveArrayRow: (index: number) => void;
}

const ConfigurationSettingDetails: React.FC<
  ConfigurationSettingDetailsProp
> = ({
  configuration,
  deviceTypes,
  onInputChange,
  onAddNewConfigurationValue,
  onDynamicRowsChange,
  handleAddRow,
  handleRemoveRow,
  onRemoveArrayRow,
}) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [selectedSettingConfiguration, setSelectedSettingConfiguration] =
    useState<Setting | null>();
  const [selectedSettingApplication, setSelectedSettingApplication] =
    useState<SettingsApplicationRecord | null>();
  const initializeDrawers = { command: false, setting: false };
  const [openDrawer, setOpenDrawer] = useState<{ [key: string]: boolean }>(
    initializeDrawers,
  );

  const getConfigurationById = (id: string): void => {
    Settings.getOneById(id)
      .then((response) => {
        setSelectedSettingConfiguration(response as Setting);
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      });
  };

  const settingSelected = (
    settingApplicationRecord: SettingsApplicationRecord,
    open: string,
  ): void => {
    setSelectedSettingApplication(
      settingApplicationRecord as SettingsApplicationRecord,
    );
    getConfigurationById(settingApplicationRecord.setting._id);
    setOpenDrawer((prev) => {
      prev[open] = true;
      return prev;
    });
  };

  const onCloseSettingDrawer = (): void => {
    setSelectedSettingApplication(null);
    setOpenDrawer(initializeDrawers);
  };

  return (
    <Grid container>
      <Grid item xs={8}>
        <ConfigurationForm
          onInputChange={onInputChange}
          action={'save'}
          configuration={(configuration as Configuration) || undefined}
          deviceTypes={deviceTypes}
          onAddNewConfigurationValue={onAddNewConfigurationValue}
          onDynamicRowsChange={onDynamicRowsChange}
          handleAddRow={handleAddRow}
          handleRemoveRow={handleRemoveRow}
          onRemoveArrayRow={onRemoveArrayRow}
        />
      </Grid>

      <Grid
        xs={3}
        className={clsx('p-7 ml-9 shadow br-1', classes.boxContainer)}
      >
        <SettingApplicationList
          settingSelected={settingSelected}
          configurationId={configuration?._id}
        />
        <CommandExecutionInfoDrawer
          open={openDrawer.command}
          handleCloseDrawer={onCloseSettingDrawer}
          commandExecution={selectedSettingApplication?.command_execution}
        />
        <SettingManagementDrawer
          selectedConfiguration={
            selectedSettingApplication?.configuration as Configuration
          }
          setting={selectedSettingConfiguration as Setting}
          open={openDrawer.setting}
          onCloseDrawer={onCloseSettingDrawer}
        />
      </Grid>
    </Grid>
  );
};

export default ConfigurationSettingDetails;
