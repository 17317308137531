import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Publish from '../../../containers/Publish';
import {
  Attribute,
  PollableAttributes,
  PollableAttributeInput,
  PollableAttributeDataType,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setNewPollableAttributesInput } from '../../../redux/reducers/pollableAttributes.reducer';
import { errorHighlight } from '../../../app/constants';
import Header from '../../../containers/HeaderWithActionButton';
import PollableForm from '../../../containers/Forms/PollableForm';
import DynamicRows from '../../../components/DynamicRows';
import { ItemList } from '../../../components/DynamicRows/constants';
import PollableAttributeMibForm from '../../../containers/Forms/PollableAttributeMibForm';
import PollableAttributeNotificationsForm from '../../../containers/Forms/PollableAttributeNotificationsForm';
import { POLLABLE_DATA_TYPES } from '../../../constants/pollableAttributes';
import useStyles from './styles';

const CreatePollable: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const pollableAttributeInput = useAppSelector(
    (state: RootState) => state.pollableAttributes.newPollableAttributeInput,
  );

  const [submitting, setSubmitting] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);

  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      pollableAttributeInput?.name !== '' &&
        pollableAttributeInput?.company_id !== '',
    );
  };

  const handleChange = (prop: string, value: string | number): void => {
    let newPollable = pollableAttributeInput;
    if (prop === 'mib.identity' && newPollable) {
      const mib = {
        ...pollableAttributeInput?.mib,
        identity: value as string,
      };
      newPollable = {
        ...newPollable,
        mib,
      };
    } else if (newPollable) {
      newPollable = {
        ...newPollable,
        [prop]: value,
      };
    }
    dispatch(
      setNewPollableAttributesInput(newPollable as PollableAttributeInput),
    );
  };

  const formatDefaultToArray = (itemList: Attribute[]): ItemList[] => {
    return itemList.map((item) => {
      return {
        key: item.name,
        label: item.value,
        type: item.data_type,
      } as ItemList;
    });
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleChange('company_id', companyId);
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);
    PollableAttributes.create(pollableAttributeInput as PollableAttributeInput)
      .then((result) => {
        dispatch(
          setAlert({
            highlight: 'Pollable created successfully',
            type: 'success',
          }),
        );
        navigate(`/data-management/pollable-attribute/${result._id}`);
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.messages,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleAddRow = (prop: string): void => {
    switch (prop) {
      case 'defaults':
        dispatch(
          setNewPollableAttributesInput({
            ...pollableAttributeInput,
            defaults: {
              ...pollableAttributeInput?.defaults,
              '': '',
            } as unknown,
          } as PollableAttributeInput),
        );
        break;
      case 'attributes':
        const attbArray = pollableAttributeInput?.attributes || [];
        dispatch(
          setNewPollableAttributesInput({
            ...pollableAttributeInput,
            attributes: [
              ...(attbArray as Attribute[]),
              {
                data_type: 'snmp_mib_integer' as PollableAttributeDataType,
                name: '',
                value: '',
              } as Attribute,
            ] as Attribute[],
          } as PollableAttributeInput),
        );
        break;
    }
  };

  const handleRemoveRow = (prop: string, item: string): void => {
    switch (prop) {
      case 'defaults':
        const defaultsObj = { ...pollableAttributeInput?.defaults };
        delete defaultsObj[item];
        dispatch(
          setNewPollableAttributesInput({
            ...pollableAttributeInput,
            defaults: defaultsObj,
          } as PollableAttributeInput),
        );
        break;
    }
  };

  const handleRemoveArrayRow = (prop: string, index: number): void => {
    switch (prop) {
      case 'attributes':
        const defaultsArray = pollableAttributeInput?.attributes;

        defaultsArray?.splice(index, 1);
        dispatch(
          setNewPollableAttributesInput({
            ...pollableAttributeInput,
            attributes: defaultsArray,
          } as PollableAttributeInput),
        );
        break;
    }
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: string,
  ): void => {
    switch (prop) {
      case 'defaults':
        const tempDefaults = pollableAttributeInput?.defaults;
        const defaultsObj = { ...tempDefaults };
        const defaultsValue = tempDefaults ? tempDefaults[index] : '';
        if (field === 'name') {
          defaultsObj[index] = defaultsObj[value];
          defaultsObj[value] = defaultsValue;
          delete defaultsObj[index];
        } else {
          defaultsObj[index] = value;
        }
        dispatch(
          setNewPollableAttributesInput({
            ...pollableAttributeInput,
            defaults: defaultsObj,
          } as PollableAttributeInput),
        );
        break;
      case 'attributes':
        const attbArray = pollableAttributeInput?.attributes || [];
        if (field === 'name') {
          attbArray[Number(index)].name = String(value);
        } else if (field === 'value') {
          attbArray[Number(index)].value = String(value);
        } else {
          attbArray[Number(index)].data_type = String(
            value,
          ) as PollableAttributeDataType;
        }
        dispatch(
          setNewPollableAttributesInput({
            ...pollableAttributeInput,
            attributes: attbArray,
          } as PollableAttributeInput),
        );
        break;
    }
  };

  useEffect(() => {
    checkSubmitEnable();
  }, [pollableAttributeInput]);

  return (
    <Grid container direction="row" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="data-management#pollable-attributes"
          goBackLabel="Pollable attributes"
          isCreatePage={true}
          model="pollableAttribute"
        />
      </Grid>
      <Grid item xs={8}>
        <Grid container direction="column" spacing={3}>
          <Grid item xs={12}>
            <Paper className="shadow p-8">
              <div className={clsx('mb-6', classes.titleContainer)}>
                <Typography
                  data-cy="create-pollable-attribute-title"
                  variant="h5"
                  className={classes.title}
                >
                  Create a new Pollable Attribute
                </Typography>
              </div>

              <PollableForm
                onInputChange={handleChange}
                labelRequiredField={true}
                pollableAttributeInput={pollableAttributeInput || undefined}
              />

              <DynamicRows
                prop="defaults"
                objectRowsTypes={pollableAttributeInput?.defaults}
                propLabel="Defaults"
                itemKey="name"
                itemValue="value"
                buttonLabel="Defatuls"
                onInputChange={handleDynamicChange}
                onAddRow={handleAddRow}
                onRemoveRow={handleRemoveRow}
              />

              <DynamicRows
                prop="attributes"
                arrayRowsTypes={formatDefaultToArray(
                  pollableAttributeInput?.attributes || [],
                )}
                propLabel="Attributes"
                itemKey="name"
                itemValue="value"
                buttonLabel="Attributes"
                dropDownDefaultValue="choose_type"
                dropDownData={POLLABLE_DATA_TYPES}
                onInputChange={handleDynamicChange}
                onAddRow={handleAddRow}
                onRemoveArrayRow={handleRemoveArrayRow}
                dropdownKey={'data_type'}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Publish
          label="account"
          submitting={submitting}
          companyId={pollableAttributeInput?.company_id || ''}
          onChangeAccount={handleOnAccountChange}
          onSubmit={handlePublishSubmit}
          enableSubmit={enableSubmit}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper className="shadow p-8">
          <PollableAttributeMibForm isNew={true} onInputChange={handleChange} />
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className="shadow p-8">
          <PollableAttributeNotificationsForm isNew={true} />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default CreatePollable;
