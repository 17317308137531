import {
  Configuration,
  DeviceType,
  DeviceTransferRequest,
} from '@edgeiq/edgeiq-api-js';
import { TableColumn, TableItemType } from '../../components/SharedTable';

export const ConfigurationColumns = (
  deviceTypes?: DeviceType[],
): TableColumn[] => {
  return [
    {
      cellValue: (item: TableItemType): string => {
        const configuration = item as Configuration;
        return configuration.name;
      },
      id: 'name',
      isBold: (_item): boolean => true,
      label: 'Name',
      link: (item: TableItemType): string => {
        const configuration = item as Configuration;
        return `/configuration/${configuration._id}`;
      },
      type: 'link',
    },
    {
      cellValue: (item: TableItemType): string => {
        const deviceTypeItem = item as DeviceTransferRequest;
        const deviceType = deviceTypes?.find((type) => {
          return type._id === deviceTypeItem.device_type_id;
        });
        return deviceType?.name ?? item._id;
      },
      id: 'device_type_id',
      label: 'Device Type',
      type: 'text',
    },
  ];
};
