import React, { useEffect, useState, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  Devices,
  DeviceTypes,
  Ingestor,
  Translators,
} from '@edgeiq/edgeiq-api-js';

import TabsPage from '../../../components/TabsPage';
import { AttachIngestorsDrawer } from '../../../containers/RightDrawer';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setStateTranslators } from '../../../redux/reducers/translators.reducer';
import {
  setOriginalIngestors,
  setSelectedIngestors,
} from '../../../redux/reducers/ingestors.reducer';
import {
  errorHighlight,
  ingestorListenerTypesMap,
  ingestorHandlerTypesMap,
} from '../../../app/constants';

const DeviceIngestors: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const originalIngestors = useAppSelector(
    (state: RootState) => state.ingestors.originalIngestors,
  );
  const selectedIngestors = useAppSelector(
    (state: RootState) => state.ingestors.selectedIngestors,
  );
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const userCompanies = useAppSelector(
    (state: RootState) => state.user.userCompanies,
  );
  const translators = useAppSelector(
    (state: RootState) => state.translators.translators,
  );

  const columns: GridColDef[] = [
    {
      field: 'name',
      flex: 0.5,
      headerName: 'Name',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <strong>{params.row.name}</strong>
      ),
    },
    {
      field: 'company_id',
      flex: 0.5,
      headerName: 'Account',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            userCompanies.find(
              (company) => company._id === params.row.company_id,
            )?.name
          }
        </span>
      ),
    },
    {
      field: 'listener_type',
      flex: 0.3,
      headerName: 'Listener Type',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{ingestorListenerTypesMap[params.row.listener_type]}</span>
      ),
    },
    {
      field: 'handler_type',
      flex: 0.3,
      headerName: 'Handler Type',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{ingestorHandlerTypesMap[params.row.handler_type]}</span>
      ),
    },
    {
      field: 'translator_id',
      flex: 0.5,
      headerName: 'Translator',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>
          {
            translators.find(
              (translator) => translator._id === params.row.translator_id,
            )?.name
          }
        </span>
      ),
    },
    {
      field: 'attached_by_device_type',
      flex: 0.5,
      headerName: 'Attach via Device Profile',
      renderCell: (params: GridRenderCellParams): ReactElement => (
        <span>{params.row.is_from_device_type ? 'True' : 'False'}</span>
      ),
    },
  ];

  const handleOpenManageIngestorsDrawer = (): void => {
    setOpen(true);
  };

  const handleCloseManageIngestorsDrawer = (): void => {
    setOpen(false);
  };

  const handleCreateIngestor = (): void => {
    navigate('/data-management/new-ingestor');
  };

  useEffect(() => {
    if (!translators.length) {
      Translators.list().then((response) => {
        dispatch(setStateTranslators(response.translators));
      });
    }
  }, []);

  useEffect(() => {
    if (editableDevice) {
      setLoading(true);

      let integrators: Ingestor[] = [];
      Devices.getIngestors(editableDevice._id)
        .then((response) => {
          integrators = response;
          return DeviceTypes.getIngestors(editableDevice.device_type_id);
        })

        .then((resDeviceTypeIntegrators) => {
          const adaptedDeviceTypeRules = resDeviceTypeIntegrators.map(
            (deviceTypeRule) => {
              return { ...deviceTypeRule, is_from_device_type: true };
            },
          );
          integrators.push(...adaptedDeviceTypeRules);
          dispatch(setOriginalIngestors(integrators));
        })

        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDevice]);

  const handleChooseIngestors = (newIngestors: Ingestor[]): void => {
    dispatch(setSelectedIngestors(newIngestors));
    handleCloseManageIngestorsDrawer();
    setLoading(true);

    const attachIngestors = newIngestors.filter((newIngestor) =>
      originalIngestors.every(
        (originalIngestor) => newIngestor._id !== originalIngestor._id,
      ),
    );
    const detachIngestors = originalIngestors.filter((originalIngestor) =>
      newIngestors.every(
        (newIngestor) => originalIngestor._id !== newIngestor._id,
      ),
    );

    Promise.all([
      Promise.all(
        attachIngestors.map(async (attachIngestor) => {
          await Devices.attachIngestor(id as string, attachIngestor._id);
        }),
      ),
      Promise.all(
        detachIngestors.map(async (detachIngestor) => {
          await Devices.detachIngestor(id as string, detachIngestor._id);
        }),
      ),
    ])
      .then(() => {
        dispatch(setOriginalIngestors(newIngestors));
        dispatch(
          setAlert({
            highlight: 'Managing Ingestors',
            message: 'Ingestors successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch(() => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: 'Error while managing ingestors.',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedIngestors}
        addButtonLabel="Manage Ingestors"
        addButtonIcon={false}
        onAddClick={handleOpenManageIngestorsDrawer}
        onCreateClick={handleCreateIngestor}
        createButtonLabel="Create Ingestor"
        tableTitle="Ingestors attached"
        loading={loading}
      />

      <AttachIngestorsDrawer
        open={open}
        handleCloseDrawer={handleCloseManageIngestorsDrawer}
        itemIngestors={true}
        onChoosingIngestors={handleChooseIngestors}
      />
    </Box>
  );
};

export default DeviceIngestors;
