import React from 'react';
import { GatewayCommand } from '@edgeiq/edgeiq-api-js';

import SummaryCard from '../../components/SummaryCard';
import { commandExecutionColorThemeMap } from '../../app/constants';
import { convertCommandStatus } from '../../helpers/utils';
import UserAvatar from '../UserAvatar';

interface GatewayCommandExecutionInfoCardProps {
  gatewayCommand: GatewayCommand;
  actionCallback?: (action: GatewayCommand | undefined) => void;
  data: React.ReactElement;
}

const CommandExecutionCard: React.FC<GatewayCommandExecutionInfoCardProps> = ({
  gatewayCommand,
  actionCallback,
  data,
}) => {
  const handleClick = (): void => {
    if (actionCallback) {
      actionCallback(gatewayCommand);
    }
  };

  return (
    <>
      <SummaryCard
        actionBtnText="View details"
        badgeColor={
          gatewayCommand.device_unique_id
            ? commandExecutionColorThemeMap[
                convertCommandStatus(
                  gatewayCommand.statuses[gatewayCommand.device_unique_id]
                    .status,
                )
              ]
            : ''
        }
        badgeText={
          gatewayCommand.device_unique_id
            ? gatewayCommand.statuses[gatewayCommand.device_unique_id].status
            : ''
        }
        handleActionBtnClick={handleClick}
        footer={
          <UserAvatar
            userId={gatewayCommand.user_id}
            hideErrorToast={true}
            preAvatarText="Executed by:"
            preAvatarTextClass={'fw-700'}
            preAvatarTextVariant="button"
            showUserRole={false}
          />
        }
      >
        {data}
      </SummaryCard>
    </>
  );
};

export default CommandExecutionCard;
