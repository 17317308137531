import { genericSorting, SortingOption } from '../../models/common';

export const sortingOptions: SortingOption[] = [
  ...genericSorting,
  {
    label: 'ID (A-Z)',
    value: 'unique_id',
  },
  {
    label: '(A-Z)',
    value: 'name',
  },
];
//NOTE: Add 'map' to the array if enable map selection button again.
export const viewsOptions = ['list', 'grid'];
