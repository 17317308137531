import React, { useEffect } from 'react';
import { Grid, MenuItem, Typography } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import { ingestorListenerTypesMap } from '../../../app/constants';
import {
  verifySubmitEnable,
  checkListenerTypeDisabled,
} from '../../../containers/Forms/IngestorForm/helper';
import SelectInput from '../../../components/SelectInput';
import ListenerTypeDynamicSection from '../../../containers/Forms/IngestorForm/ListenerTypeDynamicSection/ListenerTypeDynamicSection';

interface NewIngestorListenerConfigProps {
  newIngestor: IngestorInput;
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  setEnableSubmit: (args: boolean) => void;
  addNewFilters: () => void;
  removeFilter: (index: number) => void;
  addDeviceTypeMapping: () => void;
  removeDeviceTypeMapping: (index: number) => void;
}

const NewIngestorListenerConfig: React.FC<NewIngestorListenerConfigProps> = ({
  newIngestor,
  onInputChange,
  setEnableSubmit,
  addNewFilters,
  removeFilter,
  addDeviceTypeMapping,
  removeDeviceTypeMapping,
}) => {
  useEffect(() => {
    setEnableSubmit(verifySubmitEnable(newIngestor));
  }, [newIngestor]);

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={12}>
        <Typography variant="caption" className="pb-4">
          Some options may be disabled due the ingestor type compatibility.
        </Typography>
        <SelectInput
          prop="listener_type"
          value={newIngestor.listener_type}
          onSelectChange={onInputChange}
          options={Object.keys(ingestorListenerTypesMap).map((key, index) => (
            <MenuItem
              disabled={checkListenerTypeDisabled(newIngestor.type, key)}
              className="m-4 p-2"
              key={index}
              dense
              value={key}
            >
              {ingestorListenerTypesMap[key]}
            </MenuItem>
          ))}
        />
      </Grid>
      <ListenerTypeDynamicSection
        newIngestor={newIngestor}
        onInputChange={onInputChange}
        addNewFilters={addNewFilters}
        removeFilter={removeFilter}
        addDeviceTypeMapping={addDeviceTypeMapping}
        removeDeviceTypeMapping={removeDeviceTypeMapping}
      />
    </Grid>
  );
};

export default NewIngestorListenerConfig;
