import React from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import { GatewayCommand } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { commandExecutionColorThemeMap } from '../../app/constants';
import ColoredBox from '../../components/ColoredBox';
import { convertCommandStatus } from '../../helpers/utils';
import DetailsDrawer from '../DetailsDrawer';
import timeHelpers from '../../helpers/timeHelpers';
import useStyles from './styles';

interface GatewayCommandExecutionInfoDrawer {
  open: boolean;
  gatewayCommand: GatewayCommand;
  handleCloseDrawer: () => void;

  title: string;
  subtitle1?: string;
  subtitle2?: string;
}
const CommandExecutionDrawer: React.FC<GatewayCommandExecutionInfoDrawer> = ({
  open,
  gatewayCommand,
  handleCloseDrawer,

  title,
  subtitle1,
  subtitle2,
}) => {
  const classes = useStyles();

  return (
    <DetailsDrawer
      open={open}
      handleCloseDrawer={handleCloseDrawer}
      title={title}
      subtitle1={subtitle1}
      subtitle2={subtitle2}
      date={`Date: ${
        gatewayCommand.created_at
          ? timeHelpers.getDate(gatewayCommand.created_at)
          : '--'
      }`}
      badgeColor={
        gatewayCommand.device_unique_id
          ? commandExecutionColorThemeMap[
              convertCommandStatus(
                gatewayCommand.statuses[gatewayCommand.device_unique_id].status,
              )
            ]
          : ''
      }
      badgeText={
        gatewayCommand.device_unique_id
          ? gatewayCommand.statuses[gatewayCommand.device_unique_id].status
          : ''
      }
      user={gatewayCommand.user_id}
    >
      <>
        {gatewayCommand.statuses &&
          Object.keys(gatewayCommand.statuses).map((item) => (
            <div key={item} className="w-100">
              <Divider className="my-2" />
              <Grid
                item
                xs={12}
                className={clsx('scrollbar', classes.executionInfoContainer)}
              >
                <Grid container spacing={2}>
                  <Grid item xs={10}>
                    <Typography variant="caption" color="white">
                      {item}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} display={'flex'} justifyContent={'end'}>
                    <ColoredBox
                      value={gatewayCommand.statuses[item].status}
                      colorTheme={
                        commandExecutionColorThemeMap[
                          convertCommandStatus(
                            gatewayCommand.statuses[item].status,
                          )
                        ]
                      }
                      type="command_execution_state"
                    />
                  </Grid>
                  {gatewayCommand.statuses[item].status_message && (
                    <Grid item xs={12}>
                      <pre className={classes.executionInfo}>
                        {gatewayCommand.statuses[item].status_message}
                      </pre>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          ))}
      </>
    </DetailsDrawer>
  );
};

export default CommandExecutionDrawer;
