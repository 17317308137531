import React, { ChangeEvent, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  Configuration,
  Configurations,
  DeviceTypes,
  PaginationFilter,
  Setting,
  Settings,
  SettingsApplicationRecord,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setStateConfigurations } from '../../redux/reducers/configuration.reducer';
import {
  setStateDeviceTypes,
  setOptionsDeviceTypes,
} from '../../redux/reducers/deviceTypes.reducer';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  defaultItemsPerPage,
  deleteHighlight,
  errorHighlight,
  genericViewOptions,
  optionsPaginationsFilter,
} from '../../app/constants';
import ActionDialog from '../../components/ActionDialog';
import { SortingOption, genericSorting } from '../../models/common';
import getInitialSorting from '../../helpers/getInitialSorting';
import parseFilters from '../../helpers/parseFilters';
import ListSelection from '../../components/ListSelection';
import SharedTable from '../../components/SharedTable';
import CardsGrid from '../../components/CardsGrid';
import Card from '../../components/Card';
import ConfigurationCard from './ConfigurationCard';
import Header from '../../containers/HeaderWithActionButton';
import { ConfigurationColumns } from './columns';
import ConfigurationFilters from './ConfigurationFilters';
import SettingApplicationList from '../../containers/SettingApplicationList';
import SettingManagementDrawer from '../../containers/RightDrawer/SettingManagementDrawer/SettingManagementDrawer';
import clsx from 'clsx';
import useStyles from './styles';
import CommandExecutionInfoDrawer from '../commandContent/commandExecutionList/CommandExecutionInfoDrawer';

const ConfigurationPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector((state: RootState) => state.filters);
  const classes = useStyles();
  const configurationState = useAppSelector(
    (state: RootState) => state.configurations,
  );

  const [selectedConfiguration, setSelectedConfiguration] = useState<string[]>(
    [],
  );
  const [configurations, setConfigurations] = useState<Configuration[]>(
    configurationState.configurations,
  );
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filters.configurations.sortBy, genericSorting),
  );
  const { deviceTypes } = useAppSelector(
    (state: RootState) => state.deviceTypes,
  );
  const [selectedView, setSelectedView] = useState(filters.configurations.view);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);
  const [selectedSettingApplication, setSelectedSettingApplication] =
    useState<SettingsApplicationRecord | null>();
  const [selectedSettingConfiguration, setSelectedSettingConfiguration] =
    useState<Setting | null>();
  const initializeDrawers = { command: false, setting: false };
  const [openDrawer, setOpenDrawer] = useState<{ [key: string]: boolean }>(
    initializeDrawers,
  );

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getConfiguration = (pageNumber: number, addPage = false): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };

    Configurations.list(
      parseFilters(filters.configurations.filters ?? {}),
      pagination,
    )
      .then((result) => {
        const newConfigurations = addPage
          ? [...configurations, ...result.configurations]
          : result.configurations;
        setConfigurations(newConfigurations);
        dispatch(setStateConfigurations(newConfigurations));
        setTotal(result.pagination.total);
        if (addPage) {
          setPage(page + 1);
        }
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoading(false);
        setLoadingMore(false);
      });
  };

  const getConfigurationById = (id: string): void => {
    Settings.getOneById(id)
      .then((response) => {
        setSelectedSettingConfiguration(response as Setting);
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      });
  };

  useEffect(() => {
    if (deviceTypes.length === 0) {
      DeviceTypes.list({}, optionsPaginationsFilter)
        .then((result) => {
          dispatch(setStateDeviceTypes(result.deviceTypes));
          dispatch(setOptionsDeviceTypes(result.deviceTypes));
        })
        .catch((error) => {
          dispatchError(error.message);
        });
    }
  }, []);

  useEffect(() => {
    whenComponetMounted(1);
  }, [filters.configurations]);

  const whenComponetMounted = (newPage: number): void => {
    setLoading(true);
    setSelectedConfiguration([]);
    getConfiguration(newPage);
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getConfiguration(page + 1, true);
  };

  const checkProfileCallback =
    (deviceId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedConfiguration([...selectedConfiguration, deviceId]);
      } else {
        setSelectedConfiguration(
          selectedConfiguration.filter((item) => item !== deviceId),
        );
      }
    };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'configurations'));
    setSelectedSorting(option);
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'configurations'));
    setSelectedView(view);
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  const handleTableSorting = (value: string): void => {
    console.info(value);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);

    Configurations.deleteMultiple(selectedConfiguration)
      .then((_result) => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(
              selectedConfiguration.length,
              'Configuration',
              'Configurations',
            ),
            type: 'success',
          }),
        );
        whenComponetMounted(page);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoadingDelete(false);
        closeDeleteModal();
      });
  };

  const onCloseSettingDrawer = (): void => {
    setSelectedSettingApplication(null);
    setOpenDrawer(initializeDrawers);
  };

  const handleSelectAll = (): void => {
    if (selectedConfiguration.length !== configurations.length) {
      setSelectedConfiguration(
        configurations.map((deviceType) => deviceType._id),
      );
    } else {
      setSelectedConfiguration([]);
    }
  };

  const settingSelected = (
    settingApplicationRecord: SettingsApplicationRecord,
    open: string,
  ): void => {
    setSelectedSettingApplication(
      settingApplicationRecord as SettingsApplicationRecord,
    );
    getConfigurationById(settingApplicationRecord.setting._id);
    setOpenDrawer((prev) => {
      prev[open] = true;
      return prev;
    });
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Configurations"
        link="new-configuration"
        actionLabel="Create New Configuration"
        model="configuration"
      />
      <ConfigurationFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={genericSorting}
        selectedLabel="configuration"
        viewsOptions={genericViewOptions}
        itemsSelected={selectedConfiguration.length !== 0}
        itemsSelectedCount={selectedConfiguration.length}
        allSelected={
          configurations.length !== 0 &&
          selectedConfiguration.length === configurations.length
        }
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        deleteCallback={openDeleteModal}
      />
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          <Grid container className="pr-8">
            <Grid item xs={9}>
              {selectedView === 'grid' && (
                <CardsGrid
                  cards={configurations.map((configurationItem) => (
                    <Card
                      key={configurationItem._id}
                      baseLink="/configuration"
                      id={configurationItem._id}
                      checkboxCallback={checkProfileCallback}
                      checked={selectedConfiguration.includes(
                        configurationItem._id,
                      )}
                      content={
                        <ConfigurationCard
                          configuration={configurationItem}
                          deviceTypes={deviceTypes}
                        />
                      }
                    />
                  ))}
                />
              )}
              {selectedView === 'list' && (
                <SharedTable
                  columns={ConfigurationColumns(deviceTypes)}
                  rows={configurations}
                  sortBy={selectedSorting.value}
                  sortDirection={
                    selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
                  }
                  allSelected={
                    selectedConfiguration.length === configurations.length
                  }
                  loading={loading}
                  selectedItemsIds={selectedConfiguration}
                  onRequestSort={handleTableSorting}
                  selectAllCallback={handleSelectAll}
                  checkboxCallback={checkProfileCallback}
                />
              )}
            </Grid>
            <Grid
              item
              xs={3}
              className={clsx('p-6 shadow br-1', classes.boxContainer)}
            >
              <SettingApplicationList settingSelected={settingSelected} />
              <CommandExecutionInfoDrawer
                open={openDrawer.command}
                handleCloseDrawer={onCloseSettingDrawer}
                commandExecution={selectedSettingApplication?.command_execution}
              />
              <SettingManagementDrawer
                selectedConfiguration={
                  selectedSettingApplication?.configuration as Configuration
                }
                setting={selectedSettingConfiguration as Setting}
                open={openDrawer.setting}
                onCloseDrawer={onCloseSettingDrawer}
              />
            </Grid>
          </Grid>
          {configurations.length !== total && (
            <Grid item xs={12} className="mb-9 loading-container">
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}
      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedConfiguration.length === 1
                ? 'configuration'
                : 'configurations'
            }:`}</span>
            <ul>
              {configurations
                .filter((configuration) =>
                  selectedConfiguration.includes(configuration._id),
                )
                .map((configuration) => (
                  <li key={configuration._id}>
                    {configuration.name} - Id: {configuration._id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default ConfigurationPage;
