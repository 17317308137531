import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect, useState, ChangeEvent } from 'react';
import { EIQFile, EIQFiles, PaginationFilter } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { RootState } from '../../redux/store';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { setAlert } from '../../redux/reducers/alert.reducer';
import getInitialSorting from '../../helpers/getInitialSorting';
import {
  setOptionsFiles,
  setStateFiles,
} from '../../redux/reducers/files.reducer';
import {
  setSorting,
  setViewOption,
} from '../../redux/reducers/filters.reducer';
import { usePageSetter } from '../../hooks/usePageSetter';
import { SortingOption } from '../../models/common';
import {
  defaultItemsPerPage,
  deleteHighlight,
  genericViewOptions,
  optionsPaginationsFilter,
} from '../../app/constants';
import parseFilters from '../../helpers/parseFilters';
import { dispatchError, downloadEIQFile } from '../../helpers/utils';
import Header from '../../containers/HeaderWithActionButton';
import ListSelection from '../../components/ListSelection';
import CardsGrid from '../../components/CardsGrid';
import SharedTable from '../../components/SharedTable';
import Card from '../../components/Card';
import ActionDialog from '../../components/ActionDialog';
import NewCompanyFileDrawer from './NewCompanyFileDrawer';
import CompanyFilesFilters from './CompanyFilesFilters';
import FileCard from './FileCard';
import { ConfigDeviceCols } from './columns';
import { sortingOptions } from './constants';

const FilesPage: React.FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const fileErrorDispatch = dispatchError(dispatch);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const fileState = useAppSelector((state: RootState) => state.files);
  const [fileList, setFilesList] = useState(fileState.files || []);
  const { files: filterFiles } = useAppSelector(
    (state: RootState) => state.filters,
  );
  const { setTotalAndPage, total, page, setTotal } = usePageSetter(0, 1);
  const [selectedView, setSelectedView] = useState(filterFiles.view);
  const [selectedSorting, setSelectedSorting] = useState<SortingOption>(
    getInitialSorting(filterFiles.sortBy, sortingOptions),
  );
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };
  const [ActionDialogOpen, setActionDialogOpen] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const [showFilesUploadDrawer, setShowFilesUploadDrawer] = useState(false);

  const handleCloseFilesDrawer = (): void => {
    setShowFilesUploadDrawer(false);
  };

  const getCompaniesFiles = async (
    pageNumber: number,
    addPage = false,
  ): Promise<void> => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      order_by: selectedSorting.value,
      page: pageNumber,
    };
    try {
      const companyFiles = await EIQFiles.list(
        parseFilters(filterFiles.filters ?? {}),
        pagination,
      );
      const mappedFiles = companyFiles.files.map((e) => ({ ...e, _id: e.id }));
      const filesListArr = addPage
        ? [...fileList, ...mappedFiles]
        : mappedFiles;

      setFilesList(filesListArr);
      dispatch(setStateFiles(filesListArr));
      updateOptionsFiles();
      setTotalAndPage(companyFiles.pagination.total, addPage);
    } catch (error: unknown) {
      const { message } = error as { message: string };
      fileErrorDispatch(message);
    }
    noLoading();
  };

  const updateOptionsFiles = async (): Promise<void> => {
    try {
      const result = await EIQFiles.list({}, optionsPaginationsFilter);
      dispatch(setOptionsFiles(result.files));
    } catch (error: unknown) {
      const { message } = error as { message: string };
      fileErrorDispatch(message);
    }
  };

  const handleSelectView = (view: string): void => {
    dispatch(setViewOption(view, 'files'));
    setSelectedView(view);
  };

  const handleSorting = (option: SortingOption): void => {
    dispatch(setSorting(option.value, 'files'));
    setSelectedSorting(option);
  };

  const handleSelectAll = (): void => {
    if (selectedFiles.length !== fileList.length) {
      setSelectedFiles(fileList.map((file) => file.id));
      return;
    }
    setSelectedFiles([]);
  };

  const checkFilesCallback =
    (fileId: string) =>
    (event: ChangeEvent<HTMLInputElement>): void => {
      if (event.target.checked) {
        setSelectedFiles([...selectedFiles, fileId]);
        return;
      }
      setSelectedFiles(selectedFiles.filter((item) => item !== fileId));
    };

  useEffect(() => {
    setLoading(true);
    setSelectedFiles([]);
    getCompaniesFiles(1);
  }, [filterFiles]);

  const renderedView = !loading ? selectedView : false;

  const handleUploadFile = (): void => {
    setShowFilesUploadDrawer(true);
  };

  const hanldeFileUploadSuccess = (): void => {
    getCompaniesFiles(1);
    handleCloseFilesDrawer();
  };

  const handleDownloadFile = (file: EIQFile) => (): void => {
    downloadEIQFile(file);
  };

  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getCompaniesFiles(page + 1, true);
  };

  const handleBulkDelete = (): void => {
    setLoadingDelete(true);
    EIQFiles.deleteMultiple(selectedFiles)
      .then(() => {
        dispatch(
          setAlert({
            highlight: deleteHighlight(selectedFiles.length, 'File', 'Files'),
            type: 'success',
          }),
        );
        setFilesList(
          fileList.filter((file) => !selectedFiles.includes(file.id)),
        );
        setSelectedFiles([]);
        setTotal(total - selectedFiles.length);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => {
        setLoadingDelete(false);
        closeDeleteModal();
      });
  };

  const openDeleteModal = (): void => {
    setActionDialogOpen(true);
  };

  const closeDeleteModal = (): void => {
    setActionDialogOpen(false);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Files"
        actionLabel="Upload File"
        model="file"
        actionCallback={handleUploadFile}
      />
      <CompanyFilesFilters total={total} />
      <ListSelection
        selectedSorting={selectedSorting}
        selectedView={selectedView}
        sortingOptions={sortingOptions}
        viewsOptions={genericViewOptions}
        itemsSelected={!!selectedFiles.length}
        allSelected={
          !!fileList.length && selectedFiles.length === fileList.length
        }
        sortingCallback={handleSorting}
        selectAllCallback={handleSelectAll}
        selectViewCallback={handleSelectView}
        selectedLabel="File"
        itemsSelectedCount={selectedFiles.length}
        deleteCallback={openDeleteModal}
      />
      {loading && (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      )}
      {renderedView === 'grid' && (
        <CardsGrid
          cards={fileList.map((file) => (
            <Card
              checked={selectedFiles.includes(file.id)}
              checkboxCallback={checkFilesCallback}
              id={file.id}
              content={
                <FileCard file={file} handleDownloadFile={handleDownloadFile} />
              }
            />
          ))}
        />
      )}

      {renderedView === 'list' && (
        <SharedTable
          columns={ConfigDeviceCols(handleDownloadFile)}
          rows={fileList}
          sortBy={selectedSorting.value}
          sortDirection={
            selectedSorting.value.indexOf('-') === -1 ? 'asc' : 'desc'
          }
          allSelected={selectedFiles.length === fileList.length}
          loading={loading}
          selectedItemsIds={selectedFiles}
          selectAllCallback={handleSelectAll}
          checkboxCallback={checkFilesCallback}
        />
      )}

      {fileList.length !== total && (
        <Grid item xs={12} className={clsx('mb-9 loading-container')}>
          <Button variant="outlined" size="large" onClick={handleLoadMore}>
            {!loadingMore ? (
              <Typography variant="button">Load more</Typography>
            ) : (
              <CircularProgress size={25} />
            )}
          </Button>
        </Grid>
      )}

      <NewCompanyFileDrawer
        openDrawer={showFilesUploadDrawer}
        handleCloseDrawer={handleCloseFilesDrawer}
        handleUploadSuccess={hanldeFileUploadSuccess}
      />

      <ActionDialog
        open={ActionDialogOpen}
        loading={loadingDelete}
        content={
          <>
            <span>{`You are about to delete this ${
              selectedFiles.length === 1 ? 'file' : 'files'
            }:`}</span>
            <ul>
              {fileList
                .filter((file) => selectedFiles.includes(file.id))
                .map((file) => (
                  <li key={file.id}>
                    {file.name} - Id: {file.id}
                  </li>
                ))}
            </ul>
          </>
        }
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleBulkDelete}
      />
    </Grid>
  );
};

export default FilesPage;
