import React, { useState, useEffect, ReactElement } from 'react';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DeviceType, DeviceTypes } from '@edgeiq/edgeiq-api-js';

import { errorHighlight } from '../../../app/constants';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setActualDeviceType } from '../../../redux/reducers/deviceTypes.reducer';

import TabsPage from '../../../components/TabsPage';
import MetadataDrawer, {
  MetadataListProps,
} from '../../../containers/RightDrawer/AttachMetadata/MetadataDrawer';

const columns: GridColDef[] = [
  {
    field: 'key',
    flex: 0.5,
    headerName: 'Key',
    renderCell: (params: GridRenderCellParams): ReactElement => (
      <strong>{params.row.key}</strong>
    ),
  },
  {
    field: 'value',
    flex: 0.5,
    headerName: 'Value',
  },
];

const DeviceProfileMetadata: React.FC = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [metadataList, setMetadataList] = useState<MetadataListProps[]>();
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );

  useEffect(() => {
    const auxArray = [];

    if (editableDeviceType?.metadata) {
      for (const [key, value] of Object.entries(editableDeviceType?.metadata)) {
        auxArray.push({ key, value });
      }
    }
    setMetadataList(auxArray);
  }, [editableDeviceType]);

  const handleOpenDrawer = (): void => {
    setOpen(true);
  };

  const handleCloseDrawer = (): void => {
    setOpen(false);
  };

  const handleAddMetadata = (metadata: MetadataListProps[]): void => {
    const metadataObj = metadata.length
      ? metadata.reduce(
          (prevMetadata, nextMetadata) => ({
            ...prevMetadata,
            [nextMetadata.key as string]: nextMetadata.value,
          }),
          { [metadata[0].key as string]: metadata[0].value },
        )
      : {};

    handleCloseDrawer();
    setLoading(true);

    DeviceTypes.update({
      ...editableDeviceType,
      metadata: metadataObj,
    } as DeviceType)
      .then((response) => {
        dispatch(setActualDeviceType(response));
        dispatch(
          setAlert({
            highlight: 'Metadata updated',
            message: 'Device profile metadata successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={metadataList}
        addButtonLabel="Update Metadata"
        addButtonIcon={false}
        onAddClick={handleOpenDrawer}
        tableTitle="Metadata"
        loading={loading}
      />
      {metadataList && (
        <MetadataDrawer
          open={open}
          metadata={metadataList}
          handleCloseDrawer={handleCloseDrawer}
          onMetadataAdd={handleAddMetadata}
        />
      )}
    </Box>
  );
};

export default DeviceProfileMetadata;
