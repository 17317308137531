import { Ingestor, IngestorInput, IngestorType } from '@edgeiq/edgeiq-api-js';

export const verifySubmitEnable = (
  newIntegration: Ingestor | IngestorInput,
): boolean => {
  if (!newIntegration) {
    return false;
  }
  switch (newIntegration.type) {
    case 'cloud':
      return newIntegration.translator_id !== '';
    case 'edge':
      return newIntegration.translator_id !== '';
  }
};

export const checkListenerTypeDisabled = (
  type: string,
  listenerType: string,
): boolean => {
  if (type === 'edge') {
    return (
      listenerType === 'cloud' ||
      listenerType === 'cloud_polling_inmarsat' ||
      listenerType === 'cloud_polling_orbcomm' ||
      listenerType === 'cloud_gcp_pubsub_jci' ||
      listenerType === 'cloud_polling_assetlink'
    );
  }
  return (
    listenerType === 'shell_polling' ||
    listenerType === 'icmp_polling' ||
    listenerType === 'snmp_polling' ||
    listenerType === 'tcp_server' ||
    listenerType === 'tcp_client' ||
    listenerType === 'udp_server' ||
    listenerType === 'udp_client' ||
    listenerType === 'http_server' ||
    listenerType === 'http_client' ||
    listenerType === 'dev' ||
    listenerType === 'tcp_modbus' ||
    listenerType === 'opcua' ||
    listenerType === 'bacnet' ||
    listenerType === 'dbus_signal'
  );
};

export const checkHandlerTypeDisabled = (
  type: string,
  listenerType: string,
  handlerType: string,
): boolean => {
  switch (handlerType) {
    case 'fixed':
    case 'delimited':
      return (
        type === 'cloud' ||
        listenerType === 'http_client' ||
        listenerType === 'http_server' ||
        listenerType === 'dbus_signal'
      );
    case 'router':
      return listenerType !== 'http_client' && listenerType !== 'http_server';
    case 'dbus':
      return listenerType !== 'dbus_signal';
    case 'passthrough':
      return (
        listenerType === 'http_client' ||
        listenerType === 'http_server' ||
        listenerType === 'dbus_signal'
      );
    default:
      return false;
  }
};

export const createNewIngestorObject = (
  prop: string,
  value: string | number | string[] | boolean,
  newIngestor: Ingestor | IngestorInput,
): Ingestor | IngestorInput => {
  const propsArray = prop.split('.');

  switch (propsArray[0]) {
    case 'listener':
      return {
        ...newIngestor,
        listener: { ...newIngestor.listener, [propsArray[1]]: value },
      };
    case 'params':
      return {
        ...newIngestor,
        listener: {
          ...newIngestor.listener,
          params: { ...newIngestor.listener.params, [propsArray[1]]: value },
        },
      };
    case 'filters':
      const auxArrayFilters = [...newIngestor.listener.filters];

      switch (propsArray[1]) {
        case 'path':
          auxArrayFilters[parseInt(propsArray[2])].path = value as string;
          return {
            ...newIngestor,
            listener: { ...newIngestor.listener, filters: auxArrayFilters },
          };
        case 'interface':
          auxArrayFilters[parseInt(propsArray[2])].interface = value as string;
          return {
            ...newIngestor,
            listener: { ...newIngestor.listener, filters: auxArrayFilters },
          };
        case 'member':
          auxArrayFilters[parseInt(propsArray[2])].member = value as string;
          return {
            ...newIngestor,
            listener: { ...newIngestor.listener, filters: auxArrayFilters },
          };
        default:
          return newIngestor;
      }
    case 'device_type_mapping':
      const auxArrayMapping = [...newIngestor.listener.device_type_mapping];

      switch (propsArray[1]) {
        case 'device_type_id':
          auxArrayMapping[parseInt(propsArray[2])].device_type_id =
            value as string;

          return {
            ...newIngestor,
            listener: {
              ...newIngestor.listener,
              device_type_mapping: auxArrayMapping,
            },
          };
        case 'key':
          auxArrayMapping[parseInt(propsArray[2])].key = value as string;

          return {
            ...newIngestor,
            listener: {
              ...newIngestor.listener,
              device_type_mapping: auxArrayMapping,
            },
          };
        case 'value':
          auxArrayMapping[parseInt(propsArray[2])].value = value as string;
          return {
            ...newIngestor,
            listener: {
              ...newIngestor.listener,
              device_type_mapping: auxArrayMapping,
            },
          };
        default:
          return newIngestor;
      }
    case 'tags':
      return {
        ...newIngestor,
        listener: { ...newIngestor.listener, oids: value as string[] },
      };
    case 'type':
      switch (value) {
        case 'cloud':
          return {
            ...newIngestor,
            handler_type: 'passthrough',
            listener_type: 'cloud',
            type: value as IngestorType,
          };
        case 'edge':
          return {
            ...newIngestor,
            handler_type: 'fixed',
            listener_type: 'shell_polling',
            type: value as IngestorType,
          };
        default:
          return newIngestor;
      }
    case 'handler':
      return {
        ...newIngestor,
        handler: { ...newIngestor.handler, [propsArray[1]]: value },
      };
    case 'routes':
      const auxArrayRoutes = [...newIngestor.handler.routes];

      switch (propsArray[1]) {
        case 'method':
          auxArrayRoutes[parseInt(propsArray[2])].methods = value as string[];
          return {
            ...newIngestor,
            handler: { ...newIngestor.handler, routes: auxArrayRoutes },
          };
        case 'methods':
          auxArrayRoutes[parseInt(propsArray[2])].methods = value as string[];
          return {
            ...newIngestor,
            handler: { ...newIngestor.handler, routes: auxArrayRoutes },
          };
        case 'path':
          auxArrayRoutes[parseInt(propsArray[2])].path = value as string;
          return {
            ...newIngestor,
            handler: { ...newIngestor.handler, routes: auxArrayRoutes },
          };
        default:
          return newIngestor;
      }
    default:
      return { ...newIngestor, [prop]: value };
  }
};

export const addRowsIngestorArrays = (
  prop: string,
  newIngestor: Ingestor | IngestorInput,
): Ingestor | IngestorInput => {
  switch (prop) {
    case 'device_type_mapping':
      let auxArrayMapping;

      if (Array.isArray(newIngestor.listener.device_type_mapping)) {
        auxArrayMapping = [
          ...newIngestor.listener.device_type_mapping,
          { device_type_id: '', key: '', value: '' },
        ];
      } else {
        auxArrayMapping = [{ device_type_id: '', key: '', value: '' }];
      }

      return {
        ...newIngestor,
        listener: {
          ...newIngestor.listener,
          device_type_mapping: auxArrayMapping,
        },
      };
    case 'filters':
      let auxArrayFilters;

      if (Array.isArray(newIngestor.listener.filters)) {
        auxArrayFilters = [
          ...newIngestor.listener.filters,
          {
            interface: '',
            member: '',
            path: '',
          },
        ];
      } else {
        auxArrayFilters = [
          {
            interface: '',
            member: '',
            path: '',
          },
        ];
      }

      return {
        ...newIngestor,
        listener: { ...newIngestor.listener, filters: auxArrayFilters },
      };
    case 'routes':
      let auxArrayRoutes;

      if (Array.isArray(newIngestor.handler.routes)) {
        auxArrayRoutes = [
          ...newIngestor.handler.routes,
          { methods: ['get'], path: '' },
        ];
      } else {
        auxArrayRoutes = [{ methods: ['get'], path: '' }];
      }

      return {
        ...newIngestor,
        handler: { ...newIngestor.handler, routes: auxArrayRoutes },
      };
    default:
      return newIngestor;
  }
};

export const removeRowsIngestorArray = (
  prop: string,
  index: number,
  newIngestor: Ingestor | IngestorInput,
): Ingestor | IngestorInput => {
  switch (prop) {
    case 'device_type_mapping':
      const auxArrayMapping = [...newIngestor.listener.device_type_mapping];
      auxArrayMapping.splice(index, 1);

      return {
        ...newIngestor,
        listener: {
          ...newIngestor.listener,
          device_type_mapping: auxArrayMapping,
        },
      };
    case 'filters':
      const auxArrayFilters = [...newIngestor.listener.filters];
      auxArrayFilters.splice(index, 1);

      return {
        ...newIngestor,
        listener: { ...newIngestor.listener, filters: auxArrayFilters },
      };
    case 'routes':
      const auxArrayRoutes = [...newIngestor.handler.routes];
      auxArrayRoutes.splice(index, 1);

      return {
        ...newIngestor,
        handler: { ...newIngestor.handler, routes: auxArrayRoutes },
      };
    default:
      return newIngestor;
  }
};
