import React from 'react';
import { IntegrationInput } from '@edgeiq/edgeiq-api-js';
import { Grid } from '@mui/material';
import TextInput from '../../../components/TextInput';

interface SmtpIntegrationSectionProps {
  newIntegration: IntegrationInput;
  onInputChange: (prop: string, value: string | number) => void;
}

const SmtpIntegrationSection: React.FC<SmtpIntegrationSectionProps> = ({
  newIntegration,
  onInputChange,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Server"
          prop="server"
          value={newIntegration.server}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          label="Username"
          prop="username"
          value={newIntegration.username}
          onInputChange={onInputChange}
        />
      </Grid>
      <Grid item xs={6} className="mt-6">
        <TextInput
          type="password"
          label="Password"
          prop="password"
          value={newIntegration.password}
          onInputChange={onInputChange}
        />
      </Grid>
    </>
  );
};

export default SmtpIntegrationSection;
