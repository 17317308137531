import { CompanyInput } from '@edgeiq/edgeiq-api-js';

export const EMPTY_COMPANY: CompanyInput = {
  _id: '',
  company_id: '',
  name: '',
  portal_configuration: {
    navigation: {
      action_hub_hidden: false,
      alerts_hidden: false,
      companies_hidden: false,
      configs_hidden: false,
      devices_hidden: false,
      ha_groups_hidden: false,
      ingest_hidden: false,
      integrations_hidden: false,
      lifecycle_hidden: false,
      messages_hidden: false,
      onboarding_hidden: false,
      overview_hidden: false,
      pollable_attributes_hidden: false,
      reports_hidden: false,
      roles_hidden: false,
      rules_hidden: false,
      secrets_hidden: false,
      software_hidden: false,
      transfers_hidden: false,
      users_hidden: false,
    },
    reports_options: {
      time_filter_options: [
        {
          display: '',
          value: '',
        },
      ],
    },
    widgets: {
      data_usage_hidden: false,
      software_updates_hidden: false,
    },
  },
  ui_configuration: {
    navigation_visibility: {
      automation_hidden: false,
      configuration_hidden: false,
      dashboard_hidden: false,
      data_management_hidden: false,
      devices_hidden: false,
      messages_hidden: false,
      software_hidden: false,
    },
  },
  view_abilities: {
    companies: false,
    customers: false,
    dashboard: false,
    devices: false,
    errors: false,
    firmware: false,
    gateways: false,
    integrations: false,
    notifications: false,
    reports: false,
    roles: false,
  },
};
