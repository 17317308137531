import React, { useEffect, useState } from 'react';
import { Typography, Grid, Button, CircularProgress, Box } from '@mui/material';
import clsx from 'clsx';

import {
  DeviceError,
  DeviceErrors,
  PaginationFilter,
} from '@edgeiq/edgeiq-api-js';

import {
  activityColorThemeMap,
  defaultItemsPerPage,
  errorHighlight,
} from '../../../../../app/constants';

import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { setAlert } from '../../../../../redux/reducers/alert.reducer';
import { RootState } from '../../../../../redux/store';

import TimelineCard from '../../../../../components/TimelineCard';
import { RightDrawer } from '../../../../../containers/RightDrawer';

const DeviceErrorsActivity: React.FC = ({}) => {
  const dispatch = useAppDispatch();
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );

  const [errors, setErrors] = useState<DeviceError[]>([]);
  const [loadingMore, setLoadingMore] = useState(false);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [selectedError, setSelectedError] = useState<DeviceError>();
  const [infoDrawer, setInfoDrawer] = useState(false);

  const handleOpenDrawer = (r: DeviceError | undefined = undefined): void => {
    setInfoDrawer(!infoDrawer);
    if (r) {
      setSelectedError(r);
    }
  };

  useEffect(() => {
    setLoading(true);
    getReports(1, false);
  }, []);

  const getReports = (pageNumber: number, addPage: boolean): void => {
    const pagination: PaginationFilter = {
      itemsPerPage: defaultItemsPerPage,
      page: pageNumber,
    };

    DeviceErrors.list(
      {
        device_id: {
          key: 'device_id',
          operator: 'eq',
          value: editableDevice?._id as string,
        },
      },
      pagination,
    )
      .then((res) => {
        const newReports = addPage
          ? [...errors, ...res.resources]
          : res.resources;

        setErrors(newReports);
        setTotalAndPage(res.pagination.total, addPage);
      })
      .catch((error) => {
        dispatchError(error.message);
      })
      .finally(() => noLoading());
  };

  const setTotalAndPage = (newTotal: number, addPage = false): void => {
    setTotal(newTotal);
    if (addPage) {
      setPage(page + 1);
    }
  };

  const noLoading = (): void => {
    setLoading(false);
    setLoadingMore(false);
  };
  const handleLoadMore = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    setLoadingMore(true);
    getReports(page + 1, true);
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const openActivityDrawer = (activity: DeviceError) => (): void => {
    handleOpenDrawer(activity);
  };

  return (
    <>
      {loading ? (
        <Grid container className="loading-container">
          <CircularProgress size={75} thickness={5} />
        </Grid>
      ) : (
        <>
          {errors.map((activity, index) => (
            <TimelineCard
              key={index}
              title={activity.error}
              tag={activity.type}
              tagTheme={activityColorThemeMap}
              caption={activity.created_at}
              extraLabel="View Details"
              type={activity.type}
              titleWithToolTip={true}
              extraCallback={openActivityDrawer(activity)}
            />
          ))}
          {errors.length !== total && (
            <Grid item xs={12} className={clsx('mb-9 loading-container')}>
              <Button variant="outlined" size="large" onClick={handleLoadMore}>
                {!loadingMore ? (
                  <Typography variant="button">Load more</Typography>
                ) : (
                  <CircularProgress size={25} />
                )}
              </Button>
            </Grid>
          )}
        </>
      )}

      <RightDrawer
        open={infoDrawer}
        actionLabel="Done"
        title={'Error Details'}
        disableAction={false}
        actionCallback={handleOpenDrawer}
        onCloseDrawer={handleOpenDrawer}
        content={
          <Box>
            <div>
              <Typography variant="h5" className="custom-label">
                Error
              </Typography>
              <Typography variant="subtitle2" className="custom-label">
                {selectedError?.error}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Level
              </Typography>
              <Typography variant="subtitle2" className="custom-label">
                {selectedError?.level}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Date
              </Typography>
              <Typography variant="subtitle2" className="custom-label">
                {selectedError?.created_at}
              </Typography>
            </div>

            <div className="mt-4">
              <Typography variant="h5" className="custom-label">
                Rule Action Count
              </Typography>
              <Typography variant="subtitle2" className="custom-label">
                {selectedError?.rule_action_count}
              </Typography>
            </div>
          </Box>
        }
      />
    </>
  );
};

export default DeviceErrorsActivity;
