import React, { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { SEARCH_LETTERS_QUANTITY } from '../../../app/constants';
import useStyles from './styles';
import LoadMore from '../../../components/Loader';

interface AttachItemsLayoutProps {
  searchPlaceholder?: string;
  itemsSelected: boolean;
  allSelected: boolean;
  grid: JSX.Element;
  hasItems: boolean;
  noItemsLabel?: string;
  selectAllCallback: () => void;
  onChangeCallback?: (...args: [string]) => void;
  hideSelectAll?: boolean;
  disableDelay?: boolean;
  showLoadMore?: boolean;
  loadMoreHanlder?: () => Promise<void>;
}

const AttachItemsLayout: React.FC<AttachItemsLayoutProps> = ({
  searchPlaceholder,
  itemsSelected,
  allSelected,
  grid,
  hasItems,
  noItemsLabel = 'No items found to attach.',
  selectAllCallback,
  onChangeCallback,
  hideSelectAll = false,
  disableDelay = false,
  showLoadMore = false,
  loadMoreHanlder,
}) => {
  const classes = useStyles({ hideSelectAll });
  const [inputValue, setInputValue] = useState('');

  const handleCallbackProp = (value: string): void => {
    if (onChangeCallback) {
      onChangeCallback(value);
    }
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleCallbackProp, 1000),
    [],
  );

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = event.target.value;
    setInputValue(value);
    if (!disableDelay) {
      debouncedChangeHandler.cancel();

      if (value.length >= SEARCH_LETTERS_QUANTITY || value.length === 0) {
        debouncedChangeHandler(value);
      }
      return;
    }
    handleCallbackProp(value);
  };

  const handleClickClear = (): void => {
    setInputValue('');
    handleCallbackProp('');
  };

  const handleMouseDown = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
  };

  return (
    <>
      {searchPlaceholder && (
        <div className="mb-2">
          <FormControl variant="outlined" fullWidth>
            <OutlinedInput
              name="inputValue"
              type="text"
              data-cy="attach-items-search-input"
              className={classes.searchInput}
              placeholder={searchPlaceholder}
              value={inputValue}
              onChange={handleInputChange}
              endAdornment={
                <InputAdornment data-cy="clear-name-filter" position="end">
                  {inputValue && (
                    <IconButton
                      aria-label="clear-name-filter"
                      onClick={handleClickClear}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            />
            <FormHelperText id="attach-items-search-helper">
              Type in at least {SEARCH_LETTERS_QUANTITY} characters to filter
            </FormHelperText>
          </FormControl>
        </div>
      )}
      {!hideSelectAll && hasItems && (
        <div className="mb-2">
          <FormControlLabel
            data-cy="attach-items-select-all"
            control={
              <Checkbox
                checked={allSelected}
                indeterminate={itemsSelected && !allSelected}
                onClick={selectAllCallback}
              />
            }
            label={allSelected ? 'Deselect All' : 'Select All'}
          />
        </div>
      )}
      {!hasItems && <Typography variant="button">{noItemsLabel}</Typography>}
      <Grid container className={clsx('scrollbar pb-2', classes.content)}>
        {grid}
        {showLoadMore && loadMoreHanlder && (
          <LoadMore onLoadMore={loadMoreHanlder} />
        )}
      </Grid>
    </>
  );
};

export default AttachItemsLayout;
