import React, { useState } from 'react';
import { Button, Typography } from '@mui/material';
import Badge from '@mui/material/Badge';
import clsx from 'clsx';

import useStyles from './styles';

export interface SideTabsProps {
  tabs: {
    [key: string]: JSX.Element;
  };
  badge?: {
    [key: string]: number;
  };
  defaultTab: string;
  extraClasses?: string;
  labelMap?: {
    [key: string]: string;
  };
}

const SideTabs: React.FC<SideTabsProps> = ({
  badge,
  tabs,
  defaultTab,
  extraClasses,
  labelMap,
}) => {
  const classes = useStyles();
  const [chosenTab, setChosenTab] = useState<string>(defaultTab);

  const handleChooseConfig =
    (tab: string) =>
    (_event: React.MouseEvent<HTMLButtonElement>): void => {
      setChosenTab(tab);
    };

  const renderLabel = (label: string): string => {
    return (
      labelMap?.[label] ||
      (label.includes('_') ? label.replace('_', ' ') : label)
    );
  };

  return (
    <div className={clsx(classes.container)}>
      <div className={clsx('pr-8', classes.optionsContainer)}>
        {Object.keys(tabs).map((label, index) =>
          badge ? (
            <Badge key={index} badgeContent={badge[label]} color="primary">
              <Button
                key={index}
                variant="text"
                disableElevation
                onClick={handleChooseConfig(label)}
                className={clsx('p-2 mb-6', extraClasses, classes.button, {
                  [classes.buttonChosen]: chosenTab === label,
                })}
              >
                <Typography variant="caption" className={classes.buttonText}>
                  {renderLabel(label)}
                </Typography>
              </Button>
            </Badge>
          ) : (
            <Button
              key={index}
              variant="text"
              disableElevation
              onClick={handleChooseConfig(label)}
              className={clsx('p-2 mb-6', extraClasses, classes.button, {
                [classes.buttonChosen]: chosenTab === label,
              })}
            >
              <Typography variant="caption" className={classes.buttonText}>
                {renderLabel(label)}
              </Typography>
            </Button>
          ),
        )}
      </div>
      <div className={clsx('pl-8', classes.contentContainer)}>
        {tabs[chosenTab]}
      </div>
    </div>
  );
};

export default SideTabs;
