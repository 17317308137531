import { ConfigurableSettingsInput } from '@edgeiq/edgeiq-api-js';

export const EMPTY_CONFIGURABLE_SETTINGS: ConfigurableSettingsInput = {
  default: '',
  key: '',
  list_items: [
    {
      label: '',
      value: '',
    },
  ],
  required: false,
  type: 'string',
};
