import React from 'react';
import { Grid, MenuItem } from '@mui/material';
import { RuleAction } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../../components/TextInput';
import SelectInput from '../../../../../components/SelectInput';
import { BODY_TYPE_OPTIONS } from '../../../../../constants/policies';

interface EmailSectionProps {
  action: RuleAction;
  actionIndex: number;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const EmailSection: React.FC<EmailSectionProps> = ({
  action,
  actionIndex,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Email Subject"
          placeholder=""
          prop={`${actionIndex}.subject`}
          value={action.subject}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Sender email address"
          placeholder=""
          prop={`${actionIndex}.from`}
          value={action.from}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12}>
        <SelectInput
          label="Body Type"
          value={action.body_type}
          prop={`${actionIndex}.body_type`}
          onSelectChange={onInputChange}
          options={Object.keys(BODY_TYPE_OPTIONS).map((key) => (
            <MenuItem className="m-4 p-2" key={key} dense value={key}>
              {BODY_TYPE_OPTIONS[key]}
            </MenuItem>
          ))}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Receipient email addresses (comma separated)"
          placeholder=""
          prop={`${actionIndex}.send_to`}
          value={action.send_to}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>
    </>
  );
};

export default EmailSection;
