import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.grey[200],
    },
    chosenButton: {
      backgroundColor: theme.palette.grey.A100,
      color: theme.palette.primary.main,
    },
    drawerDeviceContainer: {
      alignItems: 'center',
      backgroundColor: theme.palette.grey.A100,
      display: 'flex',
      justifyContent: 'space-between',
    },
    drawerSelectedDevices: {
      maxHeight: '150px',
      overflow: 'auto',
    },
    errorButton: {
      // backgroundColor: theme.palette.error.light,
      color: theme.palette.error.dark,
    },
    heartbeatInput: {
      width: '100px',
    },
    infoIcon: {
      color: theme.palette.grey[200],
    },
    input: {
      width: '350px',
    },
    link: {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      fontWeight: 700,
    },
    publishContainer: {
      height: '100%',
    },
    titleContainer: {
      alignItems: 'center',
      display: 'flex',
    },
  }),
);

export default useStyles;
