import React, { ReactElement } from 'react';
import { IngestorInput, ListenerType } from '@edgeiq/edgeiq-api-js';
import ShellPollingSection from './ShellPollingSection';
import IcmpPollingSection from './IcmpPollingSection';
import SnmpPollingSection from './SnmpPollingSection';
import SingleAddressSection from './SingleAddressSection';
import HttpServerSection from './HttpServerSection';
import HttpClientSection from './HttpClientSection';
import LinuxDevSection from './LinuxDevSection';
import TcpModbusSection from './TcpModbusSection';
import OpcuaSection from './OpcuaSection';
import BacnetSection from './BacnetSection';
import DbusSignalSection from './DbusSignalSection';
import CloudPollingSection from './CloudPollingSection';
import GcpCloudSection from './GcpCloudSection';

interface ListenerTypeDynamicSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  disabled?: boolean;
  addNewFilters: () => void;
  removeFilter: (index: number) => void;
  addDeviceTypeMapping: () => void;
  removeDeviceTypeMapping: (index: number) => void;
}

const ListenerTypeDynamicSection: React.FC<ListenerTypeDynamicSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
  addNewFilters,
  removeFilter,
  addDeviceTypeMapping,
  removeDeviceTypeMapping,
}) => {
  const renderSection = (type: ListenerType): ReactElement => {
    switch (type) {
      case 'shell_polling':
        return (
          <ShellPollingSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'icmp_polling':
        return (
          <IcmpPollingSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'snmp_polling':
        return (
          <SnmpPollingSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'tcp_server':
        return (
          <SingleAddressSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'tcp_client':
        return (
          <SingleAddressSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'udp_server':
        return (
          <SingleAddressSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'udp_client':
        return (
          <SingleAddressSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'http_server':
        return (
          <HttpServerSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'http_client':
        return (
          <HttpClientSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'dev':
        return (
          <LinuxDevSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'tcp_modbus':
        return (
          <TcpModbusSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'opcua':
        return (
          <OpcuaSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'bacnet':
        return (
          <BacnetSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'dbus_signal':
        return (
          <DbusSignalSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
            addNewFilters={addNewFilters}
            removeFilter={removeFilter}
          />
        );
      case 'cloud_polling_inmarsat':
        return (
          <CloudPollingSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'cloud_polling_orbcomm':
        return (
          <CloudPollingSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      case 'cloud_gcp_pubsub_jci':
        return (
          <GcpCloudSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
            addDeviceTypeMapping={addDeviceTypeMapping}
            removeDeviceTypeMapping={removeDeviceTypeMapping}
          />
        );
      case 'cloud_polling_assetlink':
        return (
          <CloudPollingSection
            newIngestor={newIngestor}
            onInputChange={onInputChange}
            disabled={disabled}
          />
        );
      default:
        return <></>;
    }
  };
  return renderSection(newIngestor.listener_type);
};

export default ListenerTypeDynamicSection;
