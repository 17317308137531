import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import ContentCopy from '@mui/icons-material/ContentCopy';
import { DeviceType, SoftwareUpdate } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';
import useStyles from './styles';

interface SoftwarePackageCardProps {
  softwareUpdate: SoftwareUpdate;
  deviceType?: DeviceType;
}
type SoftwareUpdateWithType = SoftwareUpdate & { type: string };

const copyTextToClipboard = (text: string) => (): void => {
  navigator.clipboard.writeText(text);
};
const SoftwarePackageCard: React.FC<SoftwarePackageCardProps> = ({
  softwareUpdate,
  deviceType,
}) => {
  const classes = useStyles();
  return (
    <Box>
      <Tooltip placement="top" title={softwareUpdate.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-1', classes.title)}
        >
          {softwareUpdate.name}
        </Typography>
      </Tooltip>

      <Typography
        variant="overline"
        className={clsx('mb-2', classes.highlight)}
      >
        {(softwareUpdate as SoftwareUpdateWithType).type}
      </Typography>

      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', classes.tag)}
      >
        Reboot after installation:{' '}
        <Typography
          variant="overline"
          component="div"
          className={clsx(classes.subtitle)}
        >
          {softwareUpdate.reboot ? 'YES' : 'NO'}
        </Typography>
      </Typography>
      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-2', classes.tag)}
      >
        Files Attached:{' '}
        <Typography
          variant="overline"
          component="div"
          className={clsx(classes.subtitle)}
        >
          {softwareUpdate.files?.length ?? 0}
        </Typography>
      </Typography>

      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', classes.tag)}
      >
        <DevicesIcon fontSize="small" className="mr-2" />
        {deviceType?.name}
      </Typography>
      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', classes.tag)}
      >
        <FiberSmartRecordIcon fontSize="small" className="mr-2" />
        {softwareUpdate.company_id}
      </Typography>

      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1 br-1 p-2', classes.scriptContainer)}
        height={40}
        noWrap
      >
        {`${softwareUpdate.script}`}
        <ContentCopy
          fontSize="small"
          className={clsx(classes.copyIcon, 'ml-2')}
          onClick={copyTextToClipboard(softwareUpdate?.script ?? '')}
        />
      </Typography>
    </Box>
  );
};

export default SoftwarePackageCard;
