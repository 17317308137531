import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import {
  PollableAttribute,
  PollableAttributes,
  PollableAttributesFilters,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setStatePollableAttributes } from '../../../redux/reducers/pollableAttributes.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
} from '../../../app/constants';
import CardsGrid from '../../../components/CardsGrid';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';
import RightDrawer from '../RightDrawer';
import AttachPollableAttributeCard from './PollableAttributeCard';

interface PollableAttributesDrawerProps {
  open: boolean;
  itemPollableAttributes: boolean;
  handleCloseDrawer: () => void;
  onChoosingPollableAttributes: (
    pollableAttributes: PollableAttribute[],
  ) => void;
}

const PollableAttributesDrawer: React.FC<PollableAttributesDrawerProps> = ({
  open,
  itemPollableAttributes,
  handleCloseDrawer,
  onChoosingPollableAttributes,
}) => {
  const dispatch = useAppDispatch();
  const statePollableAttributes = useAppSelector(
    (state: RootState) => state.pollableAttributes.pollableAttributes,
  );
  const stateSelectedPollableAttributes = useAppSelector(
    (state: RootState) => state.pollableAttributes.selectedPollableAttributes,
  );

  const [selectedPollableAttributes, setSelectedPollableAttributes] = useState<
    string[]
  >([]);
  const [loading, setLoading] = useState(false);

  const getPollableAttributes = (searchName?: string): void => {
    setLoading(true);
    const filters: PollableAttributesFilters = {};
    if (searchName) {
      filters.name = {
        operator: 'like',
        value: searchName,
      };
    }

    PollableAttributes.list(filters, optionsPaginationsFilter)
      .then((result) => {
        dispatch(setStatePollableAttributes(result.pollableAttributes));
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (statePollableAttributes.length === 0) {
      getPollableAttributes();
    }
  }, []);

  useEffect(() => {
    if (
      itemPollableAttributes &&
      stateSelectedPollableAttributes.length !== 0
    ) {
      setSelectedPollableAttributes(
        stateSelectedPollableAttributes.map(
          (pollableAttribute) => pollableAttribute._id,
        ),
      );
    }
  }, [stateSelectedPollableAttributes]);

  const handleOnChangeCallback = (value: string): void => {
    getPollableAttributes(value);
  };

  const checkPollableAttributeCallback =
    (pollableAttributeId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      if (checked) {
        setSelectedPollableAttributes([
          ...selectedPollableAttributes,
          pollableAttributeId,
        ]);
      } else {
        setSelectedPollableAttributes(
          selectedPollableAttributes.filter(
            (item) => item !== pollableAttributeId,
          ),
        );
      }
    };

  const handlePollableAttributesCallback = (): void => {
    onChoosingPollableAttributes(
      statePollableAttributes.filter((pollableAttribute) =>
        selectedPollableAttributes.includes(pollableAttribute._id),
      ),
    );
  };

  const handleSelectAll = (): void => {
    if (selectedPollableAttributes.length !== statePollableAttributes.length) {
      setSelectedPollableAttributes(
        statePollableAttributes.map(
          (pollableAttribute) => pollableAttribute._id,
        ),
      );
    } else {
      setSelectedPollableAttributes([]);
    }
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Update"
      title="Select Pollable Attributes"
      disableAction={selectedPollableAttributes.length === 0}
      actionCallback={handlePollableAttributesCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <AttachItemsLayout
          allSelected={
            selectedPollableAttributes.length === statePollableAttributes.length
          }
          itemsSelected={selectedPollableAttributes.length !== 0}
          hasItems={statePollableAttributes.length !== 0}
          searchPlaceholder="Search pollable attributes"
          onChangeCallback={handleOnChangeCallback}
          selectAllCallback={handleSelectAll}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <CardsGrid
                twoColumns={true}
                containerPadding={false}
                cards={statePollableAttributes.map((pollableAttribute) => (
                  <AttachItemCard
                    content={
                      <AttachPollableAttributeCard
                        key={pollableAttribute._id}
                        pollableAttribute={pollableAttribute}
                      />
                    }
                    checked={selectedPollableAttributes.includes(
                      pollableAttribute._id,
                    )}
                    checkboxCallback={checkPollableAttributeCallback}
                    id={pollableAttribute._id}
                  />
                ))}
              />
            )
          }
        />
      }
    />
  );
};

export default PollableAttributesDrawer;
