import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  Device,
  DeviceType,
  ScheduledJobInput,
  ScheduledJobs,
} from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../app/constants';
import Header from '../../components/Header';
import useStyles from './styles';
import Publish from '../../containers/Publish';
import {
  cleanNewScheduledJob,
  setNewScheduledJobInput,
} from '../../redux/reducers/scheduledJobs.reducer';
import ScheduledJobsForm from '../../containers/Forms/ScheduledJobsForm/ScheduledJobsForm';
import SideTabs from '../../components/SideTabs';
import EntitiesSection from '../../containers/EntitiesSection';

const CreateScheduledJobPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const scheduledJobInput = useAppSelector(
    (state: RootState) => state.scheduledJobs.newScheduledJobInput,
  );

  const [enableSubmit, setEnableSubmit] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [invalidSenderJson, setInvalidSenderJson] = useState(false);
  const [selectedDevices, setSelectedDevices] = useState<Device[]>([]);
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceType[]>(
    [],
  );
  const checkSubmitEnable = (): void => {
    setEnableSubmit(
      scheduledJobInput?.job_name !== '' &&
        scheduledJobInput?.schedule !== '' &&
        scheduledJobInput?.job_type !== '' &&
        scheduledJobInput?.command_id !== '' &&
        !invalidSenderJson,
    );
  };

  useEffect(() => {
    checkSubmitEnable();
  }, [scheduledJobInput, invalidSenderJson]);

  const handleValueChange = (
    prop: string,
    value: string | number | string[] | boolean,
  ): void => {
    switch (prop) {
      case 'options':
        try {
          const type =
            scheduledJobInput?.job_type === 'user-defined-command'
              ? 'command_options'
              : 'workflow_options';
          const formattedJson = JSON.parse(value as string);
          dispatch(
            setNewScheduledJobInput({
              ...(scheduledJobInput as ScheduledJobInput),
              details: {
                [type]: formattedJson,
              },
            }),
          );
          // JSON.parse doesn't count an array as an error, so this is one more control to make sure it is shown as invalid
          if (Array.isArray(formattedJson)) {
            setInvalidJsonValue(prop, true);
          } else {
            setInvalidJsonValue(prop, false);
          }
        } catch (e) {
          if (!value) {
            setInvalidJsonValue(prop, false);
            return;
          }
          setInvalidJsonValue(prop, true);
        }
        break;
      default:
        dispatch(
          setNewScheduledJobInput({
            ...(scheduledJobInput as ScheduledJobInput),
            [prop]: value,
          }),
        );
        break;
    }
  };

  const setInvalidJsonValue = (prop: string, value: boolean): void => {
    switch (prop) {
      case 'options':
        setInvalidSenderJson(value);
        break;
    }
  };

  const handlePublishSubmit = (): void => {
    setSubmitting(true);

    ScheduledJobs.create(scheduledJobInput as ScheduledJobInput)
      .then(async (response) => {
        const jobId = response._id;

        const deviceIds = selectedDevices.map(
          (attachDevice) => attachDevice._id,
        );
        const deviceTypeIds = selectedDeviceTypes.map(
          (attachDeviceTypes) => attachDeviceTypes._id,
        );

        if (deviceIds.length > 0) {
          await ScheduledJobs.bulkAttachDevices(jobId, deviceIds);
        }
        if (deviceTypeIds.length > 0) {
          await ScheduledJobs.bulkAttachDeviceTypes(jobId, deviceTypeIds);
        }
      })
      .then((_response) => {
        dispatch(
          setAlert({
            highlight: 'Scheduled job created successfully',
            type: 'success',
          }),
        );
        dispatch(cleanNewScheduledJob());
        navigate('/scheduled-jobs');
      })
      .catch((error) => {
        const message = error.message || error.messages?.[0] || '';
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleOnAccountChange = (companyId: string): void => {
    handleValueChange('company_id', companyId);
  };

  const handleChangeDevices = (devices: Device[]): void => {
    setSelectedDevices(devices);
  };

  const handleChangeDeviceTypes = (deviceTypes: DeviceType[]): void => {
    setSelectedDeviceTypes(deviceTypes);
  };

  return (
    <Grid container direction="column" spacing={3} className="p-9">
      <Grid item xs={12}>
        <Header
          goBack="scheduled-jobs"
          goBackLabel="Scheduled jobs"
          isCreatePage={true}
        />
      </Grid>

      <Grid container direction="row" className="mt-2" spacing={2}>
        <Grid item xs={8}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={12}>
              <Paper className="shadow p-8">
                <Box className={clsx('mb-6', classes.titleContainer)}>
                  <Typography
                    data-cy="create-command-title"
                    variant="h5"
                    className={classes.title}
                  >
                    Create a new job
                  </Typography>
                </Box>
                <ScheduledJobsForm
                  invalidOptionJson={invalidSenderJson}
                  newScheduledJob={scheduledJobInput as ScheduledJobInput}
                  onInputChange={handleValueChange}
                />
              </Paper>
            </Grid>
            <Grid item xs={12}>
              {scheduledJobInput && (
                <Paper className="shadow p-8">
                  <Typography
                    variant="h5"
                    className={clsx('mb-6', classes.title)}
                  >
                    Devices
                  </Typography>
                  <SideTabs
                    tabs={{
                      relations: (
                        <EntitiesSection
                          newInput={scheduledJobInput}
                          selectedDevices={selectedDevices}
                          selectedDeviceTypes={selectedDeviceTypes}
                          onChangeDevices={handleChangeDevices}
                          onChangeDeviceTypes={handleChangeDeviceTypes}
                        />
                      ),
                    }}
                    defaultTab="relations"
                  />
                </Paper>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Publish
            label="account"
            submitting={submitting}
            companyId={scheduledJobInput?.company_id as string}
            onChangeAccount={handleOnAccountChange}
            onSubmit={handlePublishSubmit}
            enableSubmit={enableSubmit && !submitting}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreateScheduledJobPage;
