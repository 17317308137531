import React from 'react';
import {
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { RuleCondition } from '@edgeiq/edgeiq-api-js';

import SelectInput from '../../../../components/SelectInput';
import TextInput from '../../../../components/TextInput';
import SwitchButton from '../../../../components/SwitchButton';
import {
  CONTAINS_CONDITION,
  MOVING_AVERAGE,
  policyConditionTypeValueLabel,
  policyPropertyEvaluations,
  VALUE_CHANGED,
} from '../../../../constants/policies';
import useStyles from '../styles';
import { isMultipleValuesCondition } from '../helper';

interface PropertyEvaluationSectionProps {
  ruleCondition: RuleCondition;
  onInputChange: (
    prop: string,
    value: string | number | string[] | boolean,
  ) => void;
  disabled?: boolean;
  onAddNewValue: (conditionIndex?: number) => void;
  onRemoveValue: (index: number, conditionIndex?: number) => void;
  onRemoveCondition?: (conditionIndex: number) => void;
  conditionIndex?: number;
}

const PropertyEvaluationSection: React.FC<PropertyEvaluationSectionProps> = ({
  ruleCondition,
  onInputChange,
  disabled,
  onAddNewValue,
  onRemoveValue,
  conditionIndex,
  onRemoveCondition,
}) => {
  const classes = useStyles();
  const multipleValues = isMultipleValuesCondition(ruleCondition.type);
  const hasColdStart =
    ruleCondition.type === MOVING_AVERAGE ||
    ruleCondition.type === VALUE_CHANGED;

  const handleClickAddValue = () => (): void => {
    onAddNewValue(conditionIndex);
  };

  const handleClickRemoveValue = (index: number) => (): void => {
    onRemoveValue(index, conditionIndex);
  };

  const handleClickRemoveCondition = () => (): void => {
    if (onRemoveCondition) {
      onRemoveCondition(conditionIndex ?? 0);
    }
  };

  const hasIndex = typeof conditionIndex !== 'undefined';

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={hasIndex ? 6 : 12} className="mt-2 mb-4">
        <Typography variant="h5">
          Condition {hasIndex ? conditionIndex + 1 : ''}
        </Typography>
      </Grid>
      {/* There has to be at least one condition so it can't be deleted.
      If conditionIndex is undefined that's when we shouldn't show the delete button.
      If there's only one condition then conditionIndex is undefined */}
      {hasIndex && (
        <Grid item xs={6} className="mt-2 d-flex flex-justify-end">
          <IconButton
            onClick={handleClickRemoveCondition()}
            disabled={disabled}
            className={classes.errorButton}
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item xs={6} className="mb-3">
        <TextInput
          label="Property"
          placeholder=""
          prop={`property.${conditionIndex}`}
          value={ruleCondition.property}
          onInputChange={onInputChange}
          helperText={
            ruleCondition.type === CONTAINS_CONDITION
              ? 'Property should be an array of values in the report for this condition to work properly.'
              : ''
          }
        />
      </Grid>
      <Grid item xs={6} className="mb-3">
        <SelectInput
          label="Type"
          prop={`type.${conditionIndex}`}
          value={ruleCondition.type}
          onSelectChange={onInputChange}
          disabled={disabled}
          options={Object.keys(policyPropertyEvaluations).map((key, index) => (
            <MenuItem className="m-4 p-2" key={index} dense value={key}>
              {policyPropertyEvaluations[key]}
            </MenuItem>
          ))}
        />
      </Grid>
      {ruleCondition.type === MOVING_AVERAGE && (
        <>
          <Grid item xs={6}>
            <SelectInput
              label="By"
              prop={`value_type.${conditionIndex}`}
              value={ruleCondition.value_type}
              onSelectChange={onInputChange}
              disabled={disabled}
              options={Object.keys(policyConditionTypeValueLabel).map(
                (key, index) => (
                  <MenuItem className="m-4 p-2" key={index} dense value={key}>
                    {policyConditionTypeValueLabel[key]}
                  </MenuItem>
                ),
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              label="Value"
              prop={`value.${conditionIndex}`}
              value={ruleCondition.value ? (ruleCondition.value as number) : ''}
              type="number"
              onInputChange={onInputChange}
            />
          </Grid>
        </>
      )}
      {Array.isArray(ruleCondition.value) ? (
        ruleCondition.value.map((value, index) => (
          <Grid item xs={4} key={index}>
            <TextInput
              label="Value"
              prop={`value.${index}.${conditionIndex}`}
              value={value}
              onInputChange={onInputChange}
              endAdornment={
                (Array.isArray(ruleCondition.value) &&
                  ruleCondition.value.length > 1 && (
                    <IconButton onClick={handleClickRemoveValue(index)}>
                      <DeleteIcon />
                    </IconButton>
                  )) || <></>
              }
            />
          </Grid>
        ))
      ) : !hasColdStart ? (
        <Grid item xs={4}>
          <TextInput
            label="Value"
            prop={
              typeof conditionIndex !== 'undefined'
                ? `value.${conditionIndex}`
                : `value`
            }
            value={ruleCondition.value}
            onInputChange={onInputChange}
          />
        </Grid>
      ) : (
        <></>
      )}
      {multipleValues && (
        <Grid item xs={4} className="mt-2 d-flex flex-items-center">
          <Button
            variant="outlined"
            size="large"
            className="mt-4"
            onClick={handleClickAddValue()}
            startIcon={<AddIcon />}
            disabled={disabled}
          >
            Add New Value
          </Button>
        </Grid>
      )}
      {hasColdStart && (
        <Grid item xs={12}>
          <SwitchButton
            label="Cold Start Trigger"
            prop={`cold_start_trigger.${conditionIndex}`}
            onSwitchChange={onInputChange}
            value={ruleCondition.cold_start_trigger}
          />
        </Grid>
      )}
      <Grid item xs={12} className="mt-4">
        <Divider />
      </Grid>
    </Grid>
  );
};

export default PropertyEvaluationSection;
