import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import { Grid, Button, Box, Paper, Typography } from '@mui/material';
import { Integrations, Integration } from '@edgeiq/edgeiq-api-js';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import {
  getIntegrationSelector,
  setActualIntegration,
  setNewIntegration,
} from '../../redux/reducers/integrations.reducer';
import { setAlert } from '../../redux/reducers/alert.reducer';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import IntegrationForm from '../../containers/Forms/IntegrationForm/IntegrationForm';
import FooterBar from '../../components/FooterBar';
import ActionDialog from '../../components/ActionDialog';
import { errorHighlight } from '../../app/constants';
import IntegrationEvents from './integrationEvents/IntegrationEvents';
import useStyles from './styles';

const IntegrationContent: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [enableSubmit, setEnableSubmit] = useState(false);
  const [dynamicSubmit, setDynamicSubmit] = useState(false);
  const { id } = useParams<string>();

  const integrationData = useAppSelector((state: RootState) =>
    getIntegrationSelector(state.integrations, id),
  );
  const deviceCompany = useAppSelector(
    (state: RootState) => state.user.userCompany,
  );
  const newIntegration = useAppSelector(
    (state: RootState) => state.integrations.newIntegration,
  );

  const [deleteIntegrationModalOpen, setDeleteIntegrationModalOpen] =
    useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (integrationData) {
      dispatch(setActualIntegration(integrationData));
    } else if (id) {
      Integrations.getOneById(id)
        .then((response) => {
          dispatch(setActualIntegration(response));
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [integrationData]);

  const handleDeleteIntegration = (): void => {
    if (!integrationData) {
      return;
    }
    setLoading(true);

    Integrations.delete(integrationData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete integration',
            message: 'Integration successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/integrations');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    if (newIntegration !== integrationData) {
      setLoading(true);
      Integrations.update(newIntegration as Integration)
        .then((response) => {
          dispatch(setActualIntegration(response));
          dispatch(
            setAlert({
              highlight: 'Update integration',
              message: 'Integration successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const closeDeleteModal = (): void => {
    setDeleteIntegrationModalOpen(false);
  };

  const handleInputChange = (prop: string, value: string | number): void => {
    dispatch(
      setNewIntegration({
        ...newIntegration,
        [prop]: value,
      } as Integration),
    );
  };

  const checkSubmitEnable = (): void => {
    if (!newIntegration) {
      return;
    }
    setEnableSubmit(newIntegration.name !== '');
  };

  useEffect(() => {
    checkSubmitEnable();
  }, [newIntegration?.name]);

  const handleEnableSubmit = (args: boolean): void => {
    setDynamicSubmit(args);
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Integration content"
        goBack="integrations"
        model="integration"
        goBackLabel="Integrations"
      />
      {integrationData && (
        <Box className="content-page-container">
          <ContentHeader
            contentType="integration"
            title={integrationData.name}
            subtitle={integrationData._id}
            integrationType={integrationData?.readable_type as string}
            extraImage={deviceCompany?.branding?.logo_url}
            extraTitle={deviceCompany?.name}
            extraSubtitle={deviceCompany?._id}
            copySubtitleToClipboard
          />

          <Grid container className={clsx('m-8')}>
            <Grid component={Paper} item xs={8} className="p-7 shadow">
              {newIntegration && (
                <IntegrationForm
                  newIntegration={newIntegration}
                  onInputChange={handleInputChange}
                  disabled={
                    integrationData?.type === 'aws_device_integrations' ||
                    integrationData?.type === 'azure_device_integrations'
                  }
                  setEnableSubmit={handleEnableSubmit}
                />
              )}
            </Grid>
            <IntegrationEvents integrationId={integrationData._id} />
          </Grid>
        </Box>
      )}

      <FooterBar
        isCustom={true}
        initialElements={
          <Button
            data-cy="footer-delete-button"
            color="error"
            variant="outlined"
            size="large"
            startIcon={<DeleteIcon />}
            onClick={(): void => setDeleteIntegrationModalOpen(true)}
            className={classes.errorButton}
          >
            Delete
          </Button>
        }
        endElements={
          <>
            {(integrationData?.type === 'aws_device_integrations' ||
              integrationData?.type === 'azure_device_integrations') && (
              <Typography className={classes.warningText}>
                Integrations of this type cannot be edited.
              </Typography>
            )}
            <LoadingButton
              data-cy="footer-save-button"
              variant="contained"
              size="large"
              startIcon={<SaveIcon />}
              onClick={handleSaveChanges}
              disabled={
                newIntegration === integrationData ||
                !enableSubmit ||
                !dynamicSubmit
              }
              loading={loading}
            >
              Save changes
            </LoadingButton>
          </>
        }
      />

      <ActionDialog
        open={deleteIntegrationModalOpen}
        loading={loading}
        title="Are you sure?"
        content="You are about to delete this integration"
        onCloseCallback={closeDeleteModal}
        onDeleteCallback={handleDeleteIntegration}
      />
    </Grid>
  );
};

export default IntegrationContent;
