import React, { ChangeEvent, useEffect, useState } from 'react';
import { CircularProgress, Grid } from '@mui/material';
import { Ingestor, Ingestors, IngestorsFilters } from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { setStateIngestors } from '../../../redux/reducers/ingestors.reducer';
import {
  errorHighlight,
  optionsPaginationsFilter,
  warningHighlight,
} from '../../../app/constants';
import CardsGrid from '../../../components/CardsGrid';
import AttachItemsLayout from '../AttachItems/AttachItemsLayout';
import AttachItemCard from '../AttachItems/AttachItemCard';
import RightDrawer from '../RightDrawer';
import AttachIngestorsCard from './AttachIngestorsCard';

interface AttachIngestorsDrawerProps {
  open: boolean;
  itemIngestors: boolean;
  handleCloseDrawer: () => void;
  onChoosingIngestors: (ingestors: Ingestor[]) => void;
}

const AttachIngestorsDrawer: React.FC<AttachIngestorsDrawerProps> = ({
  open,
  itemIngestors,
  handleCloseDrawer,
  onChoosingIngestors,
}) => {
  const dispatch = useAppDispatch();
  const stateIngestors = useAppSelector(
    (state: RootState) => state.ingestors.ingestors,
  );
  const originalIngestors = useAppSelector(
    (state: RootState) => state.ingestors.originalIngestors,
  );
  const stateSelectedIngestors = useAppSelector(
    (state: RootState) => state.ingestors.selectedIngestors,
  );

  const [selectedIngestors, setSelectedIngestors] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const getIngestors = (searchName?: string): void => {
    setLoading(true);
    const filters: IngestorsFilters = {};
    if (searchName) {
      filters.name = {
        operator: 'like',
        value: searchName,
      };
    }

    Ingestors.list(filters, optionsPaginationsFilter)
      .then((result) => {
        dispatch(setStateIngestors(result.ingestors));
      })
      .catch((error) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: error.message,
            type: 'error',
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (stateIngestors.length === 0) {
      getIngestors();
    }
  }, []);

  useEffect(() => {
    if (itemIngestors && stateSelectedIngestors.length !== 0) {
      setSelectedIngestors(
        stateSelectedIngestors.map((ingestor) => ingestor._id),
      );
    }
  }, [stateSelectedIngestors]);

  const handleOnChangeCallback = (value: string): void => {
    getIngestors(value);
  };

  const checkIngestorCallback =
    (ingestorId: string) =>
    (_event: ChangeEvent<HTMLInputElement>, checked: boolean): void => {
      const fromDeviceType = originalIngestors.find(
        (ingestors) =>
          ingestors._id === ingestorId && ingestors.is_from_device_type,
      );

      if (fromDeviceType) {
        return dispatch(
          setAlert({
            highlight: warningHighlight,
            message:
              "You can't remove ingestor that belongs to the Device Profile.",
            type: 'warning',
          }),
        );
      }

      if (checked) {
        setSelectedIngestors([...selectedIngestors, ingestorId]);
      } else {
        setSelectedIngestors(
          selectedIngestors.filter((item) => item !== ingestorId),
        );
      }
    };

  const handleIngestorsCallback = (): void => {
    onChoosingIngestors(
      stateIngestors.filter((ingestor) =>
        selectedIngestors.includes(ingestor._id),
      ),
    );
  };

  const handleSelectAll = (): void => {
    if (selectedIngestors.length !== stateIngestors.length) {
      setSelectedIngestors(stateIngestors.map((ingestor) => ingestor._id));
    } else {
      setSelectedIngestors([]);
    }
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Update"
      title="Select Ingestors"
      disableAction={selectedIngestors.length === 0}
      actionCallback={handleIngestorsCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <AttachItemsLayout
          allSelected={selectedIngestors.length === stateIngestors.length}
          itemsSelected={selectedIngestors.length !== 0}
          hasItems={stateIngestors.length !== 0}
          searchPlaceholder="Search ingestors"
          onChangeCallback={handleOnChangeCallback}
          selectAllCallback={handleSelectAll}
          grid={
            loading ? (
              <Grid container className="loading-container">
                <CircularProgress size={75} thickness={5} />
              </Grid>
            ) : (
              <CardsGrid
                twoColumns={true}
                containerPadding={false}
                cards={stateIngestors.map((ingestor) => (
                  <AttachItemCard
                    content={
                      <AttachIngestorsCard
                        key={ingestor._id}
                        ingestor={ingestor}
                      />
                    }
                    checked={selectedIngestors.includes(ingestor._id)}
                    checkboxCallback={checkIngestorCallback}
                    id={ingestor._id}
                  />
                ))}
              />
            )
          }
        />
      }
    />
  );
};

export default AttachIngestorsDrawer;
