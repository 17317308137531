import { Companies, Company } from '@edgeiq/edgeiq-api-js';
import { useEffect, useState } from 'react';

export const useFetchCompany = (
  id: string | undefined,
  cb: (errorMessage: string) => void,
): [Company | undefined] => {
  const [company, setCompany] = useState<Company | undefined>();
  useEffect(() => {
    const getCompany = async (): Promise<void> => {
      if (id) {
        try {
          const profileCompany = await Companies.getOneById(id);
          setCompany(profileCompany);
        } catch (error) {
          const { message: errorMessage } = error as { message: string };
          cb(errorMessage);
        }
      }
    };
    getCompany();
  }, []);

  return [company] as [Company | undefined];
};
