import React, { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { CommandExecution } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import UserAvatar from '../../../containers/UserAvatar';
import ColoredBox from '../../../components/ColoredBox';
import { commandExecutionColorThemeMap } from '../../../app/constants';
import timeHelpers from '../../../helpers/timeHelpers';
import {
  getCommandExecutionStatus,
  sortCommandExecutionStatuses,
} from '../../../helpers/commandExecution';
import useStyles from './styles';

interface CommandExecutionCardProps {
  commandExecution: CommandExecution;
  actionCallback?: (action: CommandExecution | undefined) => void;
}

const CommandExecutionCard: React.FC<CommandExecutionCardProps> = ({
  commandExecution,
  actionCallback,
}) => {
  const classes = useStyles();
  const [executionStatus, setExecutionStatus] = useState<string>('');

  useEffect(() => {
    const statuses = sortCommandExecutionStatuses(commandExecution);
    setExecutionStatus(getCommandExecutionStatus(statuses));
  }, []);

  const handleClick = (): void => {
    if (actionCallback) {
      actionCallback(commandExecution);
    }
  };

  return (
    <Grid container direction="row">
      <Box className="mb-6">
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
          {executionStatus && (
            <ColoredBox
              colorTheme={commandExecutionColorThemeMap[executionStatus]}
              type="command_execution_state"
              value={executionStatus}
              className="mb-2"
            />
          )}

          <UserAvatar
            userId={commandExecution.user_id}
            hideErrorToast={true}
            preAvatarText="Executed by:"
            preAvatarTextClass={classes.title}
            preAvatarTextVariant="button"
          />

          <Typography
            variant="overline"
            component="div"
            className={clsx(classes.subtitle, 'mb-1')}
          >
            {timeHelpers.getDate(commandExecution.created_at, false, true)}
          </Typography>
        </div>
      </Box>
    </Grid>
  );
};

export default CommandExecutionCard;
