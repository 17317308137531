import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

import RightDrawer from '../RightDrawer';

import { BulkJob } from '../../../models/common';
import { DateTime } from 'luxon';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WarningIcon from '@mui/icons-material/Warning';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { formatReadableActionName } from '../../../helpers/utils';

interface BulkJobInformationDrawerProps {
  open: boolean;
  handleCloseDrawer: () => void;
  selectedBulkJob: BulkJob | undefined;
}

const BulkJobInformationDrawer: React.FC<BulkJobInformationDrawerProps> = ({
  open,
  handleCloseDrawer,
  selectedBulkJob,
}) => {
  if (!selectedBulkJob) {
    return <></>;
  }

  const render_summary = (): JSX.Element => {
    let title = 'Pending';
    let icon = <AutorenewIcon color="primary" />;
    if (selectedBulkJob.response.bulk_response_statuses != null) {
      const totalJobs = selectedBulkJob.response.bulk_response_statuses.length;
      const fail_count = +selectedBulkJob.failures;
      const success_count = +selectedBulkJob.successes;
      if (fail_count + success_count == totalJobs) {
        if (fail_count === 0) {
          title = 'Completed with No Failures';
          icon = <CheckCircleIcon color="success" />;
        } else if (fail_count > 0 && success_count > 0) {
          title = 'Completed with Some Failures';
          icon = <WarningIcon color="warning" />;
        } else if (fail_count > 0 && success_count === 0) {
          title = 'Completed with No Successes';
          icon = <ErrorIcon color="error" />;
        }
      }
    }
    return (
      <div className="d-flex flex-items-center">
        <Tooltip title={title}>{icon}</Tooltip>
        <span style={{ marginLeft: '6px' }}>{title}</span>
      </div>
    );
  };

  const render_failures = (): JSX.Element => {
    if (
      selectedBulkJob.response.bulk_response_statuses == null ||
      !selectedBulkJob.response.bulk_response_statuses ||
      selectedBulkJob.response.bulk_response_statuses.length === 0
    ) {
      return <></>;
    }
    const errors = selectedBulkJob.response.bulk_response_statuses
      .filter((value) => value && value.status === 'fail')
      .map((value) => ({
        device: value?.device_id,
        error: value?.error,
        messages: value?.messages,
      }));
    if (errors.length > 0) {
      return (
        <div className={`mt-5 `}>
          <Typography variant="h5" className="custom-label">
            Failures
          </Typography>
          <div className={''}>
            {errors.map((error, index) => (
              <div key={`${error.device}_${index}`}>
                <div className={`d-flex mt-2`}>
                  <ErrorIcon color="error" />
                  <span className="ml-2">
                    An error occurred for item {error.device}: {error.error}
                  </span>
                </div>
                {error.messages && error.messages.length > 0 ? (
                  <div className={``}>
                    <span className={``}>Messages</span>
                    {error.messages.map((message, index_m) => (
                      <div key={`${error.device}_error_${index_m}`}>
                        {message}
                      </div>
                    ))}
                  </div>
                ) : (
                  ''
                )}
              </div>
            ))}
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Done"
      title="Bulk Job Information"
      disableAction={false}
      actionCallback={handleCloseDrawer}
      onCloseDrawer={handleCloseDrawer}
      content={
        <Box>
          <div>
            <Typography variant="h5" className="custom-label">
              Date
            </Typography>
            <Typography variant="subtitle2" className="custom-label">
              {`${DateTime.fromISO(selectedBulkJob.datetime).toFormat(
                'TT',
              )} ${DateTime.fromISO(selectedBulkJob.datetime).toLocaleString(
                DateTime.DATE_SHORT,
              )}`}
            </Typography>
          </div>

          <div className="mt-5">
            <Typography variant="h5" className="custom-label">
              Status
            </Typography>

            <Typography variant="subtitle2" className="custom-label">
              {render_summary()}
            </Typography>
          </div>

          <div className="mt-5">
            <Typography variant="h5" className="custom-label">
              Action
            </Typography>

            <Typography variant="subtitle2" className="custom-label">
              {formatReadableActionName(selectedBulkJob.response.type)}
            </Typography>
          </div>

          {render_failures()}
        </Box>
      }
    />
  );
};

export default BulkJobInformationDrawer;
