import React from 'react';
import { Grid } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import SwitchButton from '../../../../components/SwitchButton';
import TextInput from '../../../../components/TextInput';

interface DelimitedSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const DelimitedSection: React.FC<DelimitedSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={6} className="mt-2">
        <TextInput
          label="Delimited"
          prop="handler.delimited"
          placeholder=""
          value={newIngestor.handler.delimited}
          onInputChange={onInputChange}
          disabled={disabled}
        />
      </Grid>

      <Grid item xs={6} className="mt-2">
        <SwitchButton
          label="Strip Delimited"
          onSwitchChange={onInputChange}
          prop="handler.strip_delimited"
          value={newIngestor.handler.strip_delimited}
        />
      </Grid>
    </>
  );
};

export default DelimitedSection;
