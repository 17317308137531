import { Configuration, DeviceType } from '@edgeiq/edgeiq-api-js';
import DevicesIcon from '@mui/icons-material/Devices';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';

import useStyles from './styles';

interface ConfigurationCardProps {
  configuration: Configuration;
  deviceTypes?: DeviceType[];
}

const ConfigurationCard: React.FC<ConfigurationCardProps> = ({
  configuration,
  deviceTypes,
}) => {
  const { name } = configuration;
  const classes = useStyles();

  const deviceTypeItem = deviceTypes?.find(
    (item) => item._id === configuration.device_type_id,
  );

  return (
    <Box className={clsx(classes.cardContainer)}>
      <Typography
        variant="h6"
        component="div"
        noWrap
        className={clsx('mb-1', classes.name)}
      >
        {name}
      </Typography>
      <Typography
        variant="button"
        component="div"
        className={clsx('mb-4', classes.subtitle)}
      >
        {`${
          configuration.configurable_settings
            ? configuration.configurable_settings.length
            : '0'
        } CONFIGURABLE SETTINGS`}
      </Typography>
      {deviceTypeItem && (
        <Typography
          component="div"
          variant="overline"
          className={clsx('mt-4', classes.account)}
        >
          <DevicesIcon className={clsx('mr-2')} />
          {deviceTypeItem.name}
        </Typography>
      )}
    </Box>
  );
};

export default ConfigurationCard;
