import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { HomeMax } from '@mui/icons-material';
import { ScheduledJob } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import useStyles from './styles';
import { getCommandSelector } from '../../redux/reducers/commands.reducer';
import FiberSmartRecordIcon from '@mui/icons-material/FiberSmartRecord';
import timeHelpers from '../../helpers/timeHelpers';

interface ScheduledJobCardProps {
  scheduledJob: ScheduledJob;
}

const ScheduledJobCard: React.FC<ScheduledJobCardProps> = ({
  scheduledJob,
}) => {
  const classes = useStyles();

  const commandData = useAppSelector((state: RootState) =>
    getCommandSelector(state.commands, scheduledJob.command_id),
  );

  return (
    <Box>
      <Tooltip placement="top" title={scheduledJob.job_name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-2', classes.title)}
        >
          {scheduledJob.job_name}
        </Typography>
      </Tooltip>
      <Tooltip placement="top" title="Type">
        <Typography
          variant="overline"
          component="div"
          className={clsx('mb-1', classes.tag)}
        >
          {scheduledJob.job_type}
        </Typography>
      </Tooltip>
      {commandData && (
        <Tooltip placement="top" title="Type">
          <Typography
            variant="overline"
            component="div"
            className={clsx('mb-1', classes.tag)}
          >
            <HomeMax fontSize="medium" className="mr-2" />
            {commandData.name}
          </Typography>
        </Tooltip>
      )}

      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', classes.tag)}
      >
        Last run: {timeHelpers.getDate(scheduledJob.last_run_time)}
      </Typography>

      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', classes.tag)}
      >
        Next run:{timeHelpers.getDate(scheduledJob.next_run_time)}
      </Typography>

      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', classes.tag)}
      >
        <FiberSmartRecordIcon fontSize="small" className="mr-2" />
        {scheduledJob.enabled ? 'Active' : 'Inactive'}
      </Typography>
    </Box>
  );
};

export default ScheduledJobCard;
