import React, { useState, useEffect } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import TextInput from '../../../components/TextInput';
import {
  ConfigurableSettingsInput,
  Command,
  Commands,
  Setting,
  SettingInput,
} from '@edgeiq/edgeiq-api-js';
import { useAppDispatch } from '../../../redux/hooks';
import useStyles from './styles';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import ConfigurationTypeSettingForm from '../ConfigurationForm/ConfigurationTypeSettingForm';

interface SettingForm {
  setting: Setting | SettingInput;
  action: string;
  onInputChange: (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ) => void;
  handleOpenAttachCommandDrawer: () => void;
}

const SettingForm: React.FC<SettingForm> = ({
  setting,
  action,
  onInputChange,
  handleOpenAttachCommandDrawer,
}) => {
  const handleDynamicChange =
    (prop: string, index: number | string) =>
    (field: string, value: string | number): void => {
      onInputChange(prop, value, field, index as number);
    };

  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [settingCommand, setSettingCommand] = useState<Command>();

  const configurationSettingList = (): JSX.Element[] | undefined => {
    const { settings_values } = setting;
    if (settings_values) {
      return settings_values?.map((item, index) => {
        return (
          <ConfigurationTypeSettingForm
            key={index}
            configurableSettings={
              item.configurable_setting as ConfigurableSettingsInput
            }
            configIndex={index}
            onInputChange={onInputChange}
            settingValue={setting.settings_values[index]}
          />
        );
      });
    }
  };

  const setCommandSettingValues = (): void => {
    if (setting?.command_id) {
      Commands.getOneById(setting.command_id)
        .then((response) => {
          setSettingCommand(response as Command);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  };

  useEffect(() => {
    setCommandSettingValues();
  }, [setting]);

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <Grid container direction="row" className="mt-2" spacing={2}>
            <Grid item xs={12}>
              <TextInput
                prop="name"
                label="Name*"
                placeholder=""
                type="text"
                value={setting?.name as string}
                onInputChange={handleDynamicChange('name', '')}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-9">
        {settingCommand && settingCommand.name ? (
          <Typography variant="h5" className={classes.title}>
            {` Selected Command: ${settingCommand.name}`}
          </Typography>
        ) : (
          <Typography variant="h5" className={classes.title}>
            {`Select a Command:`}
          </Typography>
        )}
        <Grid item xs={12} className="d-flex flex-items-center mt-2">
          <Button
            className="w-50"
            variant="contained"
            size="large"
            onClick={handleOpenAttachCommandDrawer}
            startIcon={<AddIcon />}
          >
            {action === 'create' ? 'Add Command' : 'Update Command'}
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} className="mt-9">
        <Typography variant="h5" className={classes.title}>
          Setting Values
        </Typography>
        {configurationSettingList()}
      </Grid>
    </>
  );
};

export default SettingForm;
