import React, { useState, useEffect } from 'react';
import {
  Avatar,
  ListItemAvatar,
  ListItemText,
  Typography,
  TypographyVariant,
} from '@mui/material';
import { User, UserType, UserTypes, Users } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { stringAvatar } from '../../helpers/avatar';
import { errorHighlight } from '../../app/constants';

interface UserAvatarProp {
  userId: string;
  preAvatarText?: string;
  preAvatarTextVariant?: TypographyVariant;
  preAvatarTextClass?: string;
  hideErrorToast?: boolean;
  showUserRole?: boolean;
}

const UserAvatar: React.FC<UserAvatarProp> = ({
  userId,
  preAvatarText,
  preAvatarTextVariant,
  preAvatarTextClass,
  hideErrorToast = false,
  showUserRole = true,
}) => {
  const usersState = useAppSelector((state: RootState) => state.userTypes);
  const userListState = useAppSelector((state: RootState) => state.users);

  const dispatch = useAppDispatch();
  const [user, setUser] = useState<User>();
  const [currentUserType, setCurrentUserType] = useState<UserType>();

  const getUserType = (userTypeId: string): void => {
    if (usersState.userTypes.length > 0) {
      const userType = findDeviceTypesInStateById(userTypeId) as UserType;
      if (userType) {
        setCurrentUserType(userType);
      } else {
        getUserTypeById(userTypeId);
      }
    } else {
      getUserTypeById(userTypeId);
    }
  };

  const getAndSetUserById = (): void => {
    if (userListState.users.length > 0) {
      const foundUser = findUserInStateById() as User;
      if (foundUser) {
        setUser(foundUser);
        getUserType(foundUser.user_type_id);
      } else {
        getUserById();
      }
    } else {
      getUserById();
    }
  };

  const getUserById = (): void => {
    Users.getOneById(userId)
      .then((returnedUser) => {
        setUser(returnedUser);
        getUserType(returnedUser.user_type_id);
      })
      .catch((error) => {
        if (!hideErrorToast) {
          dispatchError(error.message);
        }
      });
  };

  const getUserTypeById = (userTypeId: string): void => {
    UserTypes.getOneById(userTypeId)
      .then((result) => {
        setCurrentUserType(result);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  };

  const findUserInStateById = (): User | undefined => {
    return userListState.users.find((item) => item._id === userId) || undefined;
  };

  const findDeviceTypesInStateById = (
    userTypeId: string,
  ): UserType | undefined => {
    return usersState.userTypes.find((userType) => userType._id === userTypeId);
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const getAcronym = (): { children: string; sx: { bgcolor: string } } => {
    if (!user) {
      return {
        children: 'U',
        sx: {
          bgcolor: 'gray',
        },
      };
    }
    return stringAvatar(`${user.first_name} ${user.last_name}`);
  };

  useEffect(() => {
    if (userId) {
      getAndSetUserById();
    }
  }, [userId]);

  return (
    <>
      {preAvatarText && (
        <Typography
          variant={preAvatarTextVariant}
          component="div"
          noWrap
          className={clsx('mb-1', preAvatarTextClass)}
        >
          {preAvatarText}
        </Typography>
      )}
      <ListItemAvatar>
        {user?.logo_url ? (
          <Avatar
            alt={`${user.first_name} ${user.last_name}`}
            src={user.logo_url}
          />
        ) : (
          <Avatar {...getAcronym()} />
        )}
      </ListItemAvatar>
      <ListItemText
        primary={user ? `${user.first_name} ${user.last_name}` : 'User'}
        secondary={
          <>
            {showUserRole ? (
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {currentUserType?.name || ''}
              </Typography>
            ) : (
              <></>
            )}
          </>
        }
      />
    </>
  );
};

export default UserAvatar;
