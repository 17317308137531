import React, { ReactElement, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  SoftwareUpdates,
  SoftwareUpdate,
  SoftwareUpdatesFilters,
} from '@edgeiq/edgeiq-api-js';

import {
  setOriginalSelectedSoftwareUpdate,
  setSelectedSoftwareUpdate,
} from '../../../redux/reducers/softwareUpdates.reducer';

import TabsPage from '../../../components/TabsPage';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { RootState } from '../../../redux/store';
import { setAlert } from '../../../redux/reducers/alert.reducer';
import { errorHighlight } from '../../../app/constants';
import timeHelpers from '../../../helpers/timeHelpers';
import SoftwareUpdateDrawer from '../../../containers/RightDrawer/SoftwarePackageDrawer/SoftwarePackageDrawer';

const columns: GridColDef[] = [
  {
    field: 'name',
    flex: 1,
    headerName: 'Software Name',
    renderCell: (params: GridRenderCellParams): ReactElement => (
      <strong>{params.row.name}</strong>
    ),
  },
  {
    field: 'created_at',
    flex: 0.5,
    headerName: 'Date created',
    renderCell: (params: GridRenderCellParams): ReactElement => (
      <span>{timeHelpers.getPlainDate(params.row.created_at)}</span>
    ),
  },
  {
    field: 'summary',
    flex: 0.3,
    headerName: 'Summary',
  },
  {
    field: 'profile',
    flex: 0.3,
    headerName: 'Profile',
  },
];

const DeviceSoftwareUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { id } = useParams<string>();
  const selectedSoftwareUpdate = useAppSelector(
    (state: RootState) => state.softwareUpdates.selectedSoftwareUpdate,
  );
  const originalSoftwareUpdate = useAppSelector(
    (state: RootState) => state.softwareUpdates.originalSelectedSoftwareUpdate,
  );
  const editableDevice = useAppSelector(
    (state: RootState) => state.devices.newDevice,
  );
  const [manageSoftwareUpdateDrawer, setManageSoftwareUpdateDrawer] =
    useState(false);

  useEffect(() => {
    if (editableDevice) {
      setLoading(true);

      const filters: SoftwareUpdatesFilters = {
        device_type_id: {
          operator: 'eq',
          value: editableDevice.device_type_id,
        },
      };

      SoftwareUpdates.list(filters)
        .then((resUpdates) => {
          dispatch(setSelectedSoftwareUpdate(resUpdates.softwareUpdates));
          dispatch(
            setOriginalSelectedSoftwareUpdate(resUpdates.softwareUpdates),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [editableDevice]);

  const handleOpenManageSoftwareUpdateDrawer = (): void => {
    setManageSoftwareUpdateDrawer(true);
  };

  const handleCloseSoftwareUpdateDrawer = (): void => {
    setManageSoftwareUpdateDrawer(false);
  };

  const handleManageSoftwareUpdate = (
    newSoftwareUpdate: SoftwareUpdate[],
  ): void => {
    dispatch(setSelectedSoftwareUpdate(newSoftwareUpdate));
    handleCloseSoftwareUpdateDrawer();
    setLoading(true);

    const attachSoftwareUpdate = newSoftwareUpdate.filter((softwareUpdate) =>
      originalSoftwareUpdate.every(
        (originalSoftwareUpdateItem) =>
          softwareUpdate._id !== originalSoftwareUpdateItem._id,
      ),
    );
    const detachSoftwareUpdate = originalSoftwareUpdate.filter(
      (originalSoftwareUpdateItem) =>
        newSoftwareUpdate.every(
          (softwareUpdate) =>
            originalSoftwareUpdateItem._id !== softwareUpdate._id,
        ),
    );

    Promise.all([
      Promise.all(
        attachSoftwareUpdate.map(async (attachCommand) => {
          await SoftwareUpdates.assignToDevice(attachCommand._id, id as string);
        }),
      ),
      Promise.all(
        detachSoftwareUpdate.map(async (detachCommand) => {
          await SoftwareUpdates.removeFromDevice(
            detachCommand._id,
            id as string,
          );
        }),
      ),
    ])
      .then(() => {
        dispatch(setOriginalSelectedSoftwareUpdate(newSoftwareUpdate));
        dispatch(
          setAlert({
            highlight: 'SoftwareUpdate updated',
            message: 'Device software update successfully updated.',
            type: 'success',
          }),
        );
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCreateSoftwareUpdate = (): void => {
    navigate('/new-software-update');
  };

  return (
    <Box>
      <TabsPage
        columns={columns}
        rows={selectedSoftwareUpdate}
        addButtonLabel="Update Software"
        createButtonLabel="Create Package"
        onCreateClick={handleCreateSoftwareUpdate}
        onAddClick={handleOpenManageSoftwareUpdateDrawer}
        tableTitle="Software added"
        loading={loading}
      />

      <SoftwareUpdateDrawer
        device_type_id={editableDevice?.device_type_id}
        selectedSoftwareUpdates={[]}
        open={manageSoftwareUpdateDrawer}
        onCloseDrawer={handleCloseSoftwareUpdateDrawer}
        onChoosingSoftwareUpdate={handleManageSoftwareUpdate}
      />
    </Box>
  );
};

export default DeviceSoftwareUpdate;
