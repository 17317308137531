import React from 'react';
import { RuleInput, RuleAction, Integration } from '@edgeiq/edgeiq-api-js';
import { Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import useStyles from '../styles';
import ActionSection from './ActionSection';

interface PolicyElseActionsSectionProps {
  newPolicy: RuleInput;
  onInputChange: (
    prop: string,
    value: string | number | boolean | string[] | { [key: string]: string },
  ) => void;
  disabled?: boolean;
  onAddNewAction: () => void;
  onRemoveAction: (actionIndex: number) => void;
  integrations: Integration[];
  mixedTypes: boolean;
}

const PolicyElseActionsSection: React.FC<PolicyElseActionsSectionProps> = ({
  newPolicy,
  onInputChange,
  disabled,
  onAddNewAction,
  onRemoveAction,
  integrations,
  mixedTypes,
}) => {
  const classes = useStyles();

  const handleClickAddAction = () => (): void => {
    onAddNewAction();
  };

  const handleRemoveAction = (index: number) => (): void => {
    onRemoveAction(index);
  };

  return (
    <>
      {Array.isArray(newPolicy.else_actions) &&
        newPolicy.else_actions.map((item, index) => (
          <Grid key={index} container direction="row" spacing={2}>
            <Grid
              item
              className="d-flex flex-items-center"
              xs={
                Array.isArray(newPolicy.else_actions) &&
                newPolicy.else_actions.length > 1
                  ? 6
                  : 12
              }
            >
              <Typography variant="h5">Action {index + 1}</Typography>
            </Grid>
            {Array.isArray(newPolicy.else_actions) &&
              newPolicy.else_actions.length > 1 && (
                <Grid item xs={6} className="d-flex flex-justify-end">
                  <IconButton
                    onClick={handleRemoveAction(index)}
                    disabled={disabled}
                    className={classes.errorButton}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              )}

            <ActionSection
              action={item as RuleAction}
              actionIndex={index}
              onInputChange={onInputChange}
              integrations={integrations}
              mixedTypes={mixedTypes}
            />

            <Grid item xs={12} className="mb-4">
              <Divider />
            </Grid>
          </Grid>
        ))}
      <Grid item xs={12} className="d-flex flex-items-center">
        <Button
          className="w-100"
          variant="outlined"
          size="large"
          onClick={handleClickAddAction()}
          startIcon={<AddIcon />}
          disabled={disabled}
        >
          Add Another Action
        </Button>
      </Grid>
    </>
  );
};

export default PolicyElseActionsSection;
