import React from 'react';

import RightDrawer from '../../RightDrawer/RightDrawer';

interface MoreFiltersDrawerProps {
  open: boolean;
  content: JSX.Element;
  loading: boolean;
  handleCloseDrawer: () => void;
  applyFilters: () => void;
}

const MoreFiltersDrawer: React.FC<MoreFiltersDrawerProps> = ({
  open,
  content,
  loading,
  handleCloseDrawer,
  applyFilters,
}) => {
  const filterCallback = (): void => {
    applyFilters();
  };

  return (
    <RightDrawer
      open={open}
      actionLabel="Apply"
      title="All filters"
      size="900"
      actionLoading={loading}
      disableAction={loading}
      actionCallback={filterCallback}
      onCloseDrawer={handleCloseDrawer}
      content={content}
    />
  );
};

export default MoreFiltersDrawer;
