import { Theme } from '@mui/material';
import { CSSProperties, createStyles, makeStyles } from '@mui/styles';
import { Branding } from '@edgeiq/edgeiq-api-js';

const drawerWidthDesktop = 285;
const drawerWidthMobile = 315;
const _mobileHeaderHeight = 56;

interface StyleProps {
  branding?: Branding;
  openDrawer?: boolean;
}

const openedMixin = (theme: Theme): CSSProperties => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.enteringScreen,
    easing: theme.transitions.easing.sharp,
  }),
  [theme.breakpoints.up('sm')]: {
    width: drawerWidthDesktop,
  },
});

const closedMixin = (theme: Theme): CSSProperties => ({
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    duration: theme.transitions.duration.leavingScreen,
    easing: theme.transitions.easing.sharp,
  }),
  // width: `calc(${theme.spacing(7)} + 1px)`,
  // width: '30px',
  [theme.breakpoints.up('sm')]: {
    width: '20px',
    // width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) =>
  createStyles({
    companyLogo: {
      maxHeight: '70px',
      maxWidth: '150px',
    },
    content: {
      '&::-webkit-scrollbar': {
        backgroundColor: theme.palette.primary.main,
      },
      backgroundColor: theme.palette.grey.A100,
      height: '100vh',
      // overflowX: 'hidden',
      overflowY: 'auto',
      width: '100%',
    },
    desktopHidden: {
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    drawer: {
      '&.collapsed': {
        position: 'absolute',
      },
      '&.expanded': {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
          ...openedMixin(theme),
        },
        flexShrink: 0,
        position: 'relative',
      },
      '&.hovered': {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': {
          ...openedMixin(theme),
        },
        flexShrink: 0,
      },
      ...closedMixin(theme),
      '& .MuiDrawer-paper': {
        ...closedMixin(theme),
        backgroundColor: (props: StyleProps): string => {
          const { branding } = props;
          if (branding?.primary_color) {
            return branding.primary_color;
          }
          return theme.palette.grey[700];
        },
        backgroundImage: (props: StyleProps): string => {
          const { branding } = props;
          if (
            branding?.primary_color &&
            branding?.secondary_color &&
            branding?.gradient_sidbar
          ) {
            return `linear-gradient(to bottom left, ${branding.primary_color}, ${branding.secondary_color})`;
          } else if (branding?.primary_color) {
            return branding.primary_color;
          }
          return theme.palette.grey[700];
        },
      },
      zIndex: '200',
    },
    drawerInnerContainer: {
      backgroundColor: (props: StyleProps): string => {
        const { branding } = props;
        if (branding?.primary_color) {
          return branding.primary_color;
        }
        return theme.palette.grey[700];
      },
      backgroundImage: (props: StyleProps): string => {
        const { branding } = props;
        if (
          branding?.primary_color &&
          branding?.secondary_color &&
          branding?.gradient_sidbar
        ) {
          return `linear-gradient(to bottom left, ${branding.primary_color}, ${branding.secondary_color})`;
        } else if (branding?.primary_color) {
          return branding.primary_color;
        }
        return theme.palette.grey[700];
      },
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      minHeight: 'calc(100vh)',
      overflowX: 'hidden',
      overflowY: 'auto',
      [theme.breakpoints.up('md')]: {
        width: drawerWidthDesktop,
      },
    },
    drawerList: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingBottom: '30px',
    },
    drawerPaper: {
      boxSizing: 'border-box',
      width: drawerWidthDesktop,
    },
    drawerPaperMobile: {
      boxSizing: 'border-box',
      width: drawerWidthMobile,
    },
    expandButton: {
      '&:hover': {
        backgroundColor: (props: StyleProps): string => {
          const { branding } = props;
          if (branding?.primary_color) {
            return branding.primary_color;
          }
          return theme.palette.grey[700];
        },
        color: theme.palette.common.white,
      },
      backgroundColor: theme.palette.common.white,
      boxShadow:
        'rgba(9, 30, 66, 0.08) 0px 0px 0px 1px, rgba(9, 30, 66, 0.08) 0px 2px 4px 1px',
      height: '24px',
      left: '-12px',
      margin: '0',
      marginTop: '48px',
      position: 'absolute',
      width: '24px',
    },
    expandButtonContainer: {
      '&.collapsed': {
        left: '20px',
      },
      '&.hovered': {
        left: `${drawerWidthDesktop}px`,
      },
      left: `${drawerWidthDesktop}px`,
      position: 'relative',
      transition: theme.transitions.create('left', {
        duration: theme.transitions.duration.enteringScreen,
        easing: theme.transitions.easing.sharp,
      }),
      width: '0',
      zIndex: '1000',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    linkWithSublinks: {
      '&.Mui-selected': {
        backgroundColor: 'transparent',
      },
    },
    listContent: {
      [theme.breakpoints.up('lg')]: {
        paddingTop: '60px',
      },
    },
    logoContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    mobileHidden: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    nestedList: {
      marginLeft: '20px',
    },
    notSafariContent: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      justifyContent: 'space-between',
    },
    root: {
      display: 'flex',
    },
    userMenu: {
      '& .MuiPaper-root': {
        '& .MuiList-root': {
          border: 'none',
          boxShadow: 'none',
          padding: '8px 0',
          width: '382px',
        },
        backgroundColor: theme.palette.grey[800],
        borderRadius: '8px',
        marginLeft: '8px',
        marginTop: '-8px',
        width: '382px',
      },
    },
    userMenuAvatar: {
      alignItems: 'center',
      backgroundColor: theme.palette.primary.main,
      borderRadius: '50%',
      display: 'flex',
      height: '40px',
      justifyContent: 'center',
      width: '40px',
    },
    userMenuButton: {
      '&:hover': {
        backgroundColor: theme.palette.grey[800],
        borderRadius: '8px',
      },
      display: 'flex',
      maxWidth: '235px',
      position: 'relative',
    },
    userMenuButtonFocused: {
      backgroundColor: theme.palette.grey[800],
    },
    userMenuName: {
      width: '125px',
    },
  }),
);

export default useStyles;
