import React, { useState, useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Configuration,
  Configurations,
  DeviceType,
  DeviceTypes,
} from '@edgeiq/edgeiq-api-js';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import {
  setActualConfiguration,
  setNewConfiguration,
  getConfigurationSelector,
} from '../../redux/reducers/configuration.reducer';
import Header from '../../containers/HeaderWithActionButton';
import ContentHeader from '../../components/ContentHeader';
import VerticalTabs from '../../components/VerticalTabs';
import FooterBar from '../../components/FooterBar';
import {
  configurationTabsLabel,
  errorHighlight,
  optionsPaginationsFilter,
} from '../../app/constants';
import { EMPTY_CONFIGURABLE_SETTINGS } from '../../constants/configurableSettings';
import ConfigurationSetting from '../configurationContent/configurationSettings';
import ConfigurationSettingDetails from './configurationSettingDetails';

const ConfigurationContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const stateConfiguration = useAppSelector((state: RootState) =>
    getConfigurationSelector(state.configurations, id),
  );
  const newConfiguration = useAppSelector(
    (state: RootState) => state.configurations.newConfiguration,
  );
  const [configurationData, setConfigurationData] = useState<
    Configuration | undefined | null
  >(stateConfiguration);
  const [devicesTypes, setDevicesTypes] = useState<DeviceType[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (stateConfiguration) {
      dispatch(setActualConfiguration(stateConfiguration));
      setConfigurationData(stateConfiguration);
    } else if (id) {
      Configurations.getOneById(id)
        .then((response) => {
          dispatch(setActualConfiguration(response));
          setConfigurationData(response);
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        });
    }
  }, [stateConfiguration]);

  const handleDeleteConfiguration = (): void => {
    if (!configurationData) {
      return;
    }
    setLoading(true);

    Configurations.delete(configurationData._id)
      .then(() => {
        dispatch(
          setAlert({
            highlight: 'Delete configuration',
            message: 'Configuration successfully deleted.',
            type: 'success',
          }),
        );
        navigate('/configurations');
      })
      .catch((err) => {
        dispatch(
          setAlert({
            highlight: errorHighlight,
            message: err.message,
            type: 'error',
          }),
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSaveChanges = (): void => {
    if (newConfiguration !== configurationData) {
      setLoading(true);
      Configurations.update(newConfiguration as Configuration)
        .then((response) => {
          dispatch(setActualConfiguration(response));
          dispatch(
            setAlert({
              highlight: 'Update configuration',
              message: 'Configuration successfully updated.',
              type: 'success',
            }),
          );
        })
        .catch((err) => {
          dispatch(
            setAlert({
              highlight: errorHighlight,
              message: err.message,
              type: 'error',
            }),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const onDynamicRowsChange = (
    _prop: string,
    value: string | number,
    field: string,
    index: string,
    parentIndex: number,
  ): void => {
    const currentConfigurationSettings =
      newConfiguration?.configurable_settings || [];
    const tempArrayValues =
      currentConfigurationSettings[parentIndex].list_items || [];

    if (field === 'name') {
      tempArrayValues[Number(index)].value = String(value);
    } else {
      tempArrayValues[Number(index)].label = String(value);
    }
    currentConfigurationSettings[parentIndex].list_items = tempArrayValues as [
      { label: string; value: string },
    ];
    dispatch(
      setNewConfiguration({
        ...newConfiguration,
        configurable_settings: currentConfigurationSettings,
      } as Configuration),
    );
  };

  useEffect(() => {
    DeviceTypes.list({}, optionsPaginationsFilter)
      .then((result) => {
        setDevicesTypes(result.deviceTypes);
      })
      .catch((error) => {
        dispatchError(error.message);
      });
  }, []);

  const handleAddConfigurationValue = (): void => {
    const currentConfigurationSettings =
      newConfiguration?.configurable_settings || [];
    const newConfigurableSettings = [
      ...currentConfigurationSettings,
      ...[EMPTY_CONFIGURABLE_SETTINGS],
    ];

    dispatch(
      setNewConfiguration({
        ...newConfiguration,
        configurable_settings: newConfigurableSettings,
      } as Configuration),
    );
  };

  const handleAddRow = (_prop: string, index: number): void => {
    const currentConfigurationSettings =
      newConfiguration?.configurable_settings || [];
    let tempArrayValues =
      newConfiguration?.configurable_settings[index].list_items || [];
    tempArrayValues = [...tempArrayValues, { label: '', value: '' }] as [
      { label: string; value: string },
    ];

    currentConfigurationSettings[index].list_items = tempArrayValues;
    dispatch(
      setNewConfiguration({
        ...newConfiguration,
        configurable_settings: currentConfigurationSettings,
      } as Configuration),
    );
  };

  const handleRemoveRow = (
    _prop: string,
    index: number,
    parentIndex: number,
  ): void => {
    const currentConfigurationSettings =
      newConfiguration?.configurable_settings || [];
    const currentConfigurationSettingsItems =
      currentConfigurationSettings[parentIndex].list_items || [];
    currentConfigurationSettingsItems?.splice(index, 1);
    currentConfigurationSettings[parentIndex].list_items =
      (currentConfigurationSettingsItems as [
        {
          label: string;
          value: string;
        },
      ]) || [];

    dispatch(
      setNewConfiguration({
        ...newConfiguration,
        configurable_settings: currentConfigurationSettings,
      } as Configuration),
    );
  };

  const onRemoveArrayRow = (index: number): void => {
    const currentConfigurationSettings =
      newConfiguration?.configurable_settings || [];
    currentConfigurationSettings?.splice(index, 1);
    dispatch(
      setNewConfiguration({
        ...newConfiguration,
        configurable_settings: currentConfigurationSettings,
      } as Configuration),
    );
  };

  const handleDynamicChange = (
    prop: string,
    value: string | number,
    field: string,
    index: number,
  ): void => {
    switch (field) {
      case 'configurable_settings':
        const currentConfigurationSettings =
          newConfiguration?.configurable_settings || [];

        if (prop === 'type') {
          currentConfigurationSettings[index] = {
            ...currentConfigurationSettings[index],
            default: '',
          };
        }

        if (prop === 'number') {
          currentConfigurationSettings[index] = {
            ...currentConfigurationSettings[index],
            ['default']: value.toString(),
          };
        } else {
          currentConfigurationSettings[index] = {
            ...currentConfigurationSettings[index],
            [prop]: value,
          };
        }

        dispatch(
          setNewConfiguration({
            ...newConfiguration,
            configurable_settings: currentConfigurationSettings,
          } as Configuration),
        );
        break;
      default:
        dispatch(
          setNewConfiguration({
            ...newConfiguration,
            [prop]: value,
          } as Configuration),
        );
        break;
    }
  };

  const getTabs = (): {
    [key: string]: JSX.Element;
  } => {
    const tabs: {
      [key: string]: JSX.Element;
    } = {
      details: (
        <ConfigurationSettingDetails
          onInputChange={handleDynamicChange}
          action={'save'}
          configuration={(newConfiguration as Configuration) || undefined}
          deviceTypes={devicesTypes}
          onAddNewConfigurationValue={handleAddConfigurationValue}
          onDynamicRowsChange={onDynamicRowsChange}
          handleAddRow={handleAddRow}
          handleRemoveRow={handleRemoveRow}
          onRemoveArrayRow={onRemoveArrayRow}
        />
      ),
      settings: <ConfigurationSetting />,
    };
    return tabs;
  };

  const dispatchError = (errorMessage: string): void => {
    dispatch(
      setAlert({
        highlight: errorHighlight,
        message: errorMessage,
        type: 'error',
      }),
    );
  };

  const isAbleToBeSaved = (): boolean => {
    return (
      newConfiguration?.name !== '' &&
      newConfiguration?.device_type_id !== '' &&
      newConfiguration?.company_id !== ''
    );
  };

  return (
    <Grid container direction="column" spacing={0}>
      <Header
        title="Configuration content"
        goBack="configurations"
        model="configuration"
        link="new-setting"
        actionLabel="Create New Setting"
        goBackLabel="Configurations"
      />
      {configurationData && (
        <Box>
          <ContentHeader
            contentType="configuration"
            title={configurationData.name}
            subtitle={configurationData._id}
            copySubtitleToClipboard
          />
          <VerticalTabs
            tabs={getTabs()}
            defaultTab="details"
            tabsLabel={configurationTabsLabel}
          />
        </Box>
      )}

      <FooterBar
        deleteModalContent="You are about to delete the configuration"
        loading={loading}
        disableSaveButton={!isAbleToBeSaved()}
        handleSaveChanges={handleSaveChanges}
        handleDelete={handleDeleteConfiguration}
      />
    </Grid>
  );
};

export default ConfigurationContent;
