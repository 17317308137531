export const PAGE_OPTIONS_LABEL: {
  [key: string]: string;
} = {
  automation_hidden: 'Automation',
  configuration_hidden: 'Configuration',
  dashboard_hidden: 'Dashboard',
  data_management_hidden: 'Data Management',
  devices_hidden: 'Devices',
  messages_hidden: 'Messages',
  software_hidden: 'Software',
};
