import React from 'react';
import { Typography, Grid, Paper } from '@mui/material';
import { AwsThingGroup, DeviceType } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { RootState } from '../../../redux/store';
import { useAppSelector, useAppDispatch } from '../../../redux/hooks';
import { setNewDeviceType } from '../../../redux/reducers/deviceTypes.reducer';
import { updateThingGroupOnChange } from '../../../helpers/utils';
import { LWM2M_TYPE } from '../../../app/constants';
import Tags from '../../../containers/Tags';
import IntegrationConfig from '../../../containers/IntegrationConfig';
import DeviceTypeForm from './deviceTypeForm';
import DeviceTypeOptions from './deviceTypeOptions';
import DeviceTypeObservablePaths from './deviceTypeObservablePaths';

const DeviceTypeDetails: React.FC = () => {
  const dispatch = useAppDispatch();
  const editableDeviceType = useAppSelector(
    (state: RootState) => state.deviceTypes.newDeviceType,
  );

  const handleInputChange = (
    prop: string,
    value: string | boolean | number | string[],
  ): void => {
    switch (prop) {
      case 'important_metadata':
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            important_metadata: value,
          } as DeviceType),
        );
        break;
      case 'firmware-upgrade':
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            capabilities: {
              ...editableDeviceType?.capabilities,
              firmware: {
                ...editableDeviceType?.capabilities.firmware,
                upgrade: value,
              },
            },
          } as DeviceType),
        );
        break;
      case 'ip-tables':
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            capabilities: {
              ...editableDeviceType?.capabilities,
              iptables: value,
            },
          } as DeviceType),
        );
        break;
      default:
        dispatch(
          setNewDeviceType({
            ...editableDeviceType,
            [prop]: value,
          } as DeviceType),
        );
    }
  };

  const handleIntegrationChange = (prop: string, value: string): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        [prop]: value,
      } as DeviceType),
    );
  };

  const handleDefaultThingGroupChange = (value: AwsThingGroup): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        default_thing_groups: updateThingGroupOnChange(
          editableDeviceType?.default_thing_groups,
          value,
        ),
      } as DeviceType),
    );
  };

  const handleObservablePathsChange = (
    _prop: string,
    value: string[],
  ): void => {
    dispatch(
      setNewDeviceType({
        ...editableDeviceType,
        capabilities: {
          ...editableDeviceType?.capabilities,
          observable_paths: value,
        },
      } as DeviceType),
    );
  };

  return (
    <Grid container>
      {editableDeviceType && (
        <>
          <Grid component={Paper} item xs={8} className={clsx('p-7 shadow')}>
            {/* Details Form */}
            <Typography variant="h5">Details</Typography>
            <DeviceTypeForm
              deviceType={editableDeviceType}
              onInputChange={handleInputChange}
            />
            {/* Integration Form */}
            <IntegrationConfig
              deviceType={editableDeviceType}
              onIntegrationChange={handleIntegrationChange}
              onDefaultThingGroupChange={handleDefaultThingGroupChange}
            />
            <DeviceTypeOptions
              deviceType={editableDeviceType}
              onSwitchChange={handleInputChange}
            />
            {/* Important metadata */}
            <Typography
              variant="h6"
              className="mt-6 mb-3"
              data-cy="important-data-title"
            >
              Important Metadata
            </Typography>
            <p className="mt-3" data-cy="important-data-subtitle">
              Choose a list of device metadata to display more prominently on
              the detail view of devices assigned this profile:
            </p>
            <Tags
              key="device-type-important-metadata"
              placeholder="Add important metadata"
              originalTags={editableDeviceType.important_metadata ?? []}
              tagsOptions={false}
              prop="important_metadata"
              onChangeTags={handleInputChange}
            />

            {editableDeviceType.type === LWM2M_TYPE && (
              <DeviceTypeObservablePaths
                deviceType={editableDeviceType}
                onInputChange={handleObservablePathsChange}
              />
            )}
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default DeviceTypeDetails;
