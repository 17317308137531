import React, { useState } from 'react';
import { Typography, Link } from '@mui/material';
import { Info as InfoIcon } from '@mui/icons-material';
import { Devices, DiscoveredDevices } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { RootState } from '../../redux/store';
import { setAlert } from '../../redux/reducers/alert.reducer';
import { RightDrawer } from '../../containers/RightDrawer';
import DropZone from '../../components/DropZone';
import {
  devicesDocsURL,
  devicesTemplateURL,
  errorHighlight,
  GENRE_ACTIVE,
  promoteDevicesTemplateUrl,
} from '../../app/constants';
import useStyles from './styles';

interface ImportDevicesProps {
  openDrawer: boolean;
  handleCloseDrawer: () => void;
  handleImportSucces: () => void;
}

const ImportDevices: React.FC<ImportDevicesProps> = ({
  openDrawer,
  handleCloseDrawer,
  handleImportSucces,
}) => {
  // TODO: get devicesGenre (active or escrow) if it is possible to upload csv for escrow devices
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const devicesState = useAppSelector((state: RootState) => state.devices);
  const isActive = devicesState.devicesGenre === GENRE_ACTIVE;

  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File>();

  const handleSubmitCallback = (): void => {
    setLoading(true);
    if (uploadedFile) {
      if (isActive) {
        Devices.csvBulkUpload(uploadedFile)
          .then((_result) => {
            dispatch(
              setAlert({
                highlight: 'File uploaded successfully',
                type: 'success',
              }),
            );
            handleImportSucces();
            onRemoveFile();
          })
          .catch((error) => {
            dispatch(
              setAlert({
                highlight: errorHighlight,
                message: error.message,
                type: 'error',
              }),
            );
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        DiscoveredDevices.csvBulkPromote(uploadedFile)
          .then((_result) => {
            dispatch(
              setAlert({
                highlight: 'File uploaded successfully',
                type: 'success',
              }),
            );
            handleImportSucces();
            onRemoveFile();
          })
          .catch((error) => {
            dispatch(
              setAlert({
                highlight: errorHighlight,
                message: error.message,
                type: 'error',
              }),
            );
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const downloadCsvTemplate = (): void => {
    const link = document.createElement('a');
    link.download = isActive ? devicesTemplateURL : promoteDevicesTemplateUrl;
    link.href = isActive ? devicesTemplateURL : promoteDevicesTemplateUrl;
    link.click();
  };

  const readDocumenation = (): void => {
    const link = document.createElement('a');
    link.href = devicesDocsURL;
    link.target = '_blank';
    link.click();
  };

  const onAddFile = (file: File): void => {
    setUploadedFile(file);
  };

  const onRemoveFile = (): void => {
    setUploadedFile(undefined);
  };

  return (
    <RightDrawer
      open={openDrawer}
      actionLabel="Submit"
      title={isActive ? 'Import Devices' : 'CSV - Promote Discovered Devices'}
      disableAction={!uploadedFile}
      actionLoading={loading}
      actionCallback={handleSubmitCallback}
      onCloseDrawer={handleCloseDrawer}
      content={
        <div>
          <div
            className={clsx('br-1 p-4 mb-6', classes.importTemplateContainer)}
          >
            <InfoIcon className={clsx('mr-4', classes.infoIcon)} />
            <div>
              <Typography
                component="div"
                variant="button"
                fontWeight={700}
                className="mb-1"
                color={'#fff'}
              >
                CSV template
              </Typography>
              <Typography component="div" variant="button" color={'#fff'}>
                You can
                <Link
                  className={clsx('ml-1 mr-1', classes.link)}
                  onClick={downloadCsvTemplate}
                  underline="none"
                >
                  download a CSV
                </Link>
                file as a template to edit and {isActive ? 'upload' : 'promote'}{' '}
                devices.
              </Typography>
              {isActive && (
                <Typography component="div" variant="button" color={'#fff'}>
                  You can also
                  <Link
                    className={clsx('ml-1 mr-1', classes.link)}
                    onClick={readDocumenation}
                    underline="none"
                  >
                    read the CSV upload documenation
                  </Link>
                  .
                </Typography>
              )}
            </div>
          </div>

          <DropZone
            title="Upload CSV file"
            acceptTypes={['text/csv']}
            stateFiles={uploadedFile ? [uploadedFile] : []}
            onFileUpload={onAddFile}
            onRemoveFile={onRemoveFile}
          />
        </div>
      }
    />
  );
};

export default ImportDevices;
