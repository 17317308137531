import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { Command } from '@edgeiq/edgeiq-api-js';
import clsx from 'clsx';

import { commandsSenderTypes } from '../../../app/constants';
import useStyles from '../styles';

interface AttachCommandCardProps {
  command: Command;
}

const AttachCommandCard: React.FC<AttachCommandCardProps> = ({ command }) => {
  const classes = useStyles({});
  return (
    <>
      <Tooltip placement="top" title={command.name}>
        <Typography
          variant="button"
          component="div"
          noWrap
          className={clsx('mb-2', classes.cardTitle)}
        >
          {command.name}
        </Typography>
      </Tooltip>
      <Typography
        variant="overline"
        component="div"
        className={clsx('mb-1', classes.tag)}
      >
        {commandsSenderTypes[command.sender_type]}
      </Typography>
    </>
  );
};

export default AttachCommandCard;
