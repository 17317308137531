import React from 'react';
import { Grid } from '@mui/material';
import { IngestorInput } from '@edgeiq/edgeiq-api-js';
import TextInput from '../../../../components/TextInput';

interface SingleLengthSectionProps {
  newIngestor: IngestorInput;
  onInputChange: (prop: string, value: string | number | boolean) => void;
  disabled?: boolean;
}

const SingleLengthSection: React.FC<SingleLengthSectionProps> = ({
  newIngestor,
  onInputChange,
  disabled,
}) => {
  return (
    <>
      <Grid item xs={12} className="mt-2">
        <TextInput
          label="Length"
          prop="handler.length"
          placeholder=""
          value={newIngestor.handler.length}
          onInputChange={onInputChange}
          disabled={disabled}
          type="number"
        />
      </Grid>
    </>
  );
};

export default SingleLengthSection;
