import React, { PropsWithChildren, ReactElement } from 'react';
import { Grid, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  DevicesOther,
  HomeMax,
  Devices,
  DeviceHub,
  Aod,
  OnDeviceTraining,
  HearingOutlined,
  SettingsInputComponent,
  WorkHistory,
} from '@mui/icons-material';
import clsx from 'clsx';

import {
  ingestorListenerTypesMap,
  ingestorTypesMap,
  profileTypesMap,
  profileRolesMap,
} from '../../app/constants';
import { StatusTheme } from '../../models/common';
import ColoredBox from '../ColoredBox';
import useStyles from './styles';
import TypographyWithCopy from '../TypographyWithCopy';

export interface ContentHeaderProps {
  title: string; // Main title of the header
  image?: string; // Main image used at the side of the title
  tag?: string; // Tag used after the title
  tagTheme?: { [key: string]: StatusTheme }; // Color Theme of the tag
  subtitle?: string; // Text set below the title
  extraImage?: string; // Image set at the right part of the header, used most as the companies image
  extraTitle?: string; // Title set at the right part of the header, used most as the companies title
  extraSubtitle?: string; // Text set at the right part of the header, used most as the companies id
  profileName?: string;
  profileType?: string;
  jobType?: string;
  profileRole?: string;
  profileId?: string;
  integrationType?: string;
  commandType?: string;
  nameInitials?: string;
  origin?: string;
  hideTitleImage?: boolean;
  ingestorType?: string;
  listenerType?: string;
  policyType?: string;
  copySubtitleToClipboard?: boolean;
  contentType:
    | 'device'
    | 'device-type'
    | 'company'
    | 'integration'
    | 'discovered-device'
    | 'user'
    | 'command'
    | 'scheduled-job'
    | 'software-package'
    | 'translator'
    | 'ingestor'
    | 'pollable-attribute'
    | 'configuration'
    | 'config-connection'
    | 'policy';
}

const ContentHeader: React.FC<PropsWithChildren<ContentHeaderProps>> = ({
  title,
  image,
  tag,
  tagTheme,
  subtitle,
  extraImage,
  extraTitle,
  extraSubtitle,
  contentType,
  profileName,
  profileType,
  profileRole,
  profileId,
  integrationType,
  policyType,
  commandType,
  jobType,
  nameInitials,
  origin,
  hideTitleImage = false,
  ingestorType,
  listenerType,
  copySubtitleToClipboard,
  children,
}) => {
  const classes = useStyles({ image: '' });

  const renderOverline = (): ReactElement | null => {
    switch (contentType) {
      case 'device':
        return (
          <Typography variant="overline">
            Profile:{' '}
            <Link
              to={`/device-profile/${profileId}`}
              className={classes.overlineProfileName}
              data-cy="device-profile-link"
            >
              {profileName}
            </Link>
            <Devices className={clsx('ml-2 mr-1', classes.overlineIcon)} />
            Type: {profileTypesMap[profileType as string]}, Role:{' '}
            {profileRolesMap[profileRole as string]}
          </Typography>
        );
      case 'device-type':
        return (
          <Typography variant="overline">
            <Devices className={clsx('mr-1', classes.overlineIcon)} />
            Type: {profileTypesMap[profileType as string]}, Role:{' '}
            {profileRolesMap[profileRole as string]}
          </Typography>
        );
      case 'integration':
        return (
          <Typography variant="overline">
            Type:{' '}
            <span className={classes.overlineProfileName}>
              {integrationType}
            </span>
          </Typography>
        );
      case 'user':
        return (
          <Typography variant="overline">
            Parent Account:{' '}
            <Link
              to={`/account/${profileName}`}
              className={classes.overlineProfileName}
              data-cy="parent-account-link"
            >
              {profileName}
            </Link>
          </Typography>
        );
      case 'command':
        return (
          <Typography variant="overline">
            Sender Type:{' '}
            <span className={classes.overlineProfileName}>{commandType}</span>
          </Typography>
        );
      case 'translator':
        return (
          <Typography variant="overline">
            Origin:{' '}
            <span className={classes.overlineProfileName}>{origin}</span>
          </Typography>
        );
      case 'ingestor':
        return (
          <Typography variant="overline">
            Type:{' '}
            <span className={classes.overlineProfileName}>
              {ingestorTypesMap[ingestorType as string]}
            </span>
            <HearingOutlined
              className={clsx('ml-2 mr-1', classes.overlineIcon)}
            />
            {ingestorListenerTypesMap[listenerType as string]}
          </Typography>
        );
      case 'policy':
        return (
          <Typography variant="overline">
            Type:{' '}
            <span className={classes.overlineProfileName}>{policyType}</span>
          </Typography>
        );
      case 'scheduled-job':
        return (
          <Typography variant="overline">
            Job Type:{' '}
            <span className={classes.overlineProfileName}>{jobType}</span>
          </Typography>
        );
      default:
        return null;
    }
  };

  const renderSubtitle = (): string => {
    switch (contentType) {
      case 'device':
      case 'discovered-device':
        return `Unique Id: ${subtitle}`;
      default:
        return `Id: ${subtitle}`;
    }
  };

  const renderIcon = (): ReactElement | null => {
    switch (contentType) {
      case 'device':
        return <HomeMax data-testid="device-icon" fontSize="inherit" />;
      case 'device-type':
      case 'config-connection':
        return (
          <DevicesOther
            data-testid="config-connection-icon"
            fontSize="inherit"
          />
        );
      case 'integration':
        return <DeviceHub fontSize="inherit" data-testid="integration-icon" />;
      case 'command':
        return <Aod fontSize="inherit" data-testid="command-icon" />;
      case 'translator':
        return (
          <OnDeviceTraining fontSize="inherit" data-testid="translator-icon" />
        );
      case 'ingestor':
        return (
          <SettingsInputComponent
            fontSize="inherit"
            data-testid="ingestor-icon"
          />
        );
      case 'scheduled-job':
        return (
          <WorkHistory fontSize="inherit" data-testid="scheduled-job-icon" />
        );
      default:
        return null;
    }
  };

  return (
    <Grid item xs={12} className={clsx('px-8 pb-8', classes.container)}>
      {nameInitials ? (
        <Typography className={clsx('loading-container', classes.initials)}>
          {nameInitials}
        </Typography>
      ) : (
        <>
          {!hideTitleImage && !image && (
            <Box data-testid="icon-box" className={clsx(classes.imageBox)}>
              {renderIcon()}
            </Box>
          )}
          {image && (
            <div className={classes.logoContainer}>
              <img
                src={image}
                className={classes.extraImage}
                alt="account-logo"
              />
            </div>
          )}
        </>
      )}
      <Box className={clsx('px-6')}>
        <Box className={clsx('pb-5', classes.overline)}>{renderOverline()}</Box>

        <Box className={clsx(classes.titleContainer)}>
          <Typography
            data-cy="header-title"
            variant="h3"
            noWrap
            className={clsx(classes.title)}
          >
            {title}
          </Typography>
          {tag && tagTheme && (
            <ColoredBox
              className={clsx('ml-4', classes.tag)}
              type="heartbeat_status"
              value={tag}
              colorTheme={tagTheme[tag]}
            />
          )}
        </Box>

        {subtitle && copySubtitleToClipboard && (
          <TypographyWithCopy
            dataCy="header-subtitle"
            textClassName={classes.subtitle}
            typographyVariant="subtitle1"
            text={renderSubtitle()}
            textToCopy={subtitle}
          />
        )}
        {subtitle && !copySubtitleToClipboard && (
          <Typography
            data-cy="header-subtitle"
            className={clsx(classes.subtitle)}
            variant="subtitle1"
            noWrap
          >
            {renderSubtitle()}
          </Typography>
        )}
      </Box>

      <Box className={clsx('ml-a', classes.extraContainer)}>
        {extraImage && (
          <div>
            <img
              src={extraImage}
              className={classes.extraImage}
              alt="account-logo"
            />
          </div>
        )}
        <Box className={clsx('ml-2')}>
          <Typography variant="subtitle2">{extraTitle}</Typography>
          {extraSubtitle && (
            <TypographyWithCopy
              dataCy="header-extraSubtitle"
              textClassName={classes.subtitle}
              typographyVariant="overline"
              text={extraSubtitle}
            />
          )}
        </Box>
      </Box>
      <div className={classes.actionContainer}>{children}</div>
    </Grid>
  );
};

export default ContentHeader;
